<div class="wen-chat-selection-list-wrapper">
  <wen-list-mass-data-viewer [datasource]="listDatasource">
    <ng-container wen-list-mass-data-empty>
      <wen-empty-list src="/assets/wen-widget/image/empty-user-list.svg"
        [titleLabel]="'SEARCH_USER_LIST_EMPTY_TEXT'"></wen-empty-list>
    </ng-container>
    <ng-template wen-list-mass-data-item let-listItemData let-index="index">
      <ng-container *ngIf="listService.separatorPositions$ | async; let separators">
        <div *ngIf="separators.existingIndex === index" portal-subheading-1 class="wen-chat-selection-list-group-label">
          {{ "SEARCH_USER_LIST_EXISTING_HEADER" | translate }}
        </div>
        <div *ngIf="separators.groupIndex === index" portal-subheading-1 class="wen-chat-selection-list-group-label">
          {{ "SEARCH_USER_LIST_GROUPS_HEADER" | translate }}
        </div>
        <div *ngIf="separators.furtherIndex === index" portal-subheading-1 class="wen-chat-selection-list-group-label">
          {{ "SEARCH_USER_LIST_FURTHER_HEADER" | translate }}
        </div>
      </ng-container>
      <wen-user-list-item [user]="listItemData | toUserListItem" [disabled]="isItemDisabled(listItemData)"
        (click)="handleListItemClick(listItemData)">
        <mat-checkbox *ngIf="!isItemDisabled(listItemData)" wen-user-after [disableRipple]="'true'"
          [checked]="listItemData.selected"></mat-checkbox>
      </wen-user-list-item>
    </ng-template>
  </wen-list-mass-data-viewer>
</div>
import { WenTestIdModule } from '../../directives/test-id/test-id.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '../wen-icon/wen-icon.module';
import { EmojiReactionsComponent } from './emoji-reactions.component';
import { ReactionSelectorComponent } from './components/reaction-selector/reaction-selector.component';
import { EmojiComponent } from './components/emoji.component';
import { ThousandFormatModule } from '../../pipes/thousand-format/thousand-format.module';
import { ContextMenuComponent } from '../context-menu/context-menu.component';
import { ContextMenuTriggerDirective } from '../../services/overlays/openers/context-menu-trigger.directive';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { EmojiAnimationComponent } from './components/emoji-animation/emoji-animation.component';

export function playerFactory() {
  return player;
}


@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    WenIconModule,
    ThousandFormatModule,
    WenTestIdModule,
    ContextMenuComponent,
    ContextMenuTriggerDirective,
    LottieModule.forRoot({
      player: playerFactory
    }),
    EmojiAnimationComponent
  ],
  declarations: [
    EmojiReactionsComponent,
    EmojiComponent,
    ReactionSelectorComponent,
  ],
  exports: [
    EmojiReactionsComponent,
    ReactionSelectorComponent
  ]
})
export class EmojiReactionsModule { }

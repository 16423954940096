import { EmbeddedForwardDTO, EmbedDTOType, EmbedDTOTypes } from '@portal/wen-backend-api';
import { Embed, EmbeddedDocument, EmbeddedExpandable, EmbeddedLink, EmbeddedLocation, EmbeddedMedia, EmbeddedNotification, EmbeddedPoll, EmbeddedWeather } from '../channel.state';

export const isLinkEmbed = (embed: Embed): embed is EmbeddedLink => embed?.type === EmbedDTOType.LINK;
export const isDocumentEmbed = (embed: Embed): embed is EmbeddedDocument => embed?.type === EmbedDTOType.DOCUMENT;
export const isExpandableEmbed = (embed: Embed): embed is EmbeddedExpandable => embed?.type === EmbedDTOType.EXPANDABLE;
export const isPollEmbed = (embed: Embed): embed is EmbeddedPoll => embed?.type === EmbedDTOType.POLL;
export const isMediaEmbed = (embed: Embed): embed is EmbeddedMedia => embed?.type === EmbedDTOType.MEDIA;
export const isLocationEmbed = (embed: Embed): embed is EmbeddedLocation => embed?.type === EmbedDTOType.LOCATION;
export const isWeatherEmbed = (embed: Embed): embed is EmbeddedWeather => embed?.type === EmbedDTOType.WEATHER;
export const isNotificationEmbed = (embed: Embed): embed is EmbeddedNotification => embed?.type === EmbedDTOType.NOTIFICATION;
export const isForwardEmbed = (embed: Embed): embed is EmbeddedForwardDTO => embed?.type === EmbedDTOType.FORWARD;

export const categorizeEmbeds = (embeds: EmbedDTOTypes[] = []) => {
  return {
    mediaEmbed: embeds.find(isMediaEmbed),
    linkEmbed: embeds.find(isLinkEmbed),
    documentEmbed: embeds.find(isDocumentEmbed),
    locationEmbed: embeds.find(isLocationEmbed),
  };
};

import { Injectable } from '@angular/core';
import { EncryptionAlgorithm, EncryptionType, SendToUsersEventPayloadData, ShareKeyContent, ShareKeyEventDTO, ToUsersEventType } from '@portal/wen-backend-api';
import { map, switchMap } from 'rxjs/operators';
import { ChatBackendApi } from '../../../backend-api/backend-api';
import { OlmEncryptionResult } from '../../../decryption/crypto-results';
import { OlmDevice } from '../../../device/olm-device';
import { OutboundGroupSession } from '../../../types';
import { WenChatClient } from '../../../wen-chat-client';

export class EncrypteShareKeyEventResult {

  constructor(
    public readonly encryptionResults: OlmEncryptionResult[],
    private readonly senderCurve25519: string,
    private readonly senderUserId: string,
  ) { }

  asShareKeyPayload(): SendToUsersEventPayloadData[] {
    const shareKeyPayloads = this.encryptionResults.map(encryptionResult => {
      const shareKeyMessage: ShareKeyEventDTO<true> = {
        type: EncryptionType.ENCRYPTED,
        eventType: ToUsersEventType.SHARE_KEY,
        content: {
          algorithm: EncryptionAlgorithm.Olm,
          ciphertext: encryptionResult.encrypted,
          senderKey: this.senderCurve25519,
        },
        senderUserId: this.senderUserId,
      };
      const data: SendToUsersEventPayloadData = {
        payload: shareKeyMessage,
        deviceId: encryptionResult.targetDeviceId,
        userId: encryptionResult.targetUserId
      };
      return data;
    });
    return shareKeyPayloads;
  }

}

@Injectable()
export class ShareKeyEncryptor {

  constructor(
    private chatClient: WenChatClient,
    private olmDevice: OlmDevice,
    private chatBackendApi: ChatBackendApi,
  ) { }

  encryptShareKeyEvent(outboundGroupSession: OutboundGroupSession, roomId: string, userIds: string[]) {
    return this.chatBackendApi.getUserData().pipe(
      switchMap(({ userId, deviceId }) => {
        const { sessionKey, sessionId } = outboundGroupSession;
        const { curve25519: senderKey } = this.olmDevice.getIdentityKeys();
        const shareKeyContent: ShareKeyContent<false> = {
          deviceId,
          sessionKey,
          sessionId,
          roomId
        };
        const message = JSON.stringify(shareKeyContent);
        return this.chatClient.encryptMessageOlm(userIds, message).pipe(
          switchMap((encryptionResults) => {
            return this.chatClient.createInboundGroupSession(roomId, senderKey, sessionKey).pipe(
              map(() => {
                return new EncrypteShareKeyEventResult(
                  encryptionResults, senderKey, userId
                );
              })
            );
          })
        );
      })
    );
  }

}

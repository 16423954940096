import { ViewportRuler } from '@angular/cdk/scrolling';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { optionalDebounce } from './operators/optional-debounce';
import { createResizeObserver } from './resize-observer';

export type ResizeRect = {
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
  readonly x: number;
  readonly y: number;
};

export interface ResizeHandlerConfig {
  /**
   * Debounce time for each emit
   * Providing null or undefined will disable debouncing
   */
  debounceTime?: number | null;
}

export class ResizeHandler {

  private observer: ResizeObserver;

  private onResize = new Subject<ResizeRect>();
  public readonly onResize$: Observable<ResizeRect>;

  constructor(
    userConfig: ResizeHandlerConfig = {}
  ) {
    const defaultConfig: Required<ResizeHandlerConfig> = {
      debounceTime: 20
    };
    const config = { ...defaultConfig, ...userConfig };
    this.onResize$ = this.onResize.pipe(
      optionalDebounce(config.debounceTime),
      shareReplay(1)
    );
  }

  attach(element: HTMLElement) {
    this.observer = createResizeObserver((entries) => {
      const firstEntry = entries[0];
      this.onResize.next(firstEntry.contentRect);
    });
    this.observer.observe(element);
  }

  detach() {
    this.observer?.disconnect();
    this.observer = null;
  }

}

@Injectable()
export class ResizeHandlerProvider {

  private handlers: ResizeHandler[] = [];

  constructor(
    private viewportRuler: ViewportRuler
  ) { }

  create(element: HTMLElement, config?: ResizeHandlerConfig) {
    const handler = new ResizeHandler(config);
    handler.attach(element);
    this.handlers.push(handler);
    return handler;
  }

  observeViewport() {
    return this.viewportRuler.change();
  }

  detach() {
    this.handlers.forEach(handler => handler.detach());
    this.handlers = [];
  }

}

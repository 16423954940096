import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, OverlayManager, WenBreakpointObserver } from '@portal/wen-components';
import { filter, first, withLatestFrom } from 'rxjs/operators';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';
import { logout } from '../../../../../core/store/auth/auth.actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { WenRouteId } from '../../../../../frame/routing/types';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { RegistrationWorkaround } from '../../../common/services/registration-workaround';
import { PhoneNumberValue, phoneControlName } from '../../../common/types/form-types';
import { extractPhoneNumber } from '../../../common/util/phone-utils';
import { availableCountries, initialPhoneValue } from './registration-start-util';

@Component({
  selector: 'wen-registration-start',
  templateUrl: './registration-start.component.html',
  styleUrls: ['./registration-start.component.scss']
})
export class RegistrationStartComponent implements OnInit {

  registrationStartFormGroup = new FormGroup({
    [phoneControlName]: new FormControl(initialPhoneValue)
  });
  availableCountries = availableCountries;

  get phoneControlValue() {
    return this.registrationStartFormGroup.controls[phoneControlName].value as PhoneNumberValue;
  }

  headerGravity = this.breakpointObserver.isDesktopStyleDevice ? 'bottom' : 'top';

  constructor(
    private store: Store<RootState>,
    private translateService: TranslateService,
    private appNavigator: AppNavigator,
    private overlayManager: OverlayManager,
    private socketIoService: SocketIoService,
    private registrationWorkaround: RegistrationWorkaround,
    private breakpointObserver: WenBreakpointObserver,
    private formStoreService: FormStoreService,
  ) { }

  ngOnInit(): void {
    this.formStoreService.initializeForm();
  }

  onSubmit() {
    this.registrationStartFormGroup.updateValueAndValidity();
    if (this.registrationStartFormGroup.valid) {
      const fullPhoneNumber = extractPhoneNumber(this.phoneControlValue);
      const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, {
        header: this.translateService.instant('REGISTRATION_START_CONFIRM_NUMBER_DIALOG_HEADER', { phoneNumber: fullPhoneNumber }),
        content: this.translateService.instant('REGISTRATION_START_CONFIRM_NUMBER_DIALOG_CONTENT'),
        okLabel: this.translateService.instant('REGISTRATION_START_CONFIRM_NUMBER_DIALOG_ACCEPT'),
        dismissLabel: this.translateService.instant('REGISTRATION_START_CONFIRM_NUMBER_DIALOG_DISMISS')
      });
      dialog.afterClosed().pipe(
        first(),
        filter(data => data?.result === 'ok'),
        withLatestFrom(this.registrationWorkaround.withCurrentUser())
      ).subscribe(([_, userId]) => {
        this.socketIoService.user.requestCode.emit({ userId, identifier: fullPhoneNumber });
        this.appNavigator.navigateToRoute(WenRouteId.REGISTRATION_CODE_VERIFICATION);
      });
    }
  }

  onCancel() {
    this.store.dispatch(logout());
  }
}

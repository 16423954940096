import { Injectable } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { ChatBackendApi, GetDeviceKeysParams, GetDeviceKeysResult, GetOneTimekeyParams, GetOneTimeKeyResult, GetUserDataResult, UploadKeysParams, UploadKeysResult } from '@portal/wen-chat-client';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { WenStorageService } from '../storage/wen-storage.service';
import { WenOAuthService } from '../user-management/wen-oauth.service';

@Injectable()
export class WenChatBackendApi extends ChatBackendApi {

  constructor(
    private socketIoService: SocketIoService,
    private oAuthService: WenOAuthService,
    private storageService: WenStorageService,
  ) {
    super();
  }

  getUserData(): Observable<GetUserDataResult> {
    const { userId } = this.oAuthService.getUserData();
    const deviceId = this.storageService.getDeviceId();
    return of({
      userId,
      deviceId
    });
  }

  getOneTimeKey(params: GetOneTimekeyParams): Observable<GetOneTimeKeyResult> {
    return this.socketIoService.chat.claimOneTimeKeys.acknowledgement$({ userIds: [params.userId] }).pipe(
      map((response) => {
        return response?.oneTimeKeys;
      }),
    );
  }

  uploadKeys(params: UploadKeysParams): Observable<UploadKeysResult> {
    return this.socketIoService.chat.uploadKeys.acknowledgement$(params).pipe(
      map(() => ({ success: true }))
    );
  }

  getDeviceKeys(params: GetDeviceKeysParams): Observable<GetDeviceKeysResult> {
    return this.socketIoService.chat.downloadKeys.acknowledgement$({ userIds: params.userIds });
  }

}

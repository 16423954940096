import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { ForwardContext } from '@portal/wen-backend-api';
import { ForwardService } from '../../../../core/services/forward/forward.service';
import { AppNavigator } from '../../../../core/services/navigation/app-navigator';
import { MessageDeepLinkable } from '../../../../core/services/navigation/deep-link/deep-linkables/message-deep-linkable';
import { WenNavigationHelper } from '../../../../core/services/navigation/types';
import { EmbeddedDocument } from '../../../../core/store/channel/channel.state';
import { RootState } from '../../../../core/store/root/public-api';
import { navigateToUserProfile } from '../../../../core/store/user/user.actions';
import { WenRouteId } from '../../../../frame/routing/types';
import { ShareService } from '../../../../shared/channel-specific/providers/share.service';
import { MessageBoxActionsHandler } from '../../../../shared/components/message-box/providers/message-box-tokens';
import { channelMessageDetailIdentifier } from '../../tokens';

@Injectable()
export class ChannelMessageBoxActionsHandler extends MessageBoxActionsHandler {

  protected store = inject(Store<RootState>);
  private appNavigator = inject(AppNavigator);
  private navigationHelper = inject(WenNavigationHelper);
  private forwardService = inject(ForwardService);
  private deepLinkProvider = inject(MessageDeepLinkable);
  private shareService = inject(ShareService);

  handleCommentsClicked(messageId: string): void {
    this.appNavigator.navigateToRoute(WenRouteId.CHANNEL_MESSAGE_DETAIL, {
      [channelMessageDetailIdentifier]: messageId
    });
  }

  handleDocumentClicked(messageId: string, documentModel: EmbeddedDocument): void {
    const { articleId, docId, startPage } = documentModel;
    if (articleId) {
      this.navigationHelper.navigateToTextView({
        articleId
      });
    } else if (docId) {
      this.navigationHelper.navigateToReaderView({
        docId,
        startPage: startPage || 1,
        articleId
      });
    }
  }

  handleSenderClicked(messageId: string, authorId: string): void {
    this.store.dispatch(navigateToUserProfile({ userId: authorId }));
  }

  handleForwardClicked(messageId: string): void {
    const messageDeepLink = this.deepLinkProvider.getDeepLink({ messageId });
    this.forwardService.initiateForward(messageDeepLink, ForwardContext.CHANNEL_MESSAGE, messageId);
  }

  handleShareClicked(messageId: string): void {
    const messageDeepLink = this.deepLinkProvider.getDeepLink({ messageId });
    this.shareService.shareDeepLink(messageDeepLink);
  }

}

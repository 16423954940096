import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { GeoWeatherFeature, GeoWeatherHourly } from '@portal/wen-backend-api';
import { ContextMenuStyleDefinition, toWeatherModel, WeatherModel } from '@portal/wen-components';
import { DateUtil } from '../../../../../../core/common/date/date-util';
import { EmbeddedWeather } from '../../../../../../core/store/channel/channel.state';


const EMBED_WEATHER_ICONS_PATH = '/assets/wen-widget/image/weather/embed';

@Component({
  selector: 'wen-embed-weather',
  templateUrl: './embed-weather.component.html',
  styleUrls: ['./embed-weather.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedWeatherComponent {

  private forecastConfig = {
    amountOfForecasts: 5,
    timeDiffInHrs: 3,
    startTimeInHrs: 8
  };

  @HostBinding('class.wen-embed-weather') className = true;
  weatherViewModel: WeatherModel;
  weatherContextMenuModel: GeoWeatherFeature;
  weatherContextMenuStyle: ContextMenuStyleDefinition;

  @Input() set model(weatherEmbed: EmbeddedWeather) {
    let { weatherData } = weatherEmbed;
    const { hourly } = weatherData.properties;
    const filteredHours = this.getValidHourlyForecasts(hourly);
    this.weatherContextMenuModel = weatherData;
    weatherData = { ...weatherData, properties: { ...weatherData.properties, hourly: filteredHours } };
    this.weatherViewModel = toWeatherModel(weatherData, EMBED_WEATHER_ICONS_PATH);
    const { background } = this.weatherViewModel;
    const { gradient: { start, end } } = background;
    this.weatherContextMenuStyle = {
      styleName: 'background',
      styleValue: `linear-gradient(to right, ${start}, ${end})`
    };
  }

  private getValidHourlyForecasts(forecasts: GeoWeatherHourly[]) {
    let baseTimestamp = null;
    return forecasts.filter((forecast) => {
      const forecastDate = DateUtil.fromIsoString(forecast.timestamp);
      return forecastDate.hour >= this.forecastConfig.startTimeInHrs;
    }).reduce((finalForecasts, currentForecast) => {
      const isLessThanAmountLimit = finalForecasts.length < this.forecastConfig.amountOfForecasts;
      const hrsDiff = DateUtil.getDiffOfIsoStrings(currentForecast.timestamp, baseTimestamp).hours;
      const isValidForecastHour = !baseTimestamp || hrsDiff === this.forecastConfig.timeDiffInHrs;
      if (isLessThanAmountLimit && isValidForecastHour) {
        baseTimestamp = currentForecast.timestamp;
        finalForecasts.push(currentForecast);
      }
      return finalForecasts;
    }, [] as GeoWeatherHourly[]);
  }

}

import { Component, ContentChild, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { MenuEntryDescriptionDirective } from './menu-entry-directives';

@Component({
  selector: 'wen-menu-entry',
  templateUrl: './menu-entry.component.html',
  styleUrls: ['./menu-entry.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuEntryComponent {

  @HostBinding('class.wen-menu-entry') className = true;
  @Input() @HostBinding('class.wen-menu-entry-flat') isFlat: boolean;

  @Input() @HostBinding('class.wen-menu-entry-not-interactive') disableArrow: boolean;
  @ContentChild(MenuEntryDescriptionDirective, { static: false }) menuEntryDescription: MenuEntryDescriptionDirective;

}

<div class="we-feed-scroller" cdkScrollable #weFeedScroller tabindex="-1" wenIosViewportFix
  (afterViewportChange)="handleAfterViewportChange()">
  <ng-container *ngIf="hasItems$ | async; else emptyList">
    <ng-container *ngIf="feedItems$ | async as feedItems">
      <div class="we-feed-messages-wrapper" #wrapper [class.we-feed-messages-wrapper-flow-up]="flowDirection === 'up'">
        <div class="we-feed-message-item" @fadeOutOnLeave
          [class.we-feed-message-item-hidden]="feedLayoutCommunicator.isRenderingInProgressFor(feedItem.key)"
          *ngFor="let feedItem of feedItems; trackBy: trackByFn; let i = index"
          [wenContentBoundary]="{ maxWidth: messageMaxWidth, paddingCorrection: 16 }">
          <ng-container *ngIf="i === 1">
            <ng-content select="wen-messages-view-before-content, [wen-messages-view-before-content]"></ng-content>
          </ng-container>
          <ng-container *ngIf="feedItem.value as message">
            <ng-container [ngSwitch]="feedItem.type">
              <wen-message-box *ngSwitchCase="'MESSAGEBOX'" #wenMessageBox observeVisibility
                [rootElement]="weFeedScroller" (visible)="onMessageVisible(message)"
                [isActive]="!feedLayoutCommunicator.isRenderingInProgress()"
                [message]="message" [sender]="message.sender"
                [content]="message.content" [scheduled]="message.scheduled" [embeds]="message.embeds"
                [timestamp]="message | messageTimestamp" [modificationState]="message.modificationState"
                [messageId]="message.messageId"
                [referenceId]="message.referenceId"[status]="message.status"
                [highlighted]="message.highlighted"
                [commentsVisible]="message.commentsVisible" [commentsEnabled]="message.commentsEnabled"
                [shareVisible]="message.shareVisible"
                [reactionContext]="reactionContext"
                [disableEmojiReaction]="message.disableEmojiReaction"
                [hideEmojiReactionPlaceholder]="message.hideEmojiReactionPlaceholder"
                [textToSpeechEnabled]="message.textToSpeechEnabled">
                <ng-container *ngIf="boxContent?.templateRef" ngProjectAs="wen-message-box-content-template"
                  [ngTemplateOutlet]="boxContent.templateRef"
                  [ngTemplateOutletContext]="{ data: message }"></ng-container>
                <ng-container *ngIf="customContextMenuContent?.templateRef" ngProjectAs="wen-message-box-context-menu-template"
                  [ngTemplateOutlet]="customContextMenuContent.templateRef"
                  [ngTemplateOutletContext]="{ data: message, reference: wenMessageBox }"></ng-container>
                <ng-container *ngIf="customReactionContextMenu?.templateRef" ngProjectAs="wen-message-box-reaction-context-menu-template"
                  [ngTemplateOutlet]="customReactionContextMenu.templateRef"
                  [ngTemplateOutletContext]="{ data: message, reference: wenMessageBox }"></ng-container>
              </wen-message-box>
              <div *ngSwitchCase="'CUSTOM_ITEM_TYPE'" observeVisibility (visible)="onMessageVisible(message)">
                <ng-container  [ngTemplateOutlet]="customItemContent.templateRef"
                  [ngTemplateOutletContext]="{ data: message, maxWidth: messageMaxWidth }">
                </ng-container>
              </div>
              <wen-separator *ngSwitchCase="'SEPARATOR'" [displayData]="feedItem.value"></wen-separator>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #emptyList>
    <ng-content select="wen-we-feed-empty-placeholder"></ng-content>
  </ng-template>
</div>
<ng-container *ngIf="isScrollToBottomButtonVisible$ | async">
  <ng-content select="wen-scroll-to-button"></ng-content>
</ng-container>

import { Injectable, OnDestroy } from '@angular/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { Subject } from 'rxjs';
import { filter, first, map, shareReplay, startWith, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { createTimer } from '../../../../../core/common/util/create-timer';
import { RegistrationWorkaround } from '../../../common/services/registration-workaround';

const REGISTRATION_CODE_COOLDOWN_SEC = 60;

@Injectable()
export class RegistrationCodeResendService implements OnDestroy {

  private onDestroy = new Subject<void>();
  private disable = new Subject<void>();
  private resend = new Subject<string>();

  public readonly remaining$ = this.disable.pipe(
    switchMap(() => createTimer(REGISTRATION_CODE_COOLDOWN_SEC)),
    startWith(0),
    shareReplay(1)
  );
  public readonly isDisabled$ = this.remaining$.pipe(
    map((remaining) => remaining > 0),
    shareReplay(1)
  );

  constructor(
    private socketIoService: SocketIoService,
    private registrationWorkaround: RegistrationWorkaround,
  ) {
    this.resend.pipe(
      withLatestFrom(this.isDisabled$),
      filter(([_, isDisabled]) => !isDisabled),
      switchMap(([phoneNumber, _]) => {
        return this.registrationWorkaround.withCurrentUser().pipe(
          map((userId) => ({ userId, phoneNumber })),
          first()
        );
      }),
      takeUntil(this.onDestroy)
    ).subscribe(({ userId, phoneNumber }) => {
      this.socketIoService.user.requestCode.emit({ userId, identifier: phoneNumber });
      this.disable.next();
    });
  }

  tryResend(phoneNumber: string) {
    this.resend.next(phoneNumber);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}

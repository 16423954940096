import { Pipe, PipeTransform } from '@angular/core';
import { ActionMenuItemProvider } from '../../core/services/util/action-menu-item.provider';
import { MessageBoxModel } from '../components/feed/components/we-feed/we-feed-model';

@Pipe({
  name: 'visibilityForActionMenu',
  standalone: true
})
export class VisibilityForActionMenuPipe implements PipeTransform {

  constructor(private actionMenuItemProvider: ActionMenuItemProvider) {

  }

  transform(data: MessageBoxModel, actionMenuId: string) {
    return this.actionMenuItemProvider.selectActions(actionMenuId, data);
  }

}

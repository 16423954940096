import { Injectable } from '@angular/core';
import { createEffect } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService, UserProfileResponse } from '@portal/wen-backend-api';
import { filter, first, of, switchMap, tap } from 'rxjs';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { DateUtil } from '../../../common/date/date-util';
import { DataObjectType } from '../../common/data-objects';
import { doBackNavigation } from '../../header/header.actions';
import { RootState } from '../../root/public-api';
import { updateUserPrimaryLanguage } from '../../user/user.actions';
import { selectUserProfile } from '../../user/user.selectors';
import { clearFormValues } from '../form.actions';
import { selectEditFormById } from '../form.selectors';
import { LanguageFormChangedValues } from '../types/language-types';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { PermissionLevel } from '../../../services/user-management/permission-level';
import { WenStorageService } from '../../../services/storage/wen-storage.service';

@Injectable()
export class LanguageFormEffects {
  saveLanguageForm$ = this.formStoreMediator.createSaveEditFormEffect((saveAction) => of(saveAction).pipe(
    filter(action => action.dataObjectType === DataObjectType.LANGUAGE),
    switchMap(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first()
    )),
    switchMap((editForm) => {
      const { formId, changedValues } = editForm;
      const actions: Action[] = [];
      const changedValuesTyped = changedValues as LanguageFormChangedValues;
      const languageCode = changedValuesTyped.primaryLanguage;
      this.socketIoService.user.language.primary.emit({ languageCode });
      const isAnon = this.oAuthService.getUserData()?.permissionLevel === PermissionLevel.ANONYMOUS;

      if (isAnon) {
        // If user is anon we just update the key in local storage
        this.storageService.setLanguage(languageCode);
        this.translateService.use(languageCode);
      } else {
        actions.push(updateUserPrimaryLanguage({ languageCode }));
      }
      actions.push(clearFormValues({ formId }));
      actions.push(doBackNavigation());
      return actions;
    })
  ));

  onLanguageChange$ = createEffect(() => this.store.pipe(
    select(selectUserProfile),
    filter(v => !!v),
    tap((userProfile: UserProfileResponse) => {
      const language = userProfile?.primaryLanguage || 'de';
      this.translateService.use(language);
      DateUtil.setLocale(language);
    })
  ), { dispatch: false });

  constructor(
    private formStoreMediator: FormStoreMediator,
    private store: Store<RootState>,
    private socketIoService: SocketIoService,
    private translateService: TranslateService,
    private oAuthService: WenOAuthService,
    private storageService: WenStorageService
  ) { }
}

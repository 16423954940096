import { WenRoutes } from '../../../frame/routing/types';
import { licensesRoute, otherProfileViewRoute, userProfileChangeEmailRoute, userProfileComingSoonRoute, userProfileEditRoute, userProfileEmailSettingsRoute, userProfileManifestRoute, userProfileFAQRoute, userProfileReadRoute, userProfileCredentialsMenuRoute, userProfileCredentialDetails, userProfileSettingsRoute, userProfileViewRoute, userProfileHelpRoute, userProfileMarkdownFAQRoute, userProfileHotlineRoute, userProfileLanguageRoute, userProfilePrivacySettingsRoute } from './user-profile.routes';

export const singleOutletUserProfileRoutes: WenRoutes = [
  userProfileViewRoute,
  userProfileManifestRoute,
  userProfileFAQRoute,
  userProfileMarkdownFAQRoute,
  userProfileComingSoonRoute,
  userProfileReadRoute,
  userProfileEditRoute,
  userProfileLanguageRoute,
  userProfileHelpRoute,
  userProfileHotlineRoute,
  licensesRoute,
  userProfileEmailSettingsRoute,
  userProfileChangeEmailRoute,
  userProfileSettingsRoute,
  userProfileCredentialsMenuRoute,
  userProfileCredentialDetails,
  userProfilePrivacySettingsRoute,
  otherProfileViewRoute
];

<form wenFormStore [formGroup]="chatInfoFormGroup" class="wen-form" *ngIf="currentChat$ | async as chat">
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper wen-image-upload">
      <wen-image-upload [formControlName]="'icon'" [imageShape]="'circle'"
        [fallbackText]="imageFallbackText"></wen-image-upload>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'GROUP_CHAT_FORM_TITLE_LABEL' | translate }}</mat-label>
        <portal-field [formControlName]="'title'" [autocomplete]="'off'" [valueAdapter]="fieldValueAdapter"
        [required]="true" wenIosKeyboardStickyTarget></portal-field>
        <mat-error portal-caption>{{ "GROUP_CHAT_FORM_TITLE_ERROR" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'GROUP_CHAT_FORM_DESCRIPTION_LABEL' | translate }}</mat-label>
        <textarea
          [formControlName]="'description'"
          matInput
          autocomplete="off"
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
          wenIosKeyboardStickyTarget></textarea>
      </mat-form-field>
    </div>
  </div>
  <div class="wen-form-actions" *ngIf="canDeleteChat$ | async">
    <wen-interact-button class="wen-interact-button-menu-style" (click)="deleteChat(chat.id)">{{
      "GROUP_CHAT_DELETE_BUTTON_LABEL" |
      translate }}</wen-interact-button>
  </div>
</form>

<div class="wen-pending-join-request-container" *ngIf="pendingJoinRequests$ | async; let pendingJoinRequests">
  <div portal-subheading-2>{{ "CHANNEL_PENDING_JOIN_REQUESTS_LIST_LABEL" | translate }}</div>
  <ng-container *ngIf="pendingJoinRequests.length; else noPendingJoinRequests">
    <wen-user-list-item *ngFor="let joinRequest of pendingJoinRequests"  [user]="joinRequest.user" [userNavigable]="joinRequest.user.userId">
        <div class="wen-list-item-after" wen-user-after>
          <wen-icon (click)="acceptRequest(joinRequest.id, joinRequest.user.displayName)" class="wen-icon-medium" iconName="checkmark"></wen-icon>
          <wen-icon (click)="denyRequest(joinRequest.id, joinRequest.user.displayName)" class="wen-icon-medium" iconName="close"></wen-icon>
        </div>
    </wen-user-list-item>
  </ng-container>
</div>
<ng-template #noPendingJoinRequests>
  <img src="/assets/wen-widget/image/empty-placeholders/pic-empty-list-wen-sitting-robot.svg"/>
</ng-template>

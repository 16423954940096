<wen-form-progress [hasProgress]="hasProgress$ | async"></wen-form-progress>
<wen-progressive-slot class="wen-chat-creation-slot-wrapper" [visibilityCondition]="true" wenFocusFirstInput wenIosViewportFix>
  <div class="wen-chat-creation-wrapper">
    <wen-input-with-chips cdkScrollable tabindex="-1" wenIosKeyboardScrollable
      [prefixTranslation]="'SEARCH_USER_TO_CHAT_SEARCH_PREFIX'"
      [placeholderTranslation]="'SEARCH_USER_TO_CHAT_SEARCH_PLACEHOLDER'" [selectedItems]="selectedChips$ | async"
      [maxNumberOfSelectableItems]="maxNumberOfSelectableUsers" (searchTermChange)="onSearchChange($event)"
      (itemRemove)="onItemRemove($event)" [itemCount]="selectedChipsCount$ | async">
    </wen-input-with-chips>
    <div class="wen-chat-creation-list-container" tabindex="-1" portalDragOverlay [overlayContent]="overlayContent"
      (fileDropped)="onFilesDropped($event)">
      <wen-chat-selection-list [selectionModeEnabled]="selectionModeEnabled$ | async"
        (listItemClicked)="handleListItemClick($event)"></wen-chat-selection-list>
    </div>
    <ng-container *ngIf="showForwardButton$ | async; else sendField">
      <wen-button *ngIf="(selectedChipsCount$ | async) > 0" class="wen-button-round wen-forward" wenVariant="iconPrimary"
        iconName="send" iconSize="very-big" iconColor="white" (click)="forward()">
      </wen-button>
    </ng-container>
  </div>
</wen-progressive-slot>
<ng-template #sendField>
  <wen-chat-message-send-field class="wen-chat-creation-bottom-controls"
  [keepInputFocused]="false"></wen-chat-message-send-field>
</ng-template>
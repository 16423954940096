<wen-we-feed (scrolledToBottom)="enableAutoAcknowledge()"
  [flowDirection]="flowDirection" [flowStyle]="'alternating'"
  [reactionContext]="reactionContext"
  [messageMaxWidth]="300">
  <ng-template wen-message-box-content-template let-data="data">
    <wen-chat-message-content (requestShareKey)="onRequestShareKeyClicked()" [message]="data"></wen-chat-message-content>
  </ng-template>
  <ng-template we-feed-custom-context-menu-template let-data="data" let-actions="actions">
    <wen-reaction-selector *ngIf="!disableEmojiReaction" class="wen-huge-emojis" [data]="toReactionSelectorData(data)"></wen-reaction-selector>
    <wen-action-menu [actions]="data | visibilityForActionMenu: contextMenuId | async" [data]="data"></wen-action-menu>
  </ng-template>
  <ng-template we-feed-custom-reaction-context-menu-template let-data="data">
    <wen-reaction-selector class="wen-huge-emojis" [data]="toReactionSelectorData(data)"></wen-reaction-selector>
  </ng-template>
  <ng-container ngProjectAs="wen-we-feed-empty-placeholder">
    <ng-container *ngTemplateOutlet="emptyPlaceholder" ngProjectAs="wen-we-feed-empty-placeholder"></ng-container>
  </ng-container>
  <wen-scroll-to-button *ngIf="!disableScrollToBottomButton" (scrolledTo)="onScrollToBottomClicked()"></wen-scroll-to-button>
</wen-we-feed>

<ng-template #emptyPlaceholder>
  <ng-content select="wen-we-feed-empty-placeholder"></ng-content>
  <wen-we-feed-empty-placeholder *ngIf="isGroupChat" [text]="'GROUP_CHAT_NO_MESSAGE_TITLE' | translate"
    [imageUrl]="'/assets/wen-widget/image/pic-group-chat-success-120.svg'"></wen-we-feed-empty-placeholder>
</ng-template>

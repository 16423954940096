import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormGroupDirective, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'wen-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  standalone: true,
  imports: [MatSlideToggleModule, ReactiveFormsModule, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => WenSlideToggleComponent),
    },
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class WenSlideToggleComponent implements ControlValueAccessor {

  @HostBinding('class.wen-slide-toggle') className = true;

  @Input() forFormControlName: string;

  constructor() { }

  onChange = (value: boolean) => { };
  onTouched = () => { };

  writeValue(value: number): void {
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void { }

}

<ng-container [formGroup]="parentGroup">
  <mat-form-field
    class="wen-location-selector-field"
    (click)="openLocationSelector()"
  >
    <mat-label>{{ context.label }}</mat-label>
    <portal-field
      [formControlName]="'address'"
      [readonly]="true"
      autocomplete="'off'"
    ></portal-field>
    <wen-icon
      class="wen-selector-address-field-icon wen-icon-medium"
      *ngIf="!hasAddress"
      matSuffix
      [iconName]="'add_location'"
    ></wen-icon>
    <wen-icon
      class="wen-selector-address-field-icon wen-icon-medium"
      *ngIf="hasAddress"
      matSuffix
      [iconName]="'close'"
      (click)="clearLocation($event)"
    ></wen-icon>
  </mat-form-field>
  <div
    class="wen-current-location-picker"
    wen-primary-color
    (click)="useCurrentLocation()"
  >
    <wen-icon
      class="wen-icon-medium"
      wen-primary-color
      iconName="current_location"
    ></wen-icon>
    <span>{{
      "GEO_FILTER_DIALOG_CURRENT_LOCATION_BUTTON_LABEL" | translate
    }}</span>
  </div>
</ng-container>

<ng-container *ngIf="model">
  <ng-container *ngIf="model.subType === 'issue' else articleView">
    <wen-thumbnail-image *ngIf="model.thumbnail; let thumbnail" [thumbnail]="thumbnail" (clicked)="navigateToDocument()"
                         class="wen-thumbnail-image-issue"></wen-thumbnail-image>
    <div class="wen-embedded-document-text-wrapper">
      <div portal-title class="wen-embed-document-title">{{ model.title }}</div>
      <div *ngIf="model.description" portal-subheading-1 class="wen-embed-document-description">{{ model.description }}</div>
    </div>
    <div class="wen-embedded-document-wrapper">
      <div portal-body-2 class="wen-embed-document" (click)="navigateToDocument()">
        {{ 'EMBED_READ_ISSUE' | translate }}
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #articleView>
  <div class="wen-document-thumbnail-container" [style.height.px] = "thumbnail.height" *ngIf="model.thumbnail; let thumbnail">
    <div class="wen-document-background-image" [style.background-image]="'url(' + thumbnail.url + ')'" ></div>
    <wen-thumbnail-image [thumbnail]="thumbnail" [style.width.px]="thumbnail.width" [disableScaling]= true (clicked)="navigateToDocument()"></wen-thumbnail-image>
  </div>
  <div class="wen-embedded-document-text-wrapper">
    <div portal-title class="wen-embed-document-title">{{ model.title }}</div>
    <div *ngIf="model.description" portal-subheading-1 class="wen-embed-document-description">{{ model.description }}</div>
  </div>
  <div class="wen-embedded-document-wrapper">
    <div portal-body-2 class="wen-embed-document" (click)="navigateToDocument()">
      {{ 'EMBED_READ_ARTICLE' | translate }}
    </div>
  </div>
</ng-template>

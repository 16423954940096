import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BroadcastInformation, ReactionContext } from '@portal/wen-backend-api';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { AncestorReference, ReactionHandler } from '../../providers/reaction-handler';

export interface ReactionSelectorData {
  reactionParentId: string;
  reactionParentType: ReactionContext;
  broadcastInformation?: BroadcastInformation;
  possibleBlockingAncestor?: AncestorReference;
}

@Component({
  selector: 'wen-reaction-selector',
  templateUrl: './reaction-selector.component.html',
  styleUrls: ['./reaction-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReactionSelectorComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private reactionSelectorData$ = new BehaviorSubject<ReactionSelectorData>(null);

  availableEmojiIds$: Observable<number[]>;
  currentlySelectedEmojiId: number;

  @Input() set data(value: ReactionSelectorData) {
    this.reactionSelectorData$.next(value);
  }

  constructor(
    private reactionHandler: ReactionHandler,
  ) { }

  ngOnInit(): void {
    this.availableEmojiIds$ = this.reactionHandler.getAvailableEmojiIds();
    this.reactionSelectorData$.pipe(
      map(value => value?.reactionParentId),
      distinctUntilChanged(),
      switchMap(reactionParentId => {
        return this.reactionHandler.getReactionSummaryFor(reactionParentId).pipe(
          filter(summary => !!summary),
        );
      }),
      map((reactionSummary) => {
        return reactionSummary.currentUsersSelection;
      }),
      takeUntil(this.onDestroy$)
    ).subscribe((selectedEmojiId) => {
      this.currentlySelectedEmojiId = selectedEmojiId;
    });
  }

  onReaction(clickedEmojiId: number) {
    const {
      reactionParentId,
      reactionParentType,
      broadcastInformation,
      possibleBlockingAncestor
    } = this.reactionSelectorData$.getValue();

    const emojiId = clickedEmojiId === this.currentlySelectedEmojiId ? null : clickedEmojiId;
    this.reactionHandler.sendUserReaction(emojiId, reactionParentId, reactionParentType, broadcastInformation, possibleBlockingAncestor);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EncryptedMessageEventResponses, EncryptionAlgorithm, EncryptionType, InsertUser, isEncryptedSendMessageEventPayload, MessageEvent, MessageModificationState, SendMessageContent, ToRoomEventType } from '@portal/wen-backend-api';
import { ChatTracer, MegolmSessionNotFoundError, WenChatClient } from '@portal/wen-chat-client';
import { catchError, map, Observable, of, retry, switchMap } from 'rxjs';
import { isNullOrUndefined } from '../../../common/operators/null-check-util';
import { ChatMessageEntity } from '../../../store/chat/chat.state';
import { requestExchangeInboundGroupSession } from '../../../store/chat/key-actions';
import { ChatNotificationEventEntity } from '../../../store/notification/notification.state';
import { RootState } from '../../../store/root/public-api';
import { isEditEvent, isRealtimeEditEvent, isRedactEvent } from '../message-event/message-event-helper';
import { redactMessage } from '../message-event/modifiers/redact';
import { DecryptedMessageResultWrapper } from './decryption-result-base';
import { FailedDecryptionHandler } from './failed-decryption-handler';

export type DecryptedMessageResultProps = {
  eventId: string;
  isNew: boolean;
  insertTimestamp: string;
  insertUser: InsertUser;
};

export enum ChatMessageDecryptionError {
  UNKNOWN = 'UNKNOWN',
  NO_KEY_FOUND = 'NO_KEY_FOUND',
}

export class DecryptedMessageResult extends DecryptedMessageResultWrapper<ChatMessageEntity> {

  constructor(
    private readonly decryptedContent: SendMessageContent<false>,
    private readonly originalEvent: MessageEvent<EncryptedMessageEventResponses>,
    private readonly encryptedMessage: EncryptedMessageEventResponses,
    private readonly resultProps: DecryptedMessageResultProps,
    private readonly decryptionError?: ChatMessageDecryptionError,
  ) {
    super();
  }

  get props(): DecryptedMessageResultProps {
    return this.resultProps;
  }

  getDecryptedContent(): SendMessageContent<false> {
    return this.decryptedContent;
  }

  getDecryptionError(): ChatMessageDecryptionError {
    return this.decryptionError;
  }

  asChatStoreEntity() {
    const { eventId, isNew, insertTimestamp, insertUser } = this.props;
    const { message: { id, embeds, content } } = this.decryptedContent;
    const messageState = this.setMessageState(this.originalEvent);
    const messageEntity: ChatMessageEntity = {
      id: eventId || id,
      new: isNew,
      eventId,
      encryptionData: {
        originalEvent: this.originalEvent,
        encryptedMessage: this.encryptedMessage,
        roomId: this.encryptedMessage.roomId
      },
      insertUser,
      messageContent: {
        userId: insertUser.id,
        content,
        embeds
      },
      ...!isNullOrUndefined(insertTimestamp) && { insertTimestamp },
      decryptionError: this.decryptionError,
      state: messageState
    };
    return messageEntity;
  }

  asChatNotificationEntity() {
    const { eventId, insertUser } = this.props;
    const chatNotificationEventEntity: ChatNotificationEventEntity = {
      originalEvent: this.originalEvent,
      decryptedEvent: this.decryptedContent,
      decryptionError: this.decryptionError,
      id: eventId,
      insertUser,
      redacted: isRedactEvent(this.originalEvent)
    };
    return chatNotificationEventEntity;
  }

  private setMessageState(event: MessageEvent<EncryptedMessageEventResponses>): MessageModificationState {
    if (this.decryptionError) {
      return MessageModificationState.ERROR;
    }
    if (isRedactEvent(event)) {
      return MessageModificationState.DELETED;
    }
    if (isEditEvent(event) || isRealtimeEditEvent(event)) {
      return MessageModificationState.EDITED;
    }
    return MessageModificationState.ORIGINAL;
  }

}

export interface MessageDecryptParams {
  eventId: string;
  isNew: boolean;
  insertTimestamp: string;
  insertUser: InsertUser;
  encryptedMessage: EncryptedMessageEventResponses;
}

@Injectable()
export class MessageDecryptor {

  constructor(
    private store: Store<RootState>,
    private chatClient: WenChatClient,
    private failedDecryptionHandler: FailedDecryptionHandler,
    private chatTracer: ChatTracer,
  ) { }

  decryptRoomEventWithRetry(originalEvent: MessageEvent<EncryptedMessageEventResponses>): Observable<DecryptedMessageResult> {
    return of(originalEvent).pipe(
      switchMap(() => {
        return this.tryDecryptRoomEvent(originalEvent);
      }),
      retry({ count: 3, delay: 200 }),
      switchMap(decryptedScheduledMessageResult => {
        if (isEncryptedSendMessageEventPayload(originalEvent)) {
          const megolmSessionId = originalEvent.payload.content.sessionId;
          this.failedDecryptionHandler.unregisterFailedDecryption(megolmSessionId);
        }
        return of(decryptedScheduledMessageResult);
      }),
      this.addErrorHandler(originalEvent),
    );
  }

  private tryDecryptRoomEvent(
    originalEvent: MessageEvent<EncryptedMessageEventResponses>,
  ): Observable<DecryptedMessageResult> {
    const { payload: { content }, eventId, new: isNew, insertUser } = originalEvent;
    if (!this.isSupportedEvent(originalEvent)) {
      return of(null);
    }
    const { payload: { content: { senderKey, sessionId } }, insertTimestamp } = originalEvent;
    if (isRedactEvent(originalEvent)) {
      const redactProps: DecryptedMessageResultProps = {
        eventId: originalEvent.eventId,
        insertTimestamp: originalEvent.insertTimestamp,
        insertUser: originalEvent.insertUser,
        isNew: originalEvent.new
      };
      return of(this.convertToResult(originalEvent, JSON.stringify(redactMessage(originalEvent)), originalEvent.payload, redactProps));
    }
    return this.chatClient.decryptGroupMessage(sessionId, senderKey, content.ciphertext).pipe(
      map((result) => this.convertToResult(
        originalEvent,
        result.decrypted.plaintext,
        originalEvent.payload,
        { eventId, isNew, insertTimestamp, insertUser })
      )
    );
  }

  decryptExchangedRoomMessage(messageData: MessageDecryptParams): Observable<DecryptedMessageResult> {
    const { encryptedMessage, insertTimestamp, eventId, isNew, insertUser } = messageData;
    const { content: { senderKey, sessionId, ciphertext } } = encryptedMessage;
    return this.chatClient.decryptGroupMessage(sessionId, senderKey, ciphertext).pipe(
      map((result) => this.convertToResult(
        null,
        result.decrypted.plaintext,
        encryptedMessage,
        { eventId, isNew, insertTimestamp, insertUser }
      )),
      catchError((error) => {
        const decryptionError = error instanceof MegolmSessionNotFoundError ?
          ChatMessageDecryptionError.NO_KEY_FOUND : ChatMessageDecryptionError.UNKNOWN;
        return of(this.createErrorResult(
          null, // For exchanged sessions the original event is not known!
          encryptedMessage,
          { eventId, isNew, insertTimestamp, insertUser },
          insertTimestamp, decryptionError
        ));
      })
    );
  }

  private convertToResult(
    originalEvent: MessageEvent<EncryptedMessageEventResponses>,
    decrypted: string,
    encryptedMessage: EncryptedMessageEventResponses,
    props: DecryptedMessageResultProps
  ) {
    const decryptedContent: SendMessageContent<false> = JSON.parse(decrypted);
    if (!decryptedContent.message) {
      throw new Error();
    }
    if (originalEvent.insertTimestamp) {
      decryptedContent.message.timestamp = decryptedContent.message.timestamp || props.insertTimestamp;
    }
    return new DecryptedMessageResult(decryptedContent, originalEvent, encryptedMessage, props);
  }

  private addErrorHandler(
    originalEvent: MessageEvent<EncryptedMessageEventResponses>
  ) {
    return (source$: Observable<DecryptedMessageResult>) => {
      return source$.pipe(
        catchError((error) => {
          const { eventId, new: isNew, insertUser, payload, insertTimestamp, roomId } = originalEvent;
          const { content, content: { senderKey, sessionId } } = payload;
          const decryptionError = error instanceof MegolmSessionNotFoundError ?
            ChatMessageDecryptionError.NO_KEY_FOUND : ChatMessageDecryptionError.UNKNOWN;

          this.failedDecryptionHandler.registerFailedDecryption(content);
          this.chatTracer.captureException(error, roomId, insertTimestamp);
          if (decryptionError === ChatMessageDecryptionError.NO_KEY_FOUND) {
            this.store.dispatch(requestExchangeInboundGroupSession({ roomId, sessionId, senderKey }));
          }
          return of(this.createErrorResult(
            originalEvent,
            payload,
            { eventId, isNew, insertTimestamp, insertUser },
            insertTimestamp, decryptionError
          ));
        }),
      );
    };
  }

  private createErrorResult(
    originalEvent: MessageEvent<EncryptedMessageEventResponses>,
    encryptedMessage: EncryptedMessageEventResponses,
    props: DecryptedMessageResultProps,
    insertTimestamp: string,
    decryptionError: ChatMessageDecryptionError
  ) {
    const decryptedContent = {
      message: {
        id: props.eventId,
        timestamp: insertTimestamp,
      }
    };
    return new DecryptedMessageResult(decryptedContent, originalEvent, encryptedMessage, props, decryptionError);
  }

  private isSupportedEvent(event: MessageEvent<EncryptedMessageEventResponses>) {
    return event.payload.type === EncryptionType.ENCRYPTED &&
      event.payload.content.algorithm === EncryptionAlgorithm.Megolm &&
      event.payload.eventType === ToRoomEventType.SEND_MESSAGE
      || isRedactEvent(event) || isRealtimeEditEvent(event);
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SimpleUserDTO } from '@portal/wen-backend-api';
import { Observable, Subject, distinctUntilChanged, map, shareReplay, switchMap, takeUntil, withLatestFrom } from 'rxjs';
import { equalsIgnoreCase } from '../../../../../core/common/operators/null-check-util';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { ActionMenuItemProvider } from '../../../../../core/services/util/action-menu-item.provider';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { selectChannelPermission, selectCurrentChannel } from '../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { ColorPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { channelViewIdentifier } from '../../../tokens';
import { channelAdminContextMenuId } from './providers/action-menu/channel-admin-list-actions';
import { ChannelAdminListDatasource } from './providers/channel-admin-list.datasource';

export interface AdminListActionPayload {
  userId: string;
  isCurrentUser: boolean;
  isLastAdmin: boolean;
}

@Component({
  selector: 'wen-channel-admin-list',
  templateUrl: './channel-admin-list.component.html',
  styleUrls: ['./channel-admin-list.component.scss'],
  providers: [ChannelAdminListDatasource, ActionMenuItemProvider]
})
export class ChannelAdminListComponent implements OnInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  adminList$: Observable<{
    user: SimpleUserDTO; hasMenu: boolean;
    payload: AdminListActionPayload;
  }[]>;
  canAddAdmins$: Observable<boolean>;
  actions$ = this.actionMenuItemProvider.selectActions(channelAdminContextMenuId).pipe(
    shareReplay(1)
  );

  get addAdminMenuEntryFillColor(): ColorPalette {
    return ColorPalette.RED;
  }

  get contextMenuOpenerFillColor(): ColorPalette {
    return ColorPalette.GREY;
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly adminListDatasource: ChannelAdminListDatasource,
    private activatedRoute: ActivatedRoute,
    private actionMenuItemProvider: ActionMenuItemProvider
  ) { }

  ngOnInit(): void {
    const currentChannelId$ = this.store.pipe(
      select(selectCurrentChannel),
      map(channel => channel?.id),
      distinctUntilChanged(),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );
    const currentUserId$ = this.store.pipe(
      select(selectCurrentUserData),
      map((userData) => userData.userId.toLowerCase()),
      shareReplay(1),
    );
    this.adminList$ = this.adminListDatasource.admins$.pipe(
      withLatestFrom(currentUserId$, this.actions$),
      map(([users, currentUserId, actions]) => {
        if (!users?.length) {
          return [];
        }
        return users.map(user => {
          const payload: AdminListActionPayload = {
            userId: user.userId,
            isCurrentUser: equalsIgnoreCase(user.userId, currentUserId),
            isLastAdmin: users.length === 1
          };
          return { user, hasMenu: Boolean(actions?.length), payload };
        });
      }),
      map(users => users.sort(({ user: user1 }, { user: user2 }) => user1.displayName.localeCompare(user2.displayName)))
    );
    this.canAddAdmins$ = currentChannelId$.pipe(
      switchMap((channelId) => this.store.pipe(
        selectorWithParam(selectChannelPermission, channelId),
        takeUntil(this.onDestroy$)
      )),
      map(permission => permission?.canAddAdmins),
    );
  }

  navigateToAddAdminPage() {
    const channelId = this.activatedRoute.snapshot.params[channelViewIdentifier];
    this.navigationHelper.navigateToAddAdminPage(channelId);
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

import { AsyncPipe, NgIf } from '@angular/common';
import { Component, Input, Optional, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import { CoreComponentsModule } from '../../../core/core-components.module';
import { OverlayVisibilityService } from '../../../lightbox-dialog/providers/overlay-visibility.service';
import { VideoHideDelayService } from '../../providers/video-hide-delay.service';

@Component({
  selector: 'wen-mute',
  templateUrl: './mute.component.html',
  styleUrls: ['./mute.component.scss'],
  standalone: true,
  imports: [NgIf, AsyncPipe, CoreComponentsModule],
  providers: [
    VideoHideDelayService
  ],
})

export class MuteComponent implements OnInit, AfterViewInit {

  @Input() videoRef: HTMLVideoElement;

  private hammerManager: HammerManager;

  isMuted = false;
  constructor(
    private elRef: ElementRef,
    private videoHideDelayService: VideoHideDelayService,
    @Optional() private overlayVisibilityService: OverlayVisibilityService
  ) { }

  ngOnInit(): void {
    this.isMuted = this.videoRef.muted;
  }

  ngAfterViewInit() {
    this.hammerManager = new Hammer.Manager(this.elRef.nativeElement);
    this.hammerManager.on('hammer.input', ({ srcEvent, direction }) => {
      if (direction === Hammer.DIRECTION_HORIZONTAL || direction === Hammer.DIRECTION_NONE) {
        srcEvent.preventDefault();
        srcEvent.stopPropagation();
      }
    });
  }

  muteUnMute() {
    this.isMuted = !this.isMuted;
    this.overlayVisibilityService?.childEvent$?.next(this.videoHideDelayService.getHideDelay());
    this.setNewVolumeState();
  }

  private setNewVolumeState() {
    /** on iOS the volume property is read-only, therefore it can't be changed. Not even with the vgApiService.
     *  But the video player's muted property can be toggled */
    this.videoRef.muted = this.isMuted;
  }
}

import { Injectable } from '@angular/core';
import { EmbeddedMedia } from '../../../../core/store/channel/channel.state';

export interface MessageCustomizations {
  handleMediaEmbeds(mediaEmbeds: EmbeddedMedia[], message?: any): EmbeddedMedia[];
  handleMessageValidation?(message?: any): boolean;
  canOpenContextMenu(message?: any): boolean;
}

@Injectable()
export abstract class MessageBoxCustomizations implements MessageCustomizations {

  abstract handleMediaEmbeds(mediaEmbeds: EmbeddedMedia[], message?: any): EmbeddedMedia[];

  handleMessageValidation(message: any): boolean {
    return !!message;
  }

  canOpenContextMenu(message?: any): boolean {
    return true;
  }

  protected noopMediaFilter(mediaEmbeds: EmbeddedMedia[]): EmbeddedMedia[] {
    return mediaEmbeds;
  }
}

import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { selectCurrentUserData } from '../../../../core/store/auth/auth.selectors';
import { RootState } from '../../../../core/store/root/public-api';

// TODO[registration]: remove this service when the backend is ready
@Injectable()
export class RegistrationWorkaround {

  constructor(
    private store: Store<RootState>
  ) { }

  // The backend currently needs userid for registration endpoints -> remove this service when this limitation is gone
  withCurrentUser() {
    return this.store.pipe(
      select(selectCurrentUserData),
      map((data) => data.userId)
    );
  }

  // Allow 9638 secret code somehow
  workaroundSecretcode(code: string) {
    return code?.trim();
  }
}

import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subject, takeUntil } from 'rxjs';
import { OutletHelper } from '../../../../../core/services/navigation/outlet-specific/outlet-helper';
import { saveEditForm } from '../../../../../core/store/form/form.actions';
import { headerCommandClicked } from '../../../../../core/store/header/header.actions';
import { BaseHeaderCommand, HeaderCommandType, SaveHeaderCommand } from '../../../../../core/store/header/models/HeaderCommand';
import { RootState } from '../../../../../core/store/root/public-api';
import { EmbeddedAppNavigator } from '../../../../embedded-api/api-parts/navigation/embedded-app-navigator';

@Injectable()
export class HeaderCommandHandler implements OnDestroy {

  private onDestroy = new Subject<void>();

  private readonly tryHandleCommand = new Subject<BaseHeaderCommand>();

  constructor(
    private store: Store<RootState>,
    private readonly outletHelper: OutletHelper,
    private readonly embeddedAppNavigator: EmbeddedAppNavigator,
  ) {
    this.tryHandleCommand.pipe(
      takeUntil(this.onDestroy)
    ).subscribe((commandData) => {
      this.executeCommand(commandData);
    });
  }

  handleCommand(commandData: BaseHeaderCommand) {
    this.tryHandleCommand.next(commandData);
  }

  private executeCommand(commandData: BaseHeaderCommand) {
    switch (commandData.type) {
      case HeaderCommandType.BACK_NAVIGATION:
      case HeaderCommandType.CANCEL_BACK_NAVIGATION:
      case HeaderCommandType.OPEN_CHANNEL_DETAIL:
      case HeaderCommandType.CHANNEL_ADD_WELCOME:
      case HeaderCommandType.APP_ADD_WELCOME:
      case HeaderCommandType.OPEN_USER_PROFILE:
      case HeaderCommandType.CRM_PROFILE_READ_ONLY_VIEW:
      case HeaderCommandType.CRM_PAUSE_MASS_CHAT_SENDING:
      case HeaderCommandType.BACK_TO_PROFILE:
      case HeaderCommandType.CLOSE_PROFILE_NAVIGATION:
      case HeaderCommandType.FORWARD_IN_CREATE:
      case HeaderCommandType.CLOSE_DIALOG:
      case HeaderCommandType.CHAT_ADD_NEW:
      case HeaderCommandType.OPEN_ROOM_DETAIL:
        this.store.dispatch(headerCommandClicked({ commandType: commandData.type }));
        return;
      case HeaderCommandType.FINISH_CREATE:
      case HeaderCommandType.SAVE_BACK_NAVIGATION:
      case HeaderCommandType.FINISH_ADD_MEMBERS_TO_CHAT:
      case HeaderCommandType.INVITE:
        const inviteCommand = commandData as SaveHeaderCommand<typeof commandData.type>;
        this.store.dispatch(saveEditForm({
          formId: this.outletHelper.resolveFormStoreKey(),
          dataObjectType: inviteCommand.dataObjectType
        }));
        return;
      case HeaderCommandType.CHAT_MENU:
        return;
      case HeaderCommandType.EXIT_EMBEDDED_APP_BACK_NAVIGATION:
        this.embeddedAppNavigator.exitEmbeddedApp();
        return;
      default:
        throw new Error(`Header command type is not supported`);
    }
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}

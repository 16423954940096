import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { LottieModule } from 'ngx-lottie';
import { HeaderModule } from '../../../frame/components/header/header.module';
import { MenuEntryModule } from '../../../shared/components/menu-entry/menu-entry.module';
import { SharedModule } from '../../../shared/shared.module';
import { playerFactory } from '../../error-page/error-page.module';
import { ProfileReadOnlyViewComponent } from './components/profile-read-only-view.component';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
    TranslateModule,
    MenuEntryModule,
    SharedModule,
    ScrollingModule,
    HeaderModule,
    LottieModule.forRoot({
      player: playerFactory
    }),
  ],
  declarations: [
    ProfileReadOnlyViewComponent
  ],
  exports: [
    ProfileReadOnlyViewComponent
  ]
})
export class ProfileReadOnlyViewModule { }

import { FeaturesConfig } from '@portal/wen-backend-api';

export const defaultConfig: FeaturesConfig = {
  enableComingSoonFeatures: false,
  enableChristmasSpecial: false,
  enableMediaUpload: false,
  enableCommentReaction: false,
  enableAnimations: false,
  enableEmailVerification: false,
  enableFiltering: false,
  enablePostScheduling: false,
  enablePublicProfile: false,
  enableSaveListScrollPositions: false,
  enableIosKeyboardUtils: false,
  enableCRMEmailInviteToNetwork: false,
  enableGroupChat: false,
  enableSkipOnboarding: false,
  enableNativeShareInvite: false,
  enableCollaborativeChannel: false,
  enableTextToSpeech: false,
  enableDraftMessageSending: false,
  enableFileDownload: false,
  enableNewVideoPlayer: false,
  enableChatAutoReply: false,
  enableChannelCreatePopUp: false,
  enableAboutMeField: false,
  enableChatSchedulingEdit: false,
  enableContacts: false,
  enableDocumentUpload: false,
  enableWallet: false,
  enableNewChannelSettings: false,
  enableChatEdit: false,
  enableLanguageChange: false,
  enableTracing: false,
};

const FEATURE_FLAG_METHOD_PREFIX = 'is';
const KEYWORD = 'enable';
const KEYWORD_PLACEHOLDER = '{keyword}';

export type FeatureConfigsAsMethods<R = boolean> = {
  [K in keyof FeaturesConfig & string as `${typeof FEATURE_FLAG_METHOD_PREFIX}${Capitalize<K>}`]: () => R;
};

export const functionize = (featuresConfig: FeaturesConfig): FeatureConfigsAsMethods => {
  const mappedConfig = Object.entries(featuresConfig).reduce((acc, [key, value]) => {
    if (key.startsWith(KEYWORD)) {
      key = key.replace(KEYWORD, KEYWORD_PLACEHOLDER);
    }
    const capitalizedKey = key[0].toUpperCase() + key.slice(1);
    const capitalizedKeyword = KEYWORD[0].toUpperCase() + KEYWORD.slice(1);
    let flagMethodName = `${FEATURE_FLAG_METHOD_PREFIX}${capitalizedKey}`;
    flagMethodName = flagMethodName.replace(KEYWORD_PLACEHOLDER, capitalizedKeyword);

    const fn = { [flagMethodName]() { return value; } };
    acc = { ...acc, ...fn };
    return acc;
  }, {} as FeatureConfigsAsMethods);
  return mappedConfig;
};

import { Injector, Type } from '@angular/core';
import { ActivatedRouteSnapshot, Data, Route } from '@angular/router';
import { WenRouterOutlet } from '@portal/wen-components';
import { DataContext } from '../../core/common/types/data-context';
import { BackNavigationBehavior } from '../../core/services/navigation/back-navigation/back-navigation-behaviors/back-navigation-behavior';
import { CustomFormStoreKey } from '../../shared/form-store/form-store-types';

export enum WenRouteId {
  LOGIN = 'LOGIN',
  OAUTH_CALLBACK = 'OAUTH_CALLBACK',
  LOGOUT = 'LOGOUT',
  SSO_LOGIN = 'SSO_LOGIN',
  HOME = 'HOME',
  DASHBOARD = 'DASHBOARD',
  ERROR_PAGE = 'ERROR_PAGE',
  CHANNEL_LIST = 'CHANNEL_LIST',
  CHANNEL_DISCOVERY = 'CHANNEL_DISCOVERY',
  CHANNEL_DETAIL = 'CHANNEL_DETAIL',
  CHANNEL_SCHEDULES = 'CHANNEL_SCHEDULES',
  CHANNEL_MESSAGE_DETAIL = 'CHANNEL_MESSAGE_DETAIL',
  CHANNEL_INFO_EDIT = 'CHANNEL_INFO_EDIT',
  CHANNEL_VISIBILITY_SETTINGS = 'CHANNEL_VISIBILITY_SETTINGS',
  CHANNEL_INTERACTION_SETTINGS = 'CHANNEL_INTERACTION_SETTINGS',
  CHANNEL_RESTRICTION_SETTINGS = 'CHANNEL_RESTRICTION_SETTINGS',
  CHANNEL_PENDING_JOIN_REQUESTS = 'CHANNEL_PENDING_JOIN_REQUESTS',
  CHANNEL_ADMIN_LIST = 'CHANNEL_ADMIN_LIST',
  CHANNEL_ADD_ADMIN = 'CHANNEL_ADD_ADMIN',
  CHANNEL_CONTENT_PROVIDERS = 'CHANNEL_CONTENT_PROVIDERS',
  CHANNEL_ADD_CONTENT_PROVIDER = 'CHANNEL_ADD_CONTENT_PROVIDER',
  CHANNEL_SUBSCRIBERS = 'CHANNEL_SUBSCRIBERS',
  CHANNEL_VIEW = 'CHANNEL_VIEW',
  CHANNEL_POST_EDITOR = 'CHANNEL_POST_EDITOR',
  CHANNEL_POST_LOCATION_SELECTOR = 'CHANNEL_POST_LOCATION_SELECTOR',
  CHANNEL_FEATURED_VIEW = 'CHANNEL_FEATURED_VIEW',
  CHANNEL_ADD_WELCOME = 'CHANNEL_ADD_WELCOME',
  CHANNEL_EXPLORE = 'CHANNEL_EXPLORE',
  CHANNEL_SETTING = 'CHANNEL_SETTING',
  ADD_CHANNEL_INFO = 'ADD_CHANNEL_INFO',
  ADD_CHANNEL_RESTRICTIONS = 'ADD_CHANNEL_RESTRICTIONS',
  ADD_CHANNEL_VISIBILITY = 'ADD_CHANNEL_VISIBILITY',
  ADD_CHANNEL_INTERACTIONS = 'ADD_CHANNEL_INTERACTIONS',
  APP_LIST = 'APP_LIST',
  APP_DISCOVERY = 'APP_DISCOVERY',
  APP_DETAIL = 'APP_DETAIL',
  APP_INFO_EDIT = 'APP_INFO_EDIT',
  ADD_APP_INFO = 'ADD_APP_INFO',
  APP_VIEW = 'APP_VIEW',
  APP_ADD_WELCOME = 'APP_ADD_WELCOME',
  APP_EXPLORE = 'APP_EXPLORE',
  EMBEDDED_PAGE = 'EMBEDDED_PAGE',
  EVENT_DISCOVERY = 'EVENT_DISCOVERY',
  EVENT_LIST = 'EVENT_LIST',
  EVENT_DETAIL = 'EVENT_DETAIL',
  EVENT_EXPLORE = 'EVENT_EXPLORE',
  USERPROFILE_VIEW = 'USERPROFILE_VIEW',
  USERPROFILE_DASHBOARD = 'USERPROFILE_DASHBOARD',
  USERPROFILE_MANIFEST = 'USERPROFILE_MANIFEST',
  USERPROFILE_HELP = 'USERPROFILE_HELP',
  USERPROFILE_FAQ = 'USERPROFILE_FAQ',
  USERPROFILE_MARKDOWN = 'USERPROFILE_MARKDOWN',
  USERPROFILE_LICENSES = 'USERPROFILE_LICENSES',
  USERPROFILE_COMING_SOON = 'USERPROFILE_COMING_SOON',
  USERPROFILE_EMAIL_SETTINGS = 'USERPROFILE_EMAIL_SETTINGS',
  USERPROFILE_CHANGE_EMAIL = 'USERPROFILE_CHANGE_EMAIL',
  USERPROFILE_READ = 'USERPROFILE_READ',
  USERPROFILE_EDIT = 'USERPROFILE_EDIT',
  USERPROFILE_HOTLINE = 'USERPROFILE_HOTLINE',
  CONTACTS_LIST = 'CONTACTS_LIST',
  PENDING_CONTACTS_LIST = 'PENDING_CONTACTS_LIST',
  OTHER_USERPROFILE_READ = 'OTHER_USERPROFILE_READ',
  USERPROFILE_LANGUAGE  = 'USERPROFILE_LANGUAGE',
  USERPROFILE_SETTINGS = 'USERPROFILE_SETTINGS',
  USERPROFILE_CREDENTIALS_MENU = 'USERPROFILE_CREDENTIALS_MENU',
  USERPROFILE_CREDENTIAL_DETAILS = 'USERPROFILE_CREDENTIAL_DETAILS',
  USERPROFILE_PRIVACY_SETTINGS = 'USERPROFILE_PRIVACY_SETTINGS',
  INVITE_TO_NETWORK = 'INVITE_TO_NETWORK',
  INVITE_TO_CHANNEL_SELECTOR = 'INVITE_TO_CHANNEL_SELECTOR',
  INVITE_TO_CHANNEL = 'INVITE_TO_CHANNEL',
  CRM_ACTION_SELECTOR = 'CRM_ACTION_SELECTOR',
  CRM_PROFILE_READ_ONLY_VIEW = 'CRM_PROFILE_READ_ONLY_VIEW',
  CRM_DISTRIBUTE_CHAT = 'CRM_DISTRIBUTE_CHAT',
  CRM_CHAT_PREVIEW = 'CRM_CHAT_PREVIEW',
  CHATS = 'CHATS',
  CHAT_CREATION = 'CHAT_CREATION',
  CHAT_LIST = 'CHAT_LIST',
  CHAT_VIEW = 'CHAT_VIEW',
  CHAT_GROUP_DETAIL = 'CHAT_GROUP_DETAIL',
  CHAT_SCHEDULES = 'CHAT_SCHEDULES',
  ADD_CHAT_USERS = 'ADD_CHAT_USERS',
  ADD_CHAT_GROUP_MEMBERS = 'ADD_CHAT_GROUP_MEMBERS',
  ADD_CHAT_INFO = 'ADD_CHAT_INFO',
  CHAT_INFO_EDIT = 'CHAT_INFO_EDIT',
  REGISTRATION_START = 'REGISTRATION_START',
  REGISTRATION_CODE_VERIFICATION = 'REGISTRATION_CODE_VERIFICATION',
  REGISTRATION_PROFILE = 'REGISTRATION_PROFILE',
  ONBOARDING_START = 'ONBOARDING_START',
  SIGN_IN_HOME = 'SIGN_IN_HOME',
  SIGN_IN_START = 'SIGN_IN_START',
  SIGN_IN_POPUP = 'SIGN_IN_POPUP',
  SIGN_IN_INVITE = 'SIGN_IN_INVITE',
  READER_VIEW = 'READER_VIEW',
  TEXT_VIEW = 'TEXT_VIEW',
  EXCLUSIVE_INVITE = 'EXCLUSIVE_INVITE',
  AUTO_REPLY = 'AUTO_REPLY',
  WALLET_CREDENTIAL_CREATE = 'WALLET_CREDENTIAL_CREATE',
  WALLET_INITIATE_ISSUANCE = 'WALLET_INITIATE_ISSUANCE',
}

export enum EditLeaveConfirmationType {
  FORM = 'FORM',
  DRAFT = 'DRAFT',
}

export interface WenRouteEditModeConfig {
  withEditLeaveConfirmation: EditLeaveConfirmationType;
}

export type BackNavigationBehaviorProvider = Type<BackNavigationBehavior>;
export type BackNavigationBehaviorFactoryProvider = (injector: Injector) => BackNavigationBehavior;
export type BackNavigationBehaviorProviderTypes = BackNavigationBehaviorProvider | BackNavigationBehaviorFactoryProvider;
export type ShouldReuseRoute = (futureRoute: ActivatedRouteSnapshot, currentRoute: ActivatedRouteSnapshot) => boolean;

export interface WenRouteWithId {
  routeId: WenRouteId;
  routeTransitionEnabled?: boolean;
  customRouteReuseCheck?: ShouldReuseRoute;
  appBannerDisabled?: boolean;
  dataContext?: DataContext;
  /**
   * Provide custom form store key for all forms in a specific route
   * This can be used to group together changed values from different forms:
   * The provided key will be used in the Store to store the changed values from all related forms
   * Every change from each form which has the same key are merged together
   */
  formStoreStoreKey?: CustomFormStoreKey;
  backNavigationBehavior?: BackNavigationBehaviorProviderTypes;
}

export interface WenRoute extends Route {
  data: WenRouteWithId;
}

export type WenRoutes = (WenRoute | Route)[];

export interface WenRouteCollection extends Route {
  outlet?: WenRouterOutlet;
}

export type WenRoutesCollection = WenRouteCollection[];

export const isWenRouteWithId = (data: Data): data is WenRouteWithId => {
  return data?.routeId;
};

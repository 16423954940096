<form wenFormStore class="wen-form" [formGroup]="profileEditFormGroup" wen-test-id="wen-profile-edit-group">
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper wen-image-upload">
      <wen-image-upload [formControlName]="'avatarUrl'" [fallbackText]="fullName$ | async"></wen-image-upload>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'PROFILE_FORM_CHRISTIANNAME_LABEL' | translate }}</mat-label>
        <portal-field
          #christianName
          [formControlName]="'christianName'"
          [autocomplete]="'off'"
          [valueAdapter]="fieldValueAdapter"
          [required]="true"
          wenIosKeyboardStickyTarget
          [wen-test-id]="'profile-edit-christianname'"></portal-field>
        <mat-error portal-caption>{{ "PROFILE_FORM_CHRISTIANNAME_ERROR" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'PROFILE_FORM_LASTNAME_LABEL' | translate }}</mat-label>
        <portal-field
          #lastName
          [formControlName]="'lastName'"
          [autocomplete]="'off'"
          [valueAdapter]="fieldValueAdapter"
          [required]="true"
          wenIosKeyboardStickyTarget
          [wen-test-id]="'profile-edit-lastname'"></portal-field>
        <mat-error portal-caption>{{ "PROFILE_FORM_LASTNAME_ERROR" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="wen-form-field-wrapper wen-form-field-textarea" *ngIf="isAboutYouFieldEnabled$ | async">
      <mat-form-field>
        <mat-label>{{ 'PROFILE_FORM_ABOUT_ME_LABEL' | translate }}</mat-label>
        <div class="wen-textarea-wrapper">
          <textarea
            [formControlName]="'additionalInformation'"
            maxlength="{{ maximumCharacterLength }}"
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="5"
            cdkAutosizeMaxRows="8"
            wenIosKeyboardStickyTarget>
          </textarea>
          <div class="wen-about-me-clear-icon-wrapper" *ngIf="itemCount$ | async">
            <wen-icon wen-primary-color [iconName]="'close'"(click)="clearInput()"></wen-icon>
          </div>
        </div>
        <mat-hint align="end" class="wen-description-item-count" [align] *ngIf="itemCount$ | async as itemCount">
          {{ itemCount }}/{{ maximumCharacterLength }}
        </mat-hint>
      </mat-form-field>
    </div>
  </div>
  <div class="wen-form-actions">
    <wen-interact-button class="wen-interact-button-menu-style wen-error-interact-button" (click)="deleteProfile()">{{
      "USER_PROFILE_DELETE_PROFILE_BUTTON_LABEL" | translate }}</wen-interact-button>
  </div>
</form>
<ng-container *ngIf="hasControls">
  <wen-message-discard-edit-button></wen-message-discard-edit-button>
  <wen-link-embed-preview [previewContent]="linkPreviewContent$ | async" [disabled]="hasMediaEmbed$ | async"
    (previewChanged)="updateDraftMessageLinkEmbed($event)"></wen-link-embed-preview>
  <wen-media-embed-preview></wen-media-embed-preview>
  <wen-poll-preview></wen-poll-preview>
</ng-container>
<wen-message-send-field [readonly]="!hasControls" [inputPlaceholder]="inputPlaceholder"
  [contextId]="channelId$ | async" (messageSent)="onMessageSent()" (focused)="onFocused($event)">
  <wen-message-type-selector *ngIf="hasControls" wen-message-type-selector [menuId]="messageSelectorId"
    [disabled]="hasEmbed$ | async">
  </wen-message-type-selector>
  <wen-message-scheduler *ngIf="hasControls" wen-message-bottom-action
    [contextId]="channelId$ | async"></wen-message-scheduler>
</wen-message-send-field>
import { FactoryProvider, Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { CircleImageViewComponent, IndicatorType } from '@portal/wen-components';
import { Observable, distinctUntilChanged, filter, map, of } from 'rxjs';
import { DataContext } from '../../../core/common/types/data-context';
import { extractOutletDatas } from '../../../core/services/navigation/outlet-specific/outlet-utils';
import { selectCurrentChannel } from '../../../core/store/channel/channel.selectors';
import { selectCurrentRoom } from '../../../core/store/chat/chat.selectors';
import { RootState } from '../../../core/store/root/public-api';
import { selectUserProfile } from '../../../core/store/user/user.selectors';

export const ACTIVE_ITEM_IMAGE_COMMAND_TOKEN = new InjectionToken<CircleImageViewComponent>('ACTIVE_ITEM_IMAGE_COMMAND_TOKEN');


@Injectable()
export abstract class ActiveItemImageData {
  imageUrl$: Observable<string>;
  placeholder$: Observable<string>;
  overlayData$: Observable<IndicatorType> = of(null);
}

@Injectable()
export class CurrentChatImageData extends ActiveItemImageData {
  constructor(
    store: Store<RootState>
  ) {
    super();
    const currentRoom$ = store.pipe(
      select(selectCurrentRoom)
    );
    this.imageUrl$ = currentRoom$.pipe(
      filter(room => Boolean(room)),
      map(({ details }) => details.icon)
    );
    this.placeholder$ = currentRoom$.pipe(
      filter(room => Boolean(room)),
      map(({ details }) => details.title)
    );
  }
}

@Injectable()
export class CurrentChannelImageData extends ActiveItemImageData {
  constructor(
    store: Store<RootState>
  ) {
    super();
    const currentChannel$ = store.pipe(
      select(selectCurrentChannel)
    );
    this.imageUrl$ = currentChannel$.pipe(
      filter(channel => Boolean(channel)),
      distinctUntilChanged((a, b) => a.icon === b.icon),
      map(({ icon }) => icon)
    );
    this.placeholder$ = currentChannel$.pipe(
      filter(channel => Boolean(channel)),
      distinctUntilChanged((a, b) => a.title === b.title),
      map(({ title }) => title)
    );
    this.overlayData$ = currentChannel$.pipe(
      filter(channel => Boolean(channel)),
      distinctUntilChanged((a, b) => (a.geo === b.geo && a.readingContent === b.readingContent && a.restricted === b.restricted)),
      map(({ geo, readingContent, restricted }) => {
        if (readingContent) {
          return restricted ? IndicatorType.PREMIUM_READING_CHANNEL : IndicatorType.READING_CHANNEL;
        }
        if (geo) {
          return IndicatorType.GEO_CHANNEL;
        }
      })
    );
  }
}

@Injectable()
export class CurrentUserImageData extends ActiveItemImageData {
  constructor(
    store: Store<RootState>
  ) {
    super();
    const currentUser$ = store.pipe(
      select(selectUserProfile),
    );
    this.imageUrl$ = currentUser$.pipe(
      filter(user => Boolean(user)),
      map(userProfile => userProfile.avatarUrl)
    );
    this.placeholder$ = currentUser$.pipe(
      filter(user => Boolean(user)),
      map(userProfile => userProfile.displayname)
    );
  }
}

export function ActiveItemImageDataProviderFactoryFn(
  route: ActivatedRoute,
  store: Store<RootState>
): ActiveItemImageData {
  const extractedOutletData = extractOutletDatas(route.snapshot);
  const context = extractedOutletData.primaryData?.dataContext;
  switch (context) {
    case DataContext.CHAT:
      return new CurrentChatImageData(store);
    case DataContext.CHANNEL:
      return new CurrentChannelImageData(store);
    case DataContext.PROFILE:
      return new CurrentUserImageData(store);
    default:
      console.error('Context not supported: ' + context);
      return null;
  }
}

export const ACTIVE_ITEM_IMAGE_DATA_PROVIDER: FactoryProvider = {
  provide: ActiveItemImageData,
  useFactory: ActiveItemImageDataProviderFactoryFn,
  deps: [ActivatedRoute, Store]
};

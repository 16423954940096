import { Injectable, OnDestroy } from '@angular/core';
import { FeedRenderingRegistry } from '@portal/wen-components';
import { Subject, takeUntil } from 'rxjs';
import { isNullOrUndefined } from '../../../../core/common/operators/null-check-util';
import { WenNativeApi } from '@portal/wen-native-api';
import { ScrollerWrapper } from '../../../../core/services/scrolling/scroller-wrapper';
import { SCROLLED_TO_BOTTOM_THRESHOLD } from '../components/we-feed/we-feed-model';
import { AnimatedScroller, AnimatedScrollerBehavior } from './animated-scroller';

interface RestoreScrollPositionInfo {
  scrollHeight?: number;
  scrollBottom?: number;
  targetKey?: string;
  targetelement?: HTMLElement;
}

@Injectable()
export class FeedLayoutCommunicator implements FeedRenderingRegistry, OnDestroy {

  private onDestroy$ = new Subject<void>();

  private disableAutoScroll = false;
  private restoreScrollPositionInfo: RestoreScrollPositionInfo = {};
  private renderedItems = new Set<string>();
  private renderingProgressItems = new Set();
  private animatedScroller = new AnimatedScroller(this.scrollerWrapper);

  /**
   * Flag to indicate if the current rendering loop contained items at the top or the bottom of the old dataset
   */
  private isDown = true;
  /**
   * Flag which tries to anchor the scroll position to the bottom until it is not false
   */
  private bottomAnchor = false;

  private onItemRendered = new Subject<string>();
  public readonly onItemRendered$ = this.onItemRendered.pipe();
  hasActiveRendering$ = new Subject<boolean>();

  constructor(
    private scrollerWrapper: ScrollerWrapper,
    public nativeApi: WenNativeApi,
  ) { }

  connect(disableAutoScroll: boolean) {
    this.disableAutoScroll = disableAutoScroll;
    this.scrollerWrapper.scrollPosition$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      const { bottomOffset } = this.scrollerWrapper.measureScrollOffsets();
      if (bottomOffset < 1) {
        this.bottomAnchor = false;
      }
    });
  }

  /**
   * Indicate that there are new items to be rendered
   */
  onNewPageReceived(keys: string[], targetKey?: string) {
    if (!keys?.length) {
      this.setupIdleState();
      return;
    }
    const isNewDataset = !this.hasIntersection(keys);
    if (isNewDataset) {
      this.animatedScroller.setBehavior(AnimatedScrollerBehavior.IMMEDIATE);
      this.restoreScrollPositionInfo = {};
      this.renderedItems.clear();
      this.renderingProgressItems.clear();
    }
    const storedScrollToBottom = this.restoreScrollPositionInfo?.scrollBottom;
    const shouldSaveScrollPosition = isNullOrUndefined(storedScrollToBottom) || storedScrollToBottom > SCROLLED_TO_BOTTOM_THRESHOLD;
    if (!isNewDataset || targetKey && shouldSaveScrollPosition) {
      /**
       * Only save/override the restorable scroll position if:
       *  - The dataset has not changed (the rendering loop is still on the same dataset, but updates/size changes are received)
       *  - If the scrolling index is not at the very bottom:
       *      If the scroll position is at the very bottom the rendering should try to anchor the position instead of restoring it
       *      This can happen if there are some updates on initial load for the dataset
       *      (eg.: properties are changing as the data loads fully)
       */
      this.saveScrollPosition(targetKey);
    }
    const down = this.renderedItems.has(keys[0]);
    const up = this.renderedItems.has(keys[keys.length - 1]);
    this.isDown = down && !up;
    this.registerRenderingProgress(keys);
  }

  /**
   * Mark items not fully rendered
   */
  registerRenderingProgress(keys: string | string[]) {
    const keysArr = Array.isArray(keys) ? keys : [keys];
    const incomingItems = keysArr.filter((key) => !this.renderedItems.has(key));
    incomingItems.forEach(key => {
      this.renderingProgressItems.add(key);
    });
  }

  /**
   * Mark an item fully rendered
   */
  clearRenderingProgress(key: string, element?: HTMLElement) {
    this.renderingProgressItems.delete(key);
    if (!this.renderedItems.has(key)) {
      this.renderedItems.add(key);
      this.onItemRendered.next(key);
    }
    if (this.disableAutoScroll) {
      return;
    }
    const { targetKey, targetelement } = this.restoreScrollPositionInfo;
    /**
     * Check if the rendered item is the element we try to anchor into the view (eg.: new message line)
     * At this point the HtmlElement must be known so we can measure the scroll position of that item
     *  whereas when registering a progress usually the HtmlElement is not yet attached to the DOM
     */
    if (targetKey && key === targetKey) {
      this.restoreScrollPositionInfo.targetelement = element;
    }
    const scrollElement = this.scrollerWrapper.getElement();
    if (scrollElement) {
      if (targetelement) {
        /**
         * If there is an anchor element, scroll into that element to the top of the viewport
         * (Leave some extra negative offset so the item is surely inside the viewport)
         */
        const targetPosition = targetelement.offsetTop - 5;
        this.scrollerWrapper.scrollTo({ top: targetPosition });
      } else {
        const { scrollBottom: previousScrollBottom } = this.restoreScrollPositionInfo;
        if (this.isRenderingInProgress()) {
          // When the items update restore the previous scroll position so the scroll doesn't jump around
          let newScrollBottom: number;
          const currentScrollHeight = scrollElement.scrollHeight;
          if (this.isDown) {
            // When items/a new page added at the bottom scroll the view back upwards
            const { scrollHeight: previousScrollHeight } = this.restoreScrollPositionInfo;
            newScrollBottom = currentScrollHeight - (previousScrollHeight || currentScrollHeight) + previousScrollBottom;
            this.scrollerWrapper.scrollTo({ bottom: newScrollBottom });
          } else {
            // When items/a new page added at the top restore the previous scroll bottom or keep it at the very bottom
            newScrollBottom = previousScrollBottom || 0;
            this.scrollerWrapper.scrollTo({ bottom: newScrollBottom });
          }
          this.restoreScrollPositionInfo = {
            ...this.restoreScrollPositionInfo,
            ...{
              scrollHeight: currentScrollHeight,
              scrollBottom: newScrollBottom,
            }
          };
        } else if (previousScrollBottom <= SCROLLED_TO_BOTTOM_THRESHOLD || this.bottomAnchor || this.animatedScroller.isInProgress()) {
          // When all rendering is done and the scroll position is around bottom the view must keep that at the very bottom
          this.animatedScroller.scrollToBottom(scrollElement);
        }
      }
    }
    if (!this.isRenderingInProgress()) {
      this.setupIdleState();
    }
  }

  private setupIdleState() {
    this.animatedScroller.setBehavior(AnimatedScrollerBehavior.ANIMATED);
    this.restoreScrollPositionInfo = {};
    this.fixScrollAndRenderingIssuesForIOS();
    this.hasActiveRendering$.next(false);
  }

  private saveScrollPosition(targetKey?: string) {
    const element = this.scrollerWrapper.getElement();
    const offsets = this.scrollerWrapper.measureScrollOffsets();
    this.restoreScrollPositionInfo = {
      scrollHeight: element ? element.scrollHeight : null,
      scrollBottom: Math.floor(offsets?.bottomOffset) || 0,
      targetKey
    };
  }

  setBottomAnchor() {
    this.bottomAnchor = true;
  }

  isRenderingInProgress() {
    return this.renderingProgressItems.size !== 0;
  }

  isRenderingInProgressFor(key: string) {
    return !this.renderedItems.has(key);
  }

  fixScrollAndRenderingIssuesForIOS() {
    if (!this.nativeApi.isIOS()) {
      return;
    }
    const scrollElement = this.scrollerWrapper.getElement();
    scrollElement.style.display = 'none';
    /**
     * The offsetLeft is not needed anywhere, but must be used (eg.: saved into a variable)
     *  somehow to force the ng build optimizer to keep this line and not to
     *  optimize it out during prodbuild
     */
    const offsetLeft = scrollElement.offsetLeft;
    scrollElement.style.display = '';
    return offsetLeft;
  }

  private hasIntersection(keys: string[]) {
    const hasSameItem = keys.some((key) => {
      const hasItem = this.renderedItems.has(key);
      return hasItem;
    });
    return hasSameItem;
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

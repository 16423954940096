import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { Observable, of, queueScheduler, ReplaySubject, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, first, map, observeOn, switchMap } from 'rxjs/operators';
import { firstExisty } from '../../common/operators/first-existy';
import { initiateAppForUser } from '../../store/auth/auth.actions';
import { selectCurrentUserData } from '../../store/auth/auth.selectors';
import { UserData } from '../../store/auth/models/UserData';
import { RootState } from '../../store/root/public-api';
import { WenOAuthService } from './wen-oauth.service';

export class NotAuthenticatedError {
  public static readonly message = 'Not authenticated';
}

enum ValidationState {
  DONE = 'DONE',
  ERROR = 'ERROR',
}

type ValidationResult = {
  state: ValidationState;
  requestId: number;
  userData?: UserData;
};

@Injectable()
export class AuthStateValidator {

  private lastRequestId = 0;
  private validationResult = new ReplaySubject<ValidationResult>(50);
  private validate = new Subject<number>();

  constructor(
    private store: Store<RootState>,
    private wenOAuthService: WenOAuthService,
    private wenSocketIoService: SocketIoService,
  ) {
    this.validate.pipe(
      concatMap((requestId) => {
        return this.store.pipe(
          select(selectCurrentUserData),
          first(),
          switchMap((userData) => {
            return this.validateAuthStateInternal(userData).pipe(
              observeOn(queueScheduler)
            );
          }),
          map(innerResult => {
            return { ...innerResult, requestId };
          })
        );
      })
    ).subscribe((result) => {
      this.validationResult.next(result);
    });
  }

  validateAuthState(): Observable<UserData> {
    const currentId = this.lastRequestId++;
    this.validate.next(currentId);
    return this.validationResult.pipe(
      filter(result => result.requestId === currentId),
      first(),
      switchMap(({ state, userData }) => {
        if (state === ValidationState.ERROR) {
          return throwError(() => new NotAuthenticatedError());
        } else if (state === ValidationState.DONE) {
          return of(userData);
        }
      })
    );
  }

  private validateAuthStateInternal(userData: UserData): Observable<Omit<ValidationResult, 'requestId'>> {
    const hasValidTokens = this.wenOAuthService.hasValidTokens();
    // User is in the store and the token is valid
    if (userData && hasValidTokens) {
      return of({ state: ValidationState.DONE, userData });
    }
    // The JWT token is not parsed into the store
    if (!userData && hasValidTokens) {
      // The token is still valid (app reload)
      const data = this.wenOAuthService.getUserData();
      this.store.dispatch(initiateAppForUser({ userData: data }));
      return this.store.pipe(
        select(selectCurrentUserData),
        firstExisty(),
        switchMap((parsedUserData) => this.validateAuthStateInternal(parsedUserData))
      );
    }
    // The token is expired or doesn't exist
    if (!hasValidTokens) {
      const refreshToken = this.wenOAuthService.getRefreshToken();
      if (refreshToken) {
        return this.wenOAuthService.refreshToken().pipe(
          switchMap(() => this.wenSocketIoService.reconnectIfConnected()),
          switchMap(() => this.validateAuthStateInternal(userData)),
          catchError(() => {
            return of({ state: ValidationState.ERROR });
          })
        );
      } else {
        return of({ state: ValidationState.ERROR });
      }
    }
  }

}

import { Injectable } from '@angular/core';
import { Modifier } from '@popperjs/core';
import { createPreventScrollModifier } from '@portal/wen-components';
import { filter, first, Observable, shareReplay } from 'rxjs';
import { ScrollerWrapper } from '../../../../core/services/scrolling/scroller-wrapper';
import { FeedDatasource } from './feed-datasource';
import { FeedLayoutCommunicator } from './feed-layout-communicator';
import { EventType, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable()
export class FeedLayoutMediator {

  private scrollerWrapper: ScrollerWrapper;
  private feedLayoutCommunicator: FeedLayoutCommunicator;
  /**
   * Signal for the final navigation event, used to kick-off the rendering process
   * Rendering started before this event can cause slow-down in the navigation because
   *  it can happen that at time still the previous route is shown and it will block
   *  the ui
   *
   *  Author: Zalan Flaisz
   *  Revision: ac72fa46
   */
  afterNavigationEnd$: Observable<void>;

  constructor(
    private router: Router,
    private feedDatasource: FeedDatasource
  ) {
    this.afterNavigationEnd$ = this.router.events.pipe(
      filter(event => [ EventType.NavigationEnd, EventType.Scroll ].includes(event.type)),
      first(),
      shareReplay(1),
      map(() => null)
    );
  }

  initialize(scrollerWrapper: ScrollerWrapper, feedLayoutCommunicator: FeedLayoutCommunicator) {
    this.scrollerWrapper = scrollerWrapper;
    this.feedLayoutCommunicator = feedLayoutCommunicator;
  }

  scrollToBottom() {
    if (!this.isInitialized()) {
      return;
    }
    this.feedLayoutCommunicator.setBottomAnchor();
    this.feedDatasource.loadFirstPage().pipe(
      first()
    ).subscribe(({ hasResult }) => {
      if (!hasResult) {
        this.scrollerWrapper.scrollTo({ bottom: 0 });
      }
    });
  }

  createPopperModifiers(): Partial<Modifier<any, any>>[] {
    return [
      createPreventScrollModifier(this.scrollerWrapper.scroller.getElementRef().nativeElement)
    ];
  }

  private isInitialized() {
    return this.feedLayoutCommunicator && this.scrollerWrapper;
  }
}

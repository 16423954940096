import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { AutoReplyEditModule } from './auto-reply-edit/auto-reply-edit.module';
import { EmailSettingsModule } from './email-settings/email-settings.module';
import { FAQViewModule } from './faq-view/faq-view.module';
import { LicensesModule } from './licenses/licenses.module';
import { ManifestViewModule } from './manifest-view/manifest-view.module';
import { PrivacySettingsModule } from './privacy-view/privacy-settings.module';
import { ProfileFormViewModule } from './profile-form/profile-form-view.module';
import { UserProfileViewModule } from './user-profile-view/user-profile-view.module';
import { UserProfileService } from './user-settings/user-profile.service';
import { UserSettingsModule } from './user-settings/user-settings.module';
import { UserLanguageSettingModule } from './user-settings/components/user-language-setting/user-language-setting.module';

@NgModule({
  imports: [
    SharedModule,
    UserProfileViewModule,
    ManifestViewModule,
    FAQViewModule,
    ProfileFormViewModule,
    LicensesModule,
    EmailSettingsModule,
    UserSettingsModule,
    AutoReplyEditModule,
    UserLanguageSettingModule,
    PrivacySettingsModule,
  ],
  providers: [
    UserProfileService
  ]
})
export class UserProfileModule { }

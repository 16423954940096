import { ValidationErrors } from '@angular/forms';
import { PhoneAreaInfo, PhoneNumberValue } from '../types/form-types';

export function updateableValidator(errorKey: string) {
  let isValid = true;
  return {
    setValid: () => isValid = true,
    setInValid: () => isValid = false,
    validator: (): ValidationErrors | null => {
      return isValid ? null : { [errorKey]: true };
    }
  };
}

export const extractPhoneNumber = (data: PhoneNumberValue) => {
  const { phoneNumber, areaInfo } = data;
  if (!phoneNumber) {
    return null;
  }
  const matches = phoneNumber.match(/\d+/g);
  if (Array.isArray(matches) && matches.length) {
    const cleanedPhoneNumber = matches.join('');
    return areaInfo.areaCode + cleanedPhoneNumber;
  }
  return null;
};

export const isGermanAreaCode = (areaInfo: PhoneAreaInfo) => {
  return areaInfo?.areaCode === '+49';
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EncryptionType, RoomMemberData, SocketIoService, ToUsersEventType } from '@portal/wen-backend-api';
import { SessionKeyShareService, WenChatClient } from '@portal/wen-chat-client';
import { debounceTime, filter, mergeMap, switchMap } from 'rxjs/operators';
import { firstExisty } from '../../../common/operators/first-existy';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { onChatInitialized } from '../../../common/util/operators/on-chat-initialized';
import { SendKeyDecryptor } from '../../../services/chat/decryption/send-key-decryptor';
import { WenStorageService } from '../../../services/storage/wen-storage.service';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { RootState } from '../../root/public-api';
import { subscribeChatUpdates } from '../chat.actions';
import { selectCurrentRoom } from '../chat.selectors';
import { requestShareInboundGroupSessionForCurrentRoom } from '../key-actions';

@Injectable()
export class SessionKeyEffects {

  get userId() {
    return this.oAuthService.getUserData()?.userId;
  }

  get deviceId() {
    return this.storageService.getDeviceId();
  }

  onOneTimeKeyStatusChanged$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => this.store.pipe(onChatInitialized)),
    switchMap(() => {
      return this.socketIoService.chat.oneTimeKeysStatus.listen.pipe(
        debounceTime(200),
        switchMap((data) => {
          const { deviceOneTimeKeysCount } = data;
          return this.chatClient.ensureOneTimeKeys(deviceOneTimeKeysCount);
        }),
      );
    })
  ), { dispatch: false });

  onShareKeyReceived$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => {
      return this.socketIoService.chat.room.sendToUsers.listen.pipe(
        filter((data) => {
          if (!data?.payload) {
            return false;
          }
          const { payload } = data;
          const isShareKey = payload.type === EncryptionType.ENCRYPTED && payload.eventType === ToUsersEventType.SHARE_KEY;
          return isShareKey;
        }),
        mergeMap((event) => {
          return this.sendKeyDecryptor.decryptShareKeyEvent(event);
        })
      );
    })
  ), { dispatch: false });

  requestShareInboundGroupSessionForCurrentRoom$ = createEffect(() => this.actions$.pipe(
    ofType(requestShareInboundGroupSessionForCurrentRoom),
    mapWithFirstFrom(() => {
      return this.store.pipe(
        select(selectCurrentRoom),
        firstExisty()
      );
    }),
    mergeMap(([_, room]) => {
      const { id: roomId, members } = room;
      const roomMemberData: RoomMemberData = {
        roomId,
        memberUserIds: members.map(member => member.userId)
      };
      return this.sessionKeyShareService.ensureSessionKeysAreShared([roomMemberData]);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>,
    private oAuthService: WenOAuthService,
    private storageService: WenStorageService,
    private chatClient: WenChatClient,
    private sendKeyDecryptor: SendKeyDecryptor,
    private sessionKeyShareService: SessionKeyShareService,
  ) {
  }

}

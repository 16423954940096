import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ForwardContext } from '@portal/wen-backend-api';
import { ActionMenuItem } from '@portal/wen-components';
import { map, of } from 'rxjs';
import { mapWithFirstFrom } from '../../../../../core/common/operators/map-with-first-from';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { ForwardService } from '../../../../../core/services/forward/forward.service';
import { MessageDeepLinkable } from '../../../../../core/services/navigation/deep-link/deep-linkables/message-deep-linkable';
import { MessageBoxModel } from '../../../feed/components/we-feed/we-feed-model';

@Injectable()
export class ChannelMessageBoxForwardAction implements ActionMenuItem {

  label: string;
  icon = 'forward';

  constructor(
    private translateService: TranslateService,
    private forwardService: ForwardService,
    private deepLinkProvider: MessageDeepLinkable,
    private featureEnablementService: FeatureEnablementService
  ) {
    this.label = this.translateService.instant('MESSAGE_BOX_CONTEXT_MENU_LABEL_FORWARD');
  }

  isVisible$ = (data: MessageBoxModel) => {
    return of(Boolean(data) && !data.scheduled && !data.isDraft).pipe(
      mapWithFirstFrom(() => this.featureEnablementService.appConfigObs.enableChat$),
      map(([isValidMessage, isChatEnabled]) => isValidMessage && isChatEnabled)
    );
  };

  execute(data: MessageBoxModel) {
    this.forwardService.initiateForward(
      this.deepLinkProvider.getDeepLink({ messageId: data.messageId }),
      ForwardContext.CHANNEL_MESSAGE,
      data.messageId
    );
  }
}

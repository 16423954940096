import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../../../../../shared/shared.module';
import { ChannelIndicatorComponent, IndicatorType } from '@portal/wen-components';

export interface RestrictionModel {
  titleTranslationKey: string;
  descriptionTranslationKey: string;
  imagePath: string;
  layoutType?: 'infoLayout' | 'buttonLayout';
  action?: {
    translationKey: string;
    handler: () => void;
  };
  premiumReadingChannel?: boolean;
}

@Component({
  standalone: true,
  selector: 'wen-channel-restriction',
  templateUrl: './channel-restriction.component.html',
  styleUrls: ['./channel-restriction.component.scss'],
  imports: [
    CommonModule,
    SharedModule,
    ChannelIndicatorComponent,
  ],
})
export class ChannelRestrictionComponent {

  @Input() restrictionModel: RestrictionModel;

  protected readonly IndicatorType = IndicatorType;
}

import { Injectable } from '@angular/core';
import { ChannelMessageDTO } from '@portal/wen-backend-api';
import { FeatureEnablementService } from '../../core/services/configuration/feature-enablement';
import { Embed } from '../../core/store/channel/channel.state';
import { isMediaEmbed, isPollEmbed } from '../../core/store/channel/util/embed-type-helper';

@Injectable()
export class MessageFilterService {

  private embedFilters = new Map<(embed: Embed) => boolean, boolean>();

  constructor(private featureEnablementService: FeatureEnablementService) {
    //TODO: FLAG
    this.embedFilters.set(isPollEmbed, true);
    this.embedFilters.set(isMediaEmbed, this.featureEnablementService.featureFlagMethods.isEnableMediaUpload());
  }

  filterMessages(messages: ChannelMessageDTO[]): ChannelMessageDTO[] {
    return messages.filter(message => {
      if (message.embeds.length) {
        return this.filterMessagesWithDisabledEmbeds(message);
      }
      return message;
    });
  }

  private filterMessagesWithDisabledEmbeds(message: ChannelMessageDTO) {
    const containDisabledEmbed: boolean[] = [];
    this.embedFilters.forEach((isFeatureEnabled, embedValidator) => {
      if (!isFeatureEnabled) {
        containDisabledEmbed.push(this.hasInvalidEmbed(message, embedValidator));
      }
    });
    return containDisabledEmbed.every(v => !v);
  }

  private hasInvalidEmbed(message: ChannelMessageDTO, embedValidator: (embed: Embed) => boolean): boolean {
    return !!message.embeds.filter(e => embedValidator(e)).length;
  }
}

import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { animationFrameScheduler, Observable, observeOn, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { selectCurrentChannel } from '../../../../../../core/store/channel/channel.selectors';
import { RootState } from '../../../../../../core/store/root/public-api';
import { ChannelListItemModel } from './models/list-item-model';
import { ActionMenuItem, IndicatorType } from '@portal/wen-components';
import { ActionMenuItemProvider } from '../../../../../../core/services/util/action-menu-item.provider';
import { channelListItemContextMenuId } from './action-menu/channel-list-item.actions';

@Component({
  selector: 'wen-channel-list-item',
  templateUrl: './channel-list-item.component.html',
  styleUrls: ['./channel-list-item.component.scss']
})
export class ChannelListItemComponent implements OnInit, OnChanges {

  @HostBinding('class.wen-channel-list-item') className = true;
  @HostBinding('class.wen-channel-list-item-selected') selectedClassName = false;

  @Input() channelModel: ChannelListItemModel;

  @Input() showAfterElement: boolean;

  @Input() markdownEnabled: boolean;

  @Input() enableContextMenu = false;

  isSelected$: Observable<boolean>;
  channel: ChannelListItemModel;
  actions$: Observable<ActionMenuItem[]> = of([]);
  currentChannelId$: Observable<string>;

  constructor(
    private store: Store<RootState>,
    private actionMenuItemProvider: ActionMenuItemProvider
  ) { }

  ngOnChanges(simpleChanges: SimpleChanges) {
    const { enableContextMenu, channelModel } = simpleChanges;
    const enableContextMenuState = enableContextMenu?.currentValue ?? this.enableContextMenu;
    this.channel = channelModel?.currentValue;
    if (enableContextMenuState && channelModel?.currentValue) {
      this.actions$ = this.actionMenuItemProvider.selectActions(channelListItemContextMenuId, this.channel);
    }

    this.isSelected$ = this.currentChannelId$?.pipe(
      observeOn(animationFrameScheduler),
      map((currentChannelId) => {
        if (!currentChannelId || !this.channel) {
          return false;
        }
        return currentChannelId === this.channel.id;
      })
    );
  }

  ngOnInit(): void {
    this.currentChannelId$ = this.store.pipe(
      select(selectCurrentChannel),
      map((channel) => channel?.id),
    );
  }

  getIndicatorType(channel: ChannelListItemModel) {
    if (channel.readingContent) {
      return channel.restricted ? IndicatorType.PREMIUM_READING_CHANNEL : IndicatorType.READING_CHANNEL;
    }
    if (channel.geo) {
      return IndicatorType.GEO_CHANNEL;
    }
  }
}

import { Component, ContentChild, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, combineLatest, interval } from 'rxjs';
import { ReactionSelectorData, ResizeHandlerProvider } from '@portal/wen-components';
import { bufferWhen, filter, first, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { DateUtil } from '../../../../../core/common/date/date-util';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { selectorWithParam } from '../../../../../core/common/util/selector-with-param';
import { acknowledgeChannelMessagesLocally, enableAutoAcknowledge, requestChannelMessageAcknowledgeBroadcast } from '../../../../../core/store/channel/channel.actions';
import { selectCurrentAutoAcknowledgeChannelId, selectCurrentChannel, selectCurrentChannelMessagesHistoryFlags, selectMessageDetailById } from '../../../../../core/store/channel/channel.selectors';
import { selectNotificationCountForChannel } from '../../../../../core/store/notification/notification.selectors';
import { WeFeedEmptyPlaceholderComponent } from '../../../../../shared/components/feed/components/we-feed-empty-placeholder/we-feed-empty-placeholder.component';
import { BaseMessageModel, MessageBoxModel } from '../../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedDatasource } from '../../../../../shared/components/feed/providers/feed-datasource';
import { FeedLayoutHooks } from '../../../../../shared/components/feed/providers/feed-layout-hooks';
import { FeedLayoutMediator } from '../../../../../shared/components/feed/providers/feed-layout-mediator';
import { MessageViewScrollHelper } from '../../../../../shared/services/message-view-scroll-helper';
import { LegacyScrollToBottomVisibility } from '../../../../../shared/services/scroll-to-bottom-visibility';
import { MessageExtensionDataLoader } from '../../providers/message-extension-data-loader';
import { messageBoxContextMenuId } from '../../../../../shared/components/message-box/actions/message-box.action-menu';
import { MessageBoxCustomizations } from '../../../../../shared/components/message-box/providers/message-box-embed-customizations';
import { ChannelMessageCustomizations } from './providers/channel-message-customizations';
import { ReactionContext } from '@portal/wen-backend-api';

export interface MessagesViewRenderInfo {
  newMessageLineRef: ElementRef;
  lastMessageRef: ElementRef;
}

@Component({
  selector: 'wen-channel-messages-view',
  templateUrl: './channel-messages-view.component.html',
  styleUrls: ['./channel-messages-view.component.scss'],
  providers: [
    MessageViewScrollHelper, LegacyScrollToBottomVisibility, ResizeHandlerProvider, MessageExtensionDataLoader,
    {
      provide: FeedLayoutHooks,
      useClass: MessageExtensionDataLoader
    },
    {
      provide: MessageBoxCustomizations,
      useClass: ChannelMessageCustomizations
    }
  ]
})
export class ChannelMessagesViewComponent implements OnInit, OnDestroy {
  readonly reactionContext = ReactionContext.CHANNEL_MESSAGE;

  private onDestroy$ = new Subject<void>();
  private visibleNewMessages$ = new Subject<string>();

  public readonly contextMenuId = messageBoxContextMenuId;
  public notificationCount$: Observable<number>;
  public enabledEmojiReaction$: Observable<boolean> = combineLatest([
    this.feedDatasource.disableEmojiReaction$,
    this.feedDatasource.disableFooterFunctions$
  ]).pipe(
    map(([emojisDisabled, footerFunctonsDisabled]) => !emojisDisabled && !footerFunctonsDisabled)
  );

  @Input() channelId: string;
  @Input() flowDirection: 'up' | 'down' = 'up';
  @Input() disableContextMenu: boolean;
  @Input() disableScrollToBottomButton: boolean;

  @ContentChild(WeFeedEmptyPlaceholderComponent) customEmptyPlaceholder: WeFeedEmptyPlaceholderComponent;

  constructor(
    private store: Store,
    public scrollHelper: MessageViewScrollHelper,
    private feedLayoutMediator: FeedLayoutMediator,
    private feedDatasource: FeedDatasource
  ) { }

  ngOnInit() {
    this.visibleNewMessages$.pipe(
      mergeMap((messageId) => {
        return this.store.pipe(
          selectorWithParam(selectMessageDetailById, messageId, this.channelId),
          filter((message) => message?.new),
          first()
        );
      }),
      bufferWhen(() => interval(100)),
      filter(messages => Boolean(messages.length)),
      map(messages => messages.sort((m1, m2) => DateUtil.compare(m1.timestamp, m2.timestamp))),
      takeUntil(this.onDestroy$)
    ).subscribe((messages) => {
      this.store.dispatch(acknowledgeChannelMessagesLocally({ messages }));
      this.store.dispatch(requestChannelMessageAcknowledgeBroadcast({ channelId: messages[0].channelId }));
    });
    this.notificationCount$ = this.store.pipe(
      select(selectCurrentChannel),
      filter(channel => !!channel),
      switchMap((channel) => this.store.pipe(
        selectorWithParam(selectNotificationCountForChannel, channel.id)
      ))
    );
  }

  onScrollToBottomClicked() {
    this.feedLayoutMediator.scrollToBottom();
  }

  enableAutoAcknowledge() {
    const flags$ = this.store.pipe(select(selectCurrentChannelMessagesHistoryFlags));
    const ackId$ = this.store.pipe(select(selectCurrentAutoAcknowledgeChannelId));
    combineLatest([flags$, ackId$]).pipe(
      firstExisty(),
      takeUntil(this.onDestroy$)
    ).subscribe(([flags, ackId]) => {
      // TODO: Fix current channel selector NPE eg.: when the channel is not in the url params
      if (!flags) {
        return;
      }
      if (!ackId && !flags.hasMoreNewer) {
        this.store.dispatch(enableAutoAcknowledge({ channelId: this.channelId }));
      }
    });
  }

  onMessageVisible(messageBoxModel: MessageBoxModel) {
    this.visibleNewMessages$.next(messageBoxModel.messageId);
  }

  toReactionSelectorData(data: BaseMessageModel): ReactionSelectorData {
    return {
      reactionParentId: data.referenceId,
      reactionParentType: this.reactionContext
    };
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

interface MegolmSessionNotFoundErrorParams {
  sessionId: string;
  senderCurve25519: string;
}

export class MegolmSessionNotFoundError extends Error {

  constructor(
    public readonly params: MegolmSessionNotFoundErrorParams
  ) {
    super();
    this.message = `MegolmSessionNotFoundErrorParams + ${JSON.stringify(this.params)}`;
  }

}

interface OlmDecryptionErrorParams {
  sessionId: string;
  senderCurve25519: string;
}

export class OlmDecryptionError extends Error {

  constructor(
    public readonly params: OlmDecryptionErrorParams
  ) {
    super();
    this.message = `OlmDecryptionError + ${JSON.stringify(this.params)}`;
  }

}

export class OlmInboundDesyncError extends OlmDecryptionError {

  constructor(
    public readonly params: OlmDecryptionErrorParams
  ) {
    super(params);
    this.message = `OlmInboundDesyncError + ${JSON.stringify(this.params)}`;
  }

}

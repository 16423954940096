import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaletteProvider } from '@portal/wen-components';

@Component({
  selector: 'wen-quote',
  templateUrl: './quote.component.html',
  styleUrls: ['./quote.component.scss']
})
export class QuoteComponent {
  author = '';
  authorColor = '';

  @Input() set commentAuthor(name: string) {
    this.author = name;
    this.authorColor = this.wenPaletteProvider.getColorFor(name);
  }
  @Input() comment;
  @Input() showCloseIcon = false;
  @Input() isCommentDeleted = false;
  @Output() closed = new EventEmitter<void>();

  constructor(private wenPaletteProvider: PaletteProvider) { }

  closeQuote() {
    this.closed.emit();
  }
}

import { Inject, Injectable, InjectionToken, inject } from '@angular/core';
import { Router } from '@angular/router';
import { extractOutlets } from '../outlet-specific/outlet-utils';
import { simpleDeepLinkRecognizers } from './deep-link-recognizers';

export const DEEP_LINKABLES = new InjectionToken('DEEP_LINKABLES');

export interface DeepLinkRecognizer {
  isMatchForUrl(link: string): boolean;
  extractRouteLink(link: string): string;
  getOpenerTranslation?(): string;
}

@Injectable()
export abstract class DeepLinkable implements DeepLinkRecognizer {

  private router: Router = inject(Router);

  abstract isMatchForUrl(link: string): boolean;
  abstract extractRouteLink(link: string): string;

  protected readonly rootUrl = `${location.origin}`;

  abstract getDeepLink(params?: Record<string, string>): string;

  protected extractOutletParam(viewIdentifier: string) {
    const primaryOutlet = extractOutlets(this.router.routerState.snapshot.root)?.primary;
    if (!primaryOutlet) {
      return null;
    }
    return primaryOutlet.params[viewIdentifier];
  }

}

@Injectable()
export class DeepLinkableUtils {

  private deepLinkRecognizers: DeepLinkRecognizer[];

  constructor(
    @Inject(DEEP_LINKABLES) private deepLinkables: DeepLinkable[]
  ) {
    this.setRecognizers([...this.deepLinkables, ...simpleDeepLinkRecognizers]);
  }

  getRoutePathForUrl(link: string) {
    const recognizersMatch = this.deepLinkRecognizers.find(deepLinkable => {
      return deepLinkable.isMatchForUrl(link);
    });
    return recognizersMatch?.extractRouteLink(link);
  }

  getUrlOpenerTranslation(link: string) {
    const matchedRecognizer = this.deepLinkRecognizers.find(deepLinkable => deepLinkable.isMatchForUrl(link));
    if (matchedRecognizer) {
      return matchedRecognizer?.getOpenerTranslation();
    }
    return 'EMBED_OPEN_INTERNAL_LINK';
  }

  private setRecognizers(recognizers: DeepLinkRecognizer[]) {
    this.deepLinkRecognizers = recognizers;
  }

}

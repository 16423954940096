import { LocationStrategy } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthErrorResultType } from '@portal/wen-embed-api';
import { Tracer } from '@portal/wen-tracer';
import { OAuthErrorEvent, OAuthStorage } from 'angular-oauth2-oidc';
import { finalize, first, from, switchMap } from 'rxjs';
import { WenOauthStorage } from '../../../frame/api/oauth-storage';
import { AUTH_CLIENT_CONFIG, AuthClientConfig } from '../../../frame/api/tokens';
import { EmbeddedOauthWrapper } from '../../../frame/embedded-api/providers';
import { FeatureEnablementService } from '../configuration/feature-enablement';
import { NativeConfigurationService } from '../configuration/native-configuration';
import { WenNavigationHelper } from '../navigation/types';
import { WenStorageService } from '../storage/wen-storage.service';
import { PopupEventHandler } from '../util/popup-event-handler';
import { OauthUrlOpener } from './oauth-url-opener';
import { WenOAuthService } from './wen-oauth.service';

// TODO: Refactor oauth providers so that somehow oidc OauthService is provided per embedded app
@Injectable()
export class EmbeddedAppOauthService extends WenOAuthService {

  constructor(
    protected oauthService: EmbeddedOauthWrapper,
    @Inject(AUTH_CLIENT_CONFIG) externalConfig: AuthClientConfig,
    @Inject(OAuthStorage) oauthStorage: WenOauthStorage,
    wenStorageService: WenStorageService,
    locationStrategy: LocationStrategy,
    oauthUrlOpener: OauthUrlOpener,
    nativeConfiguration: NativeConfigurationService,
    navigationHelper: WenNavigationHelper,
    featureEnablementService: FeatureEnablementService,
    popupEventHandler: PopupEventHandler,
    tracer: Tracer,
  ) {
    super(
      oauthService, externalConfig, oauthStorage, wenStorageService, locationStrategy,
      oauthUrlOpener, nativeConfiguration, navigationHelper, featureEnablementService, tracer, popupEventHandler
    );
  }

  override init() {
    // Do not init without known client
  }

  initLoginFlowInPopup(popupOptions: {
    height?: number;
    width?: number;
  } = {}) {
    const baseConfig = this.oauthService.customQueryParams;

    const popupWindowRef = window.open(
      this.prepareExternalUrl(this.oauthRoutes.logout) + '?onlyAnon=true',
      '',
      this.calculatePopupFeatures(popupOptions)
    );

    return this.popupEventHandler.listenToPopupEvents().pipe(
      first(),
      switchMap(() => {
        this.oauthService.customQueryParams = { ...this.oauthService.customQueryParams, ui_context: 'onlylogin' };
        return from(this.oauthService.loadDiscoveryDocumentAndTryLogin()).pipe(
          switchMap(() => from(this.initLoginFlowInPopupInternal({ windowRef: popupWindowRef }))),
          finalize(() => {
            this.oauthService.customQueryParams = baseConfig;
          })
        );
      })
    );
  }

  /**
   * This function is a re-implementation of the popup flow from the 'angular-oauth2-oidc' package
   * The difference is that it will just return the auth code from the popup instead of trying to log-in with it
   */
  private initLoginFlowInPopupInternal(options?: {
    height?: number;
    width?: number;
    windowRef?: Window;
  }): Promise<{ authCode: string }> {
    options = options || {};
    return this.oauthService.createLoginUrl(
      null,
      null,
      this.oauthService.silentRefreshRedirectUri,
      false,
      { display: 'popup' }
    ).then((url) => {
      return new Promise((resolve, reject) => {
        const checkForPopupClosedInterval = 500;

        let windowRef = null;
        if (options.windowRef && !options.windowRef.closed) {
          windowRef = options.windowRef;
          windowRef.location.href = url;
        }

        let checkForPopupClosedTimer: any;

        const checkForPopupClosed = () => {
          if (!windowRef || windowRef.closed) {
            cleanup();
            reject(new OAuthErrorEvent('popup_closed', {}));
          }
        };
        if (!windowRef) {
          reject(new OAuthErrorEvent('popup_blocked', {}));
        } else {
          checkForPopupClosedTimer = window.setInterval(
            checkForPopupClosed,
            checkForPopupClosedInterval
          );
        }

        const cleanup = () => {
          window.clearInterval(checkForPopupClosedTimer);
          window.removeEventListener('message', listener);
          if (windowRef !== null) {
            windowRef.close();
          }
          windowRef = null;
        };

        const listener = (e: MessageEvent) => {
          const data: string = e?.data;
          if (!data || !(typeof data === 'string')) {
            return;
          }
          const { code, error } = this.getAuthResultFromCodeFragment(data);
          cleanup();
          if (error) {
            reject({ type: error });
            return;
          }
          resolve({ authCode: code });
        };
        window.addEventListener('message', listener);
      });
    });
  }

  getAuthResultFromCodeFragment(urlFragment: string) {
    const codeFragment = urlFragment.startsWith('#') ? urlFragment.substring(1) : urlFragment;
    const { code, error } = this.oauthService.getPartsFromUrl(codeFragment);
    if (error) {
      const errorType: AuthErrorResultType = error === 'deny' ? 'profile_access_denied' : 'unknown_error';
      return { error: errorType };
    }
    return { code };
  }
}

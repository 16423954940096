import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { SearchBarModule } from '../../../shared/components/search-bar/search-bar.module';
import { ScrollStateModule } from '../../../shared/directives/scroll-state/scroll-state.module';
import { SharedModule } from '../../../shared/shared.module';
import { ChannelListItemComponent } from './components/channel-list/channel-list-item/channel-list-item.component';
import { ChannelListComponent } from './components/channel-list/channel-list.component';
import { DiscoverChannelListComponent } from './components/discover-channel-list/discover-channel-list.component';
import { UserChannelListComponent } from './components/user-channel-list/user-channel-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { ActionMenuModule, ACTION_MENU_ITEMS, ContextMenuComponent, ContextMenuTriggerDirective, WenIconModule, WenCardModule, ChannelIndicatorComponent } from '@portal/wen-components';
import { CHANNEL_LIST_ITEM_CONTEXT_MENU_ITEMS } from './components/channel-list/channel-list-item/action-menu/channel-list-item.actions';

@NgModule({
  imports: [
    SharedModule,
    ActionMenuModule,
    SearchBarModule,
    ScrollingModule,
    ScrollStateModule,
    TranslateModule.forChild(),
    WenIconModule,
    ContextMenuTriggerDirective,
    ContextMenuComponent,
    WenCardModule,
    ChannelIndicatorComponent
  ],
  declarations: [
    ChannelListComponent,
    DiscoverChannelListComponent,
    UserChannelListComponent,
    ChannelListItemComponent
  ],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: CHANNEL_LIST_ITEM_CONTEXT_MENU_ITEMS, multi: true }
  ],
  exports: [
    DiscoverChannelListComponent,
    UserChannelListComponent
  ],
})
export class ChannelListModule { }

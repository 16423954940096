import { EntityAdapter, EntityMap, EntityState, Update } from '@ngrx/entity';
import { EntityMapOneStr } from '@ngrx/entity/src/models';
import { PagingHistoryFlags } from '@portal/wen-backend-api';
import { deepMerge } from '../../../common/util/deepmerge';
import { WithId, findEntityById } from './state-adapter-utils';

type NestedEntityProp<K extends string, T> = { [key in K]: T };

type DisallowEntityProps = {
  ids?: never;
  entities?: never;
} & Partial<Record<keyof CollectionEntityStateExtras, any>>;

export type WithNestedEntity<NestedKey extends string, TNestedEntity extends WithId> =
  WithId &
  Partial<NestedEntityProp<NestedKey, EntityState<TNestedEntity>>>;

type ExtractEntityType<T> = T extends EntityAdapter<infer GG> ? GG : never;

export type WithHistoryCount = {
  totalCount?: number;
};

export type CollectionEntityStateExtras = PagingHistoryFlags & WithHistoryCount;

export type CollectionEntityState<TMessage> = EntityState<TMessage> & CollectionEntityStateExtras;

export const createNestedEntityAdapter = <
  TRootEntityAdapter extends EntityAdapter<WithNestedEntity<KNestedEntity, TNestedEntity>>,
  TRootEntity extends ExtractEntityType<TRootEntityAdapter>,
  TNestedEntity extends WithId,
  KNestedEntity extends (keyof TRootEntity) & string
>({
  rootEntityAdapter,
  nestedEntityAdapter,
  nestedEntityKey,
  rootEntityInitializer
}: {
  rootEntityAdapter: TRootEntityAdapter;
  nestedEntityAdapter: EntityAdapter<TNestedEntity>;
  nestedEntityKey: KNestedEntity;
  rootEntityInitializer?: (id: string) => TRootEntity;
}) => {

  const ensureEntity = (
    entityId: string,
    entityState: EntityState<WithNestedEntity<KNestedEntity, TNestedEntity>>,
  ) => {
    if (!entityId) {
      throw new Error('No entity id provided for nested entity adapter!');
    }
    let targetEntity = findEntityById(entityId, entityState);
    if (!targetEntity) {
      const rootProps = rootEntityInitializer ? rootEntityInitializer(entityId) : {};
      const nestedObj = {
        [nestedEntityKey]: nestedEntityAdapter.getInitialState(),
        ...rootProps
      } as NestedEntityProp<KNestedEntity, EntityState<TNestedEntity>>;
      targetEntity = { id: entityId, ...nestedObj };
    } else if (!targetEntity[nestedEntityKey]) {
      targetEntity = { ...targetEntity, [nestedEntityKey]: nestedEntityAdapter.getInitialState() };
    }
    return targetEntity;
  };

  const upsertStateExtras = (
    rootEntityId: string,
    entityState: EntityState<TRootEntity>,
    entityStateExtras: DisallowEntityProps
  ) => {
    const targetEntity = ensureEntity(rootEntityId, entityState);
    const nestedTargetEntity = targetEntity[nestedEntityKey];
    const newEntities = rootEntityAdapter.upsertOne({
      ...targetEntity,
      [nestedEntityKey]: {
        ...nestedTargetEntity,
        ...entityStateExtras
      }
    }, entityState);
    return newEntities;
  };

  const upsertMany = (
    rootEntityId: string,
    nestedEntities: TNestedEntity[],
    entityState: EntityState<TRootEntity>,
    entityStateExtras: DisallowEntityProps = {}
  ) => {
    const targetEntity = ensureEntity(rootEntityId, entityState);
    const nestedTargetEntity = targetEntity[nestedEntityKey];
    const newNestedEntities = nestedEntityAdapter.upsertMany(nestedEntities, {
      ...nestedTargetEntity,
      ...entityStateExtras
    });
    const newEntities = rootEntityAdapter.upsertOne({ ...targetEntity, [nestedEntityKey]: newNestedEntities }, entityState);
    return newEntities;
  };

  const upsertOne = (
    rootEntityId: string,
    nestedEntity: TNestedEntity,
    entityState: EntityState<TRootEntity>,
    entityStateExtras: DisallowEntityProps = {}
  ) => {
    return upsertMany(rootEntityId, [nestedEntity], entityState, entityStateExtras);
  };

  const updateMany = (
    rootEntityId: string,
    nestedEntities: Partial<TNestedEntity>[],
    entityState: EntityState<TRootEntity>,
    entityStateExtras: DisallowEntityProps = {},
    shouldDeepMerge: boolean = false
  ) => {
    const targetEntity = ensureEntity(rootEntityId, entityState);
    const nestedTargetEntity = targetEntity[nestedEntityKey];
    if (!nestedTargetEntity?.ids?.length) {
      return entityState;
    }
    const updates: Update<TNestedEntity>[] = nestedEntities.map((nestedEntity) => {
      if (shouldDeepMerge) {
        const existingEntity = nestedTargetEntity.entities[nestedEntity.id];
        const mergedEntity = deepMerge(existingEntity, nestedEntity) as TNestedEntity;
        return { id: nestedEntity.id, changes: mergedEntity };
      }
      return { id: nestedEntity.id, changes: nestedEntity };
    });
    const newNestedEntities = nestedEntityAdapter.updateMany(updates, {
      ...nestedTargetEntity,
      ...entityStateExtras
    });
    const newEntityState = rootEntityAdapter.upsertOne({ ...targetEntity, [nestedEntityKey]: newNestedEntities }, entityState);
    return newEntityState;
  };

  const updateOne = (
    rootEntityId: string,
    nestedEntity: Partial<TNestedEntity>,
    entityState: EntityState<TRootEntity>
  ) => {
    return updateMany(rootEntityId, [nestedEntity], entityState);
  };

  const removeOne = (
    rootEntityId: string,
    nestedEntityId: string,
    entityState: EntityState<TRootEntity>
  ) => {
    const targetEntity = findEntityById(rootEntityId, entityState);
    if (!targetEntity || !targetEntity[nestedEntityKey]) {
      return entityState;
    }
    const newNestedEntities = nestedEntityAdapter.removeOne(nestedEntityId, targetEntity[nestedEntityKey]);
    const newEntityState = rootEntityAdapter.upsertOne({ ...targetEntity, [nestedEntityKey]: newNestedEntities }, entityState);
    return newEntityState;
  };

  const removeAll = (
    rootEntityId: string,
    entityState: EntityState<TRootEntity>
  ) => {
    const targetEntity = findEntityById(rootEntityId, entityState);
    if (!targetEntity || !targetEntity[nestedEntityKey]) {
      return entityState;
    }
    const newNestedEntities = nestedEntityAdapter.removeAll(targetEntity[nestedEntityKey]);
    const newEntityState = rootEntityAdapter.upsertOne({ ...targetEntity, [nestedEntityKey]: newNestedEntities }, entityState);
    return newEntityState;
  };

  const removeOneByNestedId = (
    nestedEntityId: string,
    entityState: EntityState<TRootEntity>
  ) => {
    if (!entityState?.entities) {
      return entityState;
    }
    const targetRootEntity = Object.values(entityState.entities).find((rootEntity) => {
      const nestedEntity = rootEntity[nestedEntityKey]?.entities[nestedEntityId];
      return Boolean(nestedEntity);
    });
    if (!targetRootEntity) {
      return entityState;
    }
    const newNestedEntities = nestedEntityAdapter.removeOne(nestedEntityId, targetRootEntity[nestedEntityKey]);
    const newEntityState = rootEntityAdapter.upsertOne({ ...targetRootEntity, [nestedEntityKey]: newNestedEntities }, entityState);
    return newEntityState;
  };

  const mapAll = (
    mapFn: EntityMap<TNestedEntity>,
    entityState: EntityState<TRootEntity>
  ) => {
    const changes = rootEntityAdapter.map((rootEntity) => {
      const nestedEntityState = rootEntity[nestedEntityKey];
      if (!nestedEntityState) {
        return rootEntity;
      }
      const change = nestedEntityAdapter.map(mapFn, nestedEntityState);
      return { ...rootEntity, [nestedEntityKey]: change };
    }, entityState);
    return changes;
  };

  const mapOne = (
    mapOneFn: EntityMapOneStr<TNestedEntity>,
    entityState: EntityState<TRootEntity>,
    optionalRootEntityId?: string // For less state mutation the root entity id can be specified
  ) => {
    const innerMapFn = (rootEntity: WithNestedEntity<KNestedEntity, TNestedEntity>) => {
      const nestedEntityState = rootEntity[nestedEntityKey];
      if (!nestedEntityState?.ids) {
        return rootEntity;
      }
      const ids = nestedEntityState.ids.map(String);
      if (!nestedEntityState || !ids.includes(mapOneFn.id)) {
        return rootEntity;
      }
      const change = nestedEntityAdapter.mapOne(mapOneFn, nestedEntityState);
      return { ...rootEntity, [nestedEntityKey]: change };
    };
    let changes = entityState;
    if (optionalRootEntityId) {
      changes = rootEntityAdapter.mapOne({
        id: optionalRootEntityId,
        map: (rootEntity) => {
          return innerMapFn(rootEntity);
        }
      }, entityState);
    } else {
      changes = rootEntityAdapter.map((rootEntity) => {
        return innerMapFn(rootEntity);
      }, entityState);
    }
    return changes;
  };

  return {
    upsertStateExtras,
    upsertOne,
    upsertMany,
    updateOne,
    updateMany,
    removeOne,
    removeAll,
    removeOneByNestedId,
    mapAll,
    mapOne,
  };
};

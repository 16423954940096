import { Injectable } from '@angular/core';
import { WenBreakpointObserver } from '@portal/wen-components';
import { extractWildcardRoute } from '../../../core/common/util/routing-helpers';
import { AppConfigurationProvider } from '../../../core/services/configuration/app-confguration';
import { AvailableRoutes } from '../../../core/services/navigation/available-routes';
import { primaryOutletAppRoutes } from '../../../views/apps/routes/multi-outlet-apps.routes';
import { primaryOutletChannelRoutes } from '../../../views/channel/routes/multi-outlet-channel.routes';
import { primaryOutletEventRoutes } from '../../../views/events/routes/multi-outlet-events.routes';
import { primaryOutletHomeRoutes } from '../../../views/home/routes/multi-outlet-home.routes';
import { wenMultiOutletRoutes } from './wen-multi-outlet.routes';
import { wenSingleOutletRoutes } from './wen-single-outlet.routes';

@Injectable()
export class WenRoutesProvider {

  constructor(
    private config: AppConfigurationProvider,
    private breakpointObserver: WenBreakpointObserver
  ) { }

  getSingleOutletStartPage() {
    return `${this.config.startPage}`;
  }

  getMultiOutletStartPage() {
    switch (this.config.startPage) {
      case AvailableRoutes.CHANNEL:
        return extractWildcardRoute(primaryOutletChannelRoutes).redirectTo;
      case AvailableRoutes.APPS:
        return extractWildcardRoute(primaryOutletAppRoutes).redirectTo;
      case AvailableRoutes.EVENT:
        return extractWildcardRoute(primaryOutletEventRoutes).redirectTo;

      default:
        return extractWildcardRoute(primaryOutletHomeRoutes).redirectTo;
    }
  }

  private getSingleOutletRoutes() {
    return wenSingleOutletRoutes(this.getSingleOutletStartPage());
  }

  private getMultiOutletRoutes() {
    const startPage = this.getMultiOutletStartPage();
    return wenMultiOutletRoutes(startPage);
  }

  getActiveRoutes() {
    if (this.breakpointObserver.isDesktopStyleDevice) {
      return this.getMultiOutletRoutes();
    } else {
      return this.getSingleOutletRoutes();
    }
  }

}

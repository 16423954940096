<wen-list-item 
  *ngIf="listItemModel$ | async as model"
  [showAfter]="true"
  [selected]="isSelected$ | async"
  justifyInfoContent="center"
  [context-menu-trigger]="menu"
  [openMode]="'press'"
>
  <wen-context-menu #menu>
    <wen-action-menu [actions]="actions$ | async" [data]="model"></wen-action-menu>
  </wen-context-menu>
  <wen-circle-image-view
    wen-list-item-icon
    [imageUrl]="model?.icon"
    [shape]="'circle'"
    [placeholderText]="model.title"
  ></wen-circle-image-view>
  <span wen-list-item-title>{{ model.title }}</span>
  <wen-icon *ngIf="model?.muteIcon" class="wen-icon-small" wen-list-item-title-suffixicon [iconName]="model.muteIcon" [color]="'#767676'"></wen-icon>
  <wen-icon *ngIf="model?.pinTimestamp" wen-list-item-title-suffixicon class="wen-channel-list-item-title-icon wen-icon-small" iconName="pin" color="#767676"></wen-icon>
  <div wen-list-item-description class="wen-chat-list-item-description">
    <ng-container *ngIf="!model.details?.deleted">
      <wen-message-status-indicator *isLoggedInUser="model?.insertUser?.id; else otherUserTemplate"
        [messageStatus]="model.lastMessage | calculateMessageStatus: model" [iconClassName]="'wen-icon-small'"
        class="wen-message-status-indicator">
      </wen-message-status-indicator>
    </ng-container>
    <ng-template #otherUserTemplate>
      <span *ngIf="model.roomType === 'GROUP' && model?.insertUser?.name" class="wen-list-item-description" with-colon>{{model?.insertUser?.name}}</span>
    </ng-template>
    <wen-icon *ngIf="model.details?.icon" [iconName]="model.details?.icon" class="wen-icon-small"></wen-icon>
    <span class="wen-list-item-description" *ngIf="model.details?.title" [innerHtml]="model.details.title | translate | removeMarkdown : markdownEnabled" [class.italic]="!!model.details?.isItalic"></span>
  </div>
  <ng-container wen-list-item-after>
    <div portal-subheading-1 class="wen-list-item-timestamp">{{model.timestamp | messageDateFormatter }}</div>
    <wen-notification-count *ngIf="model.count > 0" class="wen-notification-count-big" [value]="model.count" [disabled]="model.isMuted">
    </wen-notification-count>
  </ng-container>
</wen-list-item>
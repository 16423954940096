import { Component } from '@angular/core';
import { ColorPalette } from '../../../../../../../shared/directives/colorize/palette-provider';
import { UserProfileViewConfiguration } from '../../user-profile-view-configuration';
import { EntryId } from '../../user-profile-view-types';
import { WenNavigationHelper } from '../../../../../../../core/services/navigation/types';

@Component({
  selector: 'wen-help-view',
  templateUrl: './help-view.component.html',
  styleUrls: ['./help-view.component.scss'],
  providers: [UserProfileViewConfiguration]
})
export class HelpViewComponent {

  protected readonly ColorPalette = ColorPalette;

  constructor(public config: UserProfileViewConfiguration, private navigationHelper: WenNavigationHelper) { }

  handleEntryClick(entryId: EntryId) {
    switch (entryId) {
      case EntryId.FAQ: {
        this.navigationHelper.navigateToFAQ();
        return;
      }
      case EntryId.MARKDOWN: {
        this.navigationHelper.navigateToMarkdown();
        return;
      }
      default : {
        return;
      }
    }
  }
}

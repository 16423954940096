<wen-form-progress [hasProgress]="hasProgress$ | async"></wen-form-progress>
<form wenFormStore class="wen-form" [formGroup]="inviteFormGroup">
  <div class="wen-form-group wen-invite-header">
    <wen-invitees-display [inviteeData]="importedInviteeData$ | async" [headerImage]="'/assets/wen-widget/image/invite-to-channel-page-image.svg'"
      [importeeControlName]="'importedInvitees'">
      <span wen-invite-counter-label>{{ "INVITE_CHANNEL_COUNTER_LABEL" | translate }}</span>
      <span wen-invite-counter-description>{{ "INVITE_CHANNEL_COUNTER_DESCRIPTION" | translate }}</span>
    </wen-invitees-display>
  </div>
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper">
      <div class="wen-invite-to-channel-form-label" portal-subheading-1>{{ "INVITE_CHANNEL_CHANNEL_LIST_LABEL" | translate }}</div>
      <wen-selection-list [formControlName]="'selectedChannels'" [selectionItems]="selectedChannels"
        [selectionModel]="channelsSelectionModel" [selectionOptionPosition]="'postfix'"
        [selectionOptionBorderType]="'borderless'" [withImage]="true" [selectionOptionBorderType]="'normal'">
      </wen-selection-list>
    </div>
  </div>
  <div class="wen-form-group" *ngIf="hasNetworkInviteEntity$ | async">
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ "INVITE_CHANNEL_EMAIL_FIELD_LABEL" | translate }}</mat-label>
        <portal-field [formControlName]="'emailBody'" [multiLine]="true" [rows]="{ min: 1, max: 5 }"></portal-field>
      </mat-form-field>
    </div>
    <div class="wen-form-group-hint" portal-caption>{{ "INVITE_CHANNEL_EMAIL_FIELD_DESCRIPTION" | translate }}</div>
  </div>
</form>
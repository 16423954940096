<ng-container *ngIf="loadResult$ | async as loadResult">
  <wen-filter-view *ngIf="isFilteringEnabled" [formGroup]="filterGroup">
    <wen-filter-select-component [formControlName]="eventCategoryControlName"
    [defaultFilter]="categoryFilterService.fallbackAllCategoryFilter" filter-element
    [filterEntityType]="filterEntityType" (click)="openEventCategoryFilterSelector()"></wen-filter-select-component>
    <wen-filter-select-component [formControlName]="eventDateControlName"
    [defaultFilter]="dateFilterService.fallbackAllDateFilter" filter-element [filterEntityType]="filterEntityType"
    (click)="openEventDateFilterSelector()"></wen-filter-select-component>
    <wen-filter-select-component [formControlName]="eventGeoLocationControlName"
      [defaultFilter]="geoLocationFilterService.defaultGeoFilter" filter-element
      [filterEntityType]="filterEntityType" (click)="openGeoLocationFilterSelector()"></wen-filter-select-component>
  </wen-filter-view>
  <ng-container *ngTemplateOutlet="loadResult.data.length ?
    eventList : emptyListView; context: {data: loadResult.data, mode: loadResult.mode}"></ng-container>
</ng-container>

<ng-template #eventList let-data="data">
  <wen-event-list (endReached)="endReached()" [events]="data" [listItemAfterRef]="subscription">
    <ng-template #subscription let-event="event">
      <wen-discovery-event-list-item-after [event]="event"></wen-discovery-event-list-item-after>
    </ng-template>
  </wen-event-list>
</ng-template>

<ng-template #emptyListView let-mode="mode">
  <wen-empty-search-result *ngIf="mode === 'search'"></wen-empty-search-result>
</ng-template>

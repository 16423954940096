<div class="wen-message-error-wrapper" *ngIf="errorModel" [class.wen-message-error-wrapper-centered]="errorModel.centered">
  <div portal-body-2 class="wen-message-error-title">{{ errorModel.titleTranslationKey | translate }}</div>
  <div *ngIf="errorModel.descriptionTranslationKey" portal-caption class="wen-message-error-description">{{
    errorModel.descriptionTranslationKey | translate }}</div>
  <div class="wen-message-error-image-container">
    <img [src]="errorModel.imagePath" />
  </div>
  <ng-container *ngIf="showNavigateToWelcome">
    <wen-interact-button class="wen-interact-button-flat wen-interact-button-round"
      (click)="navigateToWelcome()">{{ "CHANNEL_ERROR_CHANNEL_DELETED_BUTTON_LABEL" | translate }}</wen-interact-button>
  </ng-container>
</div>
<wen-navigate-to-channel-button *ngIf="showNavigateToChannel" [image]="channelData.icon" [description]="channelData.title"
  (clicked)="navigateToChannel(channelData.id)"></wen-navigate-to-channel-button>
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EncryptedMessageEventResponses, isEncryptedSendMessageEvent, MessageEvent, MessageModificationState } from '@portal/wen-backend-api';
import { DateUtil } from '../../common/date/date-util';
import { selectChannels, selectUserChannelIds } from '../channel/channel.selectors';
import { selectAllRooms } from '../chat/chat.selectors';
import { RootState } from '../root/public-api';
import { notificationFeatureKey } from './constants';
import { channelMessageNotificationAdapter, chatMessageNotificationAdapter, chatMessageNotificationEventAdapter, ChatNotificationEventEntity, NotificationState } from './notification.state';

export interface WithFeatureState extends RootState {
  [notificationFeatureKey]: NotificationState;
}

export const selectNotificationState = createFeatureSelector<NotificationState>(
  notificationFeatureKey
);

export const selectChannelMessagesNotificationEntityState = createSelector(
  selectNotificationState,
  (state) => state.channelNotifications
);

export const selectChatMessagesNotificationEntityState = createSelector(
  selectNotificationState,
  (state) => state.chatNotifications
);

export const {
  selectAll: selectAllChannelNotifications,
  selectEntities: selectChannelNotificationEntities
} = channelMessageNotificationAdapter.getSelectors(selectChannelMessagesNotificationEntityState);

export const selectChannelNotifications = createSelector(
  selectAllChannelNotifications,
  selectUserChannelIds,
  (notifications, userChannelIds) => {
    if (!notifications) {
      return notifications;
    }
    const filteredForNetwork = notifications.filter((notification) => {
      return userChannelIds.includes(notification.channelId);
    });
    return filteredForNetwork;
  }
);

export const selectActiveChannelNotifications = createSelector(
  selectChannelNotifications,
  selectChannels,
  (notificationEntities, channelEntities) => {
    if (!notificationEntities) {
      return [];
    }
    const activeNotifications = notificationEntities.filter((notification) => {
      const channelEntity = channelEntities.find((channel) => channel.id === notification.channelId);
      return channelEntity && !channelEntity.isMuted;
    });
    return activeNotifications;
  }
);

export const selectActiveChannelNotificationCount = createSelector(
  selectActiveChannelNotifications,
  (activeNotifications) => {
    return activeNotifications.reduce((acc, current) => {
      return acc + current.unreadCount;
    }, 0);
  }
);

export const selectNotificationCountForChannel = createSelector(
  selectChannelNotifications,
  (entities) => (channelId: string) => {
    const channelMessagesNotification = entities.find(notification => notification.channelId === channelId);
    return channelMessagesNotification?.unreadCount || 0;
  }
);

export const selectNativeNotificationRegistrationToken = createSelector(
  selectNotificationState,
  (state) => state.nativeNotificationRegistrationToken
);

export const selectRecentMessageForChannel = createSelector(
  selectNotificationState,
  (state) => (channelId: string) => {
    const notificationsForChannel = state.channelNotifications.entities[channelId];
    if (!notificationsForChannel) {
      return null;
    }
    const { latestMessages } = notificationsForChannel;
    const notDeletedMessages = latestMessages.filter(message => message.type !== MessageModificationState.DELETED);
    return notDeletedMessages[notDeletedMessages.length - 1];
  }
);

export const selectRecentChannelMessage = createSelector(
  selectRecentMessageForChannel,
  (channelMessageSelector) => (channelId: string) => {
    return channelMessageSelector(channelId);
  }
);

export const selectNotificationMessagesBySessionIdForRoom = createSelector(
  selectNotificationState,
  (state) => (roomId: string, sessionIds: string[]) => {
    const notificationsForRoom = state.chatNotifications.entities[roomId];
    if (!notificationsForRoom) {
      return [];
    }
    const eventEntities = chatMessageNotificationEventAdapter.getSelectors().selectAll(notificationsForRoom.messageEvents);
    if (!eventEntities.length) {
      return [];
    }
    const foundEventEntities = sessionIds.map((sessionId) => {
      return eventEntities.filter(eventEntity => {
        const originalEvent = eventEntity.originalEvent as MessageEvent<EncryptedMessageEventResponses>;
        const { payload: { content } } = originalEvent;
        return content.sessionId === sessionId;
      });
    });
    return foundEventEntities
      .filter((eventEntity) => Boolean(eventEntity))
      .flat();
  }
);

export const {
  selectAll: selectAllChatNotifications,
  selectEntities: selectChatNotificationEntities
} = chatMessageNotificationAdapter.getSelectors(selectChatMessagesNotificationEntityState);

export const {
  selectAll: selectAllChatNotificationEventEntities
} = chatMessageNotificationEventAdapter.getSelectors();

export const selectChatNotifications = createSelector(
  selectAllChatNotifications,
  (notifications) => notifications
);

export const selectLatestMessageForChat = createSelector(
  selectChatNotifications,
  (chatNotifications) => (roomId: string) => {
    const notificationsForRoom = chatNotifications.find(notification => notification.id === roomId);
    if (!notificationsForRoom) {
      return null;
    }
    const { messageEvents } = notificationsForRoom;
    let messageNotifications = chatMessageNotificationEventAdapter.getSelectors().selectAll(messageEvents);
    messageNotifications = messageNotifications.sort((message1, message2) => {
      const message1Timestamp = message1.decryptedEvent.message.timestamp || message1.originalEvent.insertTimestamp;
      const message2Timestamp = message2.decryptedEvent.message.timestamp || message2.originalEvent.insertTimestamp;
      return DateUtil.compareNullSafe(message1Timestamp, message2Timestamp);
    });
    const latestMessage = messageNotifications[0];
    return latestMessage;
  }
);

export const selectLatestAckForChat = createSelector(
  selectChatNotifications,
  (chatNotifications) => (roomId: string) => {
    const notificationsForRoom = chatNotifications.find(notification => notification.id === roomId);
    if (!notificationsForRoom) {
      return null;
    }
    return notificationsForRoom.lastAckTimestamp;
  }
);

export const selectActiveChatNotifications = createSelector(
  selectChatNotifications,
  selectAllRooms,
  (notificationEntities, roomEntities) => {
    if (!notificationEntities.length) {
      return [];
    }
    const activeNotifications = notificationEntities.filter((notification) => {
      const roomEntity = roomEntities.find(room => room.id === notification.id);
      return roomEntity && !roomEntity.details.isMuted;
    });
    return activeNotifications;
  }
);

export const selectActiveChatNotificationCount = createSelector(
  selectActiveChatNotifications,
  (activeNotifications) => {
    return activeNotifications.reduce((acc, current) => {
      return acc + current.unreadCount;
    }, 0);
  }
);

export const selectNotificationCountForChat = createSelector(
  selectAllChatNotifications,
  chatNotifications => (roomId: string) => {
    const matchingNotification = chatNotifications.find(notification => notification.id === roomId);
    return matchingNotification?.unreadCount || 0;
  }
);

export const selectNotificationMessagesBySessionId = createSelector(
  selectAllChatNotifications,
  chatNotifications => (megolmSessionId: string) => {
    let targetEventEntity: ChatNotificationEventEntity = null;
    chatNotifications.some(notification => {
      const { messageEvents } = notification;
      if (!messageEvents?.entities) {
        return false;
      }
      const eventEntitites = selectAllChatNotificationEventEntities(messageEvents) || [];
      const targetEvent = eventEntitites.find(eventEntitity => {
        const { originalEvent } = eventEntitity;
        if (isEncryptedSendMessageEvent(originalEvent)) {
          const megolmMessageContent = originalEvent.payload.content;
          return megolmMessageContent.sessionId === megolmSessionId;
        }
        return false;
      });
      if (targetEvent) {
        targetEventEntity = targetEvent;
      }
      return Boolean(targetEvent);
    });
    return targetEventEntity;
  }
);

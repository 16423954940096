import { Pipe, PipeTransform } from '@angular/core';
import { ChatMessageEntity, ChatRoomEntity } from '../../../../core/store/chat/chat.state';
import { calculateChatMessageStatus } from '../util/chat-message-status-calculator';

@Pipe({
  name: 'calculateMessageStatus'
})
export class CalculateMessageStatusPipe implements PipeTransform {

  transform(
    messageEntity: Pick<ChatMessageEntity, 'decryptionError' | 'eventId' | 'messageContent' | 'state'>,
    room: Pick<ChatRoomEntity, 'lastReadTimestamp' | 'lastReceivedTimestamp'>
  ) {
    return calculateChatMessageStatus(messageEntity, room);
  }

}

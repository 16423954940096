import { inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { PermissionLevel } from '../../../../../core/services/user-management/permission-level';
import { selectContactsOpenRequestCount } from '../../../../../core/store/contacts/contacts.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { ColorPalette } from '../../../../../shared/directives/colorize/palette-provider';
import { EntryId, UserProfileMenuEntry, UserProfileMenuEntryFactory } from './user-profile-view-types';

export const dataProtection: UserProfileMenuEntry = {
  entryId: EntryId.DATA_PROTECTION,
  iconName: 'lock', translationKey: 'USER_PROFILE_PRIVACY',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const privacySettings: UserProfileMenuEntry = {
  entryId: EntryId.PRIVACY_SETTINGS,
  iconName: 'lock', translationKey: 'USER_PROFILE_PRIVACY_SETTINGS',
  fillColor: ColorPalette.DARK_BLUE, requiredPermission: PermissionLevel.ANONYMOUS,
  featureToggleBased: true
};
export const termsOfUser: UserProfileMenuEntry = {
  entryId: EntryId.TERMS_OF_USE,
  iconName: 'terms_of_use', translationKey: 'USER_PROFILE_TERMS_OF_USE',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const impressum: UserProfileMenuEntry = {
  entryId: EntryId.IMPRESSUM,
  iconName: 'impressum', translationKey: 'USER_PROFILE_IMPRESSUM',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const licenses: UserProfileMenuEntry = {
  entryId: EntryId.LICENSES,
  iconName: 'license', translationKey: 'USER_PROFILE_LICENSES',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const profile: UserProfileMenuEntry = {
  entryId: EntryId.PROFILE,
  iconName: 'user', translationKey: 'USER_PROFILE_MY_PROFILE',
  fillColor: ColorPalette.GOLD, requiredPermission: PermissionLevel.REGISTERED_USER
};
export const createContactsEntry: UserProfileMenuEntryFactory = () => {
  const store = inject(Store<RootState>);
  const contactsEntry: UserProfileMenuEntry = {
    entryId: EntryId.CONTACTS,
    iconName: 'contacts', translationKey: 'USER_PROFILE_CONTACTS',
    fillColor: ColorPalette.OCEAN_BLUE, requiredPermission: PermissionLevel.REGISTERED_USER,
    featureToggleBased: true,
    notificationConfig: {
      count$: store.pipe(select(selectContactsOpenRequestCount))
    }
  };
  return contactsEntry;
};
export const invite: UserProfileMenuEntry = {
  entryId: EntryId.INVITE,
  iconName: 'add_user', translationKey: 'USER_PROFILE_INVITE',
  fillColor: ColorPalette.LIGHT_GREEN, requiredPermission: PermissionLevel.REGISTERED_USER,
  featureToggleBased: true
};
export const manifest: UserProfileMenuEntry = {
  entryId: EntryId.MANIFEST,
  iconName: 'value_manifesto', translationKey: 'USER_PROFILE_MANIFEST',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const communityGuideline: UserProfileMenuEntry = {
  entryId: EntryId.COMMUNITY_GUIDELINE,
  iconName: 'community_guideline', translationKey: 'USER_PROFILE_COMMUNITY_GUIDELINE',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const help: UserProfileMenuEntry = {
  entryId: EntryId.HELP,
  iconName: 'help', translationKey: 'USER_PROFILE_HELP',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const netiquette: UserProfileMenuEntry = {
  entryId: EntryId.NETIQUETTE,
  iconName: 'netiquette', translationKey: 'USER_PROFILE_NETIQUETTE',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const contactSupport: UserProfileMenuEntry = {
  entryId: EntryId.CONTACT_SUPPORT,
  iconName: 'contact_us', translationKey: 'USER_PROFILE_CONTACT_SUPPORT',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const accessibility: UserProfileMenuEntry = {
  entryId: EntryId.ACCESSIBILITY,
  iconName: 'accessibility', translationKey: 'USER_PROFILE_ACCESSIBILITY',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const anonymousLogin: UserProfileMenuEntry = {
  entryId: EntryId.LOGOUT,
  translationKey: 'USER_PROFILE_ANONYMOUS_LOGIN',
  requiredPermission: PermissionLevel.ANONYMOUS
};
export const register: UserProfileMenuEntry = {
  entryId: EntryId.REGISTER,
  translationKey: 'USER_PROFILE_ANONYMOUS_REGISTER',
  requiredPermission: PermissionLevel.ANONYMOUS
};
export const autoRespone: UserProfileMenuEntry = {
  entryId: EntryId.AUTO_REPLY,
  iconName: 'auto_reply', translationKey: 'USER_PROFILE_AUTO_REPLY',
  fillColor: ColorPalette.ORANGE, requiredPermission: PermissionLevel.REGISTERED_USER,
  featureToggleBased: true
};
export const hotline: UserProfileMenuEntry = {
  entryId: EntryId.HOTLINE,
  iconName: 'phonecall', translationKey: 'USER_PROFILE_HOTLINE', fillColor: ColorPalette.LIGHT_BLUE,
  requiredPermission: PermissionLevel.ANONYMOUS, featureToggleBased: true
};
export const selectLanguage: UserProfileMenuEntry = {
  entryId: EntryId.SELECT_LANGUAGE,
  iconName: 'language', translationKey: 'USER_PROFILE_LANGUAGE',
  fillColor: ColorPalette.PINK, requiredPermission: PermissionLevel.ANONYMOUS, featureToggleBased: true
};

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { OverlayManager, WenSnackbarOpener } from '@portal/wen-components';
import { of } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { FormStoreMediator } from '../../../../shared/form-store/form-store-mediator';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { hasChanges } from '../../../common/util/forms';
import { RootState } from '../../root/public-api';
import { acceptLeaveEditMode, cancelLeaveEditMode, clearAllFormValues, clearFormValues, requestLeaveEditMode, showSaveSuccessToast } from '../form.actions';
import { selectEditFormById } from '../form.selectors';

@Injectable()
export class FormEffects {

  private requestLeaveWhenValidated$ = this.actions$.pipe(
    ofType(requestLeaveEditMode),
    switchMap((action) => {
      return this.store.pipe(
        select(selectEditFormById(action.formId)),
        first(),
        switchMap((editForm) => {
          if (!editForm) {
            return of(true);
          }
          return this.formStoreMediator.ensureFormValidated(editForm.formId).pipe(
            first(),
          );
        }),
        map(() => action)
      );
    })
  );

  requestLeaveEditMode$ = createEffect(() => this.requestLeaveWhenValidated$.pipe(
    mapWithFirstFrom(({ formId }) => this.store.pipe(
      select(selectEditFormById(formId)),
      first(),
      switchMap((formEntity) => {
        if (!formEntity) {
          return of(true);
        }
        const { initialValues, changedValues } = formEntity;
        const changed = hasChanges(initialValues, changedValues);
        if (changed) {
          return this.overlayManager.dialog.openLeaveConfirmationDialog().afterClosed().pipe(
            first(),
            map(result => {
              return result?.result === 'ok';
            })
          );
        } else {
          return of(true);
        }
      }),
    )),
    switchMap(([action, canLeave]) => {
      const clearAction = action.clearAllForms ? clearAllFormValues() : clearFormValues({ formId: action.formId });
      return canLeave ? [acceptLeaveEditMode(), clearAction] : [cancelLeaveEditMode()];
    })
  ));

  showSaveSuccessToast$ = createEffect(() => this.actions$.pipe(
    ofType(showSaveSuccessToast),
    tap(({ notificationText }) => {
      this.wenSnackbar.openNotificationSnackbar({
        notificationIcon: 'success',
        notificationText
      });
    })
  ), { dispatch: false });

  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private overlayManager: OverlayManager,
    private wenSnackbar: WenSnackbarOpener,
    private formStoreMediator: FormStoreMediator,
  ) { }

}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectionOption } from '@portal/wen-components';
import { Language } from '@portal/wen-backend-api';
import { UserProfile } from '../../../../../../core/store/user/models/UserProfile';
import { WenStorageService } from '../../../../../../core/services/storage/wen-storage.service';

@Injectable()
export class UserLanguageService {

  getLanguageSettings(userProfile: UserProfile) {

    if (!Boolean(userProfile)) {
      /**
       * For anon user we store / fetch the language on the device
       */
      const storedLanguage = this.storageService.getLanguage();
      if (!Boolean(storedLanguage)) {
        // Until user can set their language during registration flow
        this.storageService.setLanguage('de');
      }
      return {
        primaryLanguage: storedLanguage ?? 'de'
      };
    }
    return {
      primaryLanguage: userProfile.primaryLanguage
    };
  }
  getLanguageSettingItems(): SelectionOption<Language>[] {
    return [
      { id: Language.GERMAN, label: 'GERMAN', value: Language.GERMAN },
      { id: Language.ENGLISH, label: 'ENGLISH', value: Language.ENGLISH}
    ].map(item => {
      return {
        id: item.id,
        value: item.value,
        text: this.translateService.instant(item.label)
      };
    });
  }

constructor(
  private translateService: TranslateService,
  private storageService: WenStorageService
) { }

}

<wen-form-progress [hasProgress]="hasProgress$ | async"></wen-form-progress>
<div class="sign-in-login-content">
  <div class="sign-in-login-logo-wrapper">
    <img class="sign-in-login-logo">
  </div>
  <div class="sign-in-login-controls-wrapper">
    <div portal-title class="sign-in-login-prompt" *ngIf="config.showLoginPrompt">{{'ONBOARDING_LOGIN_PROMPT' | translate}}</div>
    <div class="sign-in-login-controls-buttons">
      <wen-rounded-button *ngIf="config.showRegisterButton" portal-title (click)="goToRegister()">{{ 'ONBOARDING_LOGIN_REGISTER' | translate }}</wen-rounded-button>
      <wen-rounded-button *ngIf="config.showAnonLoginButton" transparent border (click)="goToAnonymousLogin()">{{ 'ONBOARDING_LOGIN_LOGIN_ANONYMOUS' | translate }}</wen-rounded-button>
      <wen-rounded-button *ngIf="config.showLoginButton" transparent (click)="goToLogin()">
        <span [innerHtml]="'ONBOARDING_LOGIN_LOGIN' | translate"></span>
      </wen-rounded-button>
    </div>
    <div class="sign-in-login-data-policy" portal-subheading-1 *ngIf="config.termsAndConditions">
      {{ 'ONBOARDING_LOGIN_DATA_POLICY_1' | translate }}
      <a portal-subheading-1 [href]="'PRIVACY_POLICY_URL' | translate" target="_blank" (click)="openPrivacyPage()">{{ 'ONBOARDING_LOGIN_DATA_POLICY_2' | translate }}</a>
      {{ 'ONBOARDING_LOGIN_DATA_POLICY_3' | translate }}
      <a portal-subheading-1 [href]="'TERMS_AND_CONDITIONS_URL' | translate" target="_blank" (click)="openTermsAndConditionsPage()">{{ 'ONBOARDING_LOGIN_DATA_POLICY_4' | translate }}.</a>
    </div>
  </div>
</div>
<wen-silent-logout></wen-silent-logout>

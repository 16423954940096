import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { EmbeddedMediaDTO, MediaType } from '@portal/wen-backend-api';
import { memoizedResult } from '../../util/memo';
import { DeepReadonly } from '../../util/misc';
import { getFileExtension, getMediaTypeIcon, getIconForSupportedFileExtension } from '../../util/media-type-util';

export interface MediaEmbedHandler {
  getSrc(): SafeUrl;
  getIconName(): string;
  hasValue(): boolean;
  getFileName(): string;
  getFileSize(): number;
}

export class BlobMediaEmbed implements MediaEmbedHandler {

  private cachedSrc = memoizedResult(() => {
    const file = this.fileData;
    if (!file?.type.includes('image/')) {
      return null;
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
  });

  constructor(
    private readonly fileData: File,
    private readonly sanitizer: DomSanitizer,
  ) { }

  getSrc(): SafeUrl {
    return this.cachedSrc.get();
  }

  getIconName(): string {
    const file = this.fileData;

    if (this.getSrc()) {
      return null;
    }

    if (file?.type.includes('video/')){
      return 'video';
    }

    if(file?.type.includes('audio/')){
      return 'music';
    }

    const fileExtension = getFileExtension(file.name);
    return getIconForSupportedFileExtension(fileExtension);
  }

  hasValue(): boolean {
    return Boolean(this.fileData);
  }

  getFileName(): string {
    return this.fileData.name;
  }

  getFileSize(): number {
    return this.fileData.size;
  }
}

export class UrlMediaEmbed implements MediaEmbedHandler {

  private cachedSrc = memoizedResult(() => {
    const allowedSrcs: MediaType[] = [MediaType.PICTURE];
    if (!allowedSrcs.includes(this.embeddedMedia.subType)) {
      return null;
    }
    const thumbnailUrl = this.embeddedMedia?.thumbnailUrl || this.embeddedMedia?.rawUrl;
    return this.sanitizer.bypassSecurityTrustResourceUrl(thumbnailUrl);
  });

  constructor(
    private readonly embeddedMedia: DeepReadonly<EmbeddedMediaDTO>,
    private readonly sanitizer: DomSanitizer,
  ) { }

  getSrc(): SafeUrl {
    return this.embeddedMedia.error ? null : this.cachedSrc.get();
  }

  getIconName(): string {
    if (this.getSrc()) {
      return null;
    }
    if (this.embeddedMedia.error) {
      return 'error_icon';
    }

    if (this.embeddedMedia.subType === MediaType.DOCUMENT) {
      return getIconForSupportedFileExtension(getFileExtension(this.embeddedMedia.title));
    }
    return getMediaTypeIcon(this.embeddedMedia.subType);
  }

  hasValue(): boolean {
    const file = this.embeddedMedia?.fullUrl || this.embeddedMedia?.rawUrl;
    return Boolean(file) || this.embeddedMedia.error;
  }

  getFileName() {
    return this.embeddedMedia.title;
  }

  getFileSize(): number {
    return this.embeddedMedia.sizeInBytes;
  }
}

<div class="wen-list-item-wrapper">
  <div class="wen-list-item-icon-wrapper">
    <ng-content select="[wen-list-item-icon]"></ng-content>
  </div>
  <div class="wen-list-item-content-wrapper">
    <div class="wen-list-item-info" [ngStyle]="{'justify-content': justifyInfoContent}">
      <div class="wen-list-item-title">
        <ng-container>
          <ng-content select="wen-icon[wen-list-item-title-prefixicon]"></ng-content>
        </ng-container>
        <span class="wen-list-item-title-text">
          <ng-content select="[wen-list-item-title]"></ng-content>
        </span>
        <ng-container>
          <ng-content select="wen-icon[wen-list-item-title-suffixicon]"></ng-content>
        </ng-container>
      </div>
      <div class="wen-list-item-description"
        [ngClass]="{'wen-list-item-single-line-description': listItemSubtitle}"
      >
        <ng-content select="[wen-list-item-description]"></ng-content>
      </div>
      <div class="wen-list-item-subtitle">
        <ng-content select="[wen-list-item-subtitle]"></ng-content>
      </div>
    </div>
    <div class="wen-list-item-after" *ngIf="showAfter">
      <ng-content select="[wen-list-item-after]"></ng-content>
    </div>
  </div>
</div>

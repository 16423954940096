import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { EncryptionType, ExchangeRoomKeyRequestContent, ExchangeRoomKeyRequestEventDTO, ExchangeRoomKeyRequestSession, SendToUsersEventPayload, SendToUsersEventPayloadData, SendToUsersEventResponse, SocketIoService, ToUsersEventType } from '@portal/wen-backend-api';
import { WenChatClient } from '@portal/wen-chat-client';
import { timer } from 'rxjs';
import { buffer, debounceTime, filter, first, map, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ChatSyncDialogOpener } from '../../../../shared/components/chat-sync/chat-sync-dialog-opener';
import { firstExisty } from '../../../common/operators/first-existy';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { onChatInitialized } from '../../../common/util/operators/on-chat-initialized';
import { ExchangeSessionKeysDecryptor } from '../../../services/chat/decryption/exchange-keys-decryptor';
import { ChatMessageDecryptionError } from '../../../services/chat/decryption/message-decryptor';
import { ExchangeSessionKeysEncryptor } from '../../../services/chat/encryption/exchange-keys-encryptor';
import { WenStorageService } from '../../../services/storage/wen-storage.service';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { RootState } from '../../root/public-api';
import { subscribeChatUpdates } from '../chat.actions';
import { selectCurrentRoom } from '../chat.selectors';
import { onExchangedSessionsReceived, requestExchangeInboundGroupSession, requestExchangeInboundGroupSessionForCurrentRoom, requestOpenSyncDialog } from '../key-actions';
import { selectCurrentRoomMessagesHistory } from '../selectors/chat-message-selectors';

// Timeout for opening the sync dialog if no response was given
const CHAT_SYNC_DIALOG_TIMEOUT = 2000;

@Injectable()
export class ExchangeSessionKeyEffects {

  private filterForEvent(data: SendToUsersEventResponse, eventType: ToUsersEventType, encryptionType: EncryptionType) {
    if (!data?.payload) {
      return false;
    }
    const { payload } = data;
    const isTargetEvent = payload.type === encryptionType && payload.eventType === eventType;
    return isTargetEvent;
  }

  private readonly exchangeRoomKeysResponse$ = this.socketIoService.chat.room.sendToUsers.listen.pipe(
    filter((data) => {
      return this.filterForEvent(data, ToUsersEventType.EXCHANGE_ROOM_KEY_RESPONSE, EncryptionType.ENCRYPTED);
    }),
    shareReplay(1)
  );

  get userId() {
    return this.oAuthService.getUserData()?.userId;
  }

  get deviceId() {
    return this.storageService.getDeviceId();
  }

  requestOpenSyncDialog$ = createEffect(() => this.actions$.pipe(
    ofType(requestOpenSyncDialog),
    switchMap(() => timer(CHAT_SYNC_DIALOG_TIMEOUT).pipe(
      takeUntil(this.actions$.pipe(
        ofType(onExchangedSessionsReceived),
        first()
      ))
    )),
    switchMap(() => {
      return this.chatSyncDialogOpener.openChatSyncDialog().pipe(
        filter(result => result?.result === 'ok')
      );
    }),
    map(() => requestExchangeInboundGroupSessionForCurrentRoom({ withDialogFallback: false }))
  ));

  onExchangeRoomKeyRequest$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => this.store.pipe(onChatInitialized)),
    switchMap(() => {
      return this.socketIoService.chat.room.sendToUsers.listen.pipe(
        filter((data) => {
          return this.filterForEvent(data, ToUsersEventType.EXCHANGE_ROOM_KEY_REQUEST, EncryptionType.PLAIN);
        }),
        map((data => data.payload as ExchangeRoomKeyRequestEventDTO)),
        switchMap((payload) => {
          return this.exchangeKeysEncryptor.encryptSessionKeysForExchange(payload);
        }),
        tap(encryptionResult => {
          const data = encryptionResult.asSendToUsersPayload();
          if (!data) {
            return;
          }
          this.socketIoService.chat.room.sendToUsers.emit({
            payloads: [data]
          });
        })
      );
    })
  ), { dispatch: false });

  onExchangeRoomKeyResponse$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => this.store.pipe(onChatInitialized)),
    switchMap(() => {
      return this.exchangeRoomKeysResponse$.pipe(
        switchMap((event) => {
          this.chatSyncDialogOpener.closeChatSyncDialog();
          return this.exchangeKeysDecryptor.decryptExchangeKeyEvent(event);
        }),
        filter(decryptionResult => Boolean(decryptionResult)),
        map((decryptionResult) => {
          const sessionsByRoom = decryptionResult.asExchangedSessionsByRoom();
          return onExchangedSessionsReceived({ sessionsByRoom });
        })
      );
    })
  ));

  requestExchangeInboundGroupSessionForCurrentRoom$ = createEffect(() => this.actions$.pipe(
    ofType(requestExchangeInboundGroupSessionForCurrentRoom),
    mapWithFirstFrom(() => {
      return this.store.pipe(
        select(selectCurrentRoomMessagesHistory),
        first()
      );
    }),
    switchMap(([{ withDialogFallback }, messages]) => {
      if (!messages?.length) {
        return [];
      }
      const actions: Action[] = messages.filter(message => {
        return message.decryptionError === ChatMessageDecryptionError.NO_KEY_FOUND;
      }).map(erroredMessage => {
        const { encryptionData: { roomId, encryptedMessage: { content: { senderKey, sessionId } } } } = erroredMessage;
        return requestExchangeInboundGroupSession({ roomId, senderKey, sessionId });
      });
      if (withDialogFallback) {
        actions.push(requestOpenSyncDialog());
      }
      return actions;
    })
  ));

  requestExchangeInboundGroupSessions$ = createEffect(() => this.actions$.pipe(
    ofType(requestExchangeInboundGroupSession),
    buffer(this.actions$.pipe(
      ofType(requestExchangeInboundGroupSession),
      debounceTime(500),
    )),
    mapWithFirstFrom(() => {
      return this.store.pipe(
        select(selectCurrentRoom),
        firstExisty(),
        switchMap((room) => {
          const { members } = room;
          const uids = members.map(member => member.userId);
          return this.chatClient.getDevices(uids);
        })
      );
    }),
    tap(([actions, myDevices]) => {
      const sessions = actions.reduce((acc, current) => {
        const { roomId, senderKey, sessionId } = current;
        const isDuplicate = acc.some(existing => {
          return existing.sessionId === sessionId && existing.roomId === roomId && existing.senderKey === senderKey;
        });
        if (isDuplicate) {
          return acc;
        }
        const sessionData: ExchangeRoomKeyRequestSession = {
          roomId, senderKey, sessionId
        };
        return [...acc, sessionData];
      }, [] as ExchangeRoomKeyRequestSession[]);
      const exchangeKeyRequests = myDevices
        .filter(result => result.deviceId !== this.deviceId)
        .map(result => {
          const content: ExchangeRoomKeyRequestContent = {
            sessions,
            requestingDeviceId: this.deviceId,
          };
          const exchangeKeyRequest: ExchangeRoomKeyRequestEventDTO = {
            type: EncryptionType.PLAIN,
            eventType: ToUsersEventType.EXCHANGE_ROOM_KEY_REQUEST,
            content,
            senderUserId: this.userId,
          };
          const data: SendToUsersEventPayloadData = {
            payload: exchangeKeyRequest,
            deviceId: result.deviceId,
            userId: result.userId
          };
          return data;
        });

      const payload: SendToUsersEventPayload = {
        payloads: exchangeKeyRequests
      };
      this.socketIoService.chat.room.sendToUsers.emit(payload);
    })
  ), { dispatch: false });

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>,
    private oAuthService: WenOAuthService,
    private chatClient: WenChatClient,
    private storageService: WenStorageService,
    private exchangeKeysDecryptor: ExchangeSessionKeysDecryptor,
    private exchangeKeysEncryptor: ExchangeSessionKeysEncryptor,
    private chatSyncDialogOpener: ChatSyncDialogOpener,
  ) {
  }

}

<div class="wen-poll-option" *ngFor="let option of pollOptionsWithCalculations">
  <div class="wen-option-body">
    <div class="wen-option-prefix">
      <span>
        {{ option.averageVote }}<span>&percnt;</span></span
      >
    </div>
    <div class="wen-option">
      <div class="wen-option-text">{{ option.text }}</div>
    </div>
  </div>
  <div class="wen-option-footer">
    <div class="wen-option-prefix">
      <mat-checkbox
      *ngIf="option.chosenByUser"
      wen-primary-color
      [disableRipple]="'true'"
      [checked]="true"
      [className]="(isMultiSelect ? 'multi-check' : 'single-check') + ' cursor-initial wen-option-prefix-checkbox'"
      ></mat-checkbox>
    </div>
    <wen-status-bar
      *ngIf="option.averageVote > 0"
      [value]="option.averageVote"
    ></wen-status-bar>
  </div>
</div>

import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { EmbeddedMediaDTO } from '@portal/wen-backend-api';
import { BlobMediaEmbed, MediaEmbedHandler, UrlMediaEmbed } from './media-embed-handlers';

@Component({
  selector: 'wen-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MediaPreviewComponent {

  mediaHandler: MediaEmbedHandler;
  imageLoadError = false;
  mediaError = false;

  get mediaSrc() { return this.imageLoadError ? '' : this.mediaHandler?.getSrc(); }
  get mediaIconName() { return this.imageLoadError ? 'image' : this.mediaHandler?.getIconName(); }
  get hasValue() { return this.mediaHandler?.hasValue(); }
  get mediaFileName() { return this.mediaHandler.getFileName(); }
  get mediaFileSize() { return this.mediaHandler.getFileSize(); }

  @Input() previewSize: 'mini' | 'fit' = 'fit';

  @HostBinding('class.wen-media-preview-mini') get miniStyle() { return this.previewSize === 'mini'; }
  @HostBinding('class.wen-media-preview-fit') get fitStyle() { return this.previewSize === 'fit'; }

  @Input() set mediaData(newValue: EmbeddedMediaDTO | File) {
    if (!newValue) {
      this.mediaHandler = null;
      return;
    }

    this.mediaError = newValue instanceof File ? false : newValue?.error;
    const fileData = newValue instanceof File ? newValue : newValue?.file;
    const mediaData = newValue instanceof File ? null : newValue;
    if (fileData) {
      this.mediaHandler = new BlobMediaEmbed(fileData, this.sanitizer);
    } else if (mediaData) {
      this.mediaHandler = new UrlMediaEmbed(mediaData, this.sanitizer);
    }
  }

  @Output() dismiss = new EventEmitter();

  constructor(
    private readonly sanitizer: DomSanitizer,
  ) { }

  emitDismiss() {
    this.dismiss.emit();
  }

  onImageError() {
    this.imageLoadError = true;
  }

}

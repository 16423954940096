
import { Directive, TemplateRef } from '@angular/core';
import { MessageBoxComponent } from '../../message-box/message-box.component';

@Directive({ selector: '[we-feed-custom-context-menu-template]' })
export class WeFeedCustomContextMenuTemplateDirective {
  constructor(public templateRef: TemplateRef<{ data: any; reference: MessageBoxComponent }>) { }
}

@Directive({ selector: '[we-feed-custom-reaction-context-menu-template]' })
export class WeFeedCustomReactionContextMenuTemplateDirective {
  constructor(public templateRef: TemplateRef<{ data: any; reference: MessageBoxComponent }>) { }
}

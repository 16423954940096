import { ActionMenuConfig } from '@portal/wen-components';
import { MessageBoxEditAction } from './channel/channel-message-box-edit.action';
import { MessageBoxCopyAction } from './message-box-copy.action';
import { ChatMessageBoxDeleteAction } from './chat/chat-message-box-delete.action';
import { ChannelMessageBoxDeleteAction } from './channel/channel-message-box-delete.action';
import { ChatMessageBoxEditAction } from './chat/chat-message-box-edit.action';
import { ChannelMessageBoxForwardAction } from './channel/channel-message-box-forward.action';
import { ChatMessageBoxForwardAction } from './chat/chat-message-box-forward.action';
import { ChannelMessageBoxShareAction } from './channel/channel-message-box-share.action';

export const messageBoxContextMenuId = 'messageBoxContextMenuId';
export const chatMessageBoxContextMenuId = 'chatMessageBoxContextMenuId';

export const MESSAGE_BOX_CONTEXT_MENU_ITEMS: ActionMenuConfig = {
  menuId: messageBoxContextMenuId,
  menuItems: [
    ChannelMessageBoxForwardAction,
    ChannelMessageBoxShareAction,
    MessageBoxCopyAction,
    MessageBoxEditAction,
    ChannelMessageBoxDeleteAction
  ]
};

export const CHAT_MESSAGE_BOX_CONTEXT_MENU_ITEMS: ActionMenuConfig = {
  menuId: chatMessageBoxContextMenuId,
  menuItems: [MessageBoxCopyAction, ChatMessageBoxForwardAction, ChatMessageBoxEditAction, ChatMessageBoxDeleteAction]
};

<wen-dialog-layout [headerGravity]="'top'">
  <div class="wen-route-dialog-header" *ngIf="isHeaderVisible$ | async" wen-dialog-layout-static-header>
    <wen-app-header
      wenIosKeyboardSticky
      [title]="headerTitle$ | async"
      [subTitle]="headerSubTitle$ | async"
      [leftCommand]="headerLeftCommand$ | async"
      [rightCommand]="headerRightCommand$ | async"
      [listenEnablementEvents]="true"
    ></wen-app-header>
    <wen-search-bar
      *ngIf="searchConfig$ | async as searchConfig"
      [searchBarPlaceholder]="searchConfig.searchBarPlaceholder"
      [disabled]="searchConfig?.disabled"
      [wen-test-id]="'wen-app-header-search'"
      filter-element [filterId]="searchConfig.filterConfig.filterId" [filterEntityType]="searchConfig.filterConfig.filterEntityType"
    ></wen-search-bar>
  </div>
  <wen-app-header
    wen-dialog-layout-static-footer
    wenIosKeyboardSticky
    *ngIf="isFooterVisible$ | async"
    [title]="footerTitle$ | async"
    [subTitle]="footerSubTitle$ | async"
    [leftCommand]="footerLeftCommand$ | async"
    [rightCommand]="footerRightCommand$ | async"
    [listenEnablementEvents]="true"
  ></wen-app-header>
  <div class="wen-route-dialog-content" dialogLayoutContent>
    <router-outlet name="dialog"></router-outlet>
  </div>
</wen-dialog-layout>

<div class="wen-profile-read-scroller" cdkScrollable>
  <div class="wen-profile-read-form-header" *ngIf="headerData$ | async; let headerData" [wen-test-id]="'wen-user-profile-read-header'">
    <wen-detail-header [model]="headerData" [coverImageStyle]="'circle'" [allowLoadingIndicator]="true" [shouldAnimate]="false">
      <div wen-detail-header-pending-image>
        <div class="wen-profile-read-pending-image">
          <ng-lottie [options]="lottieOptions"></ng-lottie>
        </div>
        <div class="wen-profile-read-pending-text">{{ pendingProfileImageText$ | async | translate }}</div>
      </div>
      <div wen-detail-header-title>{{ headerData.title }}</div>
      <button wen-detail-header-quick-actions mat-mini-fab color="primary" class="mat-elevation-z0"
        (click)="navigateToProfileEdit()">
        <wen-icon iconName="edit"></wen-icon>
      </button>
    </wen-detail-header>
  </div>
  <div class="wen-profile-read-form-entries" *ngIf="userProfile$ | async; let userProfile">
    <div class="wen-profile-read-form-group">
      <ng-container *ngIf="isAboutYouFieldEnabled$ | async">
        <wen-menu-entry *ngIf="userProfile?.additionalInformation  as additionalInformation"
          (click)="navigateToProfileEdit()">
          <span menuEntryTitle>{{ "PROFILE_FORM_ABOUT_ME_LABEL" | translate }}</span>
          <span menuEntryDescription [innerHTML]="additionalInformation | removeMarkdown"></span>
        </wen-menu-entry>
      </ng-container>
      <wen-menu-entry [disableArrow]="true" [wen-test-id]="'wen-user-profile-read-PHONE'">
        <span menuEntryTitle>{{ 'USER_PROFILE_FORM_CHANGE_PHONE' | translate }}</span>
        <span menuEntryDescription>{{ userProfile.identifier }}</span>
      </wen-menu-entry>
      <wen-menu-entry (click)="navigateToEmailSettings()" [wen-test-id]="'wen-user-profile-read-EMAIL'">
        <span menuEntryTitle>{{ 'USER_PROFILE_FORM_CHANGE_EMAIL' | translate }}</span>
        <span menuEntryDescription class="wen-profile-read-form-email-description">
          <ng-container *ngIf="featureEnablementService.featureFlagMethods.isEnableEmailVerification()">
            <wen-icon *ngIf="!userProfile.emailVerified && userProfile.email" class="wen-icon-small" iconName="error_icon" wen-primary-color></wen-icon>
          </ng-container>
          <span class="wen-profile-read-form-email-description-text">{{ userProfile.email }}</span>
        </span>
      </wen-menu-entry>
    </div>
    <div class="wen-profile-read-form-group">
      <wen-menu-entry (click)="navigateToUserProfileSettings()" [wen-test-id]="'wen-user-profile-read-SETTINGS'" [attr.entry-id]="'USER_PROFILE_SETTINGS'">
        <wen-icon class="wen-profile-settings-icon wen-icon-medium" [iconName]="userProfileSettingsIcon" [fillColor]="userProfileSettingsFillColor" [color]="'white'" menuEntryIcon>
      </wen-icon>
        <span menuEntryTitle>{{ "USER_PROFILE_SETTINGS" | translate }}</span>
      </wen-menu-entry>
      <wen-menu-entry *ngIf="isWalletEnabled$ | async" (click)="navigateToWalletDetails()" [wen-test-id]="'wen-user-profile-wallet-DETAILS'">
        <wen-icon class="wen-profile-settings-icon wen-icon-medium" [iconName]="userSubscriptionsIcon" [fillColor]="userSubscriptionsFillColor" [color]="'white'" menuEntryIcon>
        </wen-icon>
        <span menuEntryTitle>{{ "USER_PROFILE_FORM_SUBSCRIPTIONS" | translate }}</span>
      </wen-menu-entry>
    </div>
    <div class="wen-profile-read-form-group">
      <div class="wen-profile-read-from-logout-wrapper" [wen-test-id]="'wen-profile-read-logout'">
        <wen-interact-button class="wen-interact-button-menu-style wen-error-interact-button" (click)="logout()">{{ "USER_PROFILE_LOGOUT" |
          translate }}</wen-interact-button>
      </div>
    </div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { EmbeddedDocument } from '../../../../core/store/channel/channel.state';

@Injectable()
export abstract class MessageBoxActionsHandler {
  abstract handleCommentsClicked(messageId: string): void;
  abstract handleDocumentClicked(messageId: string, documentModel: EmbeddedDocument): void;
  abstract handleSenderClicked(messageId: string, authorId: string): void;
  abstract handleForwardClicked(messageId: string): void;
  abstract handleShareClicked(messageId: string): void;
}

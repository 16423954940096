import { ModuleWithProviders, NgModule } from '@angular/core';
import { MegOlmDecryptor } from './decryption/megolm-decryptor';
import { MegOlmEncryptor } from './decryption/megolm-encryptor';
import { OlmDecryptor } from './decryption/olm-decryptor';
import { OlmEncryptor } from './decryption/olm-encryptor';
import { OlmDevice } from './device/olm-device';
import { EncryptionInitializer } from './key-initializers/encryption-initializer';
import { OneTimeKeyService } from './key-initializers/one-time-key-service';
import { CryptoStorage } from './persistence/crypto-storage';
import { IndexedDbDataStore } from './persistence/idb/indexed-db-data-store';
import { OlmIndexedDb } from './persistence/idb/olm-indexed-db';
import { ChatTracer } from './util/logging/chat-tracer';
import { WenChatClient } from './wen-chat-client';

@NgModule()
export class WenChatClientModule {

  static forRoot(): ModuleWithProviders<WenChatClientModule> {
    return {
      ngModule: WenChatClientModule,
      providers: [
        WenChatClient,
        OlmDevice,
        MegOlmDecryptor,
        MegOlmEncryptor,
        OlmDecryptor,
        OlmEncryptor,
        OlmIndexedDb,
        EncryptionInitializer,
        OneTimeKeyService,
        {
          provide: CryptoStorage,
          useClass: IndexedDbDataStore
        },
        ChatTracer,
      ],
    };
  }

}

<div *ngIf="isAdmin$ | async" class="wen-chat-detail-add-member-header" portal-body-2 wen-primary-color>
  <div [ngClass]="{'disabled': isMemberAddDisabled }" class="wen-chat-detail-add-member-action" (click)="navigateToAddMemberForGroupChat()">
    <wen-icon class="wen-icon-big" [iconName]="'add_user'"></wen-icon>
    {{ "GROUP_CHAT_ADD_MEMBERS" | translate }}
  </div>
  <span class="wen-chat-detail-add-member-counter">{{ (memberListView$ | async).length }}/{{ maxNumberOfUsers }}</span>
</div>
<wen-user-list-item *ngFor="let listItem of memberListView$ | async" [user]="listItem.member" [userNavigable]="listItem.member.userId">
  <wen-user-role [userRole]="listItem.member.role" wen-user-description></wen-user-role>
  <wen-icon *ngIf="listItem.member.canHaveActions"
    [iconName]="'more'"
    [color]="contextMenuOpenerFillColor"
    [context-menu-trigger]="menu"
    wen-user-after>
  </wen-icon>
  <wen-context-menu #menu>
    <wen-action-menu [actions]="listItem.actions" [data]="listItem.member.userId"></wen-action-menu>
  </wen-context-menu>
</wen-user-list-item>

<ng-container *ngIf="chat">
  <ng-container *ngIf="chat.isMuted; then unmuteButton; else muteButton"></ng-container>

  <ng-template #unmuteButton>
    <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="unmuteChat()">
      <wen-icon iconName="mute"></wen-icon>
    </button>
  </ng-template>
  <ng-template #muteButton>
    <button mat-mini-fab color="primary" class="mat-elevation-z0" (click)="muteChat()">
      <wen-icon iconName="unmute"></wen-icon>
    </button>
  </ng-template>
</ng-container>

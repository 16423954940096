<ng-container *ngIf="loadResult$ | async; let loadResult">
  <ng-container *ngIf="loadResult.data">
    <wen-filter-view *ngIf="isFilteringEnabled" [formGroup]="filterGroup">
      <wen-filter-select-component [formControlName]="channelCategoryControlName"
        [defaultFilter]="categoryFilterService.fallbackAllCategoryFilter" filter-element
        [filterEntityType]="filterEntityType" (click)="openChannelCategoryFilterSelector()"></wen-filter-select-component>
    </wen-filter-view>
    <ng-container *ngIf="loadResult.data.length; else emptyList">
      <wen-channel-list [channelList]="loadResult.data" [wen-test-id]="'wen-discovery-channel-list'"
        [enableContextMenu]="false"></wen-channel-list>
    </ng-container>
    <ng-template #emptyList>
      <wen-empty-search-result *ngIf="loadResult.mode === 'search'"></wen-empty-search-result>
    </ng-template>
  </ng-container>
</ng-container>

<form wenFormStore class="wen-form" *ngIf="channel$ | async as channel" [formGroup]="channelEditFormGroup" [wen-test-id] = "'wen-channel-info-edit-form'">
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper wen-image-upload">
      <wen-image-upload [formControlName]="'icon'"
        [fallbackText]="imageFallbackText"
        [wen-test-id] = "'channel-info-edit-image'">
      </wen-image-upload>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'CHANNEL_EDIT_FORM_CHANNEL_TITLE_LABEL' | translate }}</mat-label>
        <portal-field [formControlName]="'title'" [autocomplete]="'off'" [valueAdapter]="fieldValueAdapter"
          [required]="true" wenIosKeyboardStickyTarget
          [wen-test-id] = "'channel-info-edit-title'"></portal-field>
        <mat-error portal-caption>{{ "CHANNEL_EDIT_FORM_CHANNEL_TITLE_ERROR" | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'CHANNEL_EDIT_FORM_CHANNEL_DESCRIPTION_LABEL' | translate }}</mat-label>
        <textarea [formControlName]="'description'" matInput autocomplete="off" cdkTextareaAutosize
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5" wenIosKeyboardStickyTarget
          [wen-test-id]="'channel-info-edit-description'"></textarea>
      </mat-form-field>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'CHANNEL_EDIT_FORM_CHANNEL_DESCRIPTION_IMPRINT' | translate }}</mat-label>
        <div class="wen-textarea-wrapper">
          <textarea matInput
            cdkTextareaAutosize
            cdkAutosizeMaxRows="5"
            formControlName="imprint"
            [maxlength]="CHARACTER_LIMIT"
            wenIosKeyboardStickyTarget
          ></textarea>
          <div class="character-count-and-remove-wrapper">
            <wen-icon wen-primary-color *ngIf="characterCount$ | async" iconName="close" (click)="clearInput()"></wen-icon>
            <mat-hint portal-caption *ngIf="characterCount$ | async as characterCount" class="wen-imprint-word-count">{{ characterCount }}/{{ CHARACTER_LIMIT }}</mat-hint>
          </div>
        </div>
      </mat-form-field>
    </div>
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'CHANNEL_EDIT_FORM_CHANNEL_DESCRIPTION_DATA_PROTECTION' | translate }}</mat-label>
        <portal-field [formControlName]="'dataProtectionUrl'" [autocomplete]="'off'" [valueAdapter]="fieldValueAdapter"
           wenIosKeyboardStickyTarget
          [wen-test-id]="'channel-info-edit-data-protection'"></portal-field>
      </mat-form-field>
    </div>
  </div>
  <div class="wen-form-group" *ngIf="showGeoToggle || showCollaborativeToggle || showTextToSpeechToggle">
    <div class="wen-form-field-wrapper" *ngIf="showCollaborativeToggle">
      <div class="wen-form-field wen-toggle-field" [wen-test-id]="'collaborative-toggle'">
        <div>{{ "CHANNEL_COLLABORATIVE_TOGGLE_LABEL" | translate }}</div>
        <mat-slide-toggle color="primary" [formControlName]="'collaborative'"></mat-slide-toggle>
      </div>
      <div class="wen-channel-info-edit-toggle-hint wen-form-group-hint" portal-caption>{{
        "CHANNEL_COLLABORATIVE_TOGGLE_DESCRIPTION" | translate }}</div>
    </div>
    <div class="wen-form-field-wrapper">
      <div class="wen-form-field wen-toggle-field" [wen-test-id]="'geo-toggle'">
        <div>{{ "CHANNEL_GEO_TOGGLE_LABEL" | translate }}</div>
        <mat-slide-toggle color="primary" [formControlName]="'geo'"></mat-slide-toggle>
      </div>
      <div class="wen-channel-info-edit-toggle-hint wen-form-group-hint" portal-caption>{{ "CHANNEL_GEO_TOGGLE_DESCRIPTION" | translate }}</div>
    </div>
    <div class="wen-form-field-wrapper" *ngIf="showTextToSpeechToggle">
      <div class="wen-form-field wen-toggle-field" [wen-test-id]="'text-to-speech-toggle'">
        <div>{{ "CHANNEL_TEXT_TO_SPEECH_TOGGLE_LABEL" | translate }}</div>
        <mat-slide-toggle color="primary" [formControlName]="'textToSpeech'"></mat-slide-toggle>
      </div>
      <div class="wen-channel-info-edit-toggle-hint wen-form-group-hint" portal-caption>{{ "CHANNEL_TEXT_TO_SPEECH_TOGGLE_DESCRIPTION" | translate }}</div>
    </div>
  </div>
  <div *ngIf="channel.id" class="wen-form-group">
    <wen-menu-entry (click)="editChannelVisibilitySettings(channel.id)" [wen-test-id]="'channel-info-edit-visibility-settings'">
      <wen-icon iconName="eye_on" [fillColor]="visibilitySettingsMenuEntryFillColor" [color]="'white'"
        class="wen-icon-medium" menuEntryIcon>
      </wen-icon> <span menuEntryTitle>{{ 'CHANNEL_VISIBILITY_SETTINGS_FORM_TITLE' | translate }}</span>
    </wen-menu-entry>
    <wen-menu-entry (click)="editChannelRestrictionSettings(channel.id)" *ngIf="canUpdateRestrictions$ | async" [wen-test-id]="'channel-info-edit-restriction-settings'">
      <wen-icon iconName="user_shield_on" [fillColor]="restrictionSettingsMenuEntryFillColor"
        [color]="'white'" class="wen-icon-medium" menuEntryIcon>
      </wen-icon> <span menuEntryTitle>{{ 'CHANNEL_RESTRICTION_SETTINGS_FORM_TITLE' | translate }}</span>
    </wen-menu-entry>
    <wen-menu-entry (click)="editChannelInteractionSettings(channel.id)" [wen-test-id]="'channel-info-edit-interaction-settings'">
      <wen-icon iconName="voice_record" [fillColor]="interactionSettingsMenuEntryFillColor" [color]="'white'"
        class="wen-icon-medium" menuEntryIcon>
      </wen-icon> <span menuEntryTitle>{{ 'CHANNEL_INTERACTION_SETTINGS_FORM_TITLE' | translate }}</span>
    </wen-menu-entry>
  </div>
  <div *ngIf="canOpenCategorySelector$ | async" class="wen-form-group wen-select-wrapper">
    <wen-select [formControlName]="'categories'" [possibleValues]="allCategories$ | async"
      [mainLabel]="'CATEGORY_SELECTOR_LABEL' | translate"
      [bottomLabel]="'CHANNEL_EDIT_FORM_CHANNEL_CATEGORY_SELECT_LABEL' | translate"
      [wen-test-id]="'channel-info-edit-category-selector'">
    </wen-select>
  </div>
  <div class="wen-form-actions" *ngIf="canDelete$ | async">
    <wen-interact-button class="wen-interact-button-menu-style" (click)="deleteChannel(channel.id)" [wen-test-id]="'wen-info-edit-channel-delete-button'">{{
      "CHANNEL_DELETE_BUTTON" | translate }}</wen-interact-button>
  </div>
</form>

<div class="profile-read-only-view-scroller" cdkScrollable>
  <div *ngIf="headerData$ | async; let headerData">
    <wen-detail-header  [model]="headerData" [coverImageStyle]="'circle'"
      [shouldAnimate]="false">
      <div wen-detail-header-pending-image>
        <div class="profile-read-only-view-pending-image">
          <ng-lottie [options]="lottieOptions"></ng-lottie>
        </div>
        <div class="profile-read-only-view-pending-text">{{ pendingProfileImageText$ | async | translate }}</div>
      </div>
      <div wen-detail-header-title>{{ headerData.title }}</div>
    </wen-detail-header>
  <div class="profile-read-only-view-form-entries" *ngIf="userProfile$ | async; let userProfile">
    <div class="profile-read-only-view-form-group">
      <wen-menu-entry [disableArrow]="true" [wen-test-id]="'wen-user-profile-read-PHONE'">
        <span menuEntryTitle>{{ 'USER_PROFILE_FORM_CHANGE_PHONE' | translate }}</span>
        <span menuEntryDescription>{{ userProfile.identifier }}</span>
      </wen-menu-entry>
      <wen-menu-entry [disableArrow]="true" [wen-test-id]="'wen-user-profile-read-EMAIL'">
        <span menuEntryTitle>{{ 'USER_PROFILE_FORM_CHANGE_EMAIL' | translate }}</span>
        <span menuEntryDescription class="profile-read-only-view-form-email-description">
          <span class="profile-read-only-view-form-email-description-text">{{ userProfile.email }}</span>
        </span>
      </wen-menu-entry>
    </div>
    <div class="profile-read-only-view-form-group">
      <div class="profile-read-only-view-from-logout-wrapper" [wen-test-id]="'profile-read-only-view-logout'">
        <wen-interact-button class="wen-interact-button-menu-style wen-error-interact-button" (click)="logout()">{{
          "USER_PROFILE_LOGOUT" |
          translate }}</wen-interact-button>
      </div>
    </div>
  </div>
</div>
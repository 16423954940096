import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatChipInputEvent, MatChipGrid } from '@angular/material/chips';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { InviteeDTO } from '@portal/wen-backend-api';
import { distinctUntilChanged, filter, first, map, Observable, Subject, takeUntil } from 'rxjs';
import { shareReplay } from 'rxjs/internal/operators/shareReplay';
import { validateEmail } from '../../../../../core/common/util/field-validators';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectIsFromSmartDesign, selectNetworkInviteSelection } from '../../../../../core/store/smartdesign/smartdesign.selectors';
import { ImportedSelectionEntity } from '../../../../../core/store/smartdesign/smartdesign.state';
import { selectProgressState } from '../../../../../core/store/ui/ui.selectors';
import { ProgressState } from '../../../../../core/store/ui/ui.state';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';

export const inviteeFieldValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const values: InviteeDTO[] = control.value;
    if (!values?.length) {
      return { required: true };
    }
    return null;
  };
};

@Component({
  selector: 'wen-invite-to-network-view',
  templateUrl: './invite-to-network-view.component.html',
  styleUrls: ['./invite-to-network-view.component.scss']
})
export class InviteToNetworkViewComponent implements OnInit, OnDestroy {
  readonly separatorKeyCodes = [ENTER, COMMA];
  private onDestroy$ = new Subject<void>();

  @ViewChild('chipGrid') chipGrid: MatChipGrid;

  hasImportedData$: Observable<boolean>;
  importedInviteeData$: Observable<ImportedSelectionEntity[]>;
  emailInputLabel$: Observable<string>;
  emailDescriptionLabel$: Observable<string>;
  hasProgress$: Observable<ProgressState>;

  inviteFormGroup = new FormGroup({
    invitees: new FormControl([]),
    importedInvitees: new FormControl([]),
    emailBody: new FormControl(''),
  });

  get inviteesControl() {
    return this.inviteFormGroup.controls.invitees;
  }

  get inviteesValue() {
    return this.inviteesControl.value as InviteeDTO[];
  }

  get manualInviteesCount(): number {
    return this.inviteesValue.length;
  }

  get showError() {
    return this.inviteesControl.errors?.required;
  }

  get showHint() {
    const hasInvalidEmail = this.inviteesValue.some(email => this.isInvalidEmail(email.email));
    return !this.showError && hasInvalidEmail;
  }

  constructor(
    private store: Store<RootState>,
    private translateService: TranslateService,
    private formStoreService: FormStoreService,
  ) { }

  ngOnInit() {
    this.hasImportedData$ = this.store.pipe(
      select(selectIsFromSmartDesign),
      distinctUntilChanged()
    );
    this.importedInviteeData$ = this.store.pipe(
      select(selectNetworkInviteSelection),
      shareReplay(1)
    );
    this.hasProgress$ = this.store.pipe(
      select(selectProgressState),
      distinctUntilChanged(),
    );
    this.emailInputLabel$ = this.hasImportedData$.pipe(
      map((hasImportedData) => {
        if (hasImportedData) {
          return this.translateService.instant('INVITE_PAGE_EMAIL_FIELD_LABEL_CRM');
        } else {
          return this.translateService.instant('INVITE_PAGE_EMAIL_FIELD_LABEL');
        }
      })
    );
    this.emailDescriptionLabel$ = this.hasImportedData$.pipe(
      map((hasImportedData) => {
        let label = this.translateService.instant('INVITE_PAGE_EMAIL_FIELD_DESCRIPTION');
        if (hasImportedData) {
          label = ` ${this.translateService.instant('INVITE_PAGE_EMAIL_FIELD_DESCRIPTION_CRM')}`;
        }
        return label;
      })
    );
    this.hasImportedData$.pipe(
      first()
    ).subscribe(hasImportedData => {
      if (!hasImportedData) {
        this.inviteesControl.addValidators([inviteeFieldValidator()]);
      }
    });
    this.inviteFormGroup.statusChanges.pipe(
      distinctUntilChanged(),
      filter(status => status === 'INVALID'),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.inviteesControl.markAsTouched();
      this.inviteesControl.updateValueAndValidity();
    });

    this.inviteesControl.statusChanges.pipe(
      distinctUntilChanged(),
      takeUntil(this.onDestroy$)
    ).subscribe((status) => {
      this.chipGrid.errorState = status === 'INVALID';
    });

    this.formStoreService.clearFormData();
    this.formStoreService.initializeForm();
  }

  add(event: MatChipInputEvent) {
    if (event.value) {
      const value = event.value.trim();
      if (value) {
        const isExisting = this.inviteesValue.some(existingValue => existingValue.email === value);
        if (!isExisting) {
          const invitee = { email: value } as InviteeDTO;
          this.inviteesControl.setValue(Array.from(new Set([...this.inviteesValue, invitee])));
        }
        this.inviteesControl.markAsDirty();
        this.inviteesControl.updateValueAndValidity();
      }
      event.chipInput.clear();
    }
  }

  remove(invitee: InviteeDTO) {
    const index = this.inviteesValue.findIndex(i => i.email === invitee.email);
    if (index >= 0) {
      this.inviteesControl.value.splice(index, 1);
      this.inviteesControl.markAsDirty();
      this.inviteesControl.updateValueAndValidity();
    }
  }

  isInvalidEmail(email: string): boolean {
    return !validateEmail(email);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SocketIoService } from '@portal/wen-backend-api';
import { ConfirmationDialogComponent, ConfirmationDialogData, WenBreakpointObserver, OverlayManager } from '@portal/wen-components';
import { ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, exhaustMap, filter, finalize, first, shareReplay, startWith, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { createTimer } from '../../../../../core/common/util/create-timer';
import { WenNavigationHelper } from '../../../../../core/services/navigation/types';
import { WenNativeApi } from '@portal/wen-native-api';
import { WenOAuthService } from '../../../../../core/services/user-management/wen-oauth.service';
import { logout } from '../../../../../core/store/auth/auth.actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { SilentLogoutComponent } from '../../../../login/components/silent-logout/silent-logout.component';
import { RegistrationResolvedData } from '../../../common/guards/registration.guard';
import { RegistrationWorkaround } from '../../../common/services/registration-workaround';
import { verificationCodeControlName } from '../../../common/types/form-types';
import { updateableValidator } from '../../../common/util/phone-utils';
import { RegistrationCodeResendService } from './registration-code-resend.service';
import { WenRouteId } from '../../../../../frame/routing/types';
import { AppNavigator } from '../../../../../core/services/navigation/app-navigator';

const VERIFICATION_SUBMIT_TIMER_SEC = 30;
const VERIFICATION_CODE_LENGTH = 6;

@Component({
  selector: 'wen-registration-code-verification',
  templateUrl: './registration-code-verification.component.html',
  styleUrls: ['./registration-code-verification.component.scss'],
  providers: [
    RegistrationCodeResendService
  ]
})
export class RegistrationCodeVerificationComponent implements OnInit, OnDestroy {

  @ViewChild(SilentLogoutComponent) silentLogout: SilentLogoutComponent;

  private onDestroy = new Subject<void>();
  private resendValidator = updateableValidator('verifyCooldown');

  registrationCodeVerificationFormGroup = new FormGroup({
    [verificationCodeControlName]: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      Validators.maxLength(6),
      this.resendValidator.validator
    ])
  });

  isVerificationInProgress = false;
  submitted = new ReplaySubject<void>(1);

  resendDisabled$ = this.resendService.isDisabled$;
  remainingResend$ = this.resendService.remaining$;
  remainingResubmit$ = this.submitted.pipe(
    exhaustMap(() => createTimer(VERIFICATION_SUBMIT_TIMER_SEC)),
    tap(remaining => {
      if (remaining === 0) {
        this.resendValidator.setValid();
        this.verificationCodeControl.updateValueAndValidity();
      }
    }),
    startWith(0),
    shareReplay(),
  );

  headerGravity = this.breakpointObserver.isDesktopStyleDevice ? 'bottom' : 'top';

  codeFieldType: string;

  get routeData() {
    return this.route.snapshot.data.registrationData as RegistrationResolvedData;
  }

  get phoneNumber() {
    return this.routeData.phoneNumber;
  }

  get verificationCodeControl() {
    return this.registrationCodeVerificationFormGroup.controls[verificationCodeControlName];
  }

  get verificationCodeControlValue() {
    const value: string = this.verificationCodeControl.value;
    return this.registrationWorkaround.workaroundSecretcode(value);
  }

  constructor(
    private route: ActivatedRoute,
    private navigationHelper: WenNavigationHelper,
    private appNavigator: AppNavigator,
    private socketIoService: SocketIoService,
    private registrationWorkaround: RegistrationWorkaround,
    private resendService: RegistrationCodeResendService,
    private breakpointObserver: WenBreakpointObserver,
    private nativeApi: WenNativeApi,
    private formStoreService: FormStoreService,
    private overlayManager: OverlayManager,
    private translateService: TranslateService,
    private store: Store<RootState>,
    private authService: WenOAuthService,
  ) {
    if (this.nativeApi.isReactNativeApp()) {
      this.codeFieldType = 'number';
    } else {
      this.codeFieldType = 'text';
    }
  }

  ngOnInit(): void {
    this.formStoreService.clearFormData();
    this.formStoreService.initializeForm();
    this.verificationCodeControl.valueChanges.pipe(
      distinctUntilChanged(),
      withLatestFrom(this.remainingResubmit$),
      takeUntil(this.onDestroy)
    ).subscribe(([inputValue, remainingResubmit]: [string, number]) => {
      const canSubmit = inputValue?.length === VERIFICATION_CODE_LENGTH && remainingResubmit <= 0;
      if (this.verificationCodeControl.dirty && canSubmit) {
        this.onSubmit();
      }
    });
  }

  onCancel() {
    this.navigationHelper.navigateToRegistrationStart(false);
  }

  onResendCode() {
    this.resendService.tryResend(this.phoneNumber);
  }

  onSubmit() {
    this.registrationCodeVerificationFormGroup.updateValueAndValidity();
    this.registrationCodeVerificationFormGroup.markAllAsTouched();
    if (this.registrationCodeVerificationFormGroup.valid) {
      this.isVerificationInProgress = true;
      this.registrationWorkaround.withCurrentUser().pipe(
        first(),
        switchMap(userId => this.socketIoService.user.verifyCode.acknowledgement$({
          userId, identifier: this.phoneNumber, code: this.verificationCodeControlValue
        })),
        finalize(() => this.isVerificationInProgress = false)
      ).subscribe(response => {
        if (!response?.valid) {
          return;
        }
        if (response.flow === 'registration') {
          this.appNavigator.navigateToRoute(WenRouteId.REGISTRATION_PROFILE);
        } else if (response.flow === 'resetpassword') {
          this.handleAlreadyRegisteredUser();
        } else {
          this.submitted.next();
          this.resendValidator.setInValid();
        }
      });
    }
  }

  hasError(errorKey: string | string[]) {
    const errorKeyArr = Array.isArray(errorKey) ? errorKey : [errorKey];
    const errors = this.verificationCodeControl.errors;
    return errors && errorKeyArr.some(key => Object.keys(errors).includes(key));
  }

  private handleAlreadyRegisteredUser() {
    const dialogData: ConfirmationDialogData = {
      header: this.translateService.instant('PHONE_NUMBER_ALREADY_REGISTERED_ERROR_DIALOG_TITLE'),
      content: this.translateService.instant('PHONE_NUMBER_ALREADY_REGISTERED_ERROR_DIALOG_DESCRIPTION'),
      okLabel: this.translateService.instant('PHONE_NUMBER_ALREADY_REGISTERED_ERROR_DIALOG_LOGIN'),
      dismissLabel: this.translateService.instant('PHONE_NUMBER_ALREADY_REGISTERED_ERROR_DIALOG_PASSWORD_RESET'),
    };
    const dialog = this.overlayManager.dialog.openConfirmationDialog(ConfirmationDialogComponent, dialogData, { disableClose: true });
    dialog.afterClosed().pipe(
      first(),
      filter(data => data?.result === 'ok' || data.result === 'dismiss')
    ).subscribe((data) => {
      if (data.result === 'ok') {
        this.store.dispatch(logout());
      } else {
        this.silentLogout.silentLogout();
        this.silentLogout.logoutInProgress$.pipe(
          filter(value => !value),
          first()
        ).subscribe(() => { this.authService.initPasswordResetFlow(); });
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}

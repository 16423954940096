import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { WenIconModule } from '@portal/wen-components';
import { MenuEntryDescriptionDirective, MenuEntryIconDirective, MenuEntryTitleDirective } from './menu-entry-directives';
import { MenuEntryComponent } from './menu-entry.component';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
  ],
  declarations: [
    MenuEntryComponent,
    MenuEntryTitleDirective,
    MenuEntryIconDirective,
    MenuEntryDescriptionDirective,
  ],
  exports: [
    MenuEntryComponent,
    MenuEntryDescriptionDirective,
  ]
})
export class MenuEntryModule { }

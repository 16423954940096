<div class="wen-location-selector-input-wrapper">
  <mat-form-field class="wen-location-selector-form-field wen-hide-underline wen-form-field-white" floatLabel="never">
    <input #searchInput matInput [placeholder]="inputPlaceholder | translate"
      [autocomplete]="'off'" [wen-test-id]="'location-selector-search-input'" (input)="onSearchInput()">
    <wen-icon *ngIf="searchInputValue" matSuffix class="wen-icon-medium" [iconName]="'close'" (click)="clearInput()"></wen-icon>
  </mat-form-field>
</div>
<div class="wen-location-selector-results-scroller" cdkScrollable>
  <div class="wen-location-selector-results-wrapper" *ngIf="showResultList$ | async; else initialTemplate">
    <ng-container *ngIf="hasResultList$ | async; else emptyResultTemplate">
      <ng-container *ngFor="let embedLocation of searchResult$ | async">
        <wen-location-list-item
          [model]="embedLocation"
          [searchTerm]="searchInputValue"
          [wen-test-id]="'location-search-result-item'"
          (click)="updateValue(embedLocation)"
          (prefillActionClicked)="prefillLocation($event)"></wen-location-list-item>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="searchHistory$ | async; let searchHistory">
      <ng-container *ngIf="searchHistory.length">
        <div class="wen-location-selector-result-group-label" portal-caption>{{ 'CHANNEL_POST_LOCATION_SELECTOR_LOCATION_HISTORY_LABEL' | translate }}</div>
        <ng-container *ngFor="let embedLocation of searchHistory">
          <wen-location-list-item [model]="embedLocation" (click)="updateValue(embedLocation)" (prefillActionClicked)="prefillLocation($event)"></wen-location-list-item>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #initialTemplate>
  <div class="wen-location-selector-empty-results-wrapper">
    <img class="wen-location-selector-empty-image" src="/assets/wen-widget/image/pic-empty-location.svg">
    <ng-container>
      <div class="wen-location-selector-empty-description" portal-title>
        {{ 'CHANNEL_POST_LOCATION_SELECTOR_EMPTY_DESCRIPTION' | translate }}
      </div>
      <wen-button class="wen-location-selector-use-location-button wen-button-rounded"
        [wenVariant]="'primary'"
        (click)="requestDeviceLocation()">
        {{ 'CHANNEL_POST_LOCATION_SELECTOR_USE_POSITION_LABEL' | translate }}
      </wen-button>
    </ng-container>
  </div>
</ng-template>

<ng-template #emptyResultTemplate>
  <ng-container>
    <div class="wen-location-selector-result-group-label" portal-caption>{{ 'CHANNEL_POST_LOCATION_SELECTOR_NEARBY_LABEL' | translate }}</div>
    <ng-container *ngIf="locationPermission | async; else noPermissionCurrentLocation">
      <wen-list-item class="wen-list-item-auto-height" [justifyInfoContent]="'center'" (click)="selectCurrentLocation()">
        <wen-icon class="wen-icon-huge wen-icon-circular" [wenVariant]="'primaryFlat'" wen-list-item-icon iconName="current_location"></wen-icon>
        <span wen-list-item-title>{{ 'CHANNEL_POST_LOCATION_SELECTOR_ACTUAL_POSITION_LABEL' | translate }}</span>
      </wen-list-item>
    </ng-container>
    <ng-container *ngFor="let userLocation of userLocations$ | async">
      <wen-location-list-item [model]="userLocation" (click)="updateValue(userLocation)" (prefillActionClicked)="prefillLocation($event)"></wen-location-list-item>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #noPermissionCurrentLocation>
  <wen-list-item class="wen-list-item-auto-height" [justifyInfoContent]="'center'" (click)="requestDeviceLocation()">
    <wen-icon class="wen-icon-huge wen-icon-circular" [wenVariant]="'primaryFlat'" wen-list-item-icon iconName="location"></wen-icon>
    <span wen-list-item-title>{{ 'CHANNEL_POST_LOCATION_SELECTOR_USE_POSITION_LABEL' | translate }}</span>
  </wen-list-item>
</ng-template>

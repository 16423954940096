import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { CompareErrorStateMatcher } from '@portal/ui-kit/core';
import { RestrictionDTO, RestrictionResolveObjectType, RestrictionType } from '@portal/wen-backend-api';
import { SelectionOption, whitespaceValidator } from '@portal/wen-components';
import { Observable, Subject, combineLatest } from 'rxjs';
import { distinctUntilChanged, filter, map, share, shareReplay, startWith, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { wenEqualsValidator } from '../../../../../core/common/util/field-validators';
import { ChannelEntity } from '../../../../../core/store/channel/channel.state';
import { selectEditFormById } from '../../../../../core/store/form/form.selectors';
import { FormChangedValue, FormDataObject } from '../../../../../core/store/form/form.state';
import { RootState } from '../../../../../core/store/root/public-api';
import { selectPrivileges } from '../../../../../core/store/user/user.selectors';
import { WenRouteId } from '../../../../../frame/routing/types';
import { FormValueConverter } from '../../../../../shared/form-store/form-store.providers';
import { FormStoreService } from '../../../../../shared/form-store/form-store.service';
import { PrivilegeService } from '../../../../../shared/services/privilege.service';
import { CHANNEL_EDIT_FORM_DATA_PROVIDER, ChannelFormDataProvider } from '../../../common/providers/channel-form-data-provider';
import { ChannelRestrictionFormValueConverter } from './services/channel-restriction-form-value-converter';
import { ChannelRestrictionItemProvider } from './services/channel-restriction-item-provider';

@Component({
  selector: 'wen-channel-restriction-settings',
  templateUrl: './channel-restriction-settings.component.html',
  styleUrls: ['./channel-restriction-settings.component.scss'],
  providers: [
    ChannelRestrictionFormValueConverter, PrivilegeService, CHANNEL_EDIT_FORM_DATA_PROVIDER,
    {
      provide: FormValueConverter,
      useClass: ChannelRestrictionFormValueConverter
    },
    ChannelRestrictionItemProvider
  ]
})
export class ChannelRestrictionSettingsComponent implements OnInit, OnDestroy {
  readonly PASSWORD_COMPARE_ERROR_KEY = 'passwordCompare';
  readonly comparePasswordErrorMatcher = new CompareErrorStateMatcher('password', this.PASSWORD_COMPARE_ERROR_KEY);

  private onDestroy$ = new Subject<void>();
  channelSettingsFormGroup = new FormGroup({
    restrictions: new FormControl(),
    password: new FormControl({ disabled: true, value: '' }, [Validators.required, whitespaceValidator],),
    passwordRepeat: new FormControl({ disabled: true, value: '' }, [Validators.required, whitespaceValidator])
  }, {
    validators: [
      wenEqualsValidator('password', 'passwordRepeat', this.PASSWORD_COMPARE_ERROR_KEY, true)
    ]
  });
  private formRestrictions$: Observable<RestrictionResolveObjectType[]>;
  private filteredFormStoreRestrictionsAsOptions$: Observable<SelectionOption<RestrictionType>[]>;

  restrictionSelectionItems$: Observable<SelectionOption<RestrictionType>[]>;
  restrictionSelectionModel$: Observable<SelectionModel<SelectionOption<RestrictionType>>>;

  channel$: Observable<ChannelEntity>;
  passwordInputVisible$: Observable<boolean>;
  hasNoChannelId$: Observable<boolean>;

  get passwordValue() {
    return this.channelSettingsFormGroup.controls.password.value;
  }

  get passwordRepeatValue() {
    return this.channelSettingsFormGroup.controls.passwordRepeat.value;
  }

  constructor(
    private readonly store: Store<RootState>,
    private readonly channelFormDataProvider: ChannelFormDataProvider,
    private readonly privilegeService: PrivilegeService,
    readonly formStoreService: FormStoreService,
    private restrictionItemProvider: ChannelRestrictionItemProvider
  ) { }

  ngOnInit() {
    this.initializePasswordRestrictionChangeListeners();

    this.channel$ = this.channelFormDataProvider.channel$;

    this.initializeFormStoreRestrictionSelection(this.channel$);

    this.hasNoChannelId$ = this.channel$.pipe(
      map(channel => !channel?.id)
    );

    this.restrictionItemProvider.initialize(this.channel$);

    this.filteredFormStoreRestrictionsAsOptions$ = this.formRestrictions$.pipe(
      withLatestFrom(this.restrictionItemProvider.restrictionItems$),
      map(([selectedRestrictions, { visibleRestrictions }]) => {
        const filteredRestrictions = visibleRestrictions.filter(vR => selectedRestrictions?.some(fR => {
          return fR.privilege ? fR.privilege === vR.id : fR.type === vR.value;
        }));
        return filteredRestrictions.length ? filteredRestrictions : [];
      }),
      takeUntil(this.onDestroy$)
    );

    combineLatest([
      this.channel$.pipe(firstExisty()),
      this.filteredFormStoreRestrictionsAsOptions$,
      this.store.pipe(select(selectPrivileges), firstExisty())
    ]).pipe(firstExisty())
      .subscribe(([channel, formStoreRestrictions, privileges]) => {
        const channelRestrictions = channel.restrictions;
        const editFormRestrictionTypes: RestrictionDTO[] = formStoreRestrictions?.map(restriction => {
          return {
            restrictionType: restriction.value,
            id: restriction.id
          };
        }) ?? [];
        const resolvedRestrictions = this.privilegeService.convertDTOToResolveObject(editFormRestrictionTypes, privileges);
        const finalRestrictions = resolvedRestrictions?.length > 0 ? resolvedRestrictions : channelRestrictions;
        this.formStoreService.initializeForm({
          restrictions: finalRestrictions,
          id: channel?.id
        });
      });

    this.restrictionSelectionItems$ = this.restrictionItemProvider.restrictionItems$.pipe(
      map(({ visibleRestrictions }) => visibleRestrictions)
    );

    this.restrictionSelectionModel$ = this.restrictionItemProvider.restrictionItems$.pipe(
      withLatestFrom(this.filteredFormStoreRestrictionsAsOptions$),
      map(([{ initialRestrictions }, editFormRestrictions]) => {
        const initialValues: SelectionOption<RestrictionType>[] =
          editFormRestrictions?.length ? editFormRestrictions : initialRestrictions;
        return new SelectionModel(false, initialValues);
      })
    );

    this.formRestrictions$.pipe(
      firstExisty(),
    ).subscribe((editFormRestrictions) => {
      this.trySetPasswordValue(editFormRestrictions);
    });
  }

  private initializePasswordRestrictionChangeListeners() {
    this.passwordInputVisible$ = this.channelSettingsFormGroup.controls.restrictions.valueChanges.pipe(
      map((selectedOptions: SelectionOption<RestrictionType>[]) => {
        return Boolean(
          this.channelSettingsFormGroup.controls.restrictions &&
          selectedOptions &&
          selectedOptions.find(option => option.value === RestrictionType.PasswordRequired));
      }),
      startWith(false),
      distinctUntilChanged(),
      shareReplay(1),
    );

    this.passwordInputVisible$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe(visible => {
      if (visible) {
        this.channelSettingsFormGroup.controls.password.enable();
        this.channelSettingsFormGroup.controls.passwordRepeat.enable();
      } else {
        this.channelSettingsFormGroup.controls.password.disable();
        this.channelSettingsFormGroup.controls.passwordRepeat.disable();
        this.channelSettingsFormGroup.updateValueAndValidity();
      }
    });
  }

  private trySetPasswordValue(editFormRestrictions: RestrictionResolveObjectType[]) {
    editFormRestrictions?.forEach(restriction => {
      if (restriction.type === RestrictionType.PasswordRequired) {
        const password = restriction.password;
        this.channelSettingsFormGroup.controls.password?.setValue(password);
        this.channelSettingsFormGroup.controls.passwordRepeat?.setValue(password);
      }
    });
  }

  private initializeFormStoreRestrictionSelection(channel$: Observable<ChannelEntity>): void {
    this.formRestrictions$ = channel$.pipe(
      switchMap((channel) => {
        const matchingRoute = channel?.id ? WenRouteId.CHANNEL_RESTRICTION_SETTINGS : WenRouteId.ADD_CHANNEL_RESTRICTIONS;
        return this.store.pipe(
          select(selectEditFormById(matchingRoute)),
          filter(v => !!v),
          map((formEntity) => (Object.keys(formEntity?.changedValues).length && formEntity?.changedValues) || formEntity.initialValues),
          map((formValue: FormChangedValue | FormDataObject) => formValue.restrictions),
          distinctUntilChanged(),
          share(),
          startWith(null)
        );
      })
    );
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

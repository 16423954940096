<a class="wen-nav-bar-item-link" [wen-test-id]="'wen-navbar-item-' + label" [routerLink]="itemLink"
  routerLinkActive="wen-nav-bar-item-link-active" #rla="routerLinkActive">
  <ng-container *ngIf="iconName">
    <div class="wen-nav-bar-item-icon">
      <wen-icon [iconName]="rla.isActive ? iconNameActive : iconName"></wen-icon>
      <ng-container *ngIf="notificationCount$ | async as count">
        <wen-notification-count *ngIf="count" [value]="count"></wen-notification-count>
      </ng-container>
    </div>
  </ng-container>
  <div class="wen-nav-bar-item-label">{{label}}</div>
</a>
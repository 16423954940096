import { WenRouterOutlet } from '@portal/wen-components';
import { DataContext } from '../../../core/common/types/data-context';
import { crmIntegrationGuardFn } from '../../../core/guards/crm-integration-guard';
import { userProfileActivatorGuardFn } from '../../../core/guards/user-profile-activator-guard';
import { appInitGuardFn } from '../../../core/services/navigation/app-init-guard';
import { authGuardFn } from '../../../core/services/user-management/auth-guard';
import { onboardingAnonymousAutoLoginGuard } from '../../../core/services/user-management/onboarding-anonymous-auto-login.guard';
import { onboardingAuthGuard } from '../../../core/services/user-management/onboarding-auth.guard';
import { registrationGuardFn } from '../../../core/services/user-management/registration-guard';
import { createBackNavigationCommand } from '../../../core/store/header/models/base-commands';
import { dialogOutletAppRoutes, primaryOutletAppRoutes, sidebarOutletAppRoutes } from '../../../views/apps/routes/multi-outlet-apps.routes';
import { dialogOutletChannelRoutes, primaryOutletChannelRoutes, primaryOutletMessageRoutes, sidebarOutletChannelRoutes } from '../../../views/channel/routes/multi-outlet-channel.routes';
import { chatRootGuardFn } from '../../../views/chat/routes/chat-root.guard';
import { dialogOutletChatRoutes, primaryOutletChatRoutes, sidebarOutletChatRoutes } from '../../../views/chat/routes/multi-outlet-chat.routes';
import { dialogOutletContactsRoutes } from '../../../views/contacts/routes/multi-outlet-contacts.routes';
import { dialogOutletCrmRoutes, multiOutletCrmRoutes } from '../../../views/crm/routes/multi-outlet-crm.routes';
import { embeddedPageRoutes } from '../../../views/embedded-page/embedded-page.routes';
import { ErrorPageModule } from '../../../views/error-page/error-page.module';
import { primaryOutletEventRoutes, sidebarOutletEventRoutes } from '../../../views/events/routes/multi-outlet-events.routes';
import { exclusiveInviteRoutes } from '../../../views/exclusive-invite/routes/exclusive-invite.routes';
import { HomeComponent } from '../../../views/home/components/home/home.component';
import { primaryOutletHomeRoutes, sidebarOutletHomeRoutes } from '../../../views/home/routes/multi-outlet-home.routes';
import { dialogOutletInviteRoutes } from '../../../views/invite/routes/multi-outlet-invite.routes';
import { LoginModule } from '../../../views/login/login.module';
import { multiOutletonboardingRoutes } from '../../../views/onboarding/routes/multi-outlet-onboarding.routes';
import { ReaderModule } from '../../../views/reader/reader.module';
import { primaryOutletRegistrationRoutes } from '../../../views/registration/routes/multi-outlet-registration.routes';
import { multiOutletSignInRoutes } from '../../../views/sign-in/routes/multi-outlet-sign-in.routes';
import { signInInviteRoute, signInPopupRoute } from '../../../views/sign-in/routes/signin.routes';
import { dialogOutletProfileRoutes, primaryOutletUserProfileRoutes, sidebarOutletUserProfileRoutes } from '../../../views/user-profile/routes/multi-outlet-user-profile.routes';
import { networkRoutes } from '../../network/network.routes';
import { WenRoutesCollection } from '../types';

export const wenMultiOutletRoutes = (
  startPage: string
): WenRoutesCollection => [
    {
      path: 'error',
      loadChildren: () => ErrorPageModule,
    },
    {
      path: '',
      children: [
        {
          path: 'oauth',
          loadChildren: () => LoginModule
        },
        {
          path: 'register',
          children: primaryOutletRegistrationRoutes,
          canActivate: [registrationGuardFn],
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            appBannerDisabled: true
          }
        },
        {
          path: 'onboarding',
          children: multiOutletonboardingRoutes,
          outlet: WenRouterOutlet.PRIMARY
        },
        {
          path: 'signin',
          children: multiOutletSignInRoutes,
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            appBannerDisabled: true
          }
        },
        {
          path: 'signin',
          children: [signInPopupRoute, signInInviteRoute],
          outlet: WenRouterOutlet.DIALOG
        },
        {
          path: 'home',
          children: sidebarOutletHomeRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.SIDEBAR
        },
        {
          path: 'home',
          children: primaryOutletHomeRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.PRIMARY
        },
        {
          path: 'channel',
          children: sidebarOutletChannelRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.SIDEBAR,
          data: {
            dataContext: DataContext.CHANNEL
          }
        },
        {
          path: 'channel',
          children: primaryOutletChannelRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            dataContext: DataContext.CHANNEL
          }
        },
        {
          path: 'channel',
          children: dialogOutletChannelRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.DIALOG,
          data: {
            dataContext: DataContext.CHANNEL
          }
        },
        {
          path: 'message',
          children: primaryOutletMessageRoutes,
          canActivateChild: [onboardingAnonymousAutoLoginGuard],
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            dataContext: DataContext.CHANNEL
          }
        },
        {
          path: 'apps',
          children: sidebarOutletAppRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.SIDEBAR,
          data: {
            dataContext: DataContext.APP
          }
        },
        {
          path: 'apps',
          children: primaryOutletAppRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            dataContext: DataContext.APP
          }
        },
        {
          path: 'apps',
          children: dialogOutletAppRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.DIALOG,
          data: {
            dataContext: DataContext.APP
          }
        },
        {
          path: 'contacts',
          children: dialogOutletContactsRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.DIALOG,
          data: {
            dataContext: DataContext.CONTACTS
          }
        },
        {
          path: 'profile/read',
          canActivate: [userProfileActivatorGuardFn],
          component: HomeComponent
        },
        {
          path: 'profile',
          children: sidebarOutletUserProfileRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.SIDEBAR,
          data: {
            dataContext: DataContext.PROFILE
          }
        },
        {
          path: 'profile',
          children: primaryOutletUserProfileRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            dataContext: DataContext.PROFILE
          }
        },
        {
          path: 'profile',
          children: dialogOutletProfileRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.DIALOG,
          data: {
            dataContext: DataContext.PROFILE
          }
        },
        {
          path: 'event',
          children: sidebarOutletEventRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.SIDEBAR,
          data: {
            dataContext: DataContext.EVENT
          }
        },
        {
          path: 'event',
          children: primaryOutletEventRoutes,
          canActivateChild: [onboardingAnonymousAutoLoginGuard],
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            dataContext: DataContext.EVENT
          }
        },
        {
          path: 'chat',
          children: sidebarOutletChatRoutes,
          canActivateChild: [authGuardFn, chatRootGuardFn],
          outlet: WenRouterOutlet.SIDEBAR,
          data: {
            dataContext: DataContext.CHAT
          }
        },
        {
          path: 'chat',
          children: primaryOutletChatRoutes,
          canActivateChild: [authGuardFn, chatRootGuardFn],
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            dataContext: DataContext.CHAT
          }
        },
        {
          path: 'chat',
          children: dialogOutletChatRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.DIALOG,
          data: {
            dataContext: DataContext.CHAT
          }
        },
        {
          path: 'embedded-page',
          children: embeddedPageRoutes,
          canActivateChild: [authGuardFn],
          data: {
            headerData: {
              showLogo: true,
              leftCommand: createBackNavigationCommand()
            }
          },
          outlet: WenRouterOutlet.PRIMARY
        },
        {
          path: 'invite',
          children: dialogOutletInviteRoutes,
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.DIALOG,
        },
        {
          path: 'crm',
          children: multiOutletCrmRoutes,
          canActivateChild: [crmIntegrationGuardFn],
          outlet: WenRouterOutlet.PRIMARY,
          data: {
            dataContext: DataContext.PROFILE
          }
        },
        {
          path: 'crm',
          children: dialogOutletCrmRoutes,
          canActivateChild: [crmIntegrationGuardFn],
          outlet: WenRouterOutlet.DIALOG,
          data: {
            dataContext: DataContext.PROFILE
          }
        },
        {
          path: 'reader',
          canActivateChild: [authGuardFn],
          outlet: WenRouterOutlet.PRIMARY,
          loadChildren: () => ReaderModule,
        },
        {
          path: 'exclusive-invite',
          children: exclusiveInviteRoutes,
          outlet: WenRouterOutlet.PRIMARY,
          canActivateChild: [authGuardFn],
          data: {
            appBannerDisabled: true
          }
        },
        {
          path: 'network',
          canActivateChild: [onboardingAuthGuard],
          children: networkRoutes
        },
        {
          path: 'wallet',
          loadChildren: () => import('../../../views/wallet/wallet.routes').then(m => m.primaryWalletRoutes),
          outlet: WenRouterOutlet.PRIMARY
        },
        {
          path: '**',
          canActivate: [appInitGuardFn],
          children: []
        }
      ]
    },
  ];

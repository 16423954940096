<div class="wen-profile-scroller" cdkScrollable>
  <div class="wen-profile-header" *ngIf="headerData$ | async as headerData">
    <wen-detail-header [model]="headerData" [coverImageStyle]="'circle'" [allowLoadingIndicator]="true"
      [shouldAnimate]="false">
      <div wen-detail-header-title>{{ headerData?.title }}</div>
      <div wen-detail-header-hint *ngIf="showHint$ | async">
        {{ "CONTACTS_ACCEPT_RECEIVED_HINT_LABEL" | translate: {displayName: headerData?.title} }}
      </div>
      <div wen-detail-header-additional-content *ngIf="showActions$ | async">
        <ng-container *ngTemplateOutlet="connectionActions; context: { $implicit: profileData.profile$ | async }"></ng-container>
      </div>
    </wen-detail-header>
  </div>
  <div *ngIf="additionalInformation$ | async as additionalInformation" class="wen-profile-additional-information-box">
    <div portal-caption>{{ "PROFILE_FORM_ABOUT_ME_LABEL" | translate }}</div>
    <wen-expandable-container maxHeight="100">
      <wen-markdown-text portal-body [content]="additionalInformation"></wen-markdown-text>
    </wen-expandable-container>
  </div>
</div>

<ng-template #connectionActions let-profile>
  <div class="wen-other-profile-connection-additional-actions-wrapper">
    <wen-contact-response-action *ngIf="enableContacts$ | async" [profileData]="responseActionData$ | async"></wen-contact-response-action>
    <wen-button *ngIf="conditions.canInitiateChat$ | async" class="wen-button-rounded" wenVariant="secondary"
      iconName="message_inactive" iconSize="huge" (click)="actions.initiateChatWithUser(profile)">
      <span portal-title>{{ "CONTACTS_ACTION_SEND_MESSAGE" | translate }}</span>
    </wen-button>
  </div>
</ng-template>
import { Injectable } from '@angular/core';
import { EncryptionAlgorithm, EncryptionType, SendToUsersEventResponse, SendToUsersEventResponses, ShareKeyContent, ShareKeyEventDTO, ToUsersEventType } from '@portal/wen-backend-api';
import { ChatTracer, WenChatClient } from '@portal/wen-chat-client';
import { catchError, map, Observable, of, switchMap, tap } from 'rxjs';
import { FailedDecryptionHandler } from './failed-decryption-handler';

export type DecryptedShareKeyResult = {
  decryptedContent: ShareKeyContent<false>;
  originalEvent: SendToUsersEventResponse;
};

@Injectable()
export class SendKeyDecryptor {

  constructor(
    private chatClient: WenChatClient,
    private failedDecryptionHandler: FailedDecryptionHandler,
    private chatTracer: ChatTracer,
  ) { }

  decryptShareKeyEvent(event: SendToUsersEventResponse): Observable<DecryptedShareKeyResult> {
    const { payload } = event;
    if (!this.isSupportedEvent(payload)) {
      return of(null);
    }
    const { content: { senderKey: senderCurve25519, ciphertext }, senderUserId } = payload;
    const { insertUserTimestamp: insertTimestamp } = event;
    this.chatTracer.addShareKeyReceivedHint({ senderCurve25519, insertTimestamp });
    return this.chatClient.decryptMessageOlm(senderUserId, senderCurve25519, ciphertext).pipe(
      map((result) => this.convertToResult(result.decrypted, event)),
      switchMap((result) => {
        const { decryptedContent } = result;
        const { roomId } = result.decryptedContent;
        const { sessionKey, sessionId } = decryptedContent;
        return this.chatClient.createInboundGroupSession(roomId, senderCurve25519, sessionKey).pipe(
          tap(() => {
            this.failedDecryptionHandler.emitRetry(sessionId);
            this.chatTracer.addShareKeyProcessedHint({ senderCurve25519, sessionId, roomId, insertTimestamp });
          }),
          map(() => result)
        );
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  private convertToResult(decrypted: string, event: SendToUsersEventResponse) {
    const data: ShareKeyContent<false> = JSON.parse(decrypted);
    const resultShareKey: DecryptedShareKeyResult = {
      originalEvent: event,
      decryptedContent: data
    };
    return resultShareKey;
  }

  private isSupportedEvent(payload: SendToUsersEventResponses): payload is ShareKeyEventDTO<true> {
    return payload.type === EncryptionType.ENCRYPTED &&
      payload.eventType === ToUsersEventType.SHARE_KEY &&
      payload.content.algorithm === EncryptionAlgorithm.Olm;
  }

}

<div id="lightbox-wrapper" [photoSwipe]="images" (pswpDestroy)="close()">
  <wen-lightbox-header
  @fadeInOut *ngIf="headerVisible$ | async"
  [isDownloading]="isDownloading$"
  [isFileDownloadEnabled]="isFileDownloadEnabled"
  (closeEvent)="close()"
  (downloadEvent)="onDownload()"
  ></wen-lightbox-header>
  <wen-loading-indicator
    [style.visibility]="(isDownloading$ | async) ? 'visible' : 'hidden'"
    [size]="'icon-medium'"
    [color]="'light'"
  ></wen-loading-indicator>
</div>

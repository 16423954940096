import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { InsertUser, RoomMessageDTO, RoomType } from '@portal/wen-backend-api';
import { combineLatest, map, Observable, ReplaySubject, switchMap } from 'rxjs';
import { ChatMessageDecryptionError } from '../../../../../core/services/chat/decryption/message-decryptor';
import { isForwardEmbed, isMediaEmbed } from '../../../../../core/store/channel/util/embed-type-helper';
import { selectCurrentRoom } from '../../../../../core/store/chat/chat.selectors';
import { ChatRoomEntity, RoomDetails } from '../../../../../core/store/chat/chat.state';
import { ChatNotificationEventEntity } from '../../../../../core/store/notification/notification.state';
import { RootState } from '../../../../../core/store/root/public-api';
import { ChatListItemDatasource } from './providers/chat-list-item-datasource';
import { ActionMenuItemProvider } from '../../../../../core/services/util/action-menu-item.provider';
import { ActionMenuItem, getMediaTypeData } from '@portal/wen-components';
import { chatListItemContextMenuId } from './action-menu/chat-list-item.actions';
import { lastItem } from '../../../../../core/common/operators/array-utils';

interface ChatListItemDescription {
  title?: string;
  icon?: string;
  isItalic?: boolean;
  deleted?: boolean;
}
export type ChatListItemModel = { id: string } & RoomDetails & Partial<{
  timestamp: string;
  count: number;
  muteIcon?: string;
  isMuted?: boolean;
  lastReadTimestamp: string;
  lastReceivedTimestamp: string;
  lastMessage?: {
    decryptionError: ChatMessageDecryptionError;
    eventId: string;
    messageContent: RoomMessageDTO;
  };
  details: ChatListItemDescription;
  insertUser: InsertUser;
  roomType: RoomType;
  pinTimestamp?: string;
}>;

@Component({
  selector: 'wen-chat-list-item',
  templateUrl: './chat-list-item.component.html',
  styleUrls: ['./chat-list-item.component.scss'],
  providers: [ChatListItemDatasource],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatListItemComponent implements OnInit {

  private model$ = new ReplaySubject<ChatRoomEntity>(1);
  listItemModel$: Observable<ChatListItemModel>;
  isSelected$: Observable<boolean>;
  actions$: Observable<ActionMenuItem[]>;

  @Input() set chatItem(room: ChatRoomEntity) {
    this.model$.next(room);
    this.actions$ = this.actionMenuItemProvider.selectActions(chatListItemContextMenuId, room);
  }

  @Input() markdownEnabled: boolean;

  constructor(
    private chatListItemDatasource: ChatListItemDatasource,
    private translateService: TranslateService,
    private store: Store<RootState>,
    private actionMenuItemProvider: ActionMenuItemProvider,
  ) { }

  ngOnInit(): void {
    this.listItemModel$ = this.model$.pipe(
      switchMap((room) => {
        return combineLatest([
          this.chatListItemDatasource.getLatestMessageContent(room.id),
          this.chatListItemDatasource.getLatestNotificationFor(room.id)
        ]).pipe(
          map(([{ entity }, count]) => {
            if (!entity) {
              return {
                ...room,
              };
            }
            const { originalEvent: { insertUser, insertTimestamp } } = entity;
            const description = this.getListDescription(entity);
            return {
              ...room,
              details: this.toListItemDescription(description ?? room.details),
              timestamp: insertTimestamp,
              insertUser,
              count,
              lastMessage: {
                decryptionError: entity.decryptionError,
                eventId: entity.originalEvent.eventId,
                messageContent: { ...entity.decryptedEvent.message, timestamp: insertTimestamp }
              }
            };
          })
        );
      })
    );

    const currentRoomId$ = this.store.pipe(
      select(selectCurrentRoom),
      map((room) => room?.id),
    );

    this.isSelected$ = combineLatest([
      currentRoomId$,
      this.listItemModel$
    ]).pipe(
      map(([currentRoomId, room]) => {
        if (!currentRoomId || !room) {
          return false;
        }
        return currentRoomId === room.id;
      })
    );
  }

  private getListDescription(entity: ChatNotificationEventEntity): ChatListItemDescription {
    const { decryptedEvent: { message }, decryptionError, redacted } = entity;
    if (decryptionError) {
      return {
        title: this.translateService.instant('CHAT_MESSAGE_DECRYPTION_ERROR')
      };
    }
    if (!message?.content && !message?.embeds?.length) {
      return {
        title: this.translateService.instant('CHAT_MESSAGE_DELETED'),
        icon: 'delete',
        isItalic: true,
        deleted: redacted
      };
    }
    if (!message.content && message.embeds.length) {
      let icon = null;
      let title = null;
      const lastEmbed = lastItem(message.embeds);
      if (isMediaEmbed(lastEmbed)) {
        const data = getMediaTypeData(lastEmbed.subType);
        icon = data.icon;
        title = data.title;
      }
      if (isForwardEmbed(lastEmbed)) {
        icon = 'forward';
        title = 'FORWARDED_LABEL';
      }
      return {
        title, icon
      };
    }
    return { title: message.content };
  }

  private toListItemDescription(details: ChatListItemDescription | RoomDetails): ChatListItemDescription {
    if ('description' in details) {
      return { title: details.description };
    } else if ('isItalic' in details) {
      const { title, icon, isItalic, deleted } = details;
      return { title, icon, isItalic, deleted };
    } else {
      const { title, icon } = details;
      return { title, icon };
    }
  }
}

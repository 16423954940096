import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ChatMessageRedactRequest, SocketIoService } from '@portal/wen-backend-api';
import { first, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { categorizeEmbeds } from '../../channel/util/embed-type-helper';
import { DraftMessageEntity } from '../../common/models';
import { RootState } from '../../root/public-api';
import { editChatMessageEvent, editScheduledChatMessageEvent, redactChatMessageEvent } from '../actions/chat-message.actions';
import { removeDraftChatMessage, updateChatDraftMessage } from '../chat.actions';
import { selectCurrentRoom } from '../chat.selectors';
import { selectChatMessageById } from '../selectors/chat-message-selectors';
import { selectScheduledChatMessageByEventId } from '../selectors/chat-scheduled-selectors';

@Injectable()
export class ChatMessageModificationEffects {

  requestMessageRedact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(redactChatMessageEvent),
      withLatestFrom(this.store.pipe(select(selectCurrentRoom))),
      tap(([{ eventId }, currentRoom]) => {
        const redactRequest: ChatMessageRedactRequest = {
          eventId,
          roomId: currentRoom.id
        };
        this.socketIoService.chat.room.redact.emit(redactRequest);
      })
    );
  }, { dispatch: false });

  requestMessageEdit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editChatMessageEvent),
      withLatestFrom(this.store.pipe(select(selectCurrentRoom))),
      mergeMap(([{ eventId }, { id: roomId }]) => {
        return this.store.pipe(
          selectorWithParam(selectChatMessageById, roomId, eventId),
          first(),
          switchMap(({ messageContent: { content, embeds }, insertTimestamp }) => {
            const { mediaEmbed, linkEmbed } = categorizeEmbeds(embeds);
            const draftMessage: DraftMessageEntity = {
              id: eventId,
              contextId: roomId,
              content,
              fileEmbed: mediaEmbed,
              timestamp: insertTimestamp,
              linkEmbed,
            };
            return [
              removeDraftChatMessage({ roomId }),
              updateChatDraftMessage({ message: draftMessage })
            ];
          })
        );
      }),
    );
  });

  requestScheduledMessageEdit$ = createEffect(() => this.actions$.pipe(
    ofType(editScheduledChatMessageEvent),
    withLatestFrom(this.store.pipe(select(selectCurrentRoom))),
    mergeMap(([{ eventId }, { id: roomId }]) => {
      return this.store.pipe(
        selectorWithParam(selectScheduledChatMessageByEventId, roomId, eventId),
        first(),
        switchMap((message) => {
          const { messageContent, scheduledFor } = message;
          const { content } = messageContent;
          const { mediaEmbed, linkEmbed } = categorizeEmbeds(message.messageContent.embeds);
          const draftMessage: DraftMessageEntity = {
            id: message.eventId,
            contextId: roomId,
            content,
            fileEmbed: mediaEmbed,
            linkEmbed,
            timestamp: scheduledFor,
            scheduled: true,
          };
          return [
            removeDraftChatMessage({ roomId }),
            updateChatDraftMessage({ message: draftMessage })
          ];
        }),
      );
    }),
  ));

  constructor(
    private actions$: Actions,
    private store: Store<RootState>,
    private socketIoService: SocketIoService
  ) { }
}

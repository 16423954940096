import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { WenBreakpointObserver } from '@portal/wen-components';
import { ReplaySubject, of } from 'rxjs';
import { WenRoutesProvider } from '../../../frame/routing/routes/routes.provider';
import { DynamicOuletUrlHandlingStrategy } from './outlet-specific/dynamic-outlet-url-handling-strategy';
import { MultiOutletNavigator } from './outlet-specific/multi-outlet-navigator';
import { SingleOutletNavigator } from './outlet-specific/single-outlet-navigator';
import { WenNavigationHelper } from './types';

@Injectable()
export class RouteConfigInitializer {

  private readonly onNavigatorChange = new ReplaySubject<WenNavigationHelper>(1);
  public readonly onNavigatorChange$ = this.onNavigatorChange.asObservable();

  constructor(
    private singleOutletNavigator: SingleOutletNavigator,
    private multiOutletNavigator: MultiOutletNavigator,
    private breakpointObserver: WenBreakpointObserver,
    private routesProvider: WenRoutesProvider,
    private router: Router,
  ) { }

  public initialize() {
    const isDesktopStyleDevice = this.breakpointObserver.isDesktopStyleDevice;
    let currentNavigatorImpl: WenNavigationHelper;
    if (isDesktopStyleDevice) {
      this.router.urlHandlingStrategy = new DynamicOuletUrlHandlingStrategy(false);
      currentNavigatorImpl = this.multiOutletNavigator;
    } else {
      this.router.urlHandlingStrategy = new DynamicOuletUrlHandlingStrategy(true);
      currentNavigatorImpl = this.singleOutletNavigator;
    }
    this.router.resetConfig(this.routesProvider.getActiveRoutes());
    this.onNavigatorChange.next(currentNavigatorImpl);

    return of(true);
  }

}

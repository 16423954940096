export type BaseUserInfo = {
  userId: string;
  christianName?: string;
  lastName?: string;
  email?: string;
  avatarUrl?: string;
};
export enum Contactability {
  ANYONE = 'ANYONE',
  CONTACTS = 'CONTACTS',
  NO_ONE = 'NO_ONE'
}
export type UserProfileSettings = {
  enableChatViaProfile: boolean;
  searchableByName: boolean;
  searchableByPhone: boolean;
  contactable: Contactability;
  primaryLanguage: string;
};
export enum Language {
  GERMAN = 'de',
  ENGLISH = 'en'
}
export interface UserMetaDataSettingsResponse {
  loggingAllowed: boolean;
  primaryLanguage: string;
}
export type UserMetaDataSettingsDTO = UserMetaDataSettingsResponse;

<ng-container cdkSelection>
  <ng-container *ngIf="isGroupedItems; else simpleList">
    <ng-container *ngFor="let group of visibleSelectionItems">
      <div portal-caption>{{ group.label | translate }}</div>
      <div class="wen-selection-group">
        <ng-container *ngFor="let item of group.items; let data = item; template: listItem"></ng-container>
      </div>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #simpleList>
  <ng-container *ngFor="let item of visibleSelectionItems; let data = item; template: listItem"></ng-container>
</ng-template>

<ng-template #listItem let-data>
  <wen-selection-list-item [selectionOption]="data" [isDisabled]="isDisabled"
    [selectionModel]="selectionModel" [selectionOptionPosition]="selectionOptionPosition"
    [selectionOptionBorderType]="selectionOptionBorderType" [allowSameSelection]="allowEmptyList">
    <wen-circle-image-view *ngIf="withImage" [imageUrl]="data.icon" [placeholderText]="data.text"></wen-circle-image-view>
    <span class="wen-selection-list-text">{{ data.text }}</span>
  </wen-selection-list-item>
</ng-template>

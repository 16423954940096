<div class="wen-contact-list-container" [class.wen-contact-list-filter-active]="containerConnector.isFilteredActive()">
  <div class="wen-contact-list-menu-wrapper">
    <wen-menu-entry (click)="handleToPendingMenuItemClick()" *ngIf="!containerConnector.isFilteredActive()">
      <wen-icon [iconName]="toPendingMenuEntryConfig.iconName" [fillColor]="toPendingMenuEntryConfig.fillColor"
        [color]="'white'" class="wen-icon-medium" menuEntryIcon>
      </wen-icon>
      <span portal-body-1 menuEntryTitle>{{ toPendingMenuEntryConfig.translationKey | translate }}</span>
      <ng-container menuEntryDescription *ngIf="openContactRequestCount$ | async; let notificationCount">
        <ng-container *ngIf="notificationCount > 0">
          <wen-notification-count class="wen-notification-count-big"
            [value]="notificationCount"></wen-notification-count>
        </ng-container>
      </ng-container>
    </wen-menu-entry>
  </div>
  <div class="wen-contact-list-wrapper">
    <wen-list-mass-data-viewer [datasource]="containerConnector.activeDatasource">
      <ng-container wen-list-mass-data-empty *ngIf="containerConnector.isFilteredActive()">
        <wen-empty-list src="/assets/wen-widget/image/empty-placeholders/pic-empty-search-result-image.svg"
          [titleLabel]="'NO_SEARCH_RESULT_TEXT'"></wen-empty-list>
      </ng-container>
      <ng-container wen-list-mass-data-empty *ngIf="!containerConnector.isFilteredActive()">
        <wen-empty-list #normalListEmptyTemplate
          src="/assets/wen-widget/image/empty-placeholders/pic-empty-wen-contacts-list.svg"
          [titleLabel]="'CONTACTS_LIST_EMPTY_HEADER'" [textLabel]="'CONTACTS_LIST_EMPTY_DESCRIPTION'"></wen-empty-list>
      </ng-container>
      <ng-template wen-list-mass-data-item let-listItemData let-index="index">
        <ng-container *ngIf="containerConnector.isFilteredActive()">
          <div *ngIf="(listService.separatorPositions$ | async)?.acceptedIndex === index" portal-subheading-1
            class="wen-contact-list-group-label">
            {{ "CONTACTS_LIST_SEARCH_FRIEND_GROUP_TITLE" | translate }}
          </div>
          <div *ngIf="(listService.separatorPositions$ | async)?.suggestedIndex === index" portal-subheading-1
            class="wen-contact-list-group-label">
            {{ "CONTACTS_LIST_SEARCH_SUGGESTED_GROUP_TITLE" | translate }}
          </div>
        </ng-container>
        <ng-container *ngIf="!containerConnector.isFilteredActive()">
          <div *ngIf="index === 0" portal-subheading-1 class="wen-contact-list-group-label">
            {{"CONTACTS_LIST_SEARCH_FRIEND_GROUP_TITLE" | translate}}
          </div>
        </ng-container>
        <wen-user-list-item [user]="listItemData | toUserListItem" [userNavigable]="listItemData.user.id">
          <div class="wen-contact-user-list-item-actions-after" wen-user-after>
            <wen-contact-response-action *ngIf="isNotConnected(listItemData)" [iconOnly]="true"
              [profileData]="convertToResponseActionData(listItemData)"></wen-contact-response-action>
            <wen-icon class="wen-icon-huge" *ngIf="chatAllowed(listItemData.user.chatAllowed)" (click)="initiateChatWithUser($event, listItemData)"
              [wenVariant]="'secondaryInlined'" iconName="message_inactive"></wen-icon>
          </div>
        </wen-user-list-item>
      </ng-template>
    </wen-list-mass-data-viewer>
  </div>
</div>

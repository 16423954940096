import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ActionMenuItem } from '@portal/wen-components';
import { of } from 'rxjs';
import { updateDraftComment } from '../../../../core/store/comments/comments.actions';
import { RootState } from '../../../../core/store/root/public-api';
import { WenComment } from '../types';

@Injectable()
export class CommentQuoteAction implements ActionMenuItem {

  label: string;
  icon = 'reply';

  isVisible$ = () => of(true);

  constructor(
    private translateService: TranslateService,
    private store: Store<RootState>,
  ) {
    this.label = this.translateService.instant('COMMENT_CONTEXT_MENU_LABEL_QUOTE');
  }

  execute(comment: WenComment) {
    const { id, userName, text } = comment;
    this.store.dispatch(updateDraftComment({
      comment: {
        quote: {
          quotedCommentId: id,
          quotedAuthor: userName,
          quotedText: text
        }
      }
    }));
  }
}

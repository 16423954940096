<div class="wen-credential-card-wrapper">
  <div class="wen-credential-image-wrapper">
    <img [src]="'/assets/wen-widget/image/united-kiosk-credential-card.svg'">
    <div *ngIf="userName$ | async as userName" class="wen-credential-user-name">{{userName}}</div>
  </div>
</div>

<div class="wen-user-credentials-menu-entries">
  <wen-menu-entry [disableArrow]="true" class="wen-double-border">
    <span menuEntryTitle class="wen-credential-details-entry-title">{{'CREDENTIAL_DETAILS_NAME' | translate}}</span>
    <span menuEntryDescription class="wen-credential-details-entry-description">{{'CREDENTIAL_DETAILS_DESCRIPTION_UNITED_KIOSK_FLAT' | translate}}</span>
  </wen-menu-entry>
</div>

<wen-interact-button (click)="openRemoveCredentialDialog()" class="wen-user-credentials-interact-button wen-double-border">{{'CREDENTIAL_DETAILS_REMOVE_CONNECTION' | translate}}</wen-interact-button>

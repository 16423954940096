import { InjectionToken } from '@angular/core';
import { TranslateModuleConfig } from '@ngx-translate/core';

export interface WenTranslationConfig extends TranslateModuleConfig {
  /**
+   * project prefix for loaded files. The prefix is required in order to avoid conflicts in the Passolo translations
+   */
  projectPrefix: string;
}

export const TRANSLATION_CONFIG = new InjectionToken<WenTranslationConfig>('TRANSLATION_CONFIG');

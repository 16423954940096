const urlRegex = /(\b(https?:\/\/|www.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/i;

export const isUrl = (maybeUrl: string) => {
  return maybeUrl?.match(urlRegex);
};

export const isAbsoluteUrl = (url: string) => {
  const absoluteUrlRegexp = new RegExp('^(?:[a-z]+:)?//', 'i');
  return absoluteUrlRegexp.test(url);
};

export const isRelativeUrl = (url: string) => {
  return !isAbsoluteUrl(url) && (url.startsWith('/') || !url.includes('.'));
};

export const addQueryParam = (absoluteOrRelativeUrl: string, params: URLSearchParams) => {
  let urlObj: URL;
  const dummyDomain = 'https://dummy-domain.com';
  if (isAbsoluteUrl(absoluteOrRelativeUrl)) {
    urlObj = new URL(absoluteOrRelativeUrl);
  } else {
    urlObj = new URL(dummyDomain + absoluteOrRelativeUrl);
  }
  params.forEach((value, key) => {
    urlObj.searchParams.append(key, value);
  });
  const purifiedUrl = urlObj.toString().replace(dummyDomain, '');
  return purifiedUrl;
};

export const getQueryParam = <K extends string = string>(absoluteOrRelativeUrl: string, paramName: K) => {
  let urlObj: URL;
  const dummyDomain = 'https://dummy-domain.com';
  if (isAbsoluteUrl(absoluteOrRelativeUrl)) {
    urlObj = new URL(absoluteOrRelativeUrl);
  } else {
    urlObj = new URL(dummyDomain + absoluteOrRelativeUrl);
  }
  return urlObj.searchParams?.get(paramName);
};

export const getAuthQueryParam = <K extends ('code' | 'state')>(
  absoluteOrRelativeUrl: string, paramName: K
) => getQueryParam<K>(absoluteOrRelativeUrl, paramName);

export const convertToRelativeUrl = (url: string) => {
  if (!isAbsoluteUrl(url)) {
    return url;
  }
  const urlObj = new URL(url);
  return urlObj.pathname + urlObj.search;
};

export const extractOrigin = (url: string) => {
  if (!isAbsoluteUrl(url)) {
    return '';
  }
  const urlObj = new URL(url);
  return urlObj.origin;
};

export const isInternalUrl = (url: string) => {
  const rootUrl = getRootUrl();
  return url.includes(rootUrl);
};

export const getRootUrl = () => {
  return `${location.protocol}//${location.host}`;
};

<cdk-virtual-scroll-viewport itemSize="75" cdkScrollable scrollStateHandler>
  <div *cdkVirtualFor="let app of apps; trackBy: trackByFn">
    <wen-list-item #appListItem [context-menu-trigger]="enableContextMenu && menu" [openMode]="'press'" (click)="onClick(app)" [selected]="(currentAppId$ | async) === app.id" [wen-test-id]="'wen-app-list-list-item'">
      <wen-circle-image-view wen-list-item-icon shape="square" [imageUrl]="app.icon" [placeholderText]="app.title">
      </wen-circle-image-view>
      <span wen-list-item-title>{{ app.title }}</span>
      <span wen-list-item-description>{{ app.description }}</span>
      <span wen-list-item-subtitle *ngIf="app.isSubscribed">
        <wen-icon class="wen-icon-tiny" iconName="download_active"></wen-icon>
        {{ 'APP_LIST_SUBSCRIBED' | translate }}
      </span>
    </wen-list-item>
    <wen-context-menu #menu>
      <wen-action-menu [actions]="actions$ | async" [data]="app"></wen-action-menu>
    </wen-context-menu>
  </div>
</cdk-virtual-scroll-viewport>

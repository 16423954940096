import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ReactionContext } from '@portal/wen-backend-api';
import { ReactionSelectorData, ResizeHandlerProvider } from '@portal/wen-components';
import { Subject } from 'rxjs';
import { enableAutoReadAcknowledgeForCurrentRoom } from '../../../../../core/store/chat/chat.actions';
import { requestExchangeInboundGroupSessionForCurrentRoom } from '../../../../../core/store/chat/key-actions';
import { RootState } from '../../../../../core/store/root/public-api';
import { MessageBoxModel } from '../../../../../shared/components/feed/components/we-feed/we-feed-model';
import { FeedLayoutHooks } from '../../../../../shared/components/feed/providers/feed-layout-hooks';
import { FeedLayoutMediator } from '../../../../../shared/components/feed/providers/feed-layout-mediator';
import { chatMessageBoxContextMenuId } from '../../../../../shared/components/message-box/actions/message-box.action-menu';
import { MessageBoxCustomizations } from '../../../../../shared/components/message-box/providers/message-box-embed-customizations';
import { ChatMessageExtensionDataLoader } from '../../providers/chat-message-extension-data-loader.service';
import { ChatMessageCustomizations } from './providers/chat-message-customizations';

@Component({
  selector: 'wen-chat-messages-view',
  templateUrl: './chat-messages-view.component.html',
  styleUrls: ['./chat-messages-view.component.scss'],
  providers: [
    ResizeHandlerProvider,
    { provide: FeedLayoutHooks, useClass: ChatMessageExtensionDataLoader },
    { provide: MessageBoxCustomizations, useClass: ChatMessageCustomizations }
  ]
})
export class ChatMessagesViewComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly contextMenuId = chatMessageBoxContextMenuId;
  readonly reactionContext = ReactionContext.CHAT_MESSAGE;
  private onDestroy$ = new Subject<void>();

  @Input() isGroupChat: boolean;
  @Input() flowDirection: 'up' | 'down' = 'up';
  @Input() disableScrollToBottomButton: boolean;
  @Input() disableEmojiReaction: boolean;

  constructor(
    public store: Store<RootState>,
    public feedLayoutMediator: FeedLayoutMediator,
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  onScrollToBottomClicked() {
    this.feedLayoutMediator.scrollToBottom();
    this.enableAutoAcknowledge();
  }

  enableAutoAcknowledge() {
    this.store.dispatch(enableAutoReadAcknowledgeForCurrentRoom());
  }

  onRequestShareKeyClicked() {
    this.store.dispatch(requestExchangeInboundGroupSessionForCurrentRoom({ withDialogFallback: true }));
  }

  toReactionSelectorData(data: MessageBoxModel): ReactionSelectorData {
    return {
      reactionParentId: data.referenceId,
      reactionParentType: this.reactionContext
    };
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

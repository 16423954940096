<div class="wen-pending-contact-list-container">
  <div class="wen-pending-contact-list-wrapper">
    <wen-list-mass-data-viewer [datasource]="listDatasource">
      <ng-container wen-list-mass-data-empty>
        <div portal-subheading-1 class="wen-pending-contact-list-group-label">
          {{ "PENDING_CONTACTS_LIST_EMPTY_HEADER" | translate }}
        </div>
        <wen-empty-list
          src="/assets/wen-widget/image/empty-placeholders/pic-empty-list-wen-sitting-robot.svg"></wen-empty-list>
      </ng-container>
      <ng-template #itemTemplate wen-list-mass-data-item let-listItemData let-index="index">
        <div *ngIf="(listService.separatorPositions$ | async)?.incomingIndex === index" portal-subheading-1
          class="wen-pending-contact-list-group-label">
          {{ "PENDING_CONTACTS_LIST_HEADER" | translate }}
        </div>
        <div *ngIf="(listService.separatorPositions$ | async)?.sentIndex === index" portal-subheading-1
          class="wen-pending-contact-list-group-label">
          {{ "PENDING_SENT_CONTACTS_LIST_HEADER" | translate }}
        </div>
        <wen-user-list-item [user]="listItemData | toUserListItem" [userNavigable]="listItemData.user.id">
          <div class="wen-contact-user-list-item-actions-after" wen-user-after
            *ngIf="listItemData.requestStatus === 'REQUESTER'; else sentRequestActionsTemplate">
            <wen-icon class="wen-icon-huge" [wenVariant]="'secondaryInlined'" iconName="checkmark"
              (click)="handleAcceptIncomingAfterClick($event, listItemData)"></wen-icon>
            <wen-icon class="wen-icon-huge" [wenVariant]="'secondaryInlined'" iconName="close"
              (click)="handleDenyIncomingAfterClick($event, listItemData)"></wen-icon>
          </div>
          <ng-template #sentRequestActionsTemplate>
            <div class="wen-contact-user-list-item-actions-after" wen-list-item-after>
              <wen-contact-response-action [iconOnly]="true"
                [profileData]="convertToResponseActionData(listItemData)"></wen-contact-response-action>
            </div>
          </ng-template>
        </wen-user-list-item>
      </ng-template>
    </wen-list-mass-data-viewer>
  </div>
</div>
<wen-app-header
  [@headerTopFlyoutAnimation]="searchBarAnimationTrigger$ | async"
  [showLogo]="showLogo$ | async"
  [title]="title$ | async"
  [subTitle]="subTitle$ | async"
  [logoIcon]="logoIcon$ | async"
  [titleIcon]="titleIcon$ | async"
  [prefixTitleIcon]="prefixTitleIcon$ | async"
  [leftCommand]="leftCommand$ | async"
  [rightCommand]="rightCommand$ | async"
  [centerCommand]="centerCommand$ | async"
  [transparent]="isTransparent"
  [floating]="isFloating"
  [listenEnablementEvents]="true"
  [wen-test-id]="'wen-app-header'"
></wen-app-header>
<ng-container *ngIf="searchConfig$ | async; let searchConfig">
  <wen-search-bar
    *ngIf="!searchConfig.hideSearchBar"
    [searchBarPlaceholder]="searchConfig.searchBarPlaceholder"
    [searchEventsHandler]="searchConfig"
    [disabled]="searchConfig?.disabled"
    [wen-test-id]="'wen-app-header-search'"
    [filter-element]="searchConfig?.filterConfig"
    [filterId]="searchConfig?.filterConfig?.filterId"
    [filterEntityType]="searchConfig?.filterConfig?.filterEntityType"
  ></wen-search-bar>
</ng-container>

import { Injectable } from '@angular/core';
import { OlmOneTimeKeys } from '@portal/wen-backend-api';
import { map, of, switchMap } from 'rxjs';
import { ChatBackendApi } from '../backend-api/backend-api';
import { OlmDevice } from '../device/olm-device';
import { createDeviceKeysPayload } from './create-device-keys-payload';

@Injectable()
export class OneTimeKeyService {

  constructor(
    private chatBackendApi: ChatBackendApi,
    private olmDevice: OlmDevice,
  ) { }

  ensureOneTimeKeys(currentOneTimeKeyCount: number) {
    return this.chatBackendApi.getUserData().pipe(
      switchMap((userData) => {
        const api = this.olmDevice.getOneTimeKeyApi();
        const recommendedOneTimeKeysCount = api.getMaxNumberOfOneTimeKeys() / 2;
        if (recommendedOneTimeKeysCount <= currentOneTimeKeyCount) {
          return of(null);
        }
        const keysToGenerate = recommendedOneTimeKeysCount - currentOneTimeKeyCount;
        const oneTimeKeys$ = api.generateOneTimeKeys(keysToGenerate);
        return oneTimeKeys$.pipe(
          switchMap((generatedOneTimeKeys) => {
            const e2eKeys = this.olmDevice.getIdentityKeys();
            const { userId, deviceId } = userData;
            const oneTimeKeys: OlmOneTimeKeys = {
              deviceId,
              userId,
              keys: generatedOneTimeKeys.curve25519
            };
            const deviceKeyParams = createDeviceKeysPayload(userId, deviceId, e2eKeys);
            return this.chatBackendApi.uploadKeys({
              ...deviceKeyParams,
              oneTimeKeys
            }).pipe(
              map(() => null)
            );
          })
        );
      }),

    );
  }
}

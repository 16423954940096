import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { EmbedDTOTypes, EncryptedMessageEventResponses, InsertUser, MemberMetaData, MessageEvent, MessageModificationState, RoomDTO, RoomMembers, SimpleUserDTO, ToRoomEventType } from '@portal/wen-backend-api';
import { DateUtil } from '../../common/date/date-util';
import { LoadingState } from '../../common/types/store-loading-state';
import { ChatMessageDecryptionError } from '../../services/chat/decryption/message-decryptor';
import { createNestedEntityAdapter, CollectionEntityState } from '../common/entity-state-helpers/nested-entity-adapter';
import { DraftMessageEntity } from '../common/models';
import { createStateWithHistoryAdapter } from './chat-state-adapters/state-with-history-adapter';
import { createStateWithMessagesAdapter } from './chat-state-adapters/state-with-messages-adapter';

export type ChatMessageEntityContent = {
  userId: string;
  content?: string;
  embeds?: EmbedDTOTypes[];
  eventType?: ToRoomEventType;
};

export interface ChatMessageEntity {
  /**
   * @deprecated
   *
   * Use eventId instead!
   * TODO: remove this property
   */
  id: string;
  eventId?: string;
  new?: boolean;
  insertTimestamp?: string;
  messageContent: ChatMessageEntityContent;
  insertUser: InsertUser;
  encryptionData: {
    originalEvent: MessageEvent<EncryptedMessageEventResponses>;
    encryptedMessage?: EncryptedMessageEventResponses;
    roomId: string;
  };
  state: MessageModificationState;
  decryptionError?: ChatMessageDecryptionError;
}

export interface ChatScheduledMessageEntity extends ChatMessageEntity {
  scheduledFor: string;
}

export interface SharedInboundGroupSession {
  roomId: string;
}

export type RoomDetails = Partial<Pick<RoomDTO, 'title' | 'description' | 'icon' | 'type' | 'version' | 'isMuted'> & { timestamp: string }>;

export interface ChatRoomEntity extends MemberMetaData {
  id: string;
  details?: RoomDetails;
  members?: ChatUserDTO[];
  loadingState?: LoadingState;
  /**
   * The last time when every room member acknowledged the room with RECEIVED. @see AcknowledgeType
   */
  lastReceivedTimestamp?: string;
  /**
   * The last time when every room member acknowledged the room with READ. @see AcknowledgeType
   */
  lastReadTimestamp?: string;
  /**
   * The "real time" messages received while the app is open
   */
  messages?: EntityState<ChatMessageEntity>;
  /**
   * The historical messages received by either the result of paging or as relevant messages
   */
  history?: CollectionEntityState<ChatMessageEntity>;
  /**
   * The messages which are in the sending process and waiting for delivery (e.g file attachment upload is in progress)
   */
  pendingMessages?: EntityState<ChatMessageEntity>;
  /**
   * The messages which are not sent yet, but already constructed in the message send input field
   */
  draftMessage?: DraftMessageEntity;
  /**
   * The scheduled messages for the given room
   */
  scheduledMessages?: CollectionEntityState<ChatScheduledMessageEntity>;
  /**
   * The temporary stored file embed to display the thumbnail for the uploader
   */
  fileEmbedCache?: FileEmbedCache;
}

export type ChatUserDTO = RoomMembers & Partial<SimpleUserDTO>;

export type DraftChatItemEntity = { id: string; avatarUrl: string; title: string };

type DraftChat = {
  users: EntityState<DraftChatItemEntity>;
  rooms: EntityState<DraftChatItemEntity>;
  message: DraftMessageEntity;
};

type FileEmbedCache = {
  [uploadId: string]: string;
};

export interface ChatState {
  chatInitialized: boolean;
  rooms: EntityState<ChatRoomEntity>;
  currentAutoReadAcknowledgeRoomId: string;
  /**
   * The inbound group sessions which were successfully sent to users
   */
  sharedInboundGroupSessions: SharedInboundGroupSession[];
  userChatListLoadingState: LoadingState;
  draft: DraftChat;
}

export const chatRoomsEntityAdapter = createEntityAdapter<ChatRoomEntity>({
  selectId: (entity) => entity.id
});

export const chatMessageEntityAdapter = createEntityAdapter<ChatMessageEntity>({
  selectId: (entity) => entity.eventId,
  sortComparer: (message1, message2) => {
    return DateUtil.compare(message2.insertTimestamp, message1.insertTimestamp);
  },
});

export const pendingChatMessageEntityAdapter = createEntityAdapter<ChatMessageEntity>({
  selectId: (entity) => entity.id
});

export const userSearchListItemEntityAdapter = createEntityAdapter<DraftChatItemEntity>({
  selectId: (entity) => entity.id
});

export const chatSearchListItemEntityAdapter = createEntityAdapter<DraftChatItemEntity>({
  selectId: (entity) => entity.id
});

export const stateWithHistoryAdapter = createStateWithHistoryAdapter({
  entityAdapter: chatRoomsEntityAdapter,
  messageEntityAdapter: chatMessageEntityAdapter
});

export const stateWithMessagesAdapter = createStateWithMessagesAdapter({
  entityAdapter: chatRoomsEntityAdapter,
  messageEntityAdapter: chatMessageEntityAdapter
});

export const pendingChatMessageStateAdapter = createNestedEntityAdapter({
  rootEntityAdapter: chatRoomsEntityAdapter,
  nestedEntityAdapter: createEntityAdapter<ChatMessageEntity>({ selectId: (entity) => entity.id }),
  nestedEntityKey: 'pendingMessages'
});

export const chatMessageStateAdapter = createNestedEntityAdapter({
  rootEntityAdapter: chatRoomsEntityAdapter,
  nestedEntityAdapter: createEntityAdapter<ChatMessageEntity>({ selectId: (entity) => entity.eventId }),
  nestedEntityKey: 'messages'
});

export const chatHistoryStateAdapter = createNestedEntityAdapter({
  rootEntityAdapter: chatRoomsEntityAdapter,
  nestedEntityAdapter: createEntityAdapter<ChatMessageEntity>({ selectId: (entity) => entity.eventId }),
  nestedEntityKey: 'history'
});

export const scheduledChatMessagesEntityAdapter = createEntityAdapter<ChatScheduledMessageEntity>({ selectId: (entity) => entity.eventId });

export const scheduledChatMessagesStateAdapter = createNestedEntityAdapter({
  rootEntityAdapter: chatRoomsEntityAdapter,
  nestedEntityAdapter: scheduledChatMessagesEntityAdapter,
  nestedEntityKey: 'scheduledMessages'
});

import { LocationStrategy } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { RouteUrlBuilderResult, RouterOutlerSchema, WenPathLocationStrategy, WenRouterOutlet } from '@portal/wen-components';
import { Entries } from 'type-fest';
import { WenRouteId } from '../../../frame/routing/types';
import { PossibleRouteParams, WenRouteUrlBuilder } from './outlet-specific/wen-route-url-builder';

interface NavigationTransitionParams {
  routeId: WenRouteId;
  routeParams?: PossibleRouteParams;
  extras?: Record<string, any>;
}

type NavigationTransitions = RouterOutlerSchema<NavigationTransitionParams | WenRouteId>;

@Injectable()
export class AppNavigator {

  constructor(
    @Inject(LocationStrategy) protected readonly location: WenPathLocationStrategy,
    protected readonly router: Router,
    protected readonly routeUrlBuilder: WenRouteUrlBuilder,
  ) {
  }

  private assertNavigation(
    outlets: RouteUrlBuilderResult,
    params?: PossibleRouteParams,
  ) {
    Object.values(outlets).forEach((outletUrl) => {
      if (/\/:.*\//.test(outletUrl)) {
        console.warn(`Missing url params for ${outletUrl}! Provided params were: ${JSON.stringify(params)}`);
      }
    });
  }

  private buildUrlsForSingleRoute(
    routeId: WenRouteId,
    params?: PossibleRouteParams,
    targetOutlet?: WenRouterOutlet
  ) {
    const outlets = this.routeUrlBuilder.buildUrlsForSingleRoute(routeId, params, targetOutlet);
    this.assertNavigation(outlets, params);
    return outlets;
  }

  private buildUrlsForMultipleRoute(
    transitions: NavigationTransitions
  ) {
    const entries = Object.entries(transitions) as Entries<NavigationTransitions>;
    const outlets: RouteUrlBuilderResult = entries.reduce((acc, [key, params]) => {
      const paramsNormalized = typeof params === 'object' ? params : { routeId: params };
      const { routeId = null, routeParams = {} } = paramsNormalized || {};
      const subOutlets = this.buildUrlsForSingleRoute(routeId, routeParams, key);
      return {
        ...acc,
        ...subOutlets
      };
    }, {} as RouteUrlBuilderResult);
    return outlets;
  }

  private doNavigateToRoute(
    routeId: WenRouteId,
    params?: PossibleRouteParams,
    extras?: NavigationExtras,
  ) {
    const outlets = this.buildUrlsForSingleRoute(routeId, params);
    this.router.navigate([{ outlets }], extras);
  }

  private doNavigateToRoutes(
    transitions: NavigationTransitions,
    extras?: NavigationExtras,
  ) {
    const outlets = this.buildUrlsForMultipleRoute(transitions);
    this.router.navigate([{ outlets }], extras);
  }

  /**
   * Try to navigate to a route defined by a wen-route id
   * The navigation will recognize the correct outlet and navigate to the route in that
   *
   * @param routeId The target wen-route id
   * @param routeParams The params for the route (eg. ids)
   */
  navigateToRoute(routeId: WenRouteId, routeParams?: PossibleRouteParams, extras?: NavigationExtras): void;
  navigateToRoute(routeId: NavigationTransitions, extras?: NavigationExtras): void;
  navigateToRoute(
    routeIdOrTransition: WenRouteId | NavigationTransitions,
    routeParamsOrExtras?: PossibleRouteParams | NavigationExtras,
    extras?: NavigationExtras,
  ): void {
    if (typeof routeIdOrTransition !== 'object') {
      const routeParams = routeParamsOrExtras as PossibleRouteParams;
      this.doNavigateToRoute(routeIdOrTransition, routeParams || {}, extras);
    } else {
      const routeExtras = routeParamsOrExtras as NavigationExtras;
      this.doNavigateToRoutes(routeIdOrTransition, routeExtras);
    }
  }

  /**
   * Navigate to a a route defined by a wen-route id and skip the navigation loops
   * between the previous latest occurence of the target route and the current route
   * This is similar like a "targeted" back navigation, but falls back to a simple navigation if no loops are found
   *
   * @param routeId The target wen-route id
   * @param params The params for the route (eg. ids)
   */
  navigateToRouteCompressed(
    routeId: WenRouteId,
    params?: PossibleRouteParams
  ) {
    const outlets = this.routeUrlBuilder.buildUrlsForSingleRoute(routeId, params);
    const didNavigate = this.location.navigateCompressed(outlets.primary);
    if (!didNavigate) {
      this.router.navigate([{ outlets }], { replaceUrl: true });
    }
  }

  /**
   * Navigate to a a route and open up a navigation stack which acts like a single route
   * while the stack is open. Similar to replace state, but the stack's elements are tracked and can
   * be targeted by backnavigation
   *
   * @param routeIdOrTransition The target wen-route id
   */
  enterStackAndNavigate(
    routeIdOrTransition: WenRouteId | NavigationTransitions,
  ): void {
    const previousUrl = this.router.routerState.snapshot.url;
    const historyStack = history.state?.historyStack || [];
    const state = {
      historyStack: [...historyStack, previousUrl]
    };
    const extras: NavigationExtras = { state };
    if (typeof routeIdOrTransition !== 'object') {
      this.doNavigateToRoute(routeIdOrTransition, {}, extras);
    } else {
      this.doNavigateToRoutes(routeIdOrTransition, extras);
    }
  }

  /**
   * Navigate to a a route and close the current navigation stack
   *
   * @param routeId The target wen-route id
   */
  leaveStackAndNavigate(
    routeId: WenRouteId,
  ) {
    this.doNavigateToRoute(routeId, {}, { replaceUrl: true });
  }

  /**
   * Navigate back and close the current navigation stack
   *
   * @param routeId The target wen-route id
   */
  leaveStackAndNavigateBack() {
    this.location.back();
  }

}

<div class="wen-horizontal-card-content">
  <div class="wen-horizontal-card-image-container">
    <ng-content select="wen-horizontal-card-image, [wen-horizontal-card-image]"></ng-content>
  </div>
  <div class="wen-horizontal-card-info-container">
    <ng-content select="wen-horizontal-card-title, [wen-horizontal-card-title]"></ng-content>
    <ng-content select="wen-horizontal-card-description, [wen-horizontal-card-description]"></ng-content>
    <div *ngIf="cardLink" class="wen-horizontal-card-info-link" wen-primary-color>
      <ng-content select="wen-horizontal-card-link, [wen-horizontal-card-link]"></ng-content>
      <wen-icon wen-primary-color class="wen-icon-small" iconName="chevron_right"></wen-icon>
    </div>
  </div>
</div>
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { WenStorageService } from '../../../core/services/storage/wen-storage.service';
import { Language } from '@portal/wen-backend-api';
import { AvailableLanguages } from '@portal/wen-translation';
import { DateUtil } from '../../../core/common/date/date-util';

export function apiInitializer(translateService: TranslateService, storageService: WenStorageService) {
  let lang = storageService.getLanguage();
  if (!lang) {
    const userLang = getBrowserLanguage();
    lang = checkIfLanguageIsSupported(userLang) ? userLang : 'de';
    storageService.setLanguage(lang);
  }
  translateService.setDefaultLang(lang);
  DateUtil.setLocale(lang);
  return () => firstValueFrom(translateService.use(lang));
}

function getBrowserLanguage(): string {
  const userPrefLanguage = navigator.language || navigator.languages[0];
  return userPrefLanguage.split('-')[0];
}

function checkIfLanguageIsSupported(languageCode: string): boolean {
  return Object.values(Language).includes(languageCode as AvailableLanguages);
}

import { HttpClientModule } from '@angular/common/http';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WenComponentsModule } from '@portal/wen-components';
import { WenStorePersistenceModule } from '@portal/wen-store-persistence';
import { WenTranslationModule } from '@portal/wen-translation';
import { NetworkModule, ROOT_REDUCERS, WEN_CLIENT_CUSTOMIZATION_PROVIDER, WEN_NETWORK_INITIALIZATION_PROVIDER, WEN_RADIUS_CONFIGURATION, WenApiModule, WenChatModule, WenEmbeddedApiModule, WenRoutingModule, WenWidgetModule } from '@portal/wen-widget';
import { environment } from 'src/environments/environment';
import { getVersionInfo } from '../utils/version-helper';
import { AppComponent } from './app.component';

const versionString = getVersionInfo();

const WEN_CORE_MODULES = [
  WenComponentsModule.forRoot(),
  WenRoutingModule.forRoot(),
  WenWidgetModule,
  WenApiModule.forRoot({
    backendConfig: environment.backendConfig,
    oauthConfig: environment.oauthConfig,
    isE2EMode: environment.isE2EMode,
    appConfig: environment.appConfig,
    weLocalBaseUrl: environment.weLocalBaseUrl,
    yellowMapConfig: environment.yellowMapConfig,
    versionInfo: {
      versionString
    },
    tracerConfig: environment.tracerConfig,
    nativeConfig: environment.nativeConfig,
    debugOptions: environment.debugOptions,
    imageProxyBaseUrl: environment.imageProxyBaseUrl,
    contactInfoConfig: environment.contactInfoConfig,
    exclusiveInviteConfig: environment.exclusiveInviteConfig,
    weatherConfig: environment.weatherConfig,
    textToSpeechConfig: environment.textToSpeechConfig,
    dataProtectionConfig: environment.dataProtectionConfig,
    walletConfig: environment.walletConfig
  }),
  WenChatModule.forRoot(),
  WenTranslationModule.forRoot(),
  NetworkModule.forRoot({
    clientCustomizationProvider: WEN_CLIENT_CUSTOMIZATION_PROVIDER,
    networkInitializationHandler: WEN_NETWORK_INITIALIZATION_PROVIDER
  }),
  WenEmbeddedApiModule.forRoot(),
  environment.appConfig.enableAppStatePersistence ? WenStorePersistenceModule.forRoot() : [],
];

const WEN_PEER_MODULES = [
  StoreRouterConnectingModule.forRoot(),
  StoreModule.forRoot(ROOT_REDUCERS, {}),
  EffectsModule.forRoot([]),
  HttpClientModule,
];
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    ...WEN_CORE_MODULES,
    ...WEN_PEER_MODULES,

    environment.appConfig.enableDevTools ? StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: true, // Restrict extension to log-only mode
    }) : [],
    environment.appConfig.enablePwa ? ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }) : []
  ],
  providers: [
    environment.optionalProviderInitializers ? environment.optionalProviderInitializers() : [],
    WEN_RADIUS_CONFIGURATION
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

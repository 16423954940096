import { Observable } from 'rxjs';
import { PermissionLevel } from '../../../../../core/services/user-management/permission-level';

export enum EntryId {
  MANIFEST = 'MANIFEST',
  COMMUNITY_GUIDELINE = 'COMMUNITY_GUIDELINE',
  LOGOUT = 'LOGOUT',
  COMING_SOON = 'COMING_SOON',
  REGISTER = 'REGISTER',
  PROFILE = 'PROFILE',
  CONTACTS = 'CONTACTS',
  DATA_PROTECTION = 'DATA_PROTECTION',
  TERMS_OF_USE = 'TERMS_OF_USE',
  IMPRESSUM = 'IMPRESSUM',
  LICENSES = 'LICENSES',
  NETIQUETTE = 'NETIQUETTE',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
  HELP = 'HELP',
  FAQ = 'FAQ',
  PRIVACY_SETTINGS = 'PRIVACY_SETTINGS',
  MARKDOWN = 'MARKDOWN',
  NEW_GROUP = 'NEW_GROUP',
  NEW_CHANNEL = 'NEW_CHANNEL',
  NEW_APP = 'NEW_APP',
  INVITE = 'INVITE',
  AUTO_REPLY = 'AUTO_REPLY',
  HOTLINE = 'HOTLINE',
  ACCESSIBILITY = 'ACCESSIBILTY',
  SELECT_LANGUAGE = 'SELECT_LANGUAGE'
}

export interface UserProfileMenuEntry {
  entryId: EntryId;
  translationKey: string;
  requiredPermission: PermissionLevel;
  iconName?: string;
  fillColor?: string;
  color?: string;
  comingSoonFeature?: boolean;
  userPermissionBased?: boolean;
  featureToggleBased?: boolean;
  notificationConfig?: {
    count$: Observable<number>;
  };
}

export type UserProfileMenuEntryFactory = () => UserProfileMenuEntry;

import { AutoReplyMessageEventDTO, MessageEvent, MessageModificationState, generateId } from '@portal/wen-backend-api';
import { ChatMessageEntity } from '../../../../store/chat/chat.state';

export const toAutoReplyChatMessageEntity = (autoReplyEvent: MessageEvent<AutoReplyMessageEventDTO>) => {
  return {
    id: generateId(),
    new: autoReplyEvent.new,
    eventId: autoReplyEvent.eventId,
    encryptionData: {
      roomId: autoReplyEvent.roomId,
      encryptedMessage: null,
      originalEvent: null
    },
    insertUser: autoReplyEvent.insertUser,
    messageContent: {
      content: autoReplyEvent.payload.content,
      userId: autoReplyEvent.insertUser.id,
      timestamp: autoReplyEvent.insertTimestamp,
      eventType: autoReplyEvent.payload.eventType
    },
    insertTimestamp: autoReplyEvent.insertTimestamp,
    decryptionError: null,
    state: MessageModificationState.ORIGINAL
  } as ChatMessageEntity;
};

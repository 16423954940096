import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject, combineLatest, merge, Observable, Subject, fromEvent } from 'rxjs';
import { distinctUntilChanged, filter, first, map, shareReplay, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { WenNativeApi } from '@portal/wen-native-api';
import { FileEventHandlerService } from '../../../services/file-event-handler.service';
import { MessageSendFieldDataSource } from '../providers/message-send-field-data-source';
@Component({
  selector: 'wen-message-send-field',
  templateUrl: './message-send-field.component.html',
  styleUrls: ['./message-send-field.component.scss'],
})
export class MessageSendFieldComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  private onDestroy$ = new Subject<void>();
  private onClick$ = new Subject<void>();

  messageSendFormGroup = new FormGroup({
    sendInput: new FormControl(''),
  });

  get sendInputControl(): AbstractControl {
    return this.messageSendFormGroup.controls.sendInput;
  }

  isSendFieldValid$: Observable<boolean>;

  @ViewChild('sendInput', { static: true }) sendInput: ElementRef<HTMLTextAreaElement>;

  @Input() readonly = false;
  @Input() keepInputFocused = false;
  @Input() inputPlaceholder: string;
  @Input() contextId: string;
  @Input() hideTopBorder = false;

  private innerDisabled = false;
  @Input() set disabled(value: boolean) {
    this.innerDisabled = value;
    if (value) {
      this.disableSendField();
    } else {
      this.enableSendField();
    }
  }
  get disabled(): boolean {
    return this.innerDisabled;
  }

  private isSendingAllowed$ = new BehaviorSubject<boolean>(true);
  @Input() set isSendingAllowed(value: boolean) {
    this.isSendingAllowed$.next(value);
  }

  @Output() messageSent = new EventEmitter();
  @Output() focused = new EventEmitter<FocusEvent>();

  constructor(
    private readonly nativeApi: WenNativeApi,
    private readonly dataSource: MessageSendFieldDataSource,
    private fileEventHandler: FileEventHandlerService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.contextId && changes.contextId.currentValue) {
      this.dataSource.initialize(changes.contextId.currentValue);
      this.initializeWithDraft();
    }
  }

  ngOnInit(): void {

    const inputValue$ = this.sendInputControl.valueChanges.pipe(
      map(value => value = value?.trim() || ''),
      distinctUntilChanged(),
      shareReplay(1),
      takeUntil(this.onDestroy$)
    );

    inputValue$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((value) => {
      this.dataSource.updateDraftMessage({ content: value });
    });

    this.isSendFieldValid$ = combineLatest([
      merge(
        inputValue$.pipe(map(value => Boolean(value))),
        this.messageSent.pipe(map(() => false))
      ).pipe(
        startWith(false)
      ),
      this.dataSource.draftMessage$.pipe(
        map(message => Boolean(message?.fileEmbed) || Boolean(message?.pollEmbed)),
        startWith(false),
        takeUntil(this.onDestroy$)
      ),
      this.isSendingAllowed$
    ]).pipe(
      map(([value, fileAttachment, isSendingAllowed]) => isSendingAllowed && (value || fileAttachment)),
      shareReplay(1)
    );

    this.dataSource.draftMessage$.pipe(
      filter(message => !Boolean(message)),
      takeUntil(this.onDestroy$)
    ).subscribe(() => this.sendInputControl.setValue('', { emitEvent: false }));

    this.focusOnEdit();
    this.focusOnQuote();
  }

  private focusOnEdit() {
    this.dataSource.draftMessage$.pipe(
      distinctUntilChanged((m1, m2) => m1?.id === m2?.id),
      takeUntil(this.onDestroy$)
    ).subscribe(message => {
      // Hotfix for WEN-6063 and WEN-5478, definitely refactor this at some point
      this.sendInputControl.setValue('');
      this.sendInputControl.setValue(message?.content || '');
      const isEdit = Boolean(message?.id);
      if (isEdit) {
        this.sendInput.nativeElement.focus();
      } else {
        this.sendInput.nativeElement.blur();
      }
    });
  }

  private focusOnQuote() {
    this.dataSource.draftMessage$.pipe(
      filter(message => Boolean(message?.quote)),
      takeUntil(this.onDestroy$)
    ).subscribe(message => {
      this.sendInput.nativeElement.focus();
    });
  }

  private initializeWithDraft() {
    this.dataSource.draftMessage$.pipe(
      first()
    ).subscribe(message => {
      this.sendInputControl.setValue(message?.content, { emitEvent: false });
    });
  }

  ngAfterViewInit() {
    if (this.disabled) {
      this.disableSendField();
    }

    merge(
      this.onClick$,
      fromEvent(this.sendInput.nativeElement, 'keyup').pipe(
        filter((e: KeyboardEvent) => e.key === 'Enter' && !e.shiftKey && !this.nativeApi.isReactNativeApp())
      ),
    ).pipe(
      switchMap(() => this.isSendFieldValid$.pipe(
        first(),
        filter(isSendFieldValid => isSendFieldValid)
      )),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.messageSent.emit();
      this.sendInputControl.setValue('', { emitEvent: false });
    });
  }

  private disableSendField() {
    this.sendInput.nativeElement.setAttribute('disabled', 'true');
    this.sendInput.nativeElement.style.pointerEvents = 'none';
  }

  private enableSendField() {
    this.sendInput.nativeElement.setAttribute('disabled', 'false');
    this.sendInput.nativeElement.style.pointerEvents = 'auto';
  }

  onEnter(event: KeyboardEvent) {
    if (!this.nativeApi.isReactNativeApp()) {
      event.preventDefault();
    }
  }

  onClick() {
    this.onClick$.next();
  }

  onFocused(event: FocusEvent) {
    this.focused.emit(event);
  }

  onPaste(event: ClipboardEvent) {
    if (event.clipboardData.files.length > 0) {
      event.preventDefault();
      this.fileEventHandler.addFiles(event.clipboardData.files);
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

import { CanMatchFn, UrlMatcher } from '@angular/router';
import { validateId } from '@portal/wen-backend-api';
import { WenRoutes } from '../../../frame/routing/types';

export const createRouteMatcher = (
  deniedPaths: string[], posParam: string
): UrlMatcher => (segments) => {
  const firstSegment = segments[0];
  if (!firstSegment) {
    return null;
  }
  const isValidId = !deniedPaths.includes(firstSegment.path);
  return isValidId ? {
    consumed: segments, posParams: {
      [posParam]: firstSegment
    }
  } : null;
};

export const extractWildcardRoute = (
  routes: WenRoutes
) => {
  return routes.find(route => route.path === '**');
};

export const isGuidOnlyRoute: CanMatchFn = (_, segments) => {
  if (!segments[0]?.path) {
    return false;
  }
  const isValidUuid = validateId(segments[0].path);
  return isValidUuid;
};

import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { routerNavigationAction } from '@ngrx/router-store';
import { first, tap } from 'rxjs/operators';
import { WenNativeApi } from '@portal/wen-native-api';

@Injectable()
export class NavigationEffects {

  setupFallbackDeepLinkHistory$ = createEffect(() => this.actions$.pipe(
    ofType(routerNavigationAction),
    first(),
    tap(() => {
      if (this.nativeApi.isReactNativeApp()) {
        return;
      }
      const path = this.location.path();
      if (path !== '/') {
        this.router.navigateByUrl(path, { state: { fromDeepLink: true }, replaceUrl: true });
      }
    })
  ), { dispatch: false });

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly location: Location,
    private readonly nativeApi: WenNativeApi,
  ) {
  }

}

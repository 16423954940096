import { Injectable } from '@angular/core';
import { EncryptionAlgorithm, EncryptionType, ExchangeRoomKeyResponseContent, ExchangeRoomKeyResponseEventDTO, SendToUsersEventPayloads, SendToUsersEventResponse, ToUsersEventType } from '@portal/wen-backend-api';
import { WenChatClient } from '@portal/wen-chat-client';
import { catchError, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { groupBy } from '../../../common/util/group-by';
import { SessionsByRoom } from '../../../store/chat/key-actions';

export class DecryptedExchangeSessionKeysResult {

  constructor(
    public readonly decryptedContent: ExchangeRoomKeyResponseContent<false>,
    public readonly originalEvent: SendToUsersEventResponse
  ) { }

  asExchangedSessionsByRoom() {
    const groups = groupBy(this.decryptedContent, (item) => item.roomId);
    const sessionsByRoom: SessionsByRoom = {};
    groups.forEach((roomKeyResponses, key) => {
      const sessions = roomKeyResponses.map(({ sessionId }) => ({ sessionId }));
      sessionsByRoom[key] = sessions;
    });
    return sessionsByRoom;
  }
}

@Injectable()
export class ExchangeSessionKeysDecryptor {

  constructor(
    private chatClient: WenChatClient,
  ) { }

  decryptExchangeKeyEvent(event: SendToUsersEventResponse): Observable<DecryptedExchangeSessionKeysResult> {
    const { payload } = event;
    if (!this.isSupportedEvent(payload)) {
      return of(null);
    }
    const { content: { senderKey, ciphertext }, senderUserId } = payload;
    return this.chatClient.decryptMessageOlm(senderUserId, senderKey, ciphertext).pipe(
      map((result) => this.convertToResult(result.decrypted, event)),
      switchMap((result) => {
        const { decryptedContent } = result;
        if (!decryptedContent.length) {
          return of(result);
        }
        const imports$ = decryptedContent.map(decryptedExchange => {
          const { roomId, senderCurve25519, sessionExport, sessionId } = decryptedExchange;
          return this.chatClient.importInboundGroupSession(roomId, sessionId, senderCurve25519, sessionExport);
        });
        return forkJoin(imports$).pipe(
          map(() => result)
        );
      }),
      catchError(() => {
        return of(null);
      })
    );
  }

  private convertToResult(decrypted: string, originalEvent: SendToUsersEventResponse) {
    const decryptedContent: ExchangeRoomKeyResponseContent<false> = JSON.parse(decrypted);
    const resultShareKey = new DecryptedExchangeSessionKeysResult(decryptedContent, originalEvent);
    return resultShareKey;
  }

  private isSupportedEvent(payload: SendToUsersEventPayloads): payload is ExchangeRoomKeyResponseEventDTO<true> {
    return payload.type === EncryptionType.ENCRYPTED &&
      payload.eventType === ToUsersEventType.EXCHANGE_ROOM_KEY_RESPONSE &&
      payload.content.algorithm === EncryptionAlgorithm.Olm;
  }

}

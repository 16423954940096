import { Component} from '@angular/core';
import { FeedDatasource } from '../../../../../shared/components/feed/providers/feed-datasource';
import { FeedLayoutMediator } from '../../../../../shared/components/feed/providers/feed-layout-mediator';
import { MessageSeparatorDateFormatter, PastMessageSeparatorDateFormatter } from '../../../../../shared/directives/directives/message-separator-formatters';
import { ChatPreviewMessageFeedDatasource } from '../../services/chat-preview-message-feed.datasource';
import { LinkOpener } from '@portal/wen-components';
import { ChatPreviewLinkOpenerService } from '../../services/chat-preview-link-opener';

@Component({
  selector: 'wen-chat-preview',
  templateUrl: './chat-preview.component.html',
  providers: [
    FeedLayoutMediator,
    {
      provide: FeedDatasource,
      useClass: ChatPreviewMessageFeedDatasource
    },
    {
      provide: MessageSeparatorDateFormatter,
      useClass: PastMessageSeparatorDateFormatter
    },
    {
      provide: LinkOpener,
      useClass: ChatPreviewLinkOpenerService
    }
  ],
  styles: ['wen-chat-messages-view {background-image: var(--wen-channel-view-background-image-url);}']
})
export class ChatPreviewComponent {

  constructor() { }

}

import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { EmbedMediaModule } from './embed-media/embed-media.module';
import { EmbedDocumentComponent } from './embed-document/embed-document.component';
import { EmbedExpandableComponent } from './embed-expandable/embed-expandable.component';
import { EmbedLocationComponent } from './embed-location/embed-location.component';
import { EmbedPollComponent } from './embed-poll/embed-poll.component';
import { LocationPreviewModule, PollingModule } from '@portal/wen-components';
import { MatExpansionModule } from '@angular/material/expansion';
import { ThumbnailImageModule } from '../../../thumbnail-image/thumbnail-image.module';
import { WenPipesModule } from '../../../../pipes/wen-pipes.module';
import { EmbedMediaComponent } from './embed-media/embed-media.component';
import { CommonModule } from '@angular/common';
import { EmbedWeatherModule } from './embed-weather/embed-weather.module';
import { EmbedNotificationModule } from './embed-notification/embed-notification.module';
import { EmbedForwardModule } from './embed-forward/embed-forward.module';
import { EmbedLinkModule } from './embed-link/embed-link.module';
import { EmbedHostDirective } from './embed-host.directive';


@NgModule({
  imports: [
    CommonModule,
    EmbedMediaModule,
    PollingModule,
    MatExpansionModule,
    LocationPreviewModule,
    ThumbnailImageModule,
    WenPipesModule,
    TranslateModule.forChild(),
    EmbedWeatherModule,
    EmbedNotificationModule,
    EmbedForwardModule,
    EmbedLinkModule
  ],
  declarations: [
    EmbedDocumentComponent,
    EmbedExpandableComponent,
    EmbedPollComponent,
    EmbedLocationComponent,
    EmbedHostDirective
  ],
  exports: [
    EmbedDocumentComponent,
    EmbedExpandableComponent,
    EmbedPollComponent,
    EmbedLocationComponent,
    EmbedMediaComponent,
    EmbedWeatherModule,
    EmbedNotificationModule,
    EmbedForwardModule,
    EmbedLinkModule,
    EmbedHostDirective
  ],
})
export class EmbedsModule { }

import packageJson from '../../package.json';

export const getVersionNr = () => {
  const currentVersion = packageJson.version;
  return currentVersion;
};

export const getVersionInfo = () => {
  const currentVersion = getVersionNr();
  try {
    const versionRegexp = /(?<major>\d+)\.(?<minor>\d+)\.(?<patch>\d+)/g;
    const match = versionRegexp.exec(currentVersion);
    const major = parseInt(match.groups.major, 10);
    const minor = parseInt(match.groups.minor, 10);
    const patch = parseInt(match.groups.patch, 10);
    if (minor % 2 === 0) {
      return `Latest dev release: ${currentVersion}, Next beta: ${major}.${minor + 1}.0`;
    } else {
      return `Latest release: ${currentVersion}, Next patch: ${major}.${minor}.${patch + 1}`;
    }
  } catch (e) {
    return `Version ${currentVersion}`;
  }
};

<img *ngIf="thumbnail$ | async as thumbnail"
  #image
  [wenLazyload]="thumbnail"
  [wenLazyloadDisableScaling]="true"
  (tap)="onMediaClick($event,thumbnail)"
  (error)="handleImageError()"
  (load)="hadleImageLoaded()"
  [retry-image-load]="onFinalError"
/>
<div class="wen-embed-overlay"  *ngIf="showLoading">
  <wen-loading-indicator [variant]="'picture'"></wen-loading-indicator>
</div>
<div
  *ngIf="error"
  (tap)="reloadImage()"
  class="wen-failed-image-load"
>
  {{'RETRY_IMAGE_LOAD_TEXT' | translate}}
  <wen-icon [iconName]="'jump_forward'"/>
</div>

import { EditMessageEventDTO, EncryptedMessageEventResponses, MessageEvent } from '@portal/wen-backend-api';
import { isEditEvent } from '../message-event-helper';

export const asEncryptedEditEvent = (event: MessageEvent): MessageEvent<EncryptedMessageEventResponses> => {
  const originalEventId = event?.relations?.replace?.relatesTo?.replace.eventId
      || (event.payload as EditMessageEventDTO)?.relatesTo?.replace.eventId;
  const newPayload = event?.relations?.replace || (event?.payload as EditMessageEventDTO);
  const validTimestamp = (isEditEvent(event) && event.insertTimestamp) || null;
  const editEvent: MessageEvent<EditMessageEventDTO> = {
    eventId: originalEventId,
    insertTimestamp: validTimestamp,
    insertUser: event.insertUser,
    new: event.new,
    payload: newPayload,
    roomId: event.roomId,
    relations: event.relations
  };
  return editEvent;
};

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { CategoriesDTO, ChannelConfiguration, ChannelDTO, ChannelInviteDataDTO, ChannelMessageDTO, ChannelType, EmbeddedDocumentDTO, EmbeddedExpandableDTO, EmbeddedLinkDTO, EmbeddedLocationDTO, EmbeddedMediaDTO, EmbeddedNotificationDTO, EmbeddedPollDTO, EmbeddedWeatherDTO, EmbedDTOTypes, RestrictionDTO, SimpleUserDTO, SubscribersDTO } from '@portal/wen-backend-api';
import { DateUtil } from '../../common/date/date-util';
import { LoadingState } from '../../common/types/store-loading-state';
import { CollectionEntityState } from '../common/entity-state-helpers/nested-entity-adapter';
import { DraftMessageEntity, PendingEmbed } from '../common/models';
import { createNetworkEntityHelper, EntityWithNetworkMeta } from '../network/util/network-entity-helper';
import { ChannelPermission } from '../user/models/Permission';

export type ChannelMessageEntity = ChannelMessageDTO;
export type EmbeddedLink = EmbeddedLinkDTO;
export type EmbeddedDocument = EmbeddedDocumentDTO;
export type EmbeddedExpandable = EmbeddedExpandableDTO;
export type EmbeddedPoll = EmbeddedPollDTO;
export type EmbeddedMedia = EmbeddedMediaDTO & Partial<PendingEmbed>;
export type EmbeddedLocation = EmbeddedLocationDTO;
export type EmbeddedWeather = EmbeddedWeatherDTO;
export type EmbeddedNotification = EmbeddedNotificationDTO;
export type Embed = EmbedDTOTypes | EmbeddedExpandable;

export interface isCollaborative {
  collaborative?: boolean;
}

export interface ChannelEntity extends isCollaborative {
  id: string;
  title?: string;
  icon?: string;
  description?: string;
  subscriptions?: number;
  /**
   * The "real time" messages received while the app is open
   */
  messages?: EntityState<ChannelMessageEntity>;
  /**
   * The historical messages received by either the result of paging or as relevant messages
   */
  history?: CollectionEntityState<ChannelMessageEntity>;
  /**
   * @deprecated The pending messages are not existing anymore. Server draft messages are created instead
   * The not-yet persisted, temporary messages existing only on the client side
   */
  pendingMessages?: ChannelMessageEntity[];
  /**
   * Extra hanging messages loaded for the message detail page which can not be part of the history or the real time messages
   */
  extraMessages?: EntityState<ChannelMessageEntity>;
  /**
   * The messages which are going to be posted to a channel in the future
   */
  scheduledMessages?: EntityState<ChannelMessageEntity>;
  scheduledMessagesCount?: number;
  scheduledMessagesHasMore?: boolean;
  /**
   * The message which is in the message send input field
   */
  draftMessage?: DraftMessageEntity;
  lastReadTimestamp?: string;
  isMuted?: boolean;
  discoverable?: boolean;
  availableForOthers?: boolean;
  emoji?: boolean;
  comment?: boolean;
  permission?: ChannelPermission;
  restricted?: boolean;
  restrictions?: RestrictionDTO[];
  deleted?: boolean;
  deletedReason?: string;
  invite?: ChannelInviteDataDTO;
  categories?: CategoriesDTO;
  contentProviders?: SimpleUserDTO[];
  admins?: SimpleUserDTO[];
  subscribers?: SubscribersDTO;
  isPrivate?: boolean;
  geo?: boolean;
  readingContent?: boolean;
  textToSpeech?: boolean;
  imprint?: string;
  dataProtectionUrl?: string;
  channelType?: ChannelType;
  configuration?: ChannelConfiguration;
}

export interface FeaturedEntity {
  channel: ChannelDTO;
  message: ChannelMessageDTO;
}

export type ChannelMeta = EntityWithNetworkMeta;

export type SubscribedChannelEntity = ChannelEntity & { subscribed: boolean };

export interface ChannelState {
  channels: EntityState<ChannelEntity>;
  channelMeta: EntityState<ChannelMeta>;
  userChannelsLoading: LoadingState;
  discoverChannelsLoading: LoadingState;
  userChannelIds: string[];
  userJoinRequestChannelIds: string[];
  inviteToChannelRequestChannelIds: string[];
  discoverChannelIds: string[];
  channelViewScrollTopRestoration: number;
  currentAutoAcknowledgeChannelId: string;
  featuredData: FeaturedEntity;
}

export const channelAdapter: EntityAdapter<ChannelEntity> = createEntityAdapter<ChannelEntity>({
  selectId: (channel: ChannelEntity) => channel.id,
  sortComparer: false,
});

export const channelMetaAdapter: EntityAdapter<ChannelMeta> = createEntityAdapter<ChannelMeta>({
  selectId: (channelMeta: ChannelMeta) => channelMeta.entityId,
  sortComparer: false,
});

export const channelNetworkEntityHelper = createNetworkEntityHelper();

export const channelMessagesAdapter: EntityAdapter<ChannelMessageEntity> = createEntityAdapter<ChannelMessageEntity>({
  selectId: (channelMessage: ChannelMessageEntity) => channelMessage.id,
  sortComparer: (channelMessage1, channelMessage2) => {
    return DateUtil.compare(channelMessage2.timestamp, channelMessage1.timestamp);
  },
});

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { kebabize } from '@portal/wen-components';
import { ClientCustomizationProvider } from '../../../frame/network/tokens';
import { WenColorService } from '../util/wen-color.service';
import { WenClientStyleConfig, WenClientWeatherConfig, WenClientHomeCoverImageConfig, WenClientTranslationConfig } from './customization-types';
import { NetworkClientConfigService } from '../util/network-client-config-service';
import { FeaturedSectionConfig, Language } from '@portal/wen-backend-api';
import { WenTranslateLoader } from '@portal/wen-translation';

type AdditionalGeneratedProperties = {
  logoUrl?: string;
  welcomeLogoUrl?: string;
  homeCoverPortraitImageUrl?: string;
  homeCoverLandImageUrl?: string;
  channelViewBackgroundImageUrl?: string;
};
type ClientStyleConfigProps = WenClientStyleConfig & AdditionalGeneratedProperties;

@Injectable()
export class ClientCustomizationService {

  constructor(
    private colorService: WenColorService,
    private translateService: TranslateService,
    private networkClientConfigService: NetworkClientConfigService,
    private clientCustomizationProvider: ClientCustomizationProvider,
    private wenTranslateLoader: WenTranslateLoader
  ) { }

  applyDefaultStyling() {
    const defaultStyling = this.clientCustomizationProvider.getDefaultStyleConfig();
    this.applyStylingConfigs(defaultStyling);
  }

  applyStylingConfigs(styleConfig: WenClientStyleConfig) {
    const variableMap = new Map<keyof ClientStyleConfigProps, string>();
    const defaults = new Map(
      Object.entries(this.clientCustomizationProvider.getDefaultStyleConfig())
    ) as Map<keyof WenClientStyleConfig, string>;

    defaults.forEach((value, key) => {
      if (typeof value !== 'string') {
        return;
      }
      const cssValue = styleConfig[key] || value;
      const urlizeKeys: Array<keyof ClientStyleConfigProps> = [
        'logo', 'welcomeLogo', 'channelViewBackgroundImage'
      ];
      if (urlizeKeys.includes(key)) {
        const urlizedKey = key + 'Url' as keyof ClientStyleConfigProps;
        variableMap.set(urlizedKey, `url(${cssValue})`);
      }
      variableMap.set(key, cssValue);
    });
    this.setCssVariables(variableMap);
  }

  applyWeatherConfigs(weatherConfig: WenClientWeatherConfig): void {
    const defaultConfig = this.clientCustomizationProvider.getDefaultWeatherConfig();
    const weather = Object.assign({}, defaultConfig, weatherConfig);
    this.networkClientConfigService.setWeather(weather);
  }

  applyHomeCoverImage(homeCoverImageConfig: WenClientHomeCoverImageConfig): void {
    const defaultConfig = this.clientCustomizationProvider.getDefaultHomeCoverImageConfig();
    const homeCoverImage = { ...defaultConfig, ...homeCoverImageConfig };
    this.networkClientConfigService.setHomeCoverImage(homeCoverImage);
  }

  applyHomeFeaturedSectionConfig(featuredSections?: FeaturedSectionConfig[]): void {
    this.networkClientConfigService.setHomeFeaturedSection(featuredSections);
  }

  applyTranslationConfig(translationOverrides: WenClientTranslationConfig) {
    if (translationOverrides) {
      this.setTranslations(translationOverrides);
    }
  }

  private setCssVariables(variableMap: Map<string, string>) {
    variableMap.forEach((value, key) => {
      const kebabKey = kebabize(key);
      this.colorService.setCssProperty(`--wen-${kebabKey}`, value);
    });
  }

  private setTranslations(translations: WenClientTranslationConfig) {
    const availableLanguages = Language;
    const multiLanguageTranslation = Object.entries(translations).every(
      ([key, _]) => Object.values(availableLanguages).includes(key as Language)
    );

    let networkTranslations = translations;

    if (!multiLanguageTranslation) {
      networkTranslations ={
          [Language.ENGLISH]: translations,
          [Language.GERMAN]: translations
        };
    }

    this.wenTranslateLoader.setNetworkTranslations(networkTranslations);
    this.translateService.setTranslation(Language.ENGLISH, networkTranslations[Language.ENGLISH], true);
    this.translateService.setTranslation(Language.GERMAN, networkTranslations[Language.GERMAN], true);
  }

}

<wen-adaptive-background [desktopWidth]="375" wenIosSwipeSuppressor>
  <wen-dialog-layout wen-adaptive-background-content [headerGravity]="'top'">
    <div class="wen-error-page-content-wrapper" dialogLayoutContent>
      <div portal-body class="wen-error-page-body">
        <ng-container *ngIf="(showClientError | async); then clientError else serviceError"></ng-container>
      </div>
    </div>
  </wen-dialog-layout>
</wen-adaptive-background>


<ng-template #serviceError>
  <ng-lottie [options]="lottieOptions" (animationCreated)="onAnimationCreated($event)"></ng-lottie>
  <div portal-title class="wen-error-page-title">
    {{ "ERROR_PAGE_TITLE" | translate }}
  </div>
  <div class="wen-error-page-content">
    {{ "ERROR_PAGE_CONTENT" | translate }}
  </div>
  <!--
    TODO: Add error page link from design once we have the URL
    Translations for it are already added: "ERROR_PAGE_INFO_LINK_LABEL" and "ERROR_PAGE_INFO"
  -->
</ng-template>

<ng-template #clientError>
  <img class="wen-error-page-image" src="/assets/wen-widget/error-page/error-no-internet-robot.svg">
  <div portal-title class="wen-error-page-title">
    {{ "ERROR_PAGE_OFFLINE_TITLE" | translate }}
  </div>
  <div class="wen-error-page-content">
    {{ "ERROR_PAGE_OFFLINE_CONTENT" | translate }}
  </div>
</ng-template>
import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { GeoWeatherFeature, GeoWeatherHourly } from '@portal/wen-backend-api';
import { toWeatherModel, WeatherModel } from '@portal/wen-components';
import { DateUtil } from '../../../../../../../../core/common/date/date-util';

const EMBED_WEATHER_ICONS_PATH = '/assets/wen-widget/image/weather/embed';

@Component({
  selector: 'wen-weather-context-menu',
  templateUrl: './weather-context-menu.component.html',
  styleUrls: ['./weather-context-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WeatherContextMenuComponent {

  weatherViewModel: WeatherModel;
  weatherForecasts: GeoWeatherHourly[];
  weatherDate: string;

  @HostBinding('class.weather-context-menu') className = true;

  @Input() set weatherFeature(value: GeoWeatherFeature) {
    this.weatherViewModel = toWeatherModel(value, EMBED_WEATHER_ICONS_PATH);
    this.weatherForecasts = value.properties.hourly;
    this.weatherDate = DateUtil.toDateMed(DateUtil.fromIsoString(this.weatherForecasts[0].timestamp));
  }

}

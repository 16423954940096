<div class="wen-explore-header">
  <div class="wen-explore-header-label">{{ 'APPS_HEADER_TITLE' | translate }}</div>
  <div class="wen-explore-header-app-list-user-apps-button" portal-body-2 wen-primary-color
    (click)="navigateToUserList()" [wen-test-id]="'wen-app-explore-user-list-button'">
    <wen-icon iconName="download_active" class="wen-icon-small"></wen-icon>
    <div>{{ 'APP_EXPLORE_MINE' | translate }}</div>
  </div>
</div>
<div class="quick-filters">
  <wen-rounded-button transparent border (click)="navigateToDiscoveryList('clearFilter')"
    section-content [wen-test-id]="'wen-app-explore-discover-list-button'">{{"SHOW_ALL_APP_LABEL" | translate}}</wen-rounded-button>
</div>
<cdk-virtual-scroll-viewport class="wen-section-wrapper" itemSize="375" scrollStateHandler>
  <wen-app-section *ngFor="let section of sections$ | async" [section]="section"
    (showAll)="navigateToDiscoveryList($event)"></wen-app-section>
</cdk-virtual-scroll-viewport>
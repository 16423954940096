import { AfterViewInit, ChangeDetectorRef, Directive, OnDestroy, Optional } from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { deepCopy } from '@portal/wen-components';
import { Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { firstExisty } from '../../core/common/operators/first-existy';
import { FormStatus } from '../../core/common/util/forms';
import { OutletHelper } from '../../core/services/navigation/outlet-specific/outlet-helper';
import { forceValidateForms, setEditFormChangedState, setEditFormChangedValue } from '../../core/store/form/form.actions';
import { selectEditFormById } from '../../core/store/form/form.selectors';
import { RootState } from '../../core/store/root/public-api';
import { FormErrorStateMatcher } from './form-error-state-matcher';
import { FormValueConverter, NoopFormValueConverter } from './form-store.providers';

@Directive({
  selector: '[wenFormStore]',
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: FormErrorStateMatcher
    }
  ]
})
export class FormStoreDirective implements AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();
  private formValueConverter: FormValueConverter<any, any>;

  constructor(
    private store: Store<RootState>,
    private actions$: Actions,
    private outletHelper: OutletHelper,
    private formGroupDirective: FormGroupDirective,
    private cdr: ChangeDetectorRef,
    @Optional() formValueConverter: FormValueConverter<any, any>,
  ) {
    this.formValueConverter = formValueConverter || new NoopFormValueConverter();
  }

  ngAfterViewInit(): void {
    const formId = this.outletHelper.resolveFormStoreKey();
    this.formGroupDirective.valueChanges.pipe(
      map(() => {
        return Object.entries(this.formGroupDirective.form.controls)
          .filter(([_, control]) => control.dirty)
          .reduce((acc, [key, control]) => ({ ...acc, [key]: control.value }), {});
      }),
      distinctUntilChanged(),
      filter(changes => changes && Object.keys(changes).length > 0),
      switchMap((changes) => this.formValueConverter.convertToServerModel(changes)),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),
    ).subscribe((changedValues) => {
      const changedValuesCopy = deepCopy(changedValues);
      this.store.dispatch(setEditFormChangedValue({ formId, changedValues: changedValuesCopy }));
    });
    this.store.pipe(
      select(selectEditFormById(formId)),
      firstExisty(),
      switchMap(({ initialValues, changedValues = {} }) => {
        let values = {};
        if (initialValues) {
          values = {
            ...initialValues,
            ...changedValues
          };
        }
        return this.formValueConverter.convertToFormModel(values);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe((formValues) => {
      const hasInitialValue = formValues && Object.keys(formValues).length > 0;
      if (hasInitialValue) {
        const formValuesCopy = deepCopy(formValues);
        this.formGroupDirective.form.patchValue(formValuesCopy);
        this.formGroupDirective.control.markAsPristine();
        this.cdr.detectChanges();
      }
    });
    this.actions$.pipe(
      ofType(forceValidateForms),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.formGroupDirective.control.markAsDirty();
      this.formGroupDirective.control.markAllAsTouched();
      this.formGroupDirective.control.updateValueAndValidity();
    });
    merge(
      this.formGroupDirective.valueChanges,
      this.formGroupDirective.statusChanges,
    ).pipe(
      debounceTime(0), // Because the updateValueAndValidity is done after the markAsTouched group the events together
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.store.dispatch(setEditFormChangedState({
        formId,
        status: this.formGroupDirective.status as FormStatus,
        dirty: this.formGroupDirective.dirty
      }));
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

<div class="wen-form">
  <div class="wen-form-group wen-single-group">
    <div class="wen-form-field-label" portal-caption>{{ 'CHANNEL_SUBSCRIBERS_TITLE' | translate }}</div>
    <div class="wen-form-field-scroller">
      <wen-user-list-item *ngFor="let listItem of subscriberList$ | async" [user]="listItem.user" [userNavigable]="listItem.user.userId">
        <wen-user-role [userRole]="listItem.user.roleId" wen-user-description></wen-user-role>
        <wen-icon *ngIf="listItem.hasMenu" [iconName]="'more'" [color]="contextMenuOpenerFillColor"
          [context-menu-trigger]="userMenu" wen-user-after></wen-icon>
        <wen-context-menu #userMenu>
          <wen-action-menu [actions]="actions$ | async" [data]="listItem.user.userId"></wen-action-menu>
        </wen-context-menu>
      </wen-user-list-item>
      <div *ngIf="anonymousUserCount > 0" class="wen-anonymous-user-list-item">
        {{ anonymousUserCount }} {{ anonymousUserDisplayMessage }}
        <wen-icon [iconName]="'more'" [color]="contextMenuOpenerFillColor" [context-menu-trigger]="anonymousMenu"></wen-icon>
        <wen-context-menu #anonymousMenu>
          <wen-action-menu [actions]="anonymousUserActions$ | async"></wen-action-menu>
        </wen-context-menu>
      </div>
    </div>
  </div>
</div>

<wen-we-feed (messageVisible)="onMessageVisible($event)" (scrolledToBottom)="enableAutoAcknowledge()" [flowDirection]="flowDirection"
             [reactionContext]="reactionContext">
  <ng-container ngProjectAs="[wen-messages-view-before-content]">
    <ng-container *ngTemplateOutlet="projectedBeforeContent"
      ngProjectAs="[wen-messages-view-before-content]"></ng-container>
  </ng-container>
  <ng-container ngProjectAs="wen-we-feed-empty-placeholder">
    <ng-container *ngTemplateOutlet="emptyPlaceholder" ngProjectAs="wen-we-feed-empty-placeholder"></ng-container>
  </ng-container>
  <ng-template we-feed-custom-context-menu-template let-data="data" let-actions="actions">
    <wen-reaction-selector *ngIf="enabledEmojiReaction$ | async" class="wen-huge-emojis"
                           [data]="toReactionSelectorData(data)"
                           [wen-test-id]="'wen-reaction-selector'"></wen-reaction-selector>
    <wen-action-menu [actions]="data | visibilityForActionMenu: contextMenuId | async" [data]="data"></wen-action-menu>
  </ng-template>
  <ng-template we-feed-custom-reaction-context-menu-template let-data="data">
    <wen-reaction-selector class="wen-huge-emojis" [data]="toReactionSelectorData(data)"></wen-reaction-selector>
  </ng-template>
  <wen-scroll-to-button *ngIf="!disableScrollToBottomButton" [notificationCount]="notificationCount$ | async"
    (scrolledTo)="onScrollToBottomClicked()">
  </wen-scroll-to-button>
</wen-we-feed>

<ng-template #projectedBeforeContent>
  <ng-content select="wen-messages-view-before-content, [wen-messages-view-before-content]"></ng-content>
</ng-template>

<ng-template #emptyPlaceholder>
  <ng-content select="wen-we-feed-empty-placeholder"></ng-content>
  <wen-we-feed-empty-placeholder *ngIf="!customEmptyPlaceholder" [text]="'CHANNEL_VIEW_NO_MESSAGE_TITLE' | translate"
    [imageUrl]="'/assets/wen-widget/image/pic-success-120.svg'"></wen-we-feed-empty-placeholder>
</ng-template>

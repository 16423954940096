import { createReducer, on } from '@ngrx/store';
import { FormStoreKey } from '../../../shared/form-store/form-store-types';
import { deepMerge } from '../../common/util/deepmerge';
import { clearAllFormValues, clearFormValues, forceValidateForms, initEditForm, setEditFormChangedState, setEditFormChangedValue } from './form.actions';
import { editedFormEntityAdapter, EditFormEntity, FormChangedValue, FormState } from './form.state';

export const formInitialState: FormState = {
  editedForm: editedFormEntityAdapter.getInitialState()
};

const mergeChanges = (id: FormStoreKey, existingEntity: EditFormEntity, changes: FormChangedValue) => {
  const defaults: EditFormEntity = {
    formId: id,
    changedValues: {},
    initialValues: null,
    dirty: false,
    status: 'VALID'
  };
  const withDefaults = { ...defaults, ...existingEntity };
  const newValue = deepMerge.withOptions({ mergeArrays: false }, withDefaults, changes);
  return newValue;
};

export const formReducer = createReducer(
  formInitialState,
  on(initEditForm, (state, { formId, initialValues }) => {
    const existing = state.editedForm.entities[formId];
    const newEntity = mergeChanges(formId, existing, { initialValues });
    const editedForm = editedFormEntityAdapter.upsertOne(newEntity, state.editedForm);
    return { ...state, editedForm };
  }),
  on(setEditFormChangedValue, (state, { formId, changedValues }) => {
    const existing = state.editedForm.entities[formId];
    const newEntity = mergeChanges(formId, existing, { changedValues });
    const editedForm = editedFormEntityAdapter.upsertOne(newEntity, state.editedForm);
    return { ...state, editedForm };
  }),
  on(setEditFormChangedState, (state, { formId, dirty, status }) => {
    const existing = state.editedForm.entities[formId];
    const newEntity = mergeChanges(formId, existing, { dirty, status });
    const editedForm = editedFormEntityAdapter.upsertOne(newEntity, state.editedForm);
    return { ...state, editedForm };
  }),
  on(clearFormValues, (state, { formId }) => {
    const editedForm = editedFormEntityAdapter.removeOne(formId, state.editedForm);
    return { ...state, editedForm };
  }),
  on(forceValidateForms, (state) => {
    const newEntities = Object.values(state.editedForm.entities).map(editedForm => {
      const pendingForm: EditFormEntity = {
        ...editedForm,
        status: 'PENDING'
      };
      return pendingForm;
    });
    const newEntity = editedFormEntityAdapter.upsertMany(newEntities, state.editedForm);
    return { ...state, editedForm: newEntity };
  }),
  on(clearAllFormValues, (state) => {
    const editedForm = editedFormEntityAdapter.removeAll(state.editedForm);
    return { ...state, editedForm };
  })
);

export function formReducerFactory(state, action) {
  return formReducer(state, action);
}

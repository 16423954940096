import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { WenIconModule } from '@portal/wen-components';
import { MenuEntryModule } from '../../../shared/components/menu-entry/menu-entry.module';
import { ActionSelectorComponent } from './components/action-selector/action-selector.component';

@NgModule({
  imports: [
    CommonModule,
    WenIconModule,
    TranslateModule,
    MenuEntryModule,
  ],
  declarations: [
    ActionSelectorComponent
  ],
  exports: [
    ActionSelectorComponent
  ]
})
export class ActionSelectorModule { }

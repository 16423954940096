import { EntityState, Update } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { ChannelMessageDTO } from '@portal/wen-backend-api';
import { DateUtil } from '../../common/date/date-util';
import { addChannelMessagesNotifications, clearChatMessageNotifications, editLatestChannelMessage, handleNativeNotificationRegistration, initChannelMessagesNotification, initChatMessagesNotifications, removeChannelMessagesNotification, removeChatMessagesNotification, removeFromLatestMessages, updateChannelMessageNotifications, updateChannelMessagesNotifications, updateChatMessageNotificationsAcknowledgementState, updateChatMessagesNotifications, upsertChatMessagesNotification } from './notification.actions';
import { channelMessageNotificationAdapter, ChannelMessagesNotificationEntity, chatMessageNotificationAdapter, chatMessageNotificationEventAdapter, chatMessageNotificationStateAdapter, ChatMessagesNotificationEntity, ChatNotificationEventEntity, NotificationState } from './notification.state';

const findEntityById = <T>(
  id: string,
  entitiyState: EntityState<T>
) => {
  return entitiyState.entities[id];
};

export const notificationInitialState: NotificationState = {
  channelNotifications: channelMessageNotificationAdapter.getInitialState({}),
  nativeNotificationRegistrationToken: null,
  chatNotifications: chatMessageNotificationAdapter.getInitialState({})
};

const notificationReducer = createReducer(
  notificationInitialState,
  on(initChannelMessagesNotification, (state, entity) => {
    const newChannelNotification = channelMessageNotificationAdapter.upsertOne(entity.newEntity, state.channelNotifications);
    return { ...state, channelNotifications: newChannelNotification };
  }),
  on(addChannelMessagesNotifications, (state, { channelId, messages }) => {
    const { channelNotifications } = state;
    let targetChannelMessageNotifications = findEntityById<ChannelMessagesNotificationEntity>(channelId, channelNotifications);
    if (!targetChannelMessageNotifications) {
      targetChannelMessageNotifications = {
        channelId,
        latestMessages: [],
        unreadCount: 0
      };
    }
    const { latestMessages, unreadCount } = targetChannelMessageNotifications;
    const existingMessageIds = latestMessages.map(msg => msg.id);
    const newMessages = messages.filter((message) => {
      return !existingMessageIds.includes(message.id);
    });
    const updatedLatestMessages = latestMessages.map((latestMessage) => {
      const existingMessage = messages.find(msg => msg.id === latestMessage.id);
      if (existingMessage) {
        return existingMessage;
      }
      return latestMessage;
    });
    const newLatestMessages = [...updatedLatestMessages, ...newMessages];
    const newUnreadCount = unreadCount + newMessages.length;
    const newEntity: ChannelMessagesNotificationEntity = {
      channelId,
      latestMessages: newLatestMessages,
      unreadCount: newUnreadCount
    };
    const newChannelNotification = channelMessageNotificationAdapter.upsertOne(newEntity, state.channelNotifications);
    return { ...state, channelNotifications: newChannelNotification };
  }),
  on(updateChannelMessagesNotifications, (state, { summary }) => {
    const newChannelNotification = channelMessageNotificationAdapter.upsertOne(summary, state.channelNotifications);
    return { ...state, channelNotifications: newChannelNotification };
  }),
  on(removeChannelMessagesNotification, (state, { messages }) => {
    const { channelNotifications } = state;
    const { channelId } = messages[0];
    const targetChannelMessageNotifications = findEntityById<ChannelMessagesNotificationEntity>(channelId, channelNotifications);
    if (!targetChannelMessageNotifications) {
      return state;
    }
    const { latestMessages, unreadCount } = targetChannelMessageNotifications;
    const newLatestMessages: ChannelMessageDTO[] = latestMessages.map((latestMessage) => {
      if (messages.includes(latestMessage)) {
        return { ...latestMessage, new: false };
      }
      return latestMessage;
    });
    const newUnreadCount = Math.max(0, unreadCount - messages.length);
    const newEntity: ChannelMessagesNotificationEntity = {
      channelId,
      latestMessages: newLatestMessages,
      unreadCount: newUnreadCount
    };
    const newChannelNotification = channelMessageNotificationAdapter.upsertOne(newEntity, state.channelNotifications);
    return { ...state, channelNotifications: newChannelNotification };
  }),
  on(removeFromLatestMessages, (state, { message }) => {
    const channelId = message.channelId;
    const { channelNotifications } = state;
    const targetChannelMessageNotifications = findEntityById<ChannelMessagesNotificationEntity>(channelId, channelNotifications);
    const { latestMessages, unreadCount } = targetChannelMessageNotifications;
    const newLatestMessages = latestMessages.filter(latestMessage => latestMessage.id !== message.id);
    const newEntity: ChannelMessagesNotificationEntity = {
      channelId,
      latestMessages: newLatestMessages,
      unreadCount
    };
    const newChannelNotification = channelMessageNotificationAdapter.upsertOne(newEntity, state.channelNotifications);
    return { ...state, channelNotifications: newChannelNotification };
  }),
  on(editLatestChannelMessage, (state, { updatedMessage }) => {
    const { channelNotifications } = state;
    const { channelId } = updatedMessage;
    const targetChannelMessageNotifications = findEntityById<ChannelMessagesNotificationEntity>(channelId, channelNotifications);
    if (!targetChannelMessageNotifications) {
      return state;
    }
    const { latestMessages, unreadCount } = targetChannelMessageNotifications;
    const isMessageInLatest = latestMessages.find(msg => msg.id === updatedMessage.id);
    if (!isMessageInLatest) {
      return state;
    }
    const newLatestMessages = latestMessages.map(msg =>
      msg.id === updatedMessage.id ? updatedMessage : msg
    );
    const newEntity: ChannelMessagesNotificationEntity = {
      channelId,
      latestMessages: newLatestMessages,
      unreadCount
    };
    const newChannelNotification = channelMessageNotificationAdapter.upsertOne(newEntity, state.channelNotifications);
    return { ...state, channelNotifications: newChannelNotification };
  }),
  on(updateChannelMessageNotifications, (state, { channelId, unreadCountDiff, lastAckTimestamp }) => {
    const { channelNotifications } = state;
    const targetChannelMessageNotifications = findEntityById<ChannelMessagesNotificationEntity>(channelId, channelNotifications);
    if (!targetChannelMessageNotifications) {
      return state;
    }
    const newLatestMessages = targetChannelMessageNotifications.latestMessages.map((latestMessage) => {
      const shouldMarkAsRead = Boolean(lastAckTimestamp) && DateUtil.compare(lastAckTimestamp, latestMessage.timestamp) < 1;
      return shouldMarkAsRead ? { ...latestMessage, new: false } : latestMessage;
    });
    let newUnreadCount = Boolean(unreadCountDiff) ? targetChannelMessageNotifications.unreadCount - unreadCountDiff : 0;
    newUnreadCount = Math.max(0, newUnreadCount);
    const newEntity: ChannelMessagesNotificationEntity = {
      channelId: targetChannelMessageNotifications.channelId,
      latestMessages: newLatestMessages,
      unreadCount: newUnreadCount
    };
    const newChannelNotification = channelMessageNotificationAdapter.upsertOne(newEntity, state.channelNotifications);
    return { ...state, channelNotifications: newChannelNotification };
  }),
  on(handleNativeNotificationRegistration, (state, { payload }) => {
    return { ...state, nativeNotificationRegistrationToken: payload };
  }),
  on(initChatMessagesNotifications, (state, { newEntity, eventEntities }) => {
    const isNewEntityOutdated = DateUtil.compareNullSafe(
      state.chatNotifications.entities[newEntity.id]?.lastAckTimestamp,
      newEntity.lastAckTimestamp) === -1;
    if (isNewEntityOutdated) {
      return state;
    }
    const newChatMessageEntityState = chatMessageNotificationAdapter.upsertOne(newEntity, state.chatNotifications);
    const newChatNotification = chatMessageNotificationStateAdapter.upsertMany(newEntity.id, eventEntities, newChatMessageEntityState);
    return { ...state, chatNotifications: newChatNotification };
  }),
  on(upsertChatMessagesNotification, (state, { eventEntity }) => {
    const { roomId } = eventEntity.originalEvent;
    const chatNotifications = state.chatNotifications.entities;
    const targetChatNotification = chatNotifications[roomId];
    let isAcknowledged = !eventEntity.originalEvent.new;
    if (targetChatNotification) {
      const { lastAckTimestamp } = targetChatNotification;
      const { insertTimestamp, new: isNew } = eventEntity.originalEvent;
      isAcknowledged = !isNew || (DateUtil.compare(insertTimestamp, lastAckTimestamp) !== -1);
    }
    const { unreadCount = 0 } = targetChatNotification || {};
    const newEventEntity: ChatNotificationEventEntity = {
      ...eventEntity, originalEvent: { ...eventEntity.originalEvent, new: !isAcknowledged }
    };
    const updatedEntities = chatMessageNotificationStateAdapter.upsertOne(roomId, newEventEntity, state.chatNotifications);

    const { id: newEventId } = eventEntity;
    const existingIds = targetChatNotification?.messageEvents?.ids as string[] || [];
    const existing = existingIds.includes(newEventId);
    const newCount = unreadCount + (isAcknowledged || existing ? 0 : 1);
    const chatNotificationUpdate: Update<ChatMessagesNotificationEntity> = {
      id: eventEntity.originalEvent.roomId,
      changes: {
        unreadCount: newCount
      }
    };
    const updatedNotifications = chatMessageNotificationAdapter.updateOne(chatNotificationUpdate, updatedEntities);

    return { ...state, chatNotifications: updatedNotifications };
  }),
  on(updateChatMessagesNotifications, (state, { roomId, eventEntities }) => {
    const newChatNotification = chatMessageNotificationStateAdapter.updateMany(roomId, eventEntities, state.chatNotifications);
    return { ...state, chatNotifications: newChatNotification };
  }),
  on(updateChatMessageNotificationsAcknowledgementState, (state, { roomId, lastAckTimestamp }) => {
    const updatedChatNotification: Update<ChatMessagesNotificationEntity> = { id: roomId, changes: { lastAckTimestamp } };
    const updatedNotificationEntities = chatMessageNotificationAdapter.updateOne(updatedChatNotification, state.chatNotifications);
    return { ...state, chatNotifications: updatedNotificationEntities };
  }),
  on(clearChatMessageNotifications, (state, { roomId }) => {
    const updatedChatNotification: Update<ChatMessagesNotificationEntity> = { id: roomId, changes: { unreadCount: 0 } };
    const updatedNotificationEntities = chatMessageNotificationAdapter.updateOne(updatedChatNotification, state.chatNotifications);
    return { ...state, chatNotifications: updatedNotificationEntities };
  }),
  on(removeChatMessagesNotification, (state, { eventId, roomId, messageTimestamp }) => {
    const targetRoom = findEntityById(roomId, state.chatNotifications);
    const oldMessage = targetRoom?.messageEvents?.entities[eventId];
    if (!oldMessage) {
      return state;
    }

    const newMessage: ChatNotificationEventEntity = {
      ...oldMessage,
      decryptedEvent: { message: { id: null, timestamp: oldMessage.decryptedEvent.message.timestamp } },
      redacted: true
    };
    const updatedMessageEventNotifications = chatMessageNotificationEventAdapter.upsertOne(newMessage, targetRoom.messageEvents);
    const updatedUnreadCount = DateUtil.compareNullSafe(messageTimestamp, targetRoom.lastAckTimestamp) === -1
      ? Math.max(targetRoom.unreadCount - 1, 0)
      : targetRoom.unreadCount;
    const updatedChatMessageNotifications = chatMessageNotificationAdapter.upsertOne(
      { ...targetRoom, messageEvents: updatedMessageEventNotifications, unreadCount: updatedUnreadCount }, state.chatNotifications
    );

    return { ...state, chatNotifications: updatedChatMessageNotifications };
  })
);

export function notificationReducerFactory(state: NotificationState | undefined, action: Action) {
  return notificationReducer(state, action);
}

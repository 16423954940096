import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketIoService } from '@portal/wen-backend-api';
import { distinct, first, merge, switchMap, tap } from 'rxjs';
import { eventViewIdentifier } from '../../../../views/events/tokens';
import { RootState } from '../../root/public-api';
import { selectRouteParam } from '../../root/root.selectors';
import { subscribeForEvent, subscribeToUserEventUpdates, unsubscribeFromEvent, updateEventSubscription, updateUserEventVisibility } from '../event.actions';


export const createEventSubscriptionEffect = (
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'events'>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(subscribeToUserEventUpdates),
      first(),
      switchMap(() => {
        return merge(
          socketIoService.events.subscribe.listen,
          socketIoService.events.unsubscribe.listen
        ).pipe(
          switchMap(({ id, subscribed }) => {
            return [
              updateEventSubscription({ id, subscribed }),
              updateUserEventVisibility({ eventId: id, subscribed })
            ];
          })
        );
      })
    )
  );
};

export const createEventSubscribeEffect = (
  store: Store<RootState>,
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'events'>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(subscribeForEvent),
      distinct(() => null, actions$.pipe(ofType(updateEventSubscription))),
      concatLatestFrom(() => store.select(selectRouteParam(eventViewIdentifier))),
      tap(([{ eventId }, currentEvent]) => {
        return socketIoService.events.subscribe.emit({ id: eventId ?? currentEvent });
      })
    ), { dispatch: false }
  );
};


export const createEventUnSubscribeEffect = (
  store: Store<RootState>,
  actions$: Actions,
  socketIoService: Pick<SocketIoService, 'events'>
) => {
  return createEffect(() =>
    actions$.pipe(
      ofType(unsubscribeFromEvent),
      distinct(() => null, actions$.pipe(ofType(updateEventSubscription))),
      concatLatestFrom(() => store.select(selectRouteParam(eventViewIdentifier))),
      tap(([{ eventId }, currentEvent]) => {
        return socketIoService.events.unsubscribe.emit({ id: eventId ?? currentEvent });
      })
    ), { dispatch: false }
  );
};

<ng-container *ngIf="loadResult$ | async; let loadResult">
  <ng-container *ngIf="loadResult.data; let apps">
    <wen-filter-view *ngIf="isFilteringEnabled" [formGroup]="filterGroup">
      <wen-filter-select-component [formControlName]="appCategoryControlName"
        [defaultFilter]="categoryFilterService.fallbackAllCategoryFilter" filter-element
        [filterEntityType]="filterEntityType" (click)="openAppCategoryFilterSelector()"></wen-filter-select-component>
    </wen-filter-view>
    <div class="wen-discover-app-list">
      <ng-container *ngIf="apps.length; else emptyList">
        <wen-app-list (appClicked)="onAppClick($event)" [apps]="apps"></wen-app-list>
      </ng-container>
      <ng-template #emptyList>
        <wen-empty-search-result *ngIf="loadResult.mode === 'search'"></wen-empty-search-result>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<ng-container>
  <cdk-virtual-scroll-viewport #scrollable [itemSize]="itemSize" class="wen-pending-contacts-list-viewport">
    <ng-template #listTemplate>
      <ng-container *cdkVirtualFor="let item of datasource; let index = index; trackBy: trackByFn">
        <ng-container [ngTemplateOutlet]="itemTemplate.templateRef"
          [ngTemplateOutletContext]="{ $implicit: item, index: index }"></ng-container>
      </ng-container>
    </ng-template>
    <ng-container *ngIf="isEmpty$ | async; else listTemplate">
      <ng-content select="wen-list-mass-data-empty, [wen-list-mass-data-empty]"></ng-content>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-container>
import { hasTranslationFor } from '../../../../../shared/util/translation-util';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, merge, Observable, of, switchMap } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { firstExisty } from '../../../../../core/common/operators/first-existy';
import { AppConfigurationProvider } from '../../../../../core/services/configuration/app-confguration';
import { FeatureEnablementService } from '../../../../../core/services/configuration/feature-enablement';
import { WenNativeApi } from '@portal/wen-native-api';
import { selectCurrentUserData } from '../../../../../core/store/auth/auth.selectors';
import { RootState } from '../../../../../core/store/root/public-api';
import { AppPermission, ChannelPermission } from '../../../../../core/store/user/models/Permission';
import { selectUserAppPermission, selectUserChannelPermission } from '../../../../../core/store/user/user.selectors';
import * as helpEntries from './sub-views/help-view/help-view-configuration-entries';
import * as entries from './user-profile-view-configuration-entries';
import { EntryId, UserProfileMenuEntry } from './user-profile-view-types';
import { TranslateService } from '@ngx-translate/core';

const dataEntries: () => UserProfileMenuEntry[] = () => [
  entries.profile,
  entries.createContactsEntry(),
  entries.autoRespone,
  entries.selectLanguage,
  entries.privacySettings,
];

const createPersonalEntries: () => UserProfileMenuEntry[] = () => [
  entries.invite,
];

const dataProtectionEntries: UserProfileMenuEntry[] = [
  entries.help,
  entries.contactSupport,
  entries.hotline,
  entries.manifest,
  entries.communityGuideline,
  entries.accessibility,
  entries.dataProtection,
  entries.termsOfUser,
  entries.impressum,
  entries.netiquette,
  entries.licenses,
];

const helpViewEntries: UserProfileMenuEntry[] = [
  helpEntries.faq,
  helpEntries.markdown
];

@Injectable()
export class UserProfileViewConfiguration {

  private userLevel$ = this.store.pipe(
    select(selectCurrentUserData),
    firstExisty(),
    map(userData => userData.permissionLevel),
    shareReplay(1)
  );

  private userChannelPermission$ = this.store.pipe(
    select(selectUserChannelPermission),
    firstExisty(),
    shareReplay(1)
  );

  private userAppPermission$ = this.store.pipe(
    select(selectUserAppPermission),
    firstExisty(),
    shareReplay(1)
  );

  dataEntries$ = this.applyFilters(dataEntries());
  personalEntries$ = this.applyFilters(createPersonalEntries());
  dataProtectionEntries$ = merge(
    this.filterDataProtectionEntries(dataProtectionEntries),
    this.translateService.onLangChange.pipe(
      switchMap(() => this.filterDataProtectionEntries(dataProtectionEntries))
    )
  );
  anonymousLoginEntry$ = of(entries.anonymousLogin);
  anonymousRegisterEntry$ = of(entries.register);
  helpViewEntries$ = this.filterHelpEntries(helpViewEntries);

  constructor(
    private readonly store: Store<RootState>,
    private readonly appConfig: AppConfigurationProvider,
    private readonly featureEnablement: FeatureEnablementService,
    private readonly nativeApi: WenNativeApi,
    private readonly translateService: TranslateService
  ) {}

  private filterDataProtectionEntries(menuEntries: UserProfileMenuEntry[]): Observable<UserProfileMenuEntry[]> {
    const filteredMenuEntries = menuEntries.filter(entry => !!entry).filter(menuEntry => {
      switch (menuEntry.entryId) {
        case EntryId.NETIQUETTE:
          return hasTranslationFor(this.translateService, 'NETIQUETTE_URL');
        case EntryId.COMMUNITY_GUIDELINE:
          return hasTranslationFor(this.translateService, 'COMMUNITY_GUIDE_URL');
        case EntryId.ACCESSIBILITY:
          return hasTranslationFor(this.translateService, 'ACCESSIBILITY_URL');
        case EntryId.MANIFEST:
          return !!this.appConfig.dataProtectionConfig?.manifestEnabled;
        default:
          return true;
      }
    });
    return this.applyFilters(filteredMenuEntries);
  }

  private filterHelpEntries(menuEntries: UserProfileMenuEntry[]) {
    const filteredMenuEntries = menuEntries.filter(entry => !!entry).filter(menuEntry => {
      const objKey = menuEntry.entryId.toLowerCase();
      return hasTranslationFor(this.translateService, objKey);
    });
    return this.applyFilters(filteredMenuEntries);
  }

  private applyFilters<T extends UserProfileMenuEntry | UserProfileMenuEntry[]>(menuEntries: T): Observable<T> {
    const filtered = combineLatest([
      this.userLevel$,
      this.userChannelPermission$,
      this.userAppPermission$,
    ]).pipe(
      map(([userLevel, userChannelPermission, userAppPermission]) => {
        const isSingleEntry = !Array.isArray(menuEntries);
        const menuEntriesArray = isSingleEntry ? [menuEntries as UserProfileMenuEntry] : menuEntries as UserProfileMenuEntry[];
        const filteredEntries = menuEntriesArray.filter(entry => {
          let enabled = false;
          if (userLevel >= entry.requiredPermission) {
            enabled = true;
          }
          if (enabled && entry.userPermissionBased) {
            enabled = this.filterForUserPermissions(entry, userChannelPermission, userAppPermission);
          }
          if (enabled && entry.featureToggleBased) {
            enabled = this.filterForFeatureToggles(entry);
          }
          return enabled;
        });
        if (isSingleEntry) {
          return filteredEntries.length ? filteredEntries[0] : null;
        } else {
          return filteredEntries.length ? filteredEntries : null;
        }
      })
    );
    return filtered as Observable<T>;
  }

  private filterForUserPermissions(
    entry: UserProfileMenuEntry,
    userChannelPermission: ChannelPermission,
    userAppPermission: AppPermission
  ) {
    switch (entry.entryId) {
      case EntryId.NEW_CHANNEL:
        return userChannelPermission.canCreate;
      case EntryId.NEW_APP:
        return userAppPermission.canCreate;
      default:
        return false;
    }
  }

  private filterForFeatureToggles(
    entry: UserProfileMenuEntry
  ) {
    switch (entry.entryId) {
      case EntryId.INVITE:
        return this.featureEnablement.isInviteToNetworkEnabled() ||
          (this.featureEnablement.featureFlagMethods.isEnableNativeShareInvite() && this.nativeApi.isReactNativeApp());
      case EntryId.NEW_GROUP:
        return this.featureEnablement.featureFlagMethods.isEnableGroupChat();
      case EntryId.AUTO_REPLY:
        return this.featureEnablement.featureFlagMethods.isEnableChatAutoReply();
      case EntryId.HOTLINE:
        return this.appConfig.contactInfoConfig.hotline?.length > 0;
      case EntryId.CONTACTS:
        return this.featureEnablement.featureFlagMethods.isEnableContacts();
      case EntryId.SELECT_LANGUAGE:
        return this.featureEnablement.featureFlagMethods.isEnableLanguageChange();
      case EntryId.PRIVACY_SETTINGS:
        return this.featureEnablement.featureFlagMethods.isEnableTracing();
      default:
        return false;
    }
  }
}

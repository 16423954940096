import { NgModule } from '@angular/core';
import { LoadingIndicatorComponent } from './loading-indicator.component';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { CommonModule } from '@angular/common';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    LottieModule.forRoot({
      player: playerFactory
    })
  ],
  exports: [LoadingIndicatorComponent],
  declarations: [LoadingIndicatorComponent],
  providers: [],
})
export class LoadingIndicatorModule { }

import { Injectable } from '@angular/core';
import { OlmDeviceModel, OlmOneTimeKeys } from '@portal/wen-backend-api';
import { Observable } from 'rxjs';

export type GetUserDataResult = {
  userId: string;
  deviceId: string;
};

export type GetOneTimekeyParams = {
  userId: string;
};

export type GetOneTimeKeyResult = OlmOneTimeKeys[];

export type UploadKeysParams = {
  userId: string;
  deviceKeys: OlmDeviceModel[];
  oneTimeKeys?: OlmOneTimeKeys;
};

export type UploadKeysResult = {
  success: boolean;
};

export type GetDeviceKeysParams = {
  userIds: string[];
};

export type GetDeviceKeysResult = {
  deviceKeys: OlmDeviceModel[];
};

@Injectable()
export abstract class ChatBackendApi {

  abstract getUserData(): Observable<GetUserDataResult>;
  abstract getOneTimeKey(params: GetOneTimekeyParams): Observable<GetOneTimeKeyResult>;
  abstract uploadKeys(params: UploadKeysParams): Observable<UploadKeysResult>;
  abstract getDeviceKeys(params: GetDeviceKeysParams): Observable<GetDeviceKeysResult>;

}

<wen-confirmation-dialog>
  <div wen-confirmation-dialog-custom-content [formGroup]="confirmationFormGroup">
    <div portal-body-2>{{ "PASSWORD_PROTECTED_SUBSCRIPTION_DIALOG_DESCRIPTION" | translate }}</div>
    <mat-form-field>
      <mat-label>{{ "PASSWORD_PROTECTED_SUBSCRIPTION_DIALOG_REASON_FIELD_LABEL" | translate }}</mat-label>
      <input #passwordInput matInput autocomplete="new-password" type="password" formControlName="password">
      <mat-error *ngIf="errorState$ | async" portal-caption>{{ "PASSWORD_PROTECTED_SUBSCRIPTION_DIALOG_WRONG_PASSWORD_ERROR" | translate }}</mat-error>
      <wen-password-visibility-toggle matSuffix [for]="passwordInput"></wen-password-visibility-toggle>
    </mat-form-field>
  </div>
  <button class="wen-confirmation-dialog-wide-action" wen-confirmation-dialog-ok-action (click)="onAccept()">{{ "CHANNEL_SUBSCRIBE_BUTTON_LABEL" | translate }}</button>
  <button class="wen-confirmation-dialog-wide-action" wen-confirmation-dialog-ok-action (click)="onDismiss()">{{ "CANCEL_BUTTON_LABEL" | translate }}</button>
</wen-confirmation-dialog>

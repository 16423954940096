<div class="wen-confirmation-dialog-wrapper" [wen-test-id]="'wen-confirmation-dialog'">
  <mat-dialog-content class="wen-confirmation-dialog-content">
    <div portal-title class="wen-confirmation-dialog-header" [wen-test-id]="'wen-confirmation-dialog-header'">
      <img *ngIf="headerImage" [src]="headerImage">
      <ng-container *ngIf="header">{{ header }}</ng-container>
    </div>
    <div class="wen-confirmation-dialog-description" [wen-test-id]="'wen-confirmation-dialog-description'">
      <ng-container *ngIf="content; else projectedContent">
        <wen-markdown-text [content]="content"></wen-markdown-text>
      </ng-container>
      <ng-template #projectedContent>
        <ng-content
          select="wen-confirmation-dialog-custom-content, [wen-confirmation-dialog-custom-content]"></ng-content>
      </ng-template>
    </div>
    <form (ngSubmit)="onAccept()" [formGroup]="confirmationFormGroup" *ngIf="reasonFieldLabel">
      <mat-form-field *ngIf="!largeReasonField else fieldWithTextArea">
        <mat-label>{{ reasonFieldLabel }}</mat-label>
        <input matInput autocomplete="off" formControlName="reason" [wen-test-id]="'confirmation-dialog-reason'">
      </mat-form-field>
      <ng-template #fieldWithTextArea>
        <mat-form-field>
          <mat-label>{{ reasonFieldLabel }}</mat-label>
          <textarea matInput formControlName="reason" rows="4" maxlength="300" [wen-test-id]="'confirmation-dialog-reason'"></textarea>
        </mat-form-field>
      </ng-template>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="wen-confirmation-dialog-actions">
    <ng-content select="wen-confirmation-dialog-ok-action, [wen-confirmation-dialog-ok-action]"></ng-content>
    <button *ngIf="okLabel" class="wen-confirmation-dialog-accept-button" wen-primary-color (click)="onAccept()"
      [wen-test-id]="'wen-confirmation-dialog-accept-button'">{{ okLabel }}</button>
    <button *ngIf="neutralLabel" class="wen-confirmation-dialog-accept-button" wen-primary-color (click)="onNeutral()"
      [wen-test-id]="'wen-confirmation-dialog-neutral-button'">{{ neutralLabel }}</button>
    <button *ngIf="dismissLabel" class="wen-confirmation-dialog-cancel-button" wen-primary-color (click)="onDismiss()"
      [wen-test-id]="'wen-confirmation-dialog-cancel-button'">{{ dismissLabel }}</button>
  </mat-dialog-actions>
</div>



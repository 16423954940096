<form wenFormStore class="wen-form" [formGroup]="emailChangeFormGroup"
  [wen-test-id]="'email-change-form'">
  <div class="wen-form-group">
    <div class="wen-form-field-wrapper">
      <mat-form-field>
        <mat-label>{{ 'PROFILE_FORM_EMAIL_LABEL' | translate }}</mat-label>
        <portal-field [formControlName]="'email'" [autocomplete]="'off'" wenIosKeyboardStickyTarget [wen-test-id]="'email-change-form-email'"></portal-field>
        <wen-icon *ngIf="emailValue" matSuffix wen-primary-color class="wen-icon-medium" [iconName]="'close'" (click)="clearInput()"></wen-icon>
        <mat-error portal-caption>{{ "PROFILE_FORM_EMAIL_ERROR" | translate }}</mat-error>
      </mat-form-field>
    </div>
  </div>
</form>
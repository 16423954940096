import { Injectable } from '@angular/core';
import { combineLatest, first, map, Observable, of, switchMap } from 'rxjs';
import { ChatBackendApi } from '../backend-api/backend-api';
import { OlmDevice } from '../device/olm-device';
import { CryptoStorage } from '../persistence/crypto-storage';
import { WenChatClient } from '../wen-chat-client';
import { createDeviceKeysPayload } from './create-device-keys-payload';

@Injectable()
export class EncryptionInitializer {

  constructor(
    private chatBackendApi: ChatBackendApi,
    private olmDevice: OlmDevice,
    private chatClient: WenChatClient,
    private dataStore: CryptoStorage,
  ) { }

  initialize(): Observable<boolean> {
    return combineLatest([
      this.chatClient.init(),
      this.dataStore.init()
    ]).pipe(
      first(),
      switchMap(() => {
        return this.olmDevice.initialize();
      }),
      switchMap(() => {
        return this.chatBackendApi.getUserData().pipe(
          first(),
          switchMap((userData) => {
            if (!userData) {
              return of({ success: false });
            }
            const e2eKeys = this.olmDevice.getIdentityKeys();
            const { userId, deviceId } = userData;
            const deviceKeyParams = createDeviceKeysPayload(userId, deviceId, e2eKeys);
            return combineLatest([
              this.chatBackendApi.uploadKeys(deviceKeyParams),
              this.dataStore.storeDevice(deviceKeyParams.deviceKeys[0]),
            ]);
          }),
        );
      }),
      map(() => true)
    );
  }

}

import { Injectable, Injector } from '@angular/core';
import { AuthValidator } from '@portal/wen-backend-api';
import { Observable, catchError, map, of } from 'rxjs';
import { AuthStateValidator, NotAuthenticatedError } from '../../user-management/auth-state-validator.service';
import { WenOAuthService } from '../../user-management/wen-oauth.service';

@Injectable()
export class SocketIoAuthValidator implements AuthValidator {

  private authValidatorInstance: AuthStateValidator = null;

  private get authValidator() {
    // Use injector directly to avoid circular DI issues
    if (!this.authValidatorInstance) {
      this.authValidatorInstance = this.injector.get(AuthStateValidator);
    }
    return this.authValidatorInstance;
  }

  constructor(
    private injector: Injector,
    private oAuthService: WenOAuthService,
  ) {
  }

  validateAuth(): Observable<boolean> {
    return this.authValidator.validateAuthState().pipe(
      map(result => Boolean(result)),
      catchError((error) => {
        if (error instanceof NotAuthenticatedError) {
          return this.oAuthService.initCodeFlow().pipe(
            map(() => false)
          );
        }
        return of(false);
      })
    );
  }

}

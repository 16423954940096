
export interface ShareKeyHint {
  processedTimestamp: string;
  insertTimestamp: string;
  description: string;
  sessionId?: string;
  roomId?: string;
  senderCurve25519?: string;
}

export const convertHintsToObject = (hints: ShareKeyHint[]) => {
  if (!hints?.length) {
    return {};
  }
  return hints.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.processedTimestamp]: curr
    };
  }, {});
};

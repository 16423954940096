import { NgModule } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { PortalFieldModule } from '@portal/ui-kit/field';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { RoundedButtonModule } from '../../../shared/components/rounded-button/rounded-button.module';
import { SharedModule } from '../../../shared/shared.module';
import { RegistrationCommonModule } from '../common/registration-common.module';
import { RegistrationProfileComponent } from './components/registration-profile/registration-profile.component';
import { RegistrationSuccessComponent } from './components/registration-success/registration-success.component';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    RegistrationCommonModule,
    RoundedButtonModule,
    SharedModule,
    MatCheckboxModule,
    PortalFieldModule,
    LottieModule.forRoot({
      player: playerFactory
    }),
  ],
  declarations: [
    RegistrationProfileComponent,
    RegistrationSuccessComponent,
  ],
  exports: [
    RegistrationProfileComponent
  ]
})
export class RegistrationFormModule { }

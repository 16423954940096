// eslint-disable-next-line @typescript-eslint/ban-types
type ListenerFn = Function;

export class EventListenerManager {

  private removeEventListeners: ListenerFn[] = [];

  set addEvent(listener: ListenerFn) {
    this.removeEventListeners.push(listener);
  }

  detachAll() {
    this.removeEventListeners.forEach(remove => remove());
    this.removeEventListeners = [];
  }

}

<form wenFormStore class="wen-form" [formGroup]="channelSettingsFormGroup">
  <ng-container *ngIf="settingsSchema$ | async as schema">
    <ng-container *ngTemplateOutlet="settingsTemplate; context: { formGroup: channelSettingsFormGroup, schema: schema }"></ng-container>
  </ng-container>
</form>

<ng-template #settingsTemplate let-formGroup="formGroup" let-schema="schema">
  <ng-container [formGroup]="formGroup">
    <ng-container *ngFor="let group of schema">
      <ng-container *ngComponentOutlet="group.componentType; inputs: { context: group.context }"></ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<div class="wen-profile-email-setting-page" cdkScrollable>
  <div class="wen-profile-email-setting-page-entries">
    <div class="wen-profile-email-setting-page-entry-groups">
      <div *ngIf="(userProfile$ | async) || true; let userProfile" class="wen-profile-email-setting-page-entry-group"
        [wen-test-id]="'wen-email-page-group'">
        <div *ngIf="userProfile?.email; else emailPlaceholder" class="wen-profile-email-setting-page-email-info">
          <div class="wen-profile-email-setting-page-email-info-label" portal-caption>{{
            "USER_PROFILE_EMAIL_CHANGE_EMAIL_FIELD_LABEL" | translate }}</div>
          <div> {{ userProfile?.email }} </div>
          <div class="wen-profile-email-setting-page-email-info-description-wrapper"
            *ngIf="featureEnablementService.featureFlagMethods.isEnableEmailVerification()">
            <wen-icon *ngIf="userProfile && !userProfile.emailVerified" class="wen-icon-small" iconName="error_icon">
            </wen-icon>
            <div class="wen-profile-email-setting-page-email-info-verified-email-text"
              *ngIf="userProfile?.emailVerified" portal-caption>{{ "USER_PROFILE_EMAIL_SETTINGS_VERIFIED_INFO_TEXT" |
              translate }}</div>
            <div class="wen-profile-email-setting-page-email-info-unverified-email-text"
              *ngIf="userProfile && !userProfile.emailVerified" portal-caption>{{
              "USER_PROFILE_EMAIL_SETTINGS_UNVERIFIED_ERROR_TEXT" | translate }}</div>
          </div>
        </div>
        <wen-interact-button class="wen-interact-button-menu-style"
          *ngIf="featureEnablementService.featureFlagMethods.isEnableEmailVerification() && userProfile && !userProfile.emailVerified && userProfile.email"
          (click)="verifyEmailAddress(userProfile)" [wen-test-id]="'wen-email-button-resend'">
          <span>{{ "USER_PROFILE_EMAIL_SETTINGS_VERIFY_EMAIL_BUTTON_LABEL" | translate }}</span>
        </wen-interact-button>
        <wen-interact-button class="wen-interact-button-menu-style" (click)="changeEmailAddress()"
          [wen-test-id]="'wen-email-button-' + (userProfile.email ? 'edit' : 'add')">
          <span>{{ changeEmailAddressButtonLabel$ | async }}</span>
        </wen-interact-button>
      </div>
    </div>
  </div>
</div>

<ng-template #emailPlaceholder>
  <div class="wen-profile-email-setting-email-placeholder-wrapper">
    <wen-icon class="wen-profile-email-setting-email-placeholder wen-icon-flex wen-icon-colorful" [iconName]="'icon_mail2'"></wen-icon>
  </div>
</ng-template>
import { Injectable, inject } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { validateId } from '@portal/wen-backend-api';
import { Observable, combineLatest, distinctUntilChanged, map, of, shareReplay, startWith, switchMap } from 'rxjs';
import { flattenArray, nonNullArray } from '../../../../core/common/operators/array-utils';
import { mapAllBool } from '../../../../core/common/operators/map-all-bool';
import { selectorWithParam } from '../../../../core/common/util/selector-with-param';
import { FeatureEnablementService } from '../../../../core/services/configuration/feature-enablement';
import { selectAllDraftChatMembers, selectAllDraftChatRooms, selectRoomById } from '../../../../core/store/chat/chat.selectors';
import { selectScheduledChatMessageCollectionExtrasForRoom } from '../../../../core/store/chat/selectors/chat-scheduled-selectors';
import { RootState } from '../../../../core/store/root/public-api';
import { MessageSendFieldDataSource } from '../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { canSchedule, checkDraftMessageState } from '../../../../shared/util/draft-message-util';

@Injectable()
export class ChatMessageSendFieldConditions {

  private store = inject(Store<RootState>);
  private dataSource = inject(MessageSendFieldDataSource);
  private featureEnablementService = inject(FeatureEnablementService);

  hasMediaEmbed$: Observable<boolean>;
  isSendingAllowed$: Observable<boolean>;
  isChatScheduledSendingAllowed$: Observable<boolean>;
  isChatScheduledNavigationAllowed$: Observable<boolean>;

  private selectedUsers$ = this.store.pipe(
    select(selectAllDraftChatMembers),
    distinctUntilChanged(),
  );

  private selectedRooms$ = this.store.pipe(
    select(selectAllDraftChatRooms),
    distinctUntilChanged()
  );

  constructor() {
    const roomId$ = this.dataSource.contextId$;
    const roomByContext$ = roomId$.pipe(
      switchMap((roomId) => {
        return this.store.pipe(
          selectorWithParam(selectRoomById, roomId),
        );
      })
    );
    const isRoomCreation$ = roomId$.pipe(
      map((roomId) => {
        const isValidRoomId = validateId(roomId);
        return !isValidRoomId;
      })
    );

    this.hasMediaEmbed$ = this.dataSource.draftMessage$.pipe(
      map(message => message?.fileEmbed?.file?.name || ''),
      map(url => Boolean(url)),
      distinctUntilChanged(),
    );

    const hasDraftMessage$ = this.dataSource.draftMessage$.pipe(
      map((draftMessage) => checkDraftMessageState(draftMessage)),
      shareReplay(1)
    );

    const canSchedule$ = this.dataSource.draftMessage$.pipe(
      map(draftMessage => canSchedule(draftMessage)),
      shareReplay(1)
    );

    const hasValidRoomMembersToSend$ = isRoomCreation$.pipe(
      switchMap((isRoomCreation) => {
        if (isRoomCreation) {
          return combineLatest([
            this.selectedUsers$, this.selectedRooms$
          ]).pipe(
            map((roomMembers) => {
              const allRoomMembers = flattenArray<any>(roomMembers);
              const validRoomMembers = nonNullArray(allRoomMembers);
              return Boolean(validRoomMembers?.length);
            })
          );
        } else {
          return roomByContext$.pipe(
            map((roomEntity) => Boolean(roomEntity?.members?.length))
          );
        }
      })
    );

    this.isSendingAllowed$ = combineLatest([
      hasValidRoomMembersToSend$,
      hasDraftMessage$
    ]).pipe(
      mapAllBool(),
      distinctUntilChanged()
    );

    this.isChatScheduledSendingAllowed$ = combineLatest([
      this.isSendingAllowed$,
      canSchedule$
    ]).pipe(
      mapAllBool(),
      distinctUntilChanged()
    );

    const hasScheduledChatMessages$ = roomId$.pipe(
      distinctUntilChanged(),
      switchMap((roomId) => {
        const isValidRoomId = validateId(roomId);
        if (!isValidRoomId) {
          return of(false);
        }
        return this.store.pipe(
          selectorWithParam(selectScheduledChatMessageCollectionExtrasForRoom, roomId),
          map(({ totalCount }) => totalCount > 0)
        );
      }),
      startWith(false)
    );
    this.isChatScheduledNavigationAllowed$ = combineLatest([
      this.isSendingAllowed$,
      hasScheduledChatMessages$
    ]).pipe(
      map(([isSendingAllowed, hasScheduledChatMessages]) => {
        const isInputNotActive = !isSendingAllowed;
        return isInputNotActive && hasScheduledChatMessages;
      }),
      distinctUntilChanged(),
    );
  }

}

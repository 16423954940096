<wen-dialog-layout [headerGravity]="headerGravity">
  <wen-dialog-layout-header class="wen-image-crop-dialog-header" *ngIf="headerGravity === 'top'" wenIosKeyboardSticky>
    <div headerTitle portal-title>{{ 'IMAGE_CROP_DIALOG_HEADER' | translate }}</div>
  </wen-dialog-layout-header>
  <wen-dialog-layout-footer class="wen-image-crop-dialog-footer">
    <button headerLeft class="wen-image-crop-close-button" portal-button inlined (click)="cropCancelled()">
      <span portal-body-2>{{ 'CANCEL_BUTTON_LABEL' | translate }}</span>
    </button>
    <div *ngIf="headerGravity === 'bottom'" headerTitle portal-title>{{ 'IMAGE_CROP_DIALOG_HEADER' | translate }}</div>
    <button headerRight [disabled]="cropperNotReady$ | async" class="wen-image-crop-close-button" portal-button inlined (click)="cropSelected()">
      <span portal-body-2>{{ 'IMAGE_CROP_DIALOG_SAVE' | translate }}</span>
    </button>
  </wen-dialog-layout-footer>
  <div class="wen-image-crop-dialog-content" dialogLayoutContent>
    <div class="wen-image-crop-container">
      <span *ngIf="cropperNotReady$ | async" class="wen-loading-indicator">{{ 'IMAGE_CROP_LOADING' | translate }}</span>
      <image-cropper #imageCropper output="blob" [imageFile]="data.image" (imageCropped)="imageCropped($event)" (cropperReady)="cropperReady()" (imageLoaded)="imageLoaded()"
        [aspectRatio]="data.aspectRatio || 1" [style.display]="cropperVisible$ | async" [cropperMinWidth]="300"></image-cropper>
    </div>
  </div>
</wen-dialog-layout>

import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstTrue } from '@portal/wen-common';
import { Observable, Subject, combineLatest, merge } from 'rxjs';
import { filter, first, map, pairwise, shareReplay, switchMap, takeUntil } from 'rxjs/operators';
import { mapAllBool } from '../../../../../core/common/operators/map-all-bool';
import { fetchPermissionsForChannel } from '../../../../../core/store/channel/channel.actions';
import { ChannelMessageEntity } from '../../../../../core/store/channel/channel.state';
import { RootState } from '../../../../../core/store/root/public-api';
import { ChannelMuteService } from '../../../../../shared/channel-specific/providers/channel-mute.service';
import { ChannelHeaderDataUpdater } from '../../../../../shared/channel-specific/providers/channel-view-header-data-updater';
import { FeedDatasource } from '../../../../../shared/components/feed/providers/feed-datasource';
import { FeedLayoutMediator } from '../../../../../shared/components/feed/providers/feed-layout-mediator';
import { FileDropOverlayComponent } from '../../../../../shared/components/file-drop-overlay/file-drop-overlay.component';
import { MessageBoxActionsHandler } from '../../../../../shared/components/message-box/providers/message-box-tokens';
import { MessageSendFieldDataSource } from '../../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { MessageSeparatorDateFormatter, PastMessageSeparatorDateFormatter } from '../../../../../shared/directives/directives/message-separator-formatters';
import { SubscribedToSource } from '../../../../../shared/directives/subscibed-to/subscribed-to-source';
import { LocationFormatter } from '../../../../../shared/pipes/location-formatter.service';
import { FileEventHandlerService } from '../../../../../shared/services/file-event-handler.service';
import { MessagesDatasource } from '../../../../../shared/services/messages.datasource';
import { RestrictionAutoRefresherService } from '../../../../../shared/services/restriction-auto-refresher.service';
import { ChannelViewMessageBoxActionsHandler } from '../../../common/message-box-actions/channel-view-message-box-actions-handler';
import { ChannelMessageSendFieldDataSource } from '../../../common/providers/channel-message-send-field-data-source';
import { ChannelSubscribedToSource } from '../../../common/providers/channel-subscribed-to-source';
import { ChannelViewPreloaderGuard } from '../../../common/providers/channel-view-preloader.guard';
import { GeoLocationFilterDataSource } from './filter-sources/geo-location-filter-datasource';
import { ChannelFeedDatasource } from './providers/channel-feed-datasource';
import { ChannelViewDataSource } from './providers/channel-view-datasource';
import { ChannelViewMessagesDatasource } from './providers/channel-view-messages.datasource';
import { GeoChannelExtension } from './providers/geo-channel-extension';

@Component({
  selector: 'wen-channel-view',
  templateUrl: './channel-view.component.html',
  styleUrls: ['./channel-view.component.scss'],
  providers: [
    ChannelHeaderDataUpdater, ChannelFeedDatasource, ChannelViewDataSource, RestrictionAutoRefresherService,
    { provide: MessagesDatasource, useClass: ChannelViewMessagesDatasource },
    { provide: MessageSendFieldDataSource, useClass: ChannelMessageSendFieldDataSource },
    GeoChannelExtension, GeoLocationFilterDataSource, LocationFormatter, FeedLayoutMediator,
    {
      provide: FeedDatasource,
      useClass: ChannelFeedDatasource
    },
    {
      provide: MessageSeparatorDateFormatter,
      useClass: PastMessageSeparatorDateFormatter
    },
    {
      provide: MessageBoxActionsHandler,
      useClass: ChannelViewMessageBoxActionsHandler
    },
  ],
  viewProviders: [
    { provide: SubscribedToSource, useClass: ChannelSubscribedToSource },
  ]
})
export class ChannelViewComponent implements OnInit, AfterViewInit, OnDestroy {

  private onDestroy$ = new Subject<void>();

  currentChannelId$: Observable<string>;
  overlayContent = FileDropOverlayComponent;
  disableEmojiReaction$: Observable<boolean>;
  commentsEnabled$: Observable<boolean>;
  dragOverlayEnabled$: Observable<boolean>;
  filteringEnabled$: Observable<boolean>;

  isChannelDataReady$: Observable<boolean>;
  isChannelHistoryLoaded$: Observable<boolean>;

  constructor(
    public readonly datasource: ChannelViewDataSource,
    public readonly messagesDatasource: MessagesDatasource<ChannelMessageEntity>,
    public readonly geo: GeoChannelExtension,
    private readonly store: Store<RootState>,
    private readonly channelViewHeaderDataUpdater: ChannelHeaderDataUpdater,
    private readonly restrictionAutoRefresherService: RestrictionAutoRefresherService,
    private readonly channelMuteService: ChannelMuteService,
    private readonly fileEventHandler: FileEventHandlerService,
    private readonly feedLayoutMediator: FeedLayoutMediator,
    private readonly channelViewPreloaderGuard: ChannelViewPreloaderGuard,
  ) { }

  ngOnInit(): void {
    this.datasource.initialize();
    this.messagesDatasource.initialize();
    this.currentChannelId$ = this.datasource.currentChannelId$;
    this.channelViewHeaderDataUpdater.initialize();
    this.geo.initialize();
    this.currentChannelId$.pipe(
      takeUntil(this.onDestroy$)
    ).subscribe((channelId) => this.store.dispatch(fetchPermissionsForChannel({ channelId })));

    this.restrictionAutoRefresherService.attach();

    this.dragOverlayEnabled$ = combineLatest([
      this.datasource.canSend$, this.datasource.isGeoChannel$
    ]).pipe(
      map(([canSend, isGeoChannel]) => {
        return canSend && !isGeoChannel;
      })
    );

    this.filteringEnabled$ = combineLatest([
      this.datasource.isContentBlocked$,
      this.datasource.isSubscribedToCurrentChannel$,
      this.datasource.isGeoChannel$,
    ]).pipe(
      map(([isContentBlocked, isSubscribedToCurrentChannel, isGeoChannel]) => {
        return (!isContentBlocked || isSubscribedToCurrentChannel) && isGeoChannel;
      })
    );

    this.isChannelDataReady$ = combineLatest([
      this.channelViewPreloaderGuard.isChannelDetailsLoaded$,
      this.channelViewPreloaderGuard.isChannelPermissionssLoaded$,
    ]).pipe(
      mapAllBool(),
      firstTrue(),
      shareReplay(1)
    );
    this.isChannelHistoryLoaded$ = this.channelViewPreloaderGuard.isChannelHistoryLoaded$;
  }

  ngAfterViewInit() {
    this.initScrollBehaviors();
  }

  onFocusSendField() {
    this.feedLayoutMediator.scrollToBottom();
  }

  onSend() {
    this.feedLayoutMediator.scrollToBottom();
  }

  onClickSendField() {
    this.geo.openPostEditor();
  }

  private initScrollBehaviors() {
    this.datasource.currentChannelId$.pipe(
      filter(channelId => Boolean(channelId)),
      switchMap(() => {
        const didSubscribe$ = this.datasource.isSubscribedToCurrentChannel$.pipe(
          pairwise(),
          filter(([wasSubscribed, isSubscribed]) => !wasSubscribed && isSubscribed)
        );
        const notSubscribed$ = this.datasource.isSubscribedToCurrentChannel$.pipe(
          first(),
          filter(subscribed => !subscribed)
        );
        return merge(didSubscribe$, notSubscribed$);
      }),
      takeUntil(this.onDestroy$)
    ).subscribe(() => {
      this.feedLayoutMediator.scrollToBottom();
    });
  }

  muteToChannel() {
    this.datasource.currentChannelId$.pipe(
      first()
    ).subscribe(
      (id) => this.channelMuteService.setMuteForChannel(id, true, true)
    );
  }

  unMuteToChannel() {
    this.datasource.currentChannelId$.pipe(
      first()
    ).subscribe(
      (id) => this.channelMuteService.setMuteForChannel(id, false, true)
    );
  }

  onFilesDropped(files: FileList) {
    this.fileEventHandler.addFiles(files);
  }

  openGeoFilterSelector() {
    this.geo.openGeoFilterSelector();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

<div class="wen-message-wrapper" [wen-test-id]="'wen-message-box'">
  <div class="wen-message-box-wrapper">
    <div portal-caption class="wen-message-box-auto-reply-header" [ngClass]="{ 'wen-current-user': highlighted }" *ngIf="isAutoReply">
      <wen-icon class="wen-icon-small" iconName="auto_reply" color="{{ replyIconColor }}"></wen-icon>
      {{ 'USER_PROFILE_AUTO_REPLY' | translate }}
    </div>
    <div class="wen-message-box-content-wrapper" [ngClass]="{ 'wen-current-user': highlighted, 'wen-auto-reply-message': isAutoReply }" [wen-test-id]="'message-box-content-wrapper'" [context-menu-trigger]="canOpenContextMenu && fullMenu" openMode="press"
    [class.wen-message-box-has-content]="content">
      <div *ngIf="sender" class="wen-message-box-header" (click)="onSenderClicked()">
        <wen-circle-image-view class="wen-message-box-sender-image" [imageUrl]="getAvatarUrl()" [placeholderText]="sender">
        </wen-circle-image-view>
        <div class="wen-message-box-info">
          <div portal-caption class="wen-message-box-sender" [wenTextColorizer]="sender" #senderElement>
            {{ sender }}
          </div>
          <div class="wen-message-box-meta">
            <div class="wen-message-box-timestamp" *ngIf="formattedTimeStamp">{{ formattedTimeStamp }}</div>
            <div class="wen-message-box-edited-label" *ngIf="isEdited">{{ 'MESSAGE_BOX_EDITED_INFO_LABEL' | translate }}</div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="isValidMessage; else projectedContent">
        <wen-message-box-content [message]="message" [ngClass]="{ 'wen-highlighted-content': highlighted }"></wen-message-box-content>
      </ng-container>
      <ng-template #projectedContent>
        <ng-content select="wen-message-box-content-template, [wen-message-box-content-template]"></ng-content>
      </ng-template>

      <wen-context-menu #fullMenu>
        <ng-content select="wen-message-box-context-menu-template, [wen-message-box-context-menu-template]"></ng-content>
      </wen-context-menu>

      <div class="wen-message-box-footer-container">
        <div class="wen-message-box-footer">
          <div class="wen-message-box-edited-label" *ngIf="isEdited">{{ 'MESSAGE_BOX_EDITED_INFO_LABEL' | translate }}</div>
          <div class="wen-message-box-timestamp" *ngIf="formattedTimeStamp">{{ formattedTimeStamp }}</div>
          <wen-message-status-indicator *ngIf="status && highlighted" [messageStatus]="status" [iconClassName]="'wen-icon-tiny'" class="wen-message-box-status-indicator"></wen-message-status-indicator>
        </div>

        <wen-emoji-reactions *ngIf="footerFunctionsEnabled && !disableEmojiReaction"
          [reactionMenu]="canOpenContextMenu && partialMenu"
          [referenceId]="referenceId"
          class="wen-message-box-emoji-reaction"
          [disabled]="!isValidMessage || scheduled"
          [ngClass]="{ 'wen-current-user': highlighted }"
          [showPlaceholder]="!hideEmojiReactionPlaceholder"
          [wen-test-id]="'wen-reactions-button'"
          [reactionContext]="reactionContext">
        </wen-emoji-reactions>

        <div class="wen-message-box-footer-container-comment-section" *ngIf="footerFunctionsEnabled">
          <wen-open-comment-button
            class="wen-message-box-open-comment-button"
            [class.disabled]="!isValidMessage || scheduled || !commentsEnabled || isDraft"
            (click)="onOpenCommentsClicked()"
            [deactivated]="!commentsEnabled"
            [disabled]="!isValidMessage || scheduled || isDraft"
            [wen-test-id]="'wen-comment-button'"
            [messageId]="messageId"
          ></wen-open-comment-button>

          <wen-text-to-speech-button
            *ngIf="textToSpeechButtonVisible"
            class="wen-message-box-footer-button"
            [payload]="{ metadata: {context: messageContext, author: sender, timestamp: rawTimeStamp }, contextId: messageId, content: content }"
          ></wen-text-to-speech-button>

          <wen-icon
            *ngIf="challengeVisible"
            (click)="challengeMenu.openMenu()"
            class="wen-message-box-footer-button"
            [iconName]="'challenge'"
          ></wen-icon>

          <ng-container *ngIf="isForwardable; else sharable">
            <wen-icon
              class="wen-message-box-footer-button"
              iconName="forward"
              (click)="forwardDeepLink()"
            ></wen-icon>
          </ng-container>

          <ng-template #sharable>
            <ng-container *ngIf="isSharable">
              <wen-icon
                class="wen-message-box-footer-button"
                iconName="share"
                (click)="shareDeepLink()"
              ></wen-icon>
            </ng-container>
          </ng-template>
        </div>
      </div>
      <wen-context-menu #partialMenu>
        <ng-content select="wen-message-box-reaction-context-menu-template, [wen-message-box-reaction-context-menu-template]"></ng-content>
      </wen-context-menu>
      <wen-challenge-context-menu #challengeMenu [messageId]="message?.messageId"></wen-challenge-context-menu>
    </div>
  </div>
  <wen-comment-list *ngIf="footerFunctionsEnabled && commentsVisible && commentsEnabled" [messageId]="messageId"></wen-comment-list>
</div>

import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { MessageSeparatorModule } from '../../../../../shared/directives/directives/message-separator.module';
import { ChatMessagesViewComponent } from './chat-messages-view.component';
import { MessageDebugModule } from '../message-debug/message-debug.module';
import { ObserveVisibilityModule } from '../../../../../shared/directives/message-visibility/observe-visibility.module';
import { MessageStatusIndicatorModule } from '../../../../../shared/components/message-status-indicator/message-status-indicator.module';
import { FeedModule } from '../../../../../shared/components/feed/feed-module';
import {
  ACTION_MENU_ITEMS,
  ActionMenuModule,
  EmojiReactionsModule
} from '@portal/wen-components';
import { VisibilityForActionMenuPipe } from '../../../../../shared/pipes/visibility-for-action-menu.pipe';
import { CHAT_MESSAGE_BOX_CONTEXT_MENU_ITEMS } from '../../../../../shared/components/message-box/actions/message-box.action-menu';
import { ChatMessageContentModule } from './components/chat-message-content/chat-message-content.module';

@NgModule({
  imports: [
    SharedModule,
    ScrollingModule,
    MessageSeparatorModule,
    MessageDebugModule,
    ChatMessageContentModule,
    ObserveVisibilityModule,
    MessageStatusIndicatorModule,
    FeedModule,
    EmojiReactionsModule,
    ActionMenuModule,
    VisibilityForActionMenuPipe,
  ],
  declarations: [
    ChatMessagesViewComponent
  ],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: CHAT_MESSAGE_BOX_CONTEXT_MENU_ITEMS, multi: true }
  ],
  exports: [
    ChatMessagesViewComponent,
    FeedModule,
  ],
})
export class ChatMessagesViewModule { }

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { MessageSendFieldDataSource } from '../../../../../shared/components/message-send-field/providers/message-send-field-data-source';
import { ChatMessageSendFieldConditions } from '../../providers/chat-message-send-field-conditions';

@Component({
  selector: 'wen-chat-message-send-field',
  templateUrl: './chat-message-send-field.component.html',
  styleUrls: ['./chat-message-send-field.component.scss'],
  providers: [
    ChatMessageSendFieldConditions
  ]
})
export class ChatMessageSendFieldComponent implements OnInit {

  @Input() keepInputFocused: boolean;
  @Output() focused = new EventEmitter();
  @Output() messageSent = new EventEmitter();

  roomId$: Observable<string>;

  messageSelectorId = this.dataSource.messageTypeSelectorId;

  hasMediaEmbed$ = this.conditions.hasMediaEmbed$;
  isSendingAllowed$ = this.conditions.isSendingAllowed$;
  isChatScheduledSendingAllowed$ = this.conditions.isChatScheduledSendingAllowed$;
  isChatScheduledNavigationAllowed$ = this.conditions.isChatScheduledNavigationAllowed$;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly dataSource: MessageSendFieldDataSource,
    private readonly conditions: ChatMessageSendFieldConditions,
  ) { }

  ngOnInit(): void {
    this.roomId$ = this.dataSource.contextId$;
    this.cdr.detectChanges();
  }

  onMessageSent() {
    this.dataSource.sendMessage();
    this.messageSent.emit();
  }

  onFocused() {
    this.focused.emit();
  }

  onTap() {
    this.onFocused();
  }

}

<div class="wen-registration-success-content">
  <img class="wen-registration-success-image" src="/assets/wen-widget/image/registration-success.svg">
  <div class="wen-registration-success-header" wen-header-big>
    {{ 'REGISTRATION_SUCCESS_HEADER' | translate }}
  </div>
  <div class="wen-registration-success-subheader" portal-body-2>
    {{ 'REGISTRATION_SUCCESS_SUBHEADER' | translate }}
  </div>
  <div class="wen-registration-success-greeting" portal-body-2>
    {{ 'REGISTRATION_SUCCESS_GREETING' | translate }}
  </div>
  <div *ngIf="!isNativeApp" class="wen-registration-success-store-links">
    <img *ngIf="showPlayStore" src="/assets/wen-widget/image/google-play-badge.png" (click)="goToPlayStore()">
    <img *ngIf="showAppStore" [ngClass]="{ 'wen-registration-success-app-store-link': showPlayStore }" src="/assets/wen-widget/image/apple-store-badge.png" (click)="goToAppStore()">
  </div>
  <wen-rounded-button *ngIf="isNativeApp" (click)="navigateToLogin()" class="wen-registration-success-button">
    <span>{{ 'REGISTRATION_SUCCESS_BUTTON_LABEL' | translate }}</span>
  </wen-rounded-button>
  <wen-rounded-button *ngIf="!isNativeApp" transparent (click)="navigateToLogin()" class="wen-registration-success-button">
    <span>{{ 'REGISTRATION_SUCCESS_BROWSER_BUTTON_LABEL' | translate }}</span>
  </wen-rounded-button>
</div>
<wen-adaptive-background [desktopWidth]="375">
  <wen-dialog-layout wen-adaptive-background-content [headerGravity]="headerGravity">
    <wen-dialog-layout-header [inline]="true" wenIosKeyboardSticky>
      <button headerLeft portal-button inlined (click)="onCancel()" tabindex="-1">
        <wen-icon iconName="chevron_left"></wen-icon>
        <span portal-body-2>{{ "BUTTON_BACK_LABEL" | translate }}</span>
      </button>
    </wen-dialog-layout-header>
    <div class="wen-exclusive-invite-content" dialogLayoutContent>
      <img src="assets/wen-widget/image/pic-welcome-image.svg">
      <div class="wen-exclusive-invite-content-section">
        <div portal-headline class="wen-exclusive-invite-title">{{ "EXCLUSIVE_INVITE_TITLE" | translate }}</div>
        <div class="wen-exclusive-invite-info" portal-body-2>{{ "EXCLUSIVE_INVITE_BUTTON_INFO" | translate }}</div>
      </div>
      <form [formGroup]="formGroup">
        <mat-form-field>
          <mat-label>{{ "EXCLUSIVE_INVITE_BUTTON_INPUT_LABEL" | translate }}</mat-label>
          <input matInput [type]="codeFieldType" [pattern]="codeFieldPattern" [formControlName]="'code'" autocomplete="'off'"/>
          <mat-error portal-caption>{{ "EXCLUSIVE_INVITE_BUTTON_INPUT_VALIDATION_ERROR" | translate }}</mat-error>
        </mat-form-field>
      </form>
      <div class="wen-exclusive-invite-submit-button-wrapper">
        <wen-button class="wen-exclusive-invite-submit-button wen-button-rounded" [class.wen-button-disabled]="isSubmitDisabled" [wenVariant]="'primary'" (click)="onSubmit()">
          {{ "EXCLUSIVE_INVITE_BUTTON_CODE_LABEL" | translate }}
        </wen-button>
      </div>
      <div *ngIf="waitlistUrl" class="wen-exclusive-invite-waitlist-wrapper">
        <a (click)="navigateToWaitlist()">{{ "EXCLUSIVE_INVITE_WAITLIST_LABEL" | translate }}</a>
      </div>
    </div>
  </wen-dialog-layout>
</wen-adaptive-background>
<wen-swipeable [swipeDirection]="'right'" (swipeGesture)="onSwipe()">
  <wen-icon class="wen-reply-icon" iconName="reply" swipeableLeftIndicator></wen-icon>
  <div class="wen-comment-container" swipeableContent>
    <wen-circle-image-view [imageUrl]="imageUrl" [imageSize]="{height:48}" [placeholderText]="comment.userName">
    </wen-circle-image-view>
    <div class="wen-comment-bubble" #commentBubble [@highlight]="{ value: isHighlighted }">
      <div
        #senderName
        portal-caption
        class="wen-comment-author"
        [wenTextColorizer]="comment.userName"
        [wen-test-id]="'wen-comment-author'"
      >
        {{ comment.userName }}
      </div>
      <wen-quote *ngIf="comment.quoteComment" class="wen-comment-quote"
        [wenIsCurrentUser]="comment.userId"
        [commentAuthor]="comment.quotedUserName"
        [comment]="comment.quotedText"
        [isCommentDeleted]="!comment.quotedText"
        wenTouchEndListener
        (tap)="clickOnQuote.emit(comment.quotedCommentId)"
      >
      </wen-quote>
      <wen-markdown-text  class="wen-comment-content" [content]="comment.text" [markdownEnabled]="markdownEnabled"></wen-markdown-text>
      <div class="wen-comment-footer">
        <div class="wen-comment-extension" [wen-test-id]="'wen-comment-reaction'">
          <ng-content select="[comment-extension], comment-extension"></ng-content>
        </div>
        <div class="wen-comment-timestamp">
          {{ formattedTimeStamp }}
        </div>
      </div>
    </div>
  </div>
</wen-swipeable>

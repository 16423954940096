import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { ChannelMessageComposer, ChannelMessageComposerData, ChannelMessageComposerExtras, DraftMessageData, generateId } from '@portal/wen-backend-api';
import { first, merge, of, race } from 'rxjs';
import { mergeAll, mergeMap, switchMap } from 'rxjs/operators';
import { nonNullArray } from '../../../common/operators/array-utils';
import { firstExisty } from '../../../common/operators/first-existy';
import { mapWithFirstFrom } from '../../../common/operators/map-with-first-from';
import { createServerTimeout } from '../../../common/util/create-timer';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { FeatureEnablementService } from '../../../services/configuration/feature-enablement';
import { WenNavigationHelper } from '../../../services/navigation/types';
import { selectCurrentUserData } from '../../auth/auth.selectors';
import { DraftMessageEntity } from '../../common/models';
import { RootState } from '../../root/public-api';
import { editChannelMessage, prepareChannelMessage, removeDraftChannelMessage, removePendingChannelMessage, showScheduledChannelPostSuccessToast, updateDraftChannelMessage } from '../channel.actions';
import { selectChannelById, selectDraftChannelMessageById, selectMessageDetailById } from '../channel.selectors';
import { categorizeEmbeds } from '../util/embed-type-helper';
import { createPendingMessageActionFromDraft } from '../util/pending-message-util';

@Injectable()
export class SendChannelMessageEffects {

  private readonly currentUserData$ = this.store.pipe(
    select(selectCurrentUserData)
  );

  prepareMessageEffect$ = createEffect(() => this.actions$.pipe(
    ofType(prepareChannelMessage),
    mergeMap(({ channelId }) => {
      return this.store.pipe(
        selectorWithParam(selectDraftChannelMessageById, channelId),
        firstExisty(),
        mapWithFirstFrom(() => this.currentUserData$),
        mergeMap(([draftMessage, userData]) => {
          const { id, contextId, content, timestamp, fileEmbed, linkEmbed, pollEmbed, scheduled } = draftMessage;
          const sendMessageExtras: ChannelMessageComposerExtras = {
            embeds: {
              mediaEmbeds: nonNullArray([fileEmbed]),
              linkEmbeds: nonNullArray([linkEmbed]),
              pollEmbeds: nonNullArray([pollEmbed?.poll])
            }
          };
          const draftMessageParts: DraftMessageData = {
            draft: true
          };
          const data: ChannelMessageComposerData = {
            id,
            channelId: contextId,
            content,
            userId: userData.userId,
            timestamp,
            scheduled,
            ...this.featureEnablementService.featureFlagMethods.isEnableDraftMessageSending() && fileEmbed?.file && draftMessageParts
          };

          const sendMessage = this.channelMessageComposer.sendMessage(data, sendMessageExtras);

          const beforeSaveActions: Action[] = [];
          const afterSaveActions: Action[] = [];
          if (!sendMessage?.immediate && !data?.draft) {
            const uploadId = generateId();
            beforeSaveActions.push(createPendingMessageActionFromDraft(draftMessage, userData, uploadId));
            afterSaveActions.push(removePendingChannelMessage({ uploadId, channelId }));
          }
          beforeSaveActions.push(removeDraftChannelMessage({ channelId: draftMessage.contextId }));

          if (data.scheduled) {
            afterSaveActions.push(showScheduledChannelPostSuccessToast());
          }

          const saveTimeout$ = createServerTimeout();
          const save$ = merge(
            race(sendMessage.result$, saveTimeout$).pipe(
              switchMap(() => afterSaveActions)
            ),
            of(beforeSaveActions).pipe(
              mergeAll()
            )
          );
          return save$;
        })
      );
    })
  ));

  startEditingChannelMessageEffect$ = createEffect(() => this.actions$.pipe(
    ofType(editChannelMessage),
    mergeMap(({ messageId }) => {
      return this.store.pipe(
        selectorWithParam(selectMessageDetailById, messageId),
        first()
      );
    }),
    mapWithFirstFrom((message) => {
      return this.store.pipe(
        selectorWithParam(selectChannelById, message.channelId)
      );
    }),
    switchMap(([message, channel]) => {
      if (channel.geo) {
        const { id, channelId } = message;
        this.navigationHelper.navigateToChannelPostEditor(channelId, id);
        return [];
      } else {
        const { mediaEmbed, linkEmbed } = categorizeEmbeds(message.embeds);
        const draftMessage: DraftMessageEntity = {
          id: message.id,
          contextId: message.channelId,
          content: message.content,
          fileEmbed: mediaEmbed,
          linkEmbed,
          timestamp: message.timestamp,
          scheduled: message.scheduled
        };
        return [
          removeDraftChannelMessage({ channelId: message.channelId }),
          updateDraftChannelMessage({ message: draftMessage })
        ];
      }
    }),
  ));

  constructor(
    private readonly actions$: Actions,
    private readonly channelMessageComposer: ChannelMessageComposer,
    private readonly store: Store<RootState>,
    private readonly navigationHelper: WenNavigationHelper,
    private readonly featureEnablementService: FeatureEnablementService
  ) { }

}

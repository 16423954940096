import { MessageModificationState, PagingReplayDirection, ToRoomEventType } from '@portal/wen-backend-api';
import { WenSeparatorModel } from '@portal/wen-components';
import { MessageBoxComponent } from '../../../message-box/message-box.component';

export const SCROLLED_TO_BOTTOM_THRESHOLD = 10;

export type BaseMessageModel = {
  messageId: string;
  referenceId?: string;
  timestamp: string;
  updateTimestamp?: string;
};

export type MessageBoxModel = Pick<
  MessageBoxComponent,
  'sender' | 'content' | 'scheduled' | 'embeds' |
  'shareVisible' | 'commentsVisible' | 'commentsEnabled' | 'highlighted' |
  'wide' | 'disableEmojiReaction' | 'status' | 'modificationState' | 'textToSpeechEnabled' |
  'hideEmojiReactionPlaceholder'
> & {
  authorId: string;
  contentTemplateData?: any;
  currentUserUpdater?: boolean;
  state?: MessageModificationState;
  eventType?: ToRoomEventType;
  challengeEnabled?: boolean;
  disableFooterFunctions?: boolean;
  disableNavigationToSender?: boolean;
  avatarIcon?: string;
  isDraft?: boolean;
} & BaseMessageModel;

export interface FeedContextMenuEvent {
  messageBoxElement: HTMLElement;
  messageId: string;
  referenceId: string;
}

export enum WeFeedItemType {
  MESSAGEBOX = 'MESSAGEBOX',
  CUSTOM_ITEM_TYPE = 'CUSTOM_ITEM_TYPE',
  SEPARATOR = 'SEPARATOR'
}

export interface BaseWeFeedItem<TVALUE> {
  key: string;
  type: WeFeedItemType;
  value: TVALUE & Partial<BaseMessageModel>;
}

export interface WeFeedMessageItem extends BaseWeFeedItem<MessageBoxModel> {
  type: WeFeedItemType.MESSAGEBOX;
}

export interface WeFeedCustomItem<T = BaseMessageModel> extends BaseWeFeedItem<T> {
  type: WeFeedItemType.CUSTOM_ITEM_TYPE;
}

export interface WeFeedSeparatorItem extends BaseWeFeedItem<WenSeparatorModel> {
  type: WeFeedItemType.SEPARATOR;
}

export type WeFeedDataset = {
  newMessageLineItemId?: string;
  scrollReferenceItemId?: string;
  items: Array<WeFeedMessageItem | WeFeedCustomItem>;
  hasMoreOlder?: boolean;
};

export type WeFeedItemInternal = BaseWeFeedItem<any>;

export interface PageRequestEvent {
  direction?: PagingReplayDirection;
}

export const PAGE_LOAD_OFFSET_TRESHOLD_PX = 500;

<div class="wen-event-detail-layout" cdkScrollable>
  <ng-container *ngIf="headerData$ | async; let headerData">
    <wen-detail-header [model]="headerData" [coverImageStyle]="'square'" [shouldAnimate]="isMobileView" coverImageFitStyle="contain">
      <div wen-detail-header-title [class.wen-event-detail-header-has-title-icon]="headerData.prefixTitleIcon">
        <wen-icon class="wen-app-header-title-prefix-icon wen-icon-small" *ngIf="headerData.prefixTitleIcon" [iconName]="headerData.prefixTitleIcon"></wen-icon>
        <span portal-title>{{ headerData.title }}</span>
      </div>
      <wen-event-subscribe-button wen-detail-header-quick-actions [subscription]="currentEventSubscription$ | async"></wen-event-subscribe-button>
      <wen-share-button wen-detail-header-quick-actions [deepLink]="eventDeepLink" *ngIf="eventDeepLink"></wen-share-button>
      <wen-forward-button wen-detail-header-quick-actions [deepLink]="eventDeepLink" [context]="forwardContext" *ngIf="canForward$ | async"></wen-forward-button>
    </wen-detail-header>
  </ng-container>
  <ng-container *ngIf="currentEvent$ | async; let currentEvent">
    <div class="wen-box">
      <div>
        <div portal-caption>{{ "EVENT_DETAIL_TIME_AND_PLACE" | translate }}</div>
        <div class="wen-has-icon">
          <wen-icon iconName="event_checkmark" class="wen-icon-small"></wen-icon>
          <span portal-body  class="wen-one-line">
            {{ currentEvent.start | eventDate: currentEvent.end }}
          </span>
        </div>
        <div *ngIf="rruleText" portal-subheading-1 class="wen-align-as-has-icon">
          {{rruleText}}
        </div>
        <div *ngIf="currentEvent.locationContact?.name || currentEvent.locationContact?.addressLabel" class="wen-has-icon">
          <wen-icon iconName="location" class="wen-icon-small"></wen-icon>
          <span portal-body class="wen-one-line">
            {{
              currentEvent.locationContact?.name
              ? currentEvent.locationContact?.name
              : currentEvent.locationContact?.addressLabel
            }}
          </span>
        </div>
        <div *ngIf="currentEvent.locationContact?.name && currentEvent.locationContact?.addressLabel"
          portal-subheading-1
          class="wen-align-as-has-icon">
          {{ currentEvent.locationContact?.addressLabel }}
        </div>
        <div *ngIf="currentEvent.locationContact?.url" portal-body class="wen-wrap-text wen-align-as-has-icon">
          <a (click)="openUrl(currentEvent.locationContact?.url)">{{ currentEvent.locationContact?.url}}</a>
        </div>
        <iframe *ngIf="locationUrl$ | async" #iframe></iframe>
      </div>
    </div>
    <div class="wen-box">
      <div *ngIf="currentEvent?.description as description">
        <div portal-caption>{{ "EVENT_DETAIL_DESCRIPTION" | translate }}</div>
        <wen-expandable-container maxHeight="300">
          <div portal-body [innerHtml]="description"></div>
        </wen-expandable-container>
      </div>
      <div *ngIf="currentEvent?.categories as categories">
        <wen-read-categories-field
          [fieldLabel]="'EVENT_DETAIL_CATEGORIES' | translate"
          [categories]="categories">
        </wen-read-categories-field>
      </div>
      <div *ngIf="currentEvent?.url as url">
        <div portal-caption>{{ "EVENT_DETAIL_LINK" | translate }}</div>
        <div portal-body class="wen-wrap-text">
          <a (click)="openUrl(url)">{{ url }}</a>
        </div>
      </div>
      <div *ngIf="currentEvent?.organizerContact as organizer">
        <div portal-caption>{{ "EVENT_DETAIL_ORGANIZER" | translate }}</div>
        <div portal-body class="wen-one-line">{{ organizer.name }}</div>
        <div portal-body class="wen-wrap-text">
          <a (click)="openUrl(organizer.url)">{{ organizer.url }}</a>
        </div>
        <div portal-body class="event-organizer-contact-information wen-one-line" *ngIf="organizer.email as email">
          <span with-colon>{{ "EMAIL" | translate}}</span>
          <a (click)="mailTo(email)">{{ email }}</a>
        </div>
        <div portal-body class="event-organizer-contact-information wen-one-line" *ngIf="organizer.phone as phone">
          <span with-colon>{{ "PHONE" | translate }}</span>
          <a (click)="callPhoneNumber(phone)">{{ phone }}</a>
        </div>
        <div portal-body  class="wen-one-line">{{ organizer.addressLabel }}</div>
      </div>
    </div>
  </ng-container>
</div>

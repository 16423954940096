<div class="wen-extended-text-editor-content" [wenContentBoundary]>
  <textarea #textArea cdkTextareaAutosize [ngModel]="textContent" (input)="onTextContentInput()" [autocomplete]="'off'"
    cdkAutosizeMinRows="1" [placeholder]="inputPlaceholder"></textarea>
  <ng-container *ngIf="mediaData">
    <wen-media-preview class="wen-media-preview-geo" [mediaData]="mediaData" (dismiss)="handleMediaDismiss()"></wen-media-preview>
  </ng-container>
  <wen-link-embed-preview [orientation]="'vertical'" [previewContent]="linkData" [disabled]="isLinkPreviewDisabled"
    (previewChanged)="handleLinkEmbedChange($event)"></wen-link-embed-preview>
  <wen-location-preview [wenVariant]="'normal'" *ngIf="locationData" [iconName]="locationData | locationIconClassifier"
    [locationData]="locationData | locationFormatter" (dismiss)="handleLocationDismiss()"></wen-location-preview>
</div>
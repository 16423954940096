import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SocketIoService, MessageEvent } from '@portal/wen-backend-api';
import { defaultIfEmpty, filter, first, forkJoin, map, mergeAll, mergeMap, switchMap } from 'rxjs';
import { selectorWithParam } from '../../../common/util/selector-with-param';
import { DecryptedEventModifier } from '../../../services/chat/decryption/decrypted-event-modifier';
import { DecryptedMessageResult, MessageDecryptor } from '../../../services/chat/decryption/message-decryptor';
import { MessageEventDecryptor } from '../../../services/chat/decryption/message-event-decryptor';
import { isAutoReplyEvent, isRealtimeRedactEvent, isRealtimeUpdate } from '../../../services/chat/message-event/message-event-helper';
import { WenOAuthService } from '../../../services/user-management/wen-oauth.service';
import { removeChatMessagesNotification, upsertChatMessagesNotification } from '../../notification/notification.actions';
import { RootState } from '../../root/public-api';
import { upsertRoomMessagesHistory } from '../actions/chat-history.actions';
import { subscribeChatUpdates } from '../chat.actions';
import { onExchangedSessionsReceived } from '../key-actions';
import { ChatMessageOperations } from '../operations/chat-message-operations';
import { selectMessagesBySessionIdForRoom } from '../selectors/chat-message-selectors';
import { filterRoomEvents } from '../utils/chat-room-utils';

@Injectable()
export class MessageReceiveEffects {

  private readonly newChatMessage$ = this.socketIoService.chat.room.messages.listen;

  get userId() {
    return this.oAuthService.getUserData()?.userId;
  }

  get username() {
    return this.oAuthService.getUserData()?.username;
  }

  onMessageEvent$ = createEffect(() => this.actions$.pipe(
    ofType(subscribeChatUpdates),
    switchMap(() => this.newChatMessage$.pipe(
      mergeMap((messageEvent) => {
        const [encryptedMessages, plainMessages] = filterRoomEvents([messageEvent]);
        const filteredPlainMessages = plainMessages.filter(message => this.filterValidEvents(message));

        return this.messageEventDecryptor.decryptMessageEvents(encryptedMessages).pipe(
          map((decryptedMessages) => {
            const plainMessageEntities = this.decryptedEventModifier.toMessageEntities(plainMessages);
            const decryptedMessageEntities = this.decryptedEventModifier.toMessageEntities(decryptedMessages);
            const entities = [...decryptedMessageEntities, ...plainMessageEntities];

            const notificationMessages = decryptedMessages.length ? decryptedMessages : filteredPlainMessages;
            const notificationEntities = this.decryptedEventModifier.toNotificationEntities(notificationMessages);
            if (notificationEntities.length > 0) {
              this.store.dispatch(upsertChatMessagesNotification({ eventEntity: notificationEntities[0] }));
            }

            return entities;
          }),
          filter(entityArr => entityArr?.length > 0),
          map((entityArr) => {
            if (isRealtimeUpdate(messageEvent)) {
              this.store.dispatch(removeChatMessagesNotification({
                eventId: messageEvent.eventId,
                roomId: messageEvent.roomId,
                messageTimestamp: messageEvent.insertTimestamp
              }));
              return this.chatMessageOperations.updateMany(messageEvent.roomId, entityArr, null);
            }
            return this.chatMessageOperations.upsertOne(messageEvent.roomId, entityArr[0]);
          })
        );
      })
    ))
  ));

  onExchangedSessionsReceived$ = createEffect(() => this.actions$.pipe(
    ofType(onExchangedSessionsReceived),
    mergeMap(({ sessionsByRoom }) => {
      const exchangeActions$ = Object.entries(sessionsByRoom).map(([roomId, exchangeSessions]) => {
        const sessionIds = exchangeSessions.map(({ sessionId }) => sessionId);
        const decryptionsForRoom$ = this.store.pipe(
          selectorWithParam(selectMessagesBySessionIdForRoom, roomId, sessionIds),
          first(),
          mergeMap((messages) => {
            const messagesDecryption$ = messages.map(message => {
              const { eventId, encryptionData: { encryptedMessage }, new: isNew, insertTimestamp, insertUser } = message;
              return this.messageDecryptor.decryptExchangedRoomMessage({
                eventId, encryptedMessage, insertTimestamp, isNew, insertUser
              });
            });
            return forkJoin(messagesDecryption$).pipe(
              defaultIfEmpty([] as DecryptedMessageResult[])
            );
          }),
          map((decryptionResults) => {
            if (!decryptionResults?.length) {
              return null;
            }
            const entities = decryptionResults.map(decryptionResult => decryptionResult.asChatStoreEntity());
            return upsertRoomMessagesHistory({ roomId, messages: entities, flags: null });
          })
        );
        return decryptionsForRoom$.pipe(
          defaultIfEmpty([]),
        );
      });
      const exchangeUpdates$ = forkJoin(exchangeActions$).pipe(
        defaultIfEmpty([]),
        mergeAll(),
        filter(action => Boolean(action))
      );
      return exchangeUpdates$;
    })
  ));

  private filterValidEvents(event: MessageEvent) {
    return isAutoReplyEvent(event) || isRealtimeRedactEvent(event);
  }

  constructor(
    private actions$: Actions,
    private socketIoService: SocketIoService,
    private store: Store<RootState>,
    private oAuthService: WenOAuthService,
    private messageDecryptor: MessageDecryptor,
    private messageEventDecryptor: MessageEventDecryptor,
    private chatMessageOperations: ChatMessageOperations,
    private decryptedEventModifier: DecryptedEventModifier
  ) {
  }

}

<ng-container *ngIf="sectionItems$ | async as sectionItems">
  <wen-section *ngIf="sectionItems.length" [isNativeApp]="isNativeApp">
    <div *ngIf="sectionName" section-header-title>{{ sectionName | translate }}</div>
    <div class="wen-section-show-all-title" section-header-action (click)="showAllElements()">{{ 'SHOW_ALL_LABEL' | translate }}</div>
    <wen-card *ngFor="let item of sectionItems" [direction]="'column'" [elevate]="true"
      (click)="navigateToDetail(item.id)" section-content>
      <wen-circle-image-view [imageUrl]="item.imageSmall" shape="square" [placeholderText]="item.caption"
        card-image></wen-circle-image-view>
      <div card-title>{{ item.caption }}</div>
      <div card-description>{{ item.start | eventDate: item?.end }}</div>
      <wen-quick-subscription-panel card-floating-panel class="wen-event-card-subscription-counter"
        [eventId]="item.id"></wen-quick-subscription-panel>
    </wen-card>
    <wen-card *ngIf="sectionItems.length === sectionLimit" class="last-card" [elevate]="true" section-content (click)="showAllElements()">
      <div card-title>
        <span wen-primary-color>{{ 'SHOW_ALL_LABEL' | translate }}</span>
      </div>
    </wen-card>
  </wen-section>
</ng-container>

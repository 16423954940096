import player from 'lottie-web';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CoreComponentsModule, LoadingIndicatorModule, VideoPlayerComponent, WenIconModule, MiddleEllipsizeModule, FileSizeModule } from '@portal/wen-components';
import { LottieModule } from 'ngx-lottie';
import { LazyLoadModule } from '../../../../../directives/lazy-load/lazy-load.module';
import { ScaleToWidthModule } from '../../../../../directives/scale-to-width/scale-to-width.module';
import { EmbedMediaComponent } from './embed-media.component';
import { EmbedVideoComponent } from './components/embed-video/embed-video.component';
import { EmbedImageComponent } from './components/embed-image/embed-image.component';
import { EmbedAudioComponent } from './components/embed-audio/embed-audio.component';
import { EmbedUploadComponent } from './components/embed-upload/embed-upload.component';
import { LightboxService } from './providers/lightbox.service';
import { ThumbnailEvaluatorDirective } from './directives/thumbnail-evaluator.directive';
import { RetryImageLoadDirective } from './directives/retry-image-load.directive';
import { EmbedErrorComponent } from './components/embed-error/embed-error.component';
import { EmbedFileComponent } from './components/embed-file/embed-file.component';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    ScaleToWidthModule,
    LottieModule.forRoot({
      player: playerFactory
    }),
    LazyLoadModule,
    TranslateModule.forChild(),
    CoreComponentsModule,
    VideoPlayerComponent,
    WenIconModule,
    LoadingIndicatorModule,
    MiddleEllipsizeModule,
    FileSizeModule
  ],
  exports: [EmbedMediaComponent],
  declarations: [
    EmbedMediaComponent,
    EmbedImageComponent,
    EmbedVideoComponent,
    EmbedAudioComponent,
    EmbedUploadComponent,
    EmbedErrorComponent,
    EmbedFileComponent,
    ThumbnailEvaluatorDirective,
    RetryImageLoadDirective
  ],
  providers: [LightboxService],
})
export class EmbedMediaModule { }

<div class="wen-channel-view-desktop-header-content" *ngIf="currentChannel$ | async; let currentChannel" (click)="openDetail(currentChannel)">
  <wen-active-item-image-view [overlaySize]="'medium'"></wen-active-item-image-view>
  <div class="wen-channel-view-desktop-header-info">
    <div class="wen-channel-view-desktop-header-info-title">
      <wen-icon class="wen-icon-small" *ngIf="currentChannel.isPrivate && !currentChannel.subscribed" [iconName]="'lock'" [color]="'#767676'"></wen-icon>
      <div portal-title>{{ currentChannel.title }}</div>
      <wen-icon class="wen-icon-small" *ngIf="currentChannel.isMuted" iconName="mute" [color]="'#767676'"></wen-icon>
    </div>
    <div *ngIf="currentChannel.channelType !== channelType.PERSONAL" portal-body-2>{{ currentChannel.subscriptions }} {{'SUBSCRIBER' | translate: {count: currentChannel.subscriptions} }}
    </div>
  </div>
</div>

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppConfig, BACKEND_API_CONFIG, BackendApiConfig, FeaturesConfig, WenNetwork } from '@portal/wen-backend-api';
import { smartDistinctUntilChanged } from '@portal/wen-components';
import { Observable, Subject, map, of, takeUntil } from 'rxjs';
import { APP_CONFIG, DEBUG_OPTIONS_CONFIG, DebugOptions } from '../../../frame/api/tokens';
import { FeatureConfigsAsMethods, defaultConfig, functionize } from '../../common/util/feature-flag-functionize';
import { RootState } from '../../store/root/public-api';
import { selectFeaturesConfigState } from '../../store/ui/ui.selectors';
import { ObservableAppProxy, ObservableFeaturesProxy, createConfigProxy } from './features-proxy';

@Injectable()
export class FeatureEnablementService implements OnDestroy {

  private onDestroy$ = new Subject<void>();
  private readonly featureProxy: ObservableFeaturesProxy;
  private readonly appProxy: ObservableAppProxy;
  private readonly featureFlags$: Observable<FeaturesConfig>;
  private featureFlagsAsMethods: FeatureConfigsAsMethods;

  constructor(
    @Inject(APP_CONFIG) private appConfig: AppConfig,
    @Inject(BACKEND_API_CONFIG) private apiConfig: BackendApiConfig,
    @Inject(DEBUG_OPTIONS_CONFIG) private debugOptions: DebugOptions,
    private store: Store<RootState>
  ) {
    const featureflagsState$ = this.store.pipe(
      select(selectFeaturesConfigState),
      map((config) => {
        if (Object.keys(config).length === 0) {
          return defaultConfig;
        }
        return {
          ...defaultConfig,
          ...config
        };
      }),
      takeUntil(this.onDestroy$)
    );
    this.featureFlags$ = featureflagsState$;
    featureflagsState$.pipe(
      smartDistinctUntilChanged(),
      map((config) => functionize(config))
    ).subscribe(functionizedConfig => {
      this.featureFlagsAsMethods = functionizedConfig;
    });
    this.featureProxy = createConfigProxy(this.featureFlags$);
    this.appProxy = createConfigProxy(of(this.appConfig));
  }

  get featureConfigObs() {
    return this.featureProxy;
  }

  get appConfigObs() {
    return this.appProxy;
  }

  get featureFlagMethods() {
    return this.featureFlagsAsMethods;
  }

  isChatEnabled() {
    return this.appConfig.enableChat;
  }

  isChatDebugModeEnabled() {
    return this.appConfig.enableChatDebugMode;
  }

  isPartnerApp() {
    return !this.apiConfig.network.includes(WenNetwork.WE_NETWORK);
  }

  isPwaEnabled() {
    return this.appConfig.enablePwa;
  }

  isAutoRefreshByAssetChangesEnabled() {
    return this.debugOptions?.enableAutoRefreshByAssetChanges ?? false;
  }

  isAgbEnabled() {
    return this.appConfig.enableAgb;
  }

  isInviteOnlyNetworkEnabled() {
    return this.appConfig.enableInviteOnlyNetwork;
  }

  isInviteToNetworkEnabled() {
    return this.appConfig.enableInviteToNetwork;
  }

  isAppBannerEnabled() {
    return this.appConfig.enableAppBanner;
  }

  isEnableShareMenu() {
    return this.appConfig.enableShareMenu;
  }

  isEnableCRMDistributionCancelling() {
    return this.appConfig.enableCRMDistributionCancelling;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

}

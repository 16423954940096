import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageModificationState } from '@portal/wen-backend-api';
import { MessageBoxModel } from '../../../../../../../shared/components/feed/components/we-feed/we-feed-model';

@Component({
  selector: 'wen-chat-message-content',
  templateUrl: './chat-message-content.component.html'
})
export class ChatMessageContentComponent {

  messageState = MessageModificationState;
  @Input() message: MessageBoxModel;

  @Output() requestShareKey = new EventEmitter<void>();

  onRequestShareKeyClicked() {
    this.requestShareKey.next();
  }

}

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EmbedDTOType, EmbedDTOTypes, EmbeddedForwardDTO, EmbeddedMediaDTO, ForwardData } from '@portal/wen-backend-api';
import { DateTime, Duration } from 'luxon';
import { Observable, combineLatest, map, of, shareReplay } from 'rxjs';
import { DateUtil } from '../../common/date/date-util';
import { EmbeddedMedia } from '../../store/channel/channel.state';
import { isForwardEmbed, isMediaEmbed } from '../../store/channel/util/embed-type-helper';
import { updateChatMessageMediaByEmbedData } from '../../store/chat/chat.actions';
import { ChatMessageEntity, ChatScheduledMessageEntity } from '../../store/chat/chat.state';
import { RootState } from '../../store/root/public-api';
import { MediaRetriever } from './media-retriever';

@Injectable()
export class ChatMessageEmbedResolver {

  private mediaEmbedCache = new Map<string, Observable<EmbeddedMedia>>();

  constructor(
    private mediaRetriever: MediaRetriever,
    private store: Store<RootState>,
  ) { }

  resolveEmbeds<EntityType extends ChatMessageEntity | ChatScheduledMessageEntity>(messages: EntityType[]): Observable<EntityType>[] {
    return messages.map(message => {
      return this.resolveEmbed(message.eventId, message?.messageContent?.embeds, message.insertTimestamp).pipe(
        map(embeds => {
          const updatedMessage: EntityType = { ...message, messageContent: { ...message.messageContent, embeds } };
          return updatedMessage;
        })
      );
    });
  }

  private resolveEmbed(eventId: string, embeds: EmbedDTOTypes[], timestamp?: string): Observable<EmbedDTOTypes[]> {
    if (!embeds?.length) {
      return of([]);
    }

    const resolvedEmbeds$ = embeds.map(embed => {
      const isNormalMediaEmbed = isMediaEmbed(embed);
      if (isNormalMediaEmbed && !embed.playable) {
        return this.fetchMedia(eventId, embed, timestamp);
      }
      if (isForwardEmbed(embed)) {
        const forwardedMedia = embed.forwardData.data.embeds?.find(isMediaEmbed);
        if (forwardedMedia && !forwardedMedia.playable) {
          return this.fetchMedia(eventId, forwardedMedia, timestamp).pipe(
            map(resolvedEmbed => this.updateMediaEmbedInForwardEmbed(embed, resolvedEmbed))
          );
        }
      }
      return of(embed);
    });

    return combineLatest(resolvedEmbeds$);
  }

  private fetchMedia(eventId: string, mediaEmbed: EmbeddedMedia, timestamp?: string): Observable<EmbeddedMedia> {
    if (!this.mediaEmbedCache.has(eventId)) {
      const resolvedMediaEmbed$ = this.mediaRetriever.getMedia(mediaEmbed, eventId).pipe(
        map(media => {
          if (media.errorMessage) {
            if (media.errorCode === 'MEDIA_NOT_FOUND') {
              return this.tryToIgnoreErrorsForNewMessage(mediaEmbed, timestamp);
            }
            return this.createErrorEmbed(mediaEmbed, 'UNKNOWN');
          }
          this.store.dispatch(updateChatMessageMediaByEmbedData({ mediaEmbed: media }));
          return media;
        }),
        shareReplay(1)
      );
      this.mediaEmbedCache.set(eventId, resolvedMediaEmbed$);
    }
    return this.mediaEmbedCache.get(eventId);
  }

  private updateMediaEmbedInForwardEmbed(forwardEmbed: EmbeddedForwardDTO, embedResult: EmbeddedMedia): EmbeddedForwardDTO {
    const forwardData = forwardEmbed.forwardData;
    const updatedForwardData: ForwardData = {
      ...forwardData,
      data: {
        ...forwardData.data,
        embeds: [embedResult]
      }
    };
    return {
      ...forwardEmbed,
      forwardData: updatedForwardData
    };
  }

  /**
   * When a media message is sent, the receiver receives the message instantly, even before the media is fully uploaded.
   * If a MEDIA_NOT_FOUND error occurs on the receiver's side, the client cannot determine if the media upload has failed
   * or if it is still in progress. Therefore, we ignore the MEDIA_NOT_FOUND error for "new" messages.
   *  */
  private tryToIgnoreErrorsForNewMessage(embed: EmbeddedMediaDTO, timestamp: string) {
    const messageSentTime = DateUtil.fromIsoString(timestamp);
    const now = DateTime.now();
    const messageAge = now.diff(messageSentTime);
    const isNewMessage = messageAge < Duration.fromMillis(600000);
    return isNewMessage ? this.createErrorEmbed(embed, null) : this.createErrorEmbed(embed, 'MEDIA_NOT_FOUND');
  }

  createErrorEmbed(embed: EmbeddedMediaDTO, errorCode: string): EmbeddedMedia {
    return {
      id: null,
      uploadId: embed.uploadId,
      type: EmbedDTOType.MEDIA,
      subType: embed.subType,
      error: !!errorCode,
      errorCode,
      uploaded: false,
      playable: false,
      fullyProcessed: false
    } as EmbeddedMedia;
  }
}

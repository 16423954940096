import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../../shared/shared.module';
import { ChannelMessagesViewComponent } from './channel-messages-view.component';
import { MessageSeparatorModule } from '../../../../../shared/directives/directives/message-separator.module';
import { ObserveVisibilityModule } from '../../../../../shared/directives/message-visibility/observe-visibility.module';
import { FeedModule } from '../../../../../shared/components/feed/feed-module';
import { ActionMenuModule, ACTION_MENU_ITEMS, EmojiReactionsModule } from '@portal/wen-components';
import { MESSAGE_BOX_CONTEXT_MENU_ITEMS } from '../../../../../shared/components/message-box/actions/message-box.action-menu';
import { VisibilityForActionMenuPipe } from '../../../../../shared/pipes/visibility-for-action-menu.pipe';

@NgModule({
  imports: [
    SharedModule,
    ScrollingModule,
    MessageSeparatorModule,
    ObserveVisibilityModule,
    FeedModule,
    EmojiReactionsModule,
    ActionMenuModule,
    VisibilityForActionMenuPipe

  ],
  declarations: [
    ChannelMessagesViewComponent,
  ],
  providers: [
    { provide: ACTION_MENU_ITEMS, useValue: MESSAGE_BOX_CONTEXT_MENU_ITEMS, multi: true }
  ],
  exports: [
    ChannelMessagesViewComponent,
    FeedModule,
  ],
})
export class ChannelMessagesViewModule { }

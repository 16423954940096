<div class="wen-search-bar-desktop-container">
  <wen-search-input [inputValue]="searchTerm$ | async" (changed)="onChange($event)" (cleared)="onClear()"
    [placeholder]="searchBarPlaceholder | translate" [disabled]="disabled" (focused)="onSearchInputFocused()"></wen-search-input>
  <wen-icon *ngIf="canCreateChannel$ | async" [iconName]="'add'" (click)="navigateToChannelAdd()"></wen-icon>
  <wen-icon *ngIf="canCreateApp$ | async" [iconName]="'add'" (click)="navigateToAppAdd()"></wen-icon>
  <div class="wen-chat-create-wrapper" *ngIf="canCreateChat$ | async">
    <wen-header-command *ngIf="hasGroupChatEnabled; else directDialog" [commandData]="rightCommand"></wen-header-command>
    <ng-template #directDialog>
      <wen-icon [iconName]="'add'" (click)="navigateToNewChat()"></wen-icon>
    </ng-template>
  </div>
</div>
<ng-container *ngIf="sectionItems$ | async as sectionItems">
  <wen-section *ngIf="sectionItems.length" [isNativeApp]="isNativeApp">
    <div *ngIf="sectionName" section-header-title>{{ sectionName | translate }}</div>
    <div class="wen-section-show-all-title" section-header-action (click)="showAllElements()">{{ 'SHOW_ALL_LABEL' |
      translate }}</div>
    <wen-card class="wen-card-narrow" 
      *ngFor="let item of sectionItems; trackBy:trackById" [direction]="'column'" [elevate]="true"
      (click)="navigateToChannelView(item.id)" section-content 
      [wen-test-id]="'channel-explore-card'">
      <wen-circle-image-view [imageUrl]="item.icon" [placeholderText]="item.title"
        [imageSize]="imageSize" card-image></wen-circle-image-view>
      <div card-title>{{item.title}}</div>
      <div card-description>{{ item.subscriptions | thousandFormat }} {{ 'SUBSCRIBER' | translate: {count: item.subscriptions} }}</div>
      <wen-quick-action-panel
        class="wen-icon-medium"
        [iconColor]="item.iconColor"
        [iconName]="item.iconName"
        (quickActionTriggered)="onQuickActionClick(item)"
        card-floating-panel>
      </wen-quick-action-panel>
      <wen-channel-indicator *ngIf="item.readingContent || item.geo" [indicatorType]="getIndicatorType(item)" card-indicator></wen-channel-indicator>
    </wen-card>
    <wen-card *ngIf="sectionItems.length === sectionLimit" class="last-card wen-card-narrow" [elevate]="true" section-content
      (click)="showAllElements()">
      <div card-title>
        <span wen-primary-color>{{ 'SHOW_ALL_LABEL' | translate }}</span>
      </div>
    </wen-card>
  </wen-section>
</ng-container>

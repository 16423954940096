import { WenRoutes } from '../../../frame/routing/types';
import { licensesRoute, otherProfileViewRoute, userProfileChangeEmailRoute, userProfileComingSoonRoute, userProfileDashboardRoute, userProfileEditRoute, userProfileEmailSettingsRoute, userProfileManifestRoute, userProfileFAQRoute, userProfileReadRoute, userProfileSettingsRoute, userProfileCredentialsMenuRoute, userProfileCredentialDetails, userProfileViewRoute, userProfileHelpRoute, userProfileMarkdownFAQRoute, userProfileHotlineRoute, userProfileLanguageRoute, userProfilePrivacySettingsRoute } from './user-profile.routes';

export const dialogOutletProfileRoutes: WenRoutes = [
  userProfileReadRoute,
  userProfileEditRoute,
  userProfileLanguageRoute,
  userProfileEmailSettingsRoute,
  userProfileChangeEmailRoute,
  userProfileSettingsRoute,
  userProfileCredentialsMenuRoute,
  otherProfileViewRoute,
  userProfileCredentialDetails,
  userProfilePrivacySettingsRoute,
];

export const sidebarOutletUserProfileRoutes: WenRoutes = [
  userProfileHelpRoute,
  userProfileHotlineRoute,
  userProfileViewRoute,
  {
    path: '**',
    redirectTo: ''
  }
];

export const primaryOutletUserProfileRoutes: WenRoutes = [
  userProfileDashboardRoute,
  userProfileManifestRoute,
  userProfileFAQRoute,
  userProfileMarkdownFAQRoute,
  userProfileComingSoonRoute,
  licensesRoute,
  {
    path: '**',
    redirectTo: '/home/dashboard(sidebar:profile)'
  }
];

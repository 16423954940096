<div *ngIf="searchBarPlaceholder" class="wen-search-bar-search-field">
  <mat-form-field floatLabel="never">
    <wen-icon iconName="search" matIconPrefix></wen-icon>
    <input #searchInput
      portal-body-1
      matInput
      autocomplete="off"
      type="search"
      placeholder="{{ searchBarPlaceholder }}"
      [(ngModel)]="inputValue"
      (ngModelChange)="onChange($event)"
      (focus)="onFocus()"/>
    <wen-icon matIconSuffix [style.display]="clearButtonVisibility" iconName="close" (click)="onClear()"></wen-icon>
  </mat-form-field>
</div>
<div class="wen-search-bar-cancel-search">
  <div portal-body-2 (click)="onCancel()">{{ 'CANCEL_BUTTON_LABEL' | translate }}</div>
</div>

import { EntryId, UserProfileMenuEntry } from '../../user-profile-view-types';
import { ColorPalette } from '../../../../../../../shared/directives/colorize/palette-provider';
import { PermissionLevel } from '../../../../../../../core/services/user-management/permission-level';

export const faq: UserProfileMenuEntry = {
  entryId: EntryId.FAQ,
  iconName: 'help', translationKey: 'USER_PROFILE_FAQ',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
export const markdown: UserProfileMenuEntry = {
  entryId: EntryId.MARKDOWN,
  iconName: 'edit', translationKey: 'USER_PROFILE_MARKDOWN',
  fillColor: ColorPalette.LIGHT_BLUE, requiredPermission: PermissionLevel.ANONYMOUS
};
